import { AxiosResponse } from "axios";

interface EnvParams {
    clientId?: string;
    systemUrl?: string;
    blockSubtasks?: string;
    blockArchive?: string;
    socketUrl?: string;
    blockProjectReports?: string;

}

export class DataStore {
    constructor(axiosResp: AxiosResponse<any, any>) {
        if (axiosResp) {
            let resp: EnvParams = axiosResp.data;
            this.OAUTH_CLIENT_ID = resp.clientId;
            this.EXTERNAL_URL = resp.systemUrl;
            this.BLOCK_SUBTASKS = resp.blockSubtasks === "true";
            this.BLOCK_ARCHIVE = resp.blockArchive === "true";
            this.SOCKET_URL = resp.socketUrl;
            this.BLOCK_PROJECT_REPORTS = resp.blockProjectReports === "true";

        }
    }
    OAUTH_CLIENT_ID?: string = "";
    EXTERNAL_URL?: string = "";
    BLOCK_SUBTASKS: boolean = false;
    BLOCK_ARCHIVE: boolean = false;
    SOCKET_URL?: string = "";
    BLOCK_PROJECT_REPORTS:boolean = false;
}
