import {IEditUserInfo, IFullUserInfo, ISustemPerson, IUser, IUserWithStatus} from "../models/IUser";
import {makeAutoObservable, set} from "mobx";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import ProjectService from "../services/ProjectService";
import DashBoardService from "../services/DashBoardService";
import {ICardProject, IProject, IProjects} from "../models/IProject";
import {IDashBoard} from "../models/IDashBoard";
import {IColumnDisplay} from "../models/IColumnDisplay";
import {ITask, ITaskGeneralInfo} from "../models/ITask";
import TaskService from "../services/TaskService";
import FileService from "../services/FileService";
import {FileResponse} from "../models/response/FileResponce";
import CommonService from "../services/CommonService";
import {ProjectForMenu} from "../models/response/LeftMenuResponse";
import {
    IGetTaskHistoryDisplay,
    IGetTasksDisplayData,
    // ITaskChecklist,
    ITaskMonitorFilters,
    ITasksDisplay,
    ITasksDisplayFilters,
} from "../models/TaskModels";
import {
    IGetProjectHistoryDisplay,
    IProjectEditReq,
    IProjectGeneralInfo,
    IProjectProgressInfo,
    IProjectDeleteReq, IProjectCreateReq,
    IProjectHistoryDisplay, IProjectUnarchiveReq,
} from "../models/ProjectModels";
import {IGetTeamDisplay, ITeamDisplay} from "../models/TeamModels";
import {
    IFileFilterOptions,
    IFilesDisplay,
    IGetFilesDisplayData,
    IGetFilesTaskDisplayData,
} from "../models/FileModels";
import {IMoveTaskBoardData, IMoveTaskData, IMoveTasksData, IMoveTasksOutsideData} from "../models/IMoveTaskData";
import {IDeleteTasks} from "../models/IDeleteTasks";
import TeamService from "../services/TeamService";
import {IAddTeamDisplay, IDeleteTeamDisplay} from "../models/TeamModels";
import {ICreateTegTask} from "../models/ICreateTegTask";
import FilterService from "../services/FilterDataService";
import {IIParametrsFilter, SelectedOptions} from "../models/IFilterModel";
import {ITaskCreate} from "../models/ITaskCreate";
import {IBreadCrumb} from "../models/IBreadCrumb";
import {ITag} from "../models/ITag";
import {ITaskEdit} from "../models/ITaskEdit";
import {IMessageGroup} from "../models/IChatMessage";
import {ITagFromDisplay, ITaskFormDisplay} from "../models/ITaskFormDisplay";
import {IColumnEdit} from "../models/IColumnEdit";
import ColumnService from "../services/ColumnService";
import {IMakeColumnProxy} from "../models/IMakeColumnProxy";
import {IMakeColumnCommon} from "../models/IMakeColumnCommon";
import {IDeleteColumn} from "../models/IDeleteColumn";
import {IUpdateTegTask} from "../models/IUpdateTegTask";
import {IUpdateTypeTask} from "../models/IUpdateTypeTask";
import {IDeleteBoard} from "../models/IDeleteBoard";
import {IAddColumn} from "../models/IAddColumn";
import {IBoardEdit} from "../models/IBoardEdit";
import {IMoveColumn} from "../models/IMoveColumn";
import {IRecoverPassword} from "../models/IRecoverPassword";
import {IFeedback} from "../models/IFeedback";
import {IGetTaskRequest} from "../models/IGetTaskRequest";
import {ITypeAndPriority} from "../models/ITypeAndPriority";
import {IEditElemSettings, IElemSettings} from "../models/IElemSettings";
import {IGetRole} from "../models/IGetRole";
import {IGetOneRole} from "../models/IGetOneRole";
import {IEditRole} from "../models/IEditRole";
import {IGetAllAvailablePermissions, IRoleFunctionGroups} from "../models/IGetAllAvailablePermissions";
import {ICreateRole} from "../models/ICreateRole";
import {IGetAllUsersWithSystemRoles} from "../models/IGetAllUsersWithSystemRoles";
import {IGetAllRoles} from "../models/IGetAllRoles";
import {IEditUser} from "../models/IEditUser";
import {ISystemDeletePerson} from "../models/ISystemDeletePerson";
import {ISystemInvitePerson} from "../models/ISystemInvitePerson";
import {ISideBar} from "../models/LeftMenuModels";
import UserMonitorService from "../services/UserMonitorService";
import {ISaveExcludePermission} from "../models/ISaveExcludePermission";
import {IRestrictAccess, IRestrictAccessBoardsUpdate} from "../models/IRestrictAccess";
import {IUserMonitorGetTasksRequest} from "../models/IUserMonitorTaskFullInfo";
import {ICheckRole, IHasPermission} from "../models/IChekRole";
import {IUserStatus} from "../models/response/IUserStatusResponse";
import {IExcludePermissionFunction} from "../models/IExcludePermissionFunction";
import {IChangePassword} from "../models/IChangePassword";
import {IEditPersonalSettingsRequest, IPersonalSettings, PersonalSettingsOptions} from "../models/PersonalSettings";
import {ITaskChecklistGroup} from "../models/ITaskChecklistGroup";
import {ITaskChecklistGroupEdit} from "../models/ITaskChecklistGroupEdit";
import {ITaskChecklist} from "../models/ITaskChecklist";
import {ITaskChecklistEdit} from "../models/ITaskChecklistEdit";
import { DataStore } from "../DataStore";
import $api from "..";
import {IProjectMinimal} from "../models/IProjectMinimal";
import {ISubTask, ISubTaskView} from "../models/ISubTask";
import {ISetTaskParent} from "../models/ISetTaskParent";
import ChatService from "../services/ChatService";
import {IViewBasket} from "../models/IViewBasket";
import {IViewBasketResp} from "../models/IViewBasketResp";
import BasketService from "../services/BasketService";
import NotificationService from "../services/NotificationService";
import {SearchService} from "../services/SearchService";
import {IBurnDownInfo, IBurnDownRequest} from "../component/GraphRenderer/GraphTypes";
import StatisticsService from "../services/StatisticsService";
import { IBoardSavedFilters } from "../models/IBoardSavedFilters";
import { ICreateTeg } from "../models/ICreateTeg";

export default class Store {

    constructor() {
        makeAutoObservable(this);
        // Если в localStorage есть информация о польозвателе, то флаги выставляются true,
        // если сессия устарела, или отсутствовала вовсе, то после первого запроса к серверу флаг isAuth
        // будет выставлен на false
        const userString = localStorage.getItem("user");
        const user = userString ? JSON.parse(userString) as IFullUserInfo : null;
        if (user) {
            this.isAuth = true;
            this.isActivate = user.isActivated;
            this.setUser(user);
        }
        this.userMonitorShowFavourite = localStorage.getItem("user-monitor-show-favourite") === "true";
        this.boardsSavedFilters = JSON.parse(localStorage.getItem("boards-filters") ?? "[]");
        this.leftMenuCollapsed = localStorage.getItem("left-menu-collapsed") === "true"
    }


    // #region ENV params
    loadEnvParams(){
        $api.get('/System/getParams').then(x =>{
            let dataStorage = new DataStore(x);
            this.OAUTH_CLIENT_ID = dataStorage.OAUTH_CLIENT_ID ?? '';
            this.EXT_URL = dataStorage.EXTERNAL_URL ?? '';
            this.BLOCK_SUBTASKS = dataStorage.BLOCK_SUBTASKS;
            this.BLOCK_PROJECT_REPORTS = dataStorage.BLOCK_PROJECT_REPORTS;
            this.BLOCK_ARCHIVE = dataStorage.BLOCK_ARCHIVE;
            this.SOCKET_URL = dataStorage.SOCKET_URL ?? '';
        })
    }

    private OAUTH_CLIENT_ID: string = '';
    private EXT_URL: string = '';
    private BLOCK_SUBTASKS: boolean = false;
    private BLOCK_PROJECT_REPORTS: boolean = false;
    private BLOCK_ARCHIVE: boolean = false;
    private SOCKET_URL: string = "";

    getSocketUrl(): string {
        return this.SOCKET_URL
    }

    getOAuthId(): string {
        return this.OAUTH_CLIENT_ID;
    }

    getExtUrl(): string {
        return this.EXT_URL;
    }

    getBlockSubtasks(): boolean {
        return this.BLOCK_SUBTASKS;
    }

    getBlockArchive(): boolean {
        return this.BLOCK_ARCHIVE;
    }

    getBlockProjectReports(): boolean {
        return this.BLOCK_PROJECT_REPORTS;
    }

    // #endregion Env params

    // #region Экземпляры сервисов

    authService = new AuthService();
    basketService = new BasketService();
    chatService = new ChatService();
    columnService = new ColumnService();
    commonService = new CommonService();
    dashBoardService = new DashBoardService();
    fileService = new FileService();
    filterService = new FilterService();
    projectService = new ProjectService();
    taskService = new TaskService();
    teamService = new TeamService();
    userMonitorService = new UserMonitorService();
    userService = new UserService();
    notificationService = new NotificationService();
    searchService = new SearchService();


    // #endregion Экземпляры сервисов
    // #region Информация, хранящаяся в localStorage
    user: IFullUserInfo = {} as IFullUserInfo;

    setUser(user: IFullUserInfo) {
        this.user = user;
        localStorage.setItem("user", JSON.stringify(user));
    }

    userMonitorShowFavourite: boolean = false;

    setUserMonitorShowFavourite(flag: boolean) {
        this.userMonitorShowFavourite = flag;
        localStorage.setItem("user-monitor-show-favourite", JSON.stringify(flag));
    }

    boardsSavedFilters: IBoardSavedFilters[] = []

    getBoardSavedFilters(boardId: number): SelectedOptions {
        const setting = this.personalSettings.find(p => p.paramName === PersonalSettingsOptions.saveBoardFilters);
        if (!setting || setting.value) { //Если нет записи об этой настройке или если есть запись и значение "true"
            const foundFilters = this.boardsSavedFilters.find(f => f.boardId === boardId);
            return foundFilters?.selectedFilters ?? {}
        } else {
            return {}
        }
    }

    setBoardsSavedFilters(boardId: number, options: SelectedOptions) {
        const foundFilters = this.boardsSavedFilters.find(f => f.boardId === boardId);
        if (foundFilters)
            foundFilters.selectedFilters = options;
        else
            this.boardsSavedFilters.push({
                boardId: boardId,
                selectedFilters: options
            })
        localStorage.setItem("boards-filters", JSON.stringify(this.boardsSavedFilters));
    }

    leftMenuCollapsed: boolean = false;

    setLeftMenuCollapsed(collapsed: boolean) {
        this.leftMenuCollapsed = collapsed;
        localStorage.setItem("left-menu-collapsed", JSON.stringify(collapsed));
    }

    // #endregion Информация, хранящаяся в localStorage

    personalSettings: IPersonalSettings[] = [];

    setPersonalSettings(settings: IPersonalSettings[]) {
        this.personalSettings = settings;
    }

    deletePersons: boolean = true;

    setDeletePersons(set: boolean) {
        this.deletePersons = set;
    }

    userWithStatus: IUserWithStatus = {} as IUserWithStatus;

    setUserWithStatus(userWithStatus: IUserWithStatus) {
        this.userWithStatus = userWithStatus;
    }

    currentProjectId: number = 0;

    setCurrentProjectId(val: number) {
        this.currentProjectId = val;
    }

    currentBoardId: number = 0;

    setCurrentBoard(val: number) {
        this.currentBoardId = val;
    }

    currentBoardData: IBreadCrumb = {} as IBreadCrumb;

    setCurrentBoardData(val: IBreadCrumb) {
        this.currentBoardData = val;
    }

    project: IProject = {} as IProject;
    projects: ICardProject[] = [];

    dashBoards: IDashBoard[] = [];

    columns: IColumnDisplay[] = [];

    tasks: ITask[] = [];

    isAuth: boolean = false;
    isActivate: boolean = false;
    isDrag: boolean = true;

    //Настройки


    setDrag(drag: boolean) {
        this.isDrag = drag;
    }

    linkNewPerson: string = "";

    setLinkNewPerson(saveLinkNewPerson: string) {
        this.linkNewPerson = saveLinkNewPerson;
    }

    isTaskUpdate: string = "";

    files: FileResponse[] = [];

    tree: ProjectForMenu = {nodes: []};

    sideBar: ISideBar = {projects: []}

    updateFile: boolean = false;

    systemSettings: IElemSettings[] = [];

    setSystemSettings(settings: IElemSettings[]) {
        this.systemSettings = settings;
    }

    setTaskUpdate(bool: string) {
        this.isTaskUpdate += bool;

    }

    setUpdateFile(bool: boolean) {
        this.updateFile = bool;
    }


    setActivate(bool: boolean) {
        this.isActivate = bool;
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setProjects(projects: ICardProject[]) {
        this.projects = projects;
    }

    setProject(project: any) {
        this.project = project;
    }

    setDashBoard(dashBoards: any) {
        this.dashBoards = dashBoards;
    }

    setColumns(columns: any) {
        this.columns = columns;
    }

    setTasks(tasks: any) {
        this.tasks = tasks;
    }

    setFiles(files: any) {
        this.files = files;
    }

    setProgectsForMenu(tree: ProjectForMenu) {
        this.tree = tree;
    }

    personsWithRoles: ISustemPerson = {} as ISustemPerson;

    setPersonsWithRoles(personsWithRoles: any) {
        this.personsWithRoles = personsWithRoles;
    }

    dataForFilter: any;

    setDataForFilter(dataForFilter: any) {
        this.dataForFilter = dataForFilter;
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);


            this.setAuth(true);
            this.setActivate(response.data.isActivated);
            this.setUser(response.data);
        } catch (err: any) {
            return err.response?.data;
        }
    }

    async loginOAuth(access_token: string){
        try {
            const response = await AuthService.loginOAuth(access_token);

            this.setAuth(true);
            this.setActivate(response.data.isActivated);
            this.setUser(response.data);
        } catch (err: any) {
            return err.response?.data;
        }
    }

    async getCurrentUser(): Promise<void> {
        try {
            const response = await AuthService.fetchCurentUser();

            this.setUser(response.data);
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async editUserInfo(body: IEditUserInfo): Promise<IUser> {
        try {
            const response = await UserService.editUser(body);

            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async logout() {
        try {
            await AuthService.logout();

            this.setAuth(false);
            this.setUser({} as IFullUserInfo);
            localStorage.removeItem("user");

            return true;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    

    async viewBasket(reqBody: IViewBasket): Promise<IViewBasketResp | undefined> {
        try {
            const response = await BasketService.viewBasket(reqBody);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async restoreBasket(reqBody: number[]): Promise<number | undefined> {
        try {
            const response = await BasketService.restoreBasket(reqBody);

            return response.status;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.response.status;
        }
    }

    async deleteBasketFiles(reqBody: number[]): Promise<number | undefined> {
        try {
            const response = await BasketService.deleteBasketFiles(reqBody);

            return response.status;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async hasPermission(check: ICheckRole): Promise<IHasPermission[]> {
        try {
            const response = await CommonService.hasPermission(check);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return [];
        }
    }

    async getDataLinkPerson(link: string): Promise<IUserWithStatus | undefined> {
        try {
            const response = await UserService.getDataLinkPerson(link);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
    // async getTypeAndPriority(): Promise<ITypeAndPriority | undefined> {
    //     try {
    //         const response = await TaskService.getTypeAndPriority();
    //
    //         return response.data;
    //     } catch (err: any) {
    //         console.log(err.response?.data?.message);
    //     }
    // }

    async getUserAllStatuses(): Promise<IUserStatus[] | undefined> {
        try {
            const response = await UserService.getAllUserStatuses();

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getUser(userId: number): Promise<IFullUserInfo | undefined> {
        try {
            const response = await UserService.getUser(userId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editUser(editUser: IEditUser): Promise<any> {
        try {
            const response = await CommonService.editUser(editUser);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getPersonalSettings(): Promise<any> {
        try {
            const response = await UserService.getPersonalSettings();
            this.setPersonalSettings(response.data);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editPersonalSettings(editSettings: IEditPersonalSettingsRequest): Promise<any> {
        try {
            const response = await UserService.editPersonalSettings(editSettings);
            this.setPersonalSettings(response.data);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }


    async systemInviteUser(data: ISystemInvitePerson): Promise<any> {
        try {
            const response = await CommonService.systemInviteUser(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteSystemPerson(data: ISystemDeletePerson[]): Promise<any> {
        try {
            const response = await CommonService.deleteSystemPerson(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getChatMessages(chatId: number): Promise<IMessageGroup[] | undefined> {
        try {
            const response = await ChatService.getChatMessages(chatId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getNameAllRoles(): Promise<IGetRole[] | undefined> {
        try {
            const response = await CommonService.getNameAllRoles();
            let systemRole = response.data.filter(xx => xx.requiredProject == 0);


            return systemRole;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteImg(img: string): Promise<any> {
        try {
            let data: { img: string } = {img: img}
            const response = await CommonService.deleteImg(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async saveRestrict(restrict: IRestrictAccessBoardsUpdate): Promise<any> {
        try {
            const response = await CommonService.saveRestrict(restrict);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async saveExcludeAccessPermission(data: ISaveExcludePermission): Promise<any> {
        try {
            const response = await CommonService.saveExcludeAccessPermission(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllUsersWithSystemRoles(projectId: number | undefined): Promise<IGetAllUsersWithSystemRoles[] | undefined> {
        try {
            const response = await UserService.getAllUsersWithSystemRoles(projectId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllAvailablePermissions(): Promise<IRoleFunctionGroups[] | undefined> {
        try {
            const response = await CommonService.getAllAvailablePermissions();

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getDataToExcludePermissions(id: number): Promise<IExcludePermissionFunction | undefined> {
        try {
            const response = await CommonService.getDataToExcludePermissions(id);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteRole(id: number): Promise<any> {
        try {
            const response = await CommonService.deleteRoles({id: [id]});

            return response;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteRoles(data: { id: number[] }): Promise<any> {
        try {
            const response = await CommonService.deleteRoles(data);

            return response;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getRole(id: number): Promise<IGetOneRole | undefined> {
        try {
            const response = await CommonService.getRole(id);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async createRole(data: ICreateRole): Promise<any> {
        try {
            const response = await CommonService.createRole(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editRole(data: IEditRole): Promise<any> {
        try {
            const response = await CommonService.editRole(data);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllRoles(): Promise<IGetRole[] | undefined> {
        try {
            const response = await CommonService.getAllRoles();

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteAllFilesWithoutBind(){
        try {
            const response = await CommonService.deleteAllFilesWithoutBind();

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllProject(includeSubprojects: boolean) {
        try {
            const response = await ProjectService.getAllProject(includeSubprojects);

            this.setProjects(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getArchiveProjects() {
        try {
            const response = await ProjectService.getArchiveProjects();

            this.setProjects(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getCurentProject(id: number) {
        try {
            const response = await ProjectService.getCurentProject(id);

            this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addDashBoard(name: string, projectId: number, groupId: number) {
        try {
            const response = await DashBoardService.addDashBoard(
                name,
                projectId,
                groupId
            );
            this.setDashBoard(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editBoard(body: IBoardEdit) {
        try {
            await DashBoardService.editBoard(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectIdDashBoards(id: number) {
        try {
            let response = await DashBoardService.getProjectIdDashBoards(id);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectDashBoards(id: number) {
        try {
            const response = await DashBoardService.getProjectDashBoards(id);
            this.setDashBoard(response.data);
            return response.data;
            // this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectGroup(id: number) {
        try {
            const response = await DashBoardService.getProjectDashBoards(id);

            this.setDashBoard(response.data);
            // this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllColumnsBoard(id: number) {
        try {
            const response = await DashBoardService.getAllColumnBoard(id);

            this.setColumns(response.data);
            return response.data
        } catch (err: any) {
            this.setColumns([]);
            console.log(err.response?.data?.message);
            return null;
        }
    }

    async getAllTasksBoard(id: number) {
        try {
            const response = await DashBoardService.getAllTasksBoard(id);
            this.setTasks(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
    async deleteBoard(body: IDeleteBoard): Promise<void> {
        try {
            await DashBoardService.deleteBoard(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }


    async fetchAllUsers(projectId: number | undefined): Promise<IFullUserInfo[] | undefined> {
        try {
            const res = await UserService.fetchAllUsers(projectId);
            return res.data;
        } catch (err: any) {
            return undefined;
        }
    }

  

    async addSystemSettings(newSettings: IEditElemSettings[]): Promise<any> {
        try {
            const res = await CommonService.addSystemSettings(newSettings);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async editSystemSettings(newSettings: IEditElemSettings[]): Promise<any> {
        try {
            const res = await CommonService.editSystemSettings(newSettings);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemCover(): Promise<any> {
        try {
            const res = await CommonService.getSystemCover();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemLogo(): Promise<any> {
        try {
            const res = await CommonService.getSystemLogo();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async activatePerson(link: string): Promise<any> {
        try {
            const res = await AuthService.activatePerson(link);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemSettings(): Promise<IElemSettings[]> {
        try {
            const res = await CommonService.getSystemSettings();
            this.setSystemSettings(res.data);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getPublicSettings(): Promise<IElemSettings[]> {
        try {
            const res = await CommonService.getPublicSettigns();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addColumn(body: IAddColumn): Promise<void> {
        try {
            await ColumnService.addColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editColumn(body: IColumnEdit): Promise<void> {
        try {
            await ColumnService.editColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async moveColumn(body: IMoveColumn): Promise<void> {
        try {
            await ColumnService.moveColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
    async makeColumnProxy(body: IMakeColumnProxy): Promise<void> {
        try {
            await ColumnService.makeColumnProxy(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async makeColumnCommon(body: IMakeColumnCommon): Promise<void> {
        try {
            await ColumnService.makeColumnCommon(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteColumn(body: IDeleteColumn): Promise<void> {
        try {
            await ColumnService.deleteColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getFilesTaskDisplayData(
        body: IGetFilesTaskDisplayData
    ): Promise<IFilesDisplay> {
        try {

            const response = await FileService.getFilesTaskDisplayData(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getFilesDisplayData(
        body: IGetFilesDisplayData
    ): Promise<IFilesDisplay | null> {
        try {

            const response = await FileService.getFilesDisplayData(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getFileFilterOptions(): Promise<IFileFilterOptions | undefined> {
        try {
            const response = await FileService.getFileFilterOptions();
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectProgressData(
        projectId: number
    ): Promise<IProjectProgressInfo | null> {
        try {
            const response = await ProjectService.getProjectProgressData(
                projectId
            );

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectGeneralInfo(projectId: number): Promise<IProjectGeneralInfo | null | number> {
        try {
            const response = await ProjectService.getProjectGeneralInfo(projectId);

            return response.data;
        } catch (err: any) {
            console.error(err.response?.data?.message);
            return err.response?.status;
        }
    }

    async getProjectHistoryDisplayData(
        body: IGetProjectHistoryDisplay
    ): Promise<IProjectHistoryDisplay[]> {
        try {
            const response = await ProjectService.getProjectHistoryDisplayData(
                body
            );
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectTeamDisplayData(body: IGetTeamDisplay): Promise<ITeamDisplay | null> {
        try {
            const response = await ProjectService.getProjectTeamDisplayData(
                body
            );
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return null;
        }
    }

    async registration(email: string, password: string, name: string, surname: string, middlename: string) {
        try {
            await AuthService.registration(
                email,
                password,
                name,
                surname,
                middlename
            );
        } catch (err: any) {
            return err.response?.status;
        }
    }

    // Метод проверки активированности пользователя
    // Вызывается если при логине в DTO пользователя isActivate = false
    async checkActivate(email: string) {
        try {
            const response = await AuthService.checkActivate(email);
            if (response.data.isActivated === true) {
                this.setActivate(true);
                this.setAuth(true);
            }
            const userString = localStorage.getItem("user");
            const user = userString ? JSON.parse(userString) as IFullUserInfo : null;
            if (user) {
                user.isActivated = true;
                this.setUser(user);
            }
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async requestPasswordRecover(email: string) {
        try {
            await AuthService.requestPasswordRecover(email);
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async recoverPassword(body: IRecoverPassword) {
        try {
            await AuthService.recoverPassword(body);
        } catch (err: any) {
            return err.response.data;
        }
    }

    async changePassword(body: IChangePassword) {
        try {
            return await AuthService.changePassword(body);

        } catch (err: any) {
            return err.response
        }
    }

    async addRole(userId: number, roleId: number) {
        try {
            const response = await UserService.addRole(userId, roleId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async saveEditMessage(message: string, messageId: number, mentionsIds: number[]) {
        try {
            const messageInt: { message: string; messageId: number; mentionsIds: number[] } = {
                message: message,
                messageId: messageId,
                mentionsIds: mentionsIds ? mentionsIds : []
            };
            const response = await ChatService.saveEditMessage(messageInt);
            return response.data;
        } catch (err) {
        }
    }

    async sendMessage(message: string, chatId: number, mentionsIds: number[]) {
        try {
            const response = await ChatService.sendMessage({ message, chatId, mentionsIds });
            return response.data;
        } catch (err) {
        }
    }

    async deleteMessage(idMessage: number) {
        try {
            const response = await ChatService.deleteMessage(idMessage);
            return response.data;
        } catch (err) {
        }
    }

    async addFileSystem(file: any, projectId: number): Promise<FileResponse> {
        try {
            const response = await FileService.addFileSystem(file, projectId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }
    
    async addFileChat(file: any, chatId: number): Promise<FileResponse> {
        try {
            const response = await FileService.addFileChat(file, chatId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addFileTask(file: any, taskId: number) {
        try {
            const response = await FileService.addFileTask(file, taskId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addSystemFile(file: any): Promise<{ url: string, fileId: number }> {
        try {
            const response = await FileService.addSystemFile(file);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addFileToProject(file: any, projectId: number, useId: number) {
        try {
            const response = await FileService.addFileToProject(file, projectId, useId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addFile(file: File): Promise<FileResponse> {
        try {
            const response = await FileService.addFile(file);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getFiles(id: number) {
        try {
            const response = await FileService.getProjectFiles(id);


            response.data && this.setFiles(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async downloadFile(id: string) {
        try {
            const response = await FileService.downloadFile(id);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteFiles(ids: number[]) {
        try {
            const response = await FileService.deleteFile(ids);

        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async restrictAccess(projectId: number): Promise<IRestrictAccess | undefined> {
        try {
            const response = await CommonService.restrictAccess(projectId);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectsForLeftMenu() {
        try {
            const response = await CommonService.getProjects();
            if (response.data) this.sideBar = response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addFeedback(reqBody: IFeedback) {
        try {
            await CommonService.addFeedback(reqBody);
        } catch (err: any) {
            return err.response?.data;
        }
    }

    async getSystemPersonWithRoles() {
        try {
            const response = await UserService.getSystemPersonWithRoles();
            this.setPersonsWithRoles(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addTeamMembers(body: IAddTeamDisplay) {
        try {
            const response = await TeamService.addTeamMembers(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err;
        }
    }

    async deleteTeamMembers(body: IDeleteTeamDisplay) {
        try {
            await TeamService.deleteTeamMembers(body);
            return null;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.response?.data?.message;
        }
    }

    async getDataForFilter(body: IIParametrsFilter) {
        try {
            const response = await FilterService.getFilters(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async createProject(body: IProjectCreateReq) {
        try {
            const response = await ProjectService.createProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editProject(body: IProjectEditReq) {
        try {
            const response = await ProjectService.editProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteProject(body: IProjectDeleteReq) {
        try {
            const response = await ProjectService.deleteProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async archiveProject(projectId:number) {
        try {
            const response = await ProjectService.archiveProject(projectId);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async unarchiveProjects(body: IProjectUnarchiveReq) {
        try {
            const response = await ProjectService.unarchiveProjects(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    //Монитор пользователя
    async getFavouriteTasks() {
        try {
            const response = await UserMonitorService.getFavouriteTasks();
            return response.data;
        } catch (err: any) {
            // console.log(err.response?.data?.message);
        }
    }

    async setFavouriteTasks(body: any) {
        try {
            const response = await UserMonitorService.setFavouriteTasks(body);
            return response.data;
        } catch (err: any) {
            // console.log(err.response?.data?.message);
        }
    }

    async getMonitorFilters(): Promise<ITaskMonitorFilters | undefined> {
        try {
            const response = await UserMonitorService.getFilters();
            return response.data;
        } catch (err: any) {
            // console.log(err.response?.data?.message);
        }
    }

   

    async getVersion() {
        const response = await CommonService.getVersion();
        return response.data
    }
}
