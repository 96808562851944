import React, {
    CSSProperties,
    FC,
    ReactNode,
    useState,
    Children,
    isValidElement,
    ReactElement,
    useEffect,
} from "react";
import arrowDown from "../../../assets/arrowDown.svg";
import cross from "../../../assets/cancelGreey.svg";
import "./SelectDropdown.css";
import Search from "../../UiLib/Search/Search";

interface SelectDropdownProps {
    errorMessage?: string;
    optionsContainerStyle?: CSSProperties;
    optionsListStyle?: CSSProperties;
    children: ReactNode;
    enableSearch?: boolean;
    showResetButton?: boolean;
    onReset?: () => void;
}

interface SelectedProps {
    children: ReactNode;
}

interface OptionProps {
    children: ReactNode;
    textForSearch?: string;
}

const SelectDropdown: FC<SelectDropdownProps> & {
    Selected: FC<SelectedProps>;
    Option: FC<OptionProps>;
} = ({
    children,
    errorMessage,
    optionsContainerStyle,
    optionsListStyle,
    enableSearch,
    showResetButton,
    onReset,
}) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [options, setOptions] = useState<ReactElement<OptionProps>[]>([]);
    const [selectedElement, setSelectedElement] = useState<ReactNode>();

    useEffect(() => {
        const opts: ReactElement<OptionProps>[] = [];

        Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                if (child.type === SelectDropdown.Selected) {
                    setSelectedElement(child);
                } else if (child.type === SelectDropdown.Option) {
                    const childTyped = child as ReactElement<OptionProps>;
                    if (!enableSearch) {
                        opts.push(childTyped);
                        return;
                    }
                    if (
                        childTyped.props.textForSearch &&
                        childTyped.props.textForSearch
                            .toLocaleLowerCase()
                            .includes(searchText.toLocaleLowerCase())
                    )
                        opts.push(childTyped);
                }
            }
        });
        setOptions(opts);
    }, [searchText, children]);

    return (
        <div>
            <div
                className={`container_pointer ${
                    errorMessage ? "div_with_error" : ""
                }`}
                onClick={() => setOpenDropdown(!openDropdown)}
            >
                <div className="selected-option">{selectedElement}</div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    {showResetButton ? (
                        <img
                            alt="cross"
                            src={cross}
                            onClick={(e) => {
                                if (onReset) onReset();
                                e.stopPropagation();
                            }}
                        />
                    ) : null}
                    <img
                        alt="arrowDown"
                        src={arrowDown}
                        style={
                            openDropdown
                                ? { rotate: "180deg" }
                                : { rotate: "0deg" }
                        }
                    />
                </div>
            </div>
            {openDropdown && (
                <>
                    <div style={{ position: "relative" }}>
                        <div
                            className="dropdown_overview"
                            style={optionsContainerStyle}
                        >
                            {enableSearch ? (
                                <Search
                                    value={searchText}
                                    changeSearchValue={(val) =>
                                        setSearchText(val)
                                    }
                                    hideSubmitButton
                                />
                            ) : null}
                            <div style={optionsListStyle}>
                                {options.map((option) => (
                                    <div
                                        className="dropdown_option"
                                        onClick={() => {
                                            setOpenDropdown(false);
                                            setSearchText("")
                                        }}
                                    >
                                        {option.props.children}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ opacity: "0.5" }}
                        className="full-screen-overlay_dropdown"
                        onClick={() => setOpenDropdown(false)}
                    ></div>
                </>
            )}
        </div>
    );
};

// Дочерние компоненты
SelectDropdown.Selected = ({ children }: SelectedProps) => <>{children}</>;
SelectDropdown.Option = ({ children }: OptionProps) => <>{children}</>;

export default SelectDropdown;
