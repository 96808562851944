import React, {CSSProperties, FC} from 'react';
import "./CheckboxIos.css"

interface ICheckboxIosProps {
    id?: string;
    callBack: () => void;
    style?: CSSProperties;
    classBlock?: string | string[];
    checked?: boolean;
    disabled?: boolean;
}

/**
 * Компонент для отображения кастомного переключателя в стиле iOS.
 *
 * @param {ICheckboxIosProps} props - Свойства компонента.
 * @param {string | undefined} props.id - Уникальный идентификатор для чекбокса. Используется для связывания `<label>` с `<input>`.
 * @param {() => void} props.callBack - Функция обратного вызова, вызываемая при изменении состояния чекбокса.
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру.
 * @param {string | string[]} [props.classBlock] - Дополнительные CSS-классы, которые применяются к контейнеру. Может быть строкой или массивом строк.
 * @param {boolean} [props.checked] - Состояние чекбокса (активен/неактивен). Необязательный параметр.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли чекбокс. Если true, чекбокс недоступен для взаимодействия и отображается в заблокированном состоянии. Необязательный параметр.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий кастомный переключатель в стиле iOS.
 */
const CheckboxIos: FC<ICheckboxIosProps> = ({id, callBack, style, classBlock, checked, disabled}) => {
    return (
        <label key={id} style={{...style}} className={`${classBlock} ui__component--checkbox-ios`}>
            <input type="checkbox" disabled={disabled} onChange={callBack} checked={checked}/>
            <span className="ui__component--checkbox-ios-switch"></span>
        </label>
    );
};

export default CheckboxIos;