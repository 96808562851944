import "./SearchResults.css";
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import {ISearchBoardResult, ISearchProjectResult, ISearchResult, ISearchTaskResult,} from "../../../models/ISearch";
import React, {FC, ReactNode} from "react";
import BoardIcon from "../../../assets/board_frame.svg";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import PersonWithSubtext from "../../Shared/PersonWithSubtext/PersonWithSubtext";
import {fullNameFormat} from "../../../helpers/Inicials";
import SmallProgressBar from "../../Shared/ProgressBar/SmallProgressBar";
import {formatEditMessage} from "../../../helpers/chatFucntion";
import TaskType from "../../Shared/TaskType";
import TaskTeg from "../../Shared/TaskTeg";
import MarkdownPreview from "../../Shared/MarkdownPreview/MarkdownPreview";
import {GlobalSearchNavigator} from "../../../pages/GlobalSearch/GlobalSearch";
import {useNavigate} from "react-router-dom";
import TaskStatus from "../../Shared/TaskStatus";
import TaskPriority from "../../Shared/TaskPriority";


interface IResultContainerProps {
    children: ReactNode;
}

const ResultContainer: FC<IResultContainerProps> = ({children}) => {
    return <div className="search-result-container">{children}</div>;
};

interface ISearchResultsProps {
    result?: ISearchResult;
    searchText?: string;
    onScroll?: (event: any) => void;
    currentTab: GlobalSearchNavigator;
}

const SearchResults: FC<ISearchResultsProps> = ({
                                                    result,
                                                    searchText,
                                                    onScroll,
                                                    currentTab
                                                }) => {
    const navigate = useNavigate();

    const getProjectResult = (project: ISearchProjectResult) => {
        return (
            <ResultContainer>
                <div style={{display: "flex", gap: "17px", cursor: 'pointer'}}
                     onClick={() => {
                         navigate(`/project/${project.id}`);
                     }}>
                    <img
                        alt="project-cover"
                        width={90}
                        height={60}
                        className="search-result-img"
                        src={getFileUrlFromId(project.photoId ?? undefined)}
                    />
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className={'search_result_component-number'}>

                            {project.whatFound?.strEnum == 2 ?
                                <MarkdownPreview content={project.whatFound?.str}/> :
                                <div>{project.projectNumber}</div>}
                        </div>
                        <div className="search-result-name">
                            {project.whatFound?.strEnum == 0 ?
                                <MarkdownPreview content={project.whatFound?.str}/> :
                                <div>{project.name}</div>}
                        </div>

                        <span className="search-result-description">
                            {project.whatFound?.strEnum == 1 ?
                                <MarkdownPreview content={project.whatFound?.str}/> :
                                project.description}
                        </span>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <PersonWithSubtext
                        style={{width: "auto", marginRight: "10px"}}
                        photoId={project.createdBy.photoId}
                        name={fullNameFormat(
                            {
                                name: project.createdBy.name,
                                surname: project.createdBy.surname,
                                middlename: project.createdBy.middlename,
                            },
                            "s N M"
                        )}
                        subtext="Создатель"
                    />
                    <div className="search-result-progress">
                        <div style={{display: "flex", alignItems: "center", marginBottom: "4px"}}>
                            <span>Прогресс</span><a>{project.percentageCompletion}%</a>
                        </div>
                        <SmallProgressBar progress={project.percentageCompletion}/>
                    </div>
                </div>
            </ResultContainer>
        );
    };

    const getBoardResult = (board: ISearchBoardResult) => {

        return (
            <ResultContainer>
                <div style={{display: "flex", alignItems: "center", cursor: 'pointer'}}
                     onClick={() => {
                         navigate(`/board/${board.id}`);
                     }}
                >
                    <img src={BoardIcon}/>
                    <div style={{marginLeft: "10px"}}>
                        <div className="search-result-name">
                            {board.whatFound?.str.length >0 ? <MarkdownPreview content={board.whatFound?.str}/> : board.name }
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{marginRight: "10px"}}>
                        {board.project.name}
                    </span>
                    <img
                        alt="project-cover"
                        width={32}
                        height={32}
                        className="search-result-img"
                        src={getFileUrlFromId(board.project.photoId)}
                    />
                </div>
            </ResultContainer>
        );
    };

    const getTaskResult = (task: ISearchTaskResult) => {
        const disassembledText = (str: string) => {
            return formatEditMessage(str, {data: []}).disassembledText
        }
        return (
            <ResultContainer>
                <div className={'search-result-task-left'}
                     onClick={() => {
                         navigate(`/task/${task.id}`)
                     }}
                >
                    <div className={'search_result_component-number'}>{task?.whatFound?.strEnum == 2 ?
                        <MarkdownPreview content={task?.whatFound?.str}/> : task?.taskNumber}
                    </div>
                    <div className="search-result-name">
                        {task.whatFound?.strEnum == 0 ? <MarkdownPreview content={task?.whatFound?.str}/> : task.name}
                    </div>
                    {task.whatFound?.strEnum == 1 && disassembledText.length > 0 ?
                        <span className="search-result-description">
                        <MarkdownPreview content={disassembledText?.length > 40 ?
                            disassembledText(task?.whatFound?.str)?.slice(0, 40) + "..."
                            :
                            disassembledText(task?.whatFound?.str)?.slice(0, 40)}
                        />
                            </span>
                        :
                        <span className="search-result-description">
                            {task.description.length > 40 ? disassembledText(task.description)?.slice(0, 40) + "..."
                                : disassembledText(task.description)?.slice(0, 40) ?? ''
                            }
                        </span>
                    }
                </div>
                <div className="search-result-task-middle" style={{display: "flex"}}>
                        {task.tags.length ? 
                            <div className="search-result-task-tags">
                                {task.tags.slice(0, 2).map((tag) => (
                                    <div style={{margin: "2px", width: "fit-content"}}>
                                        <TaskTeg color={tag.color} name={tag.name}/>
                                    </div>
                                ))}
                            </div>
                            : null}
                    <div className="search-result-task-type">
                        <TaskType
                            name={task.taskType.name}
                            icon={JSON.parse(task.taskType.color).icon}
                            color={JSON.parse(task.taskType.color).color}
                        />
                        <TaskPriority
                            name={task.priority.name}
                            icon={JSON.parse(task.priority.style).icon}
                            color={JSON.parse(task.priority.style).color}
                        />
                        <TaskStatus

                            name={task.status.name}
                            icon={JSON.parse(task.status.style).icon}
                            color={JSON.parse(task.status.style).color}
                        />
                    </div>

                    <div className="search-result-task-users">
                        <PersonWithSubtext
                            photoId={task.createdBy.photoId}
                            name={fullNameFormat(
                                {
                                    name: task.createdBy.name,
                                    surname: task.createdBy.surname,
                                    middlename: task.createdBy.middlename,
                                },
                                "s N M"
                            )}
                            subtext="Автор"
                        />
                        <PersonWithSubtext
                            photoId={task.performer.photoId}
                            name={fullNameFormat(
                                {
                                    name: task.performer.name,
                                    surname: task.performer.surname,
                                    middlename: task.performer.middlename,
                                },
                                "s N M"
                            )}
                            subtext="Исполнитель"
                        />
                    </div>
                </div>
                <div className="search-result-task-right">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span className="search-result-task-projectName" style={{marginRight: "10px", paddingLeft: '10px'}}>
                            {task.project.name}
                        </span>
                        <img
                            alt="project-cover"
                            width={32}
                            height={32}
                            className="search-result-img"
                            src={getFileUrlFromId(task.project.photoId)}
                        />
                    </div>
                </div>
            </ResultContainer>
        );
    };

    return (
        <WidgetContainer
            style={{
                margin: "30px",
                padding: "20px",
                overflowY: "auto",
                minHeight: "0px"
            }}
        >
            <div
                style={{height: "100%", overflow: "scroll", paddingRight: "8px"}}
                onScroll={onScroll}
            >
                {result ? (
                    <div>
                    <div className="search-result-header">
                            <span className="search-result-heading">
                                Найдено для:
                            </span>
                            <span className="search-result-searchable">
                                {searchText ?? ""}
                            </span>
                        </div>
                        {(currentTab == "all" || currentTab == "project") && result.projects?.length ?
                            <div className="search-results-container">
                                <span>Проекты - {result.projects.length}</span>
                                {result.projects?.map((p) =>
                                    getProjectResult(p)
                                )}
                            </div>
                            : null}
                        {(currentTab == "all" || currentTab == "board") && result.boards?.length ? (
                            <div className="search-results-container">
                                Доски - {result.boards.length}
                                {result.boards.map((b) => getBoardResult(b))}
                            </div>
                        ) : null}
                        {(currentTab == "all" || currentTab == "task") && result.tasks?.length ? (
                            <div className="search-results-container">
                                Задачи - {result.tasks.length}
                                {result.tasks.map((t) => getTaskResult(t))}
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <span className="search-result-heading">
                        Нажмите "Поиск" для получения результата
                    </span>
                )}
            </div>
        </WidgetContainer>
    );
};

export default SearchResults;
