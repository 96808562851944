import {FC, ReactNode, useContext, useEffect, useState} from "react";
import {
    CurrentTab,
    TabNavigator,
} from "../../UiLib/TabNavigator/TabNavigator";
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import "./SearchFilters.css";
import ButtonBase from "../../buttons/ButtonBase/ButtonBase";
import Search from "../../UiLib/Search/Search";
import {ISearchFilters, ISearchRequest} from "../../../models/ISearch";
import {Context} from "../../..";
import {isAxiosError} from "axios";
import {AppContext} from "../../../App";
import SelectDropdown from "../../Shared/SelectDropdown/SelectDropdown";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import {fullNameFormat} from "../../../helpers/Inicials";
import TaskType from "../../Shared/TaskType";
import {GlobalSearchNavigator, SEARCH_TAKE} from "../../../pages/GlobalSearch/GlobalSearch";
import SimpleCalendar from "../../Shared/Calendar/SimpleCalendar";
import Button from "../../UiLib/Button/Button";
import FilterIcon from "../../../assets/filter.svg";
import Cross from "../../../assets/cancelGreey.svg";
import {dateToNiceString} from "../../../helpers/dateToNiceString";
import {useUpdateEffect} from "primereact/hooks";


interface FilterContainerProps {
    label: string;
    children: ReactNode;
}

const FilterContainer: FC<FilterContainerProps> = ({label, children}) => {
    return (
        <div>
            <p className="search-filter-label">{label}</p>
            {children}
        </div>
    );
};

interface ISearchFiltersProps {
    onSearch: (req: ISearchRequest | undefined) => void;
    defaultSearchText?: string;
    currentTab: GlobalSearchNavigator;
    setCurrentTab: (tab: GlobalSearchNavigator) => void;
}

const SearchFilters: FC<ISearchFiltersProps> = ({
                                                    onSearch,
                                                    defaultSearchText,
                                                    currentTab,
                                                    setCurrentTab
                                                }) => {
    const {showToast} = useContext(AppContext);
    const {store} = useContext(Context);

    const [filters, setFilters] = useState<ISearchFilters>();
    const [searchText, setSearchText] = useState<string>(
        defaultSearchText ?? ""
    );
    const [createdBy, setCreatedBy] = useState<number>();
    const [createdAt, setCreatedAt] = useState<Date>();
    const [projectTeam, setProjectTeam] = useState<number>();
    const [taskPerformer, setTaskPerformer] = useState<number>();
    const [taskType, setTaskType] = useState<number>();
    const [taskTagText, setTaskTagText] = useState<string>("");

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [validSearch, setValidSearch] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try {
                const res = await store.searchService.getFilters();
                setFilters(res.data);
            } catch (err) {
                if (isAxiosError(err)) showToast(err.message);
            }
        })();
    }, []);

    //Сброс результатов поиска при изменении фильтров/вкладки
    useUpdateEffect(() => {
        validateSearch();
        onSearch(undefined);
    }, [
        searchText,
        createdBy,
        createdAt,
        projectTeam,
        taskPerformer,
        taskType,
        taskTagText,
        // currentTab,
    ]);

    const search = () => {
        // Условий много, сделать красиво не получилось
        const type = currentTab;
        const isAll = type === "all";
        setShowFilters(false);

        const projectFilter =
            (isAll || type === "project") &&
            (createdAt || createdBy || (!isAll && projectTeam))
                ? {
                    team: isAll ? null : projectTeam ? [projectTeam] : null,
                }
                : null;

        const boardFilter =
            (isAll || type === "board") && (createdAt || createdBy)
                ? {}
                : null;

        const taskFilter =
            (isAll || type === "task") &&
            (createdAt ||
                createdBy ||
                (!isAll && (taskType || taskPerformer || taskTagText)))
                ? {
                    taskType: isAll ? null : taskType ? [taskType] : null,
                    taskPerformer: isAll
                        ? null
                        : taskPerformer
                            ? [taskPerformer]
                            : null,
                    tags: isAll ? null : taskTagText ? [taskTagText] : null,
                }
                : null;

        const filters =

            projectFilter || boardFilter || taskFilter
                ? {
                    createdAt: createdAt ? createdAt.toISOString() : null,
                    createdBy: createdBy ? [createdBy] : null,
                    project: projectFilter,
                    board: boardFilter,
                    task: taskFilter,
                }
                : null;


        // Проверка: если searchText пустой и фильтров нет - запрос не отправляем
        if (!searchText.trim() && !filters) {
            return;
        }

        const req: ISearchRequest = {
            search: searchText,
            filters,


            sort: {
                createdAt: "asc",
                createdBy: null,
                name: null,
            },
            take: SEARCH_TAKE,
            skip: 0,
        };

        onSearch(req);
    };

    // Проверка на валидность
    const validateSearch = () => {
        if (searchText?.length > 0 || createdBy != undefined || createdAt != undefined || taskPerformer != undefined ||
            projectTeam != undefined || taskType != undefined || taskTagText?.length > 0) {
            setValidSearch(true);
            return true;
        } else {
            setValidSearch(false);
            return false;
        }
    }

    // Кнопка поиска
    const getSearchButton = (func: () => void) => {
        return (
            <div style={{display: "flex", justifyContent: "end"}}>
                <ButtonBase onClickFunc={() => {
                    if (validateSearch()) func()
                }} textContent="Поиск" optionalClasses={"search-global-button"}/>
            </div>
        );
    };

    // Фильтр пользователей
    const getUsersFilter = (
        placeholderText: string,
        selectedUser: number | undefined,
        onSelect: (id: number | undefined) => void
    ) => {
        const selectedUserData = filters?.users?.find(
            (u) => u.id === selectedUser
        );

        return (
            <SelectDropdown
                optionsListStyle={{
                    maxHeight: "200px",
                    overflow: "scroll",
                }}
                enableSearch
                showResetButton={selectedUser !== undefined}
                onReset={() => onSelect(undefined)}
            >
                <SelectDropdown.Selected>
                    {selectedUserData ? (
                        <div className="search-filters-user-selected">
                            <PersonLineDisplay
                                photoId={selectedUserData.photoId}
                                name={fullNameFormat(
                                    {
                                        name: selectedUserData.name,
                                        middlename: selectedUserData.middlename,
                                        surname: selectedUserData.surname,
                                    },
                                    "s n"
                                )}
                            />
                        </div>
                    ) : (
                        <div>{placeholderText}</div>
                    )}
                </SelectDropdown.Selected>
                {filters?.users.map((u) => (
                    <SelectDropdown.Option
                        textForSearch={
                            u.surname + " " + u.middlename + " " + u.name
                        }
                    >
                        <div
                            className="search-filters-user-options"
                            onClick={() => onSelect(u.id)}
                        >
                            <PersonLineDisplay
                                photoId={u.photoId}
                                name={fullNameFormat(
                                    {
                                        name: u.name,
                                        middlename: u.middlename,
                                        surname: u.surname,
                                    },
                                    "s n"
                                )}
                            />
                        </div>
                    </SelectDropdown.Option>
                ))}
            </SelectDropdown>
        );
    };

    // Фильтр типов задач
    const getTaskTypeFilter = () => {
        const selectedType = filters?.taskTypes.find((t) => t.id === taskType);

        const selectedTypeColor = JSON.parse(selectedType?.color ?? "{}").color;
        const selectedTypeIcon = JSON.parse(selectedType?.color ?? "{}").icon;

        return (
            <SelectDropdown
                showResetButton={selectedType !== undefined}
                onReset={() => setTaskType(undefined)}
            >
                <SelectDropdown.Selected>
                    {selectedType ? (
                        <TaskType
                            color={selectedTypeColor}
                            name={selectedType.name}
                            icon={selectedTypeIcon}
                        />
                    ) : (
                        <div>Тип</div>
                    )}
                </SelectDropdown.Selected>
                {filters?.taskTypes.map((type) => {
                    const color = JSON.parse(type.color).color;
                    const icon = JSON.parse(type.color).icon;
                    return (
                        <SelectDropdown.Option>
                            <div
                                className="search-filters-type-options"
                                onClick={() => setTaskType(type.id)}
                            >
                                <TaskType
                                    color={color}
                                    name={type.name}
                                    icon={icon}
                                />
                            </div>
                        </SelectDropdown.Option>
                    );
                })}
            </SelectDropdown>
        );
    };

    // Очистка всех фильтров
    const clearAllFilters = () => {
        // setCreatedBy(undefined);
        // setCreatedAt(undefined);
        setTaskType(undefined);
        setTaskPerformer(undefined);
        setProjectTeam(undefined);
        setTaskTagText("");
    }

    // Фильтр тегов
    const getTaskTagFilter = () => {
        return (
            <Search
                value={taskTagText}
                changeSearchValue={(val) => setTaskTagText(val)}
                showResetButton={taskTagText.length > 0}
                onReset={() => setTaskTagText("")}
                hideSubmitButton
            />
        );
    };

    // Фильтры вкладки "Задача"
    const getTaskFilters = () => {
        return (
            <div style={{height: "160px"}}>
                <div className="shared-filters-container">
                    <div className="filter-width-container">
                        <FilterContainer label="По типу">
                            {getTaskTypeFilter()}
                        </FilterContainer>
                    </div>
                    <div className="filter-width-container">
                        <FilterContainer label="По исполнителю">
                            {getUsersFilter(
                                "Исполнитель",
                                taskPerformer,
                                (id) => setTaskPerformer(id)
                            )}
                        </FilterContainer>
                    </div>
                    <div className="filter-width-container">
                        <FilterContainer label="По тегу">
                            {getTaskTagFilter()}
                        </FilterContainer>
                    </div>
                </div>
                {getBaseFilters("0px")}
            </div>
        )
    }

    // Фильтры вкладки "Проект"
    const getProjectFilters = () => {
        return (
            <div style={{height: "160px"}}>

                <div className="shared-filters-container">
                    <div className="filter-width-container">
                        <FilterContainer label="По команде">
                            {getUsersFilter(
                                "Пользователь",
                                projectTeam,
                                (id) => setProjectTeam(id)
                            )}
                        </FilterContainer>
                    </div>
                </div>
                {getBaseFilters("0px")}
            </div>
        )
    }

    // Фильтры вкладки "Все"
    const getBaseFilters = (style?: string) => {
        return (
            <div style={style ? {} : {height: "160px"}}>
                <div className="shared-filters-container">
                    <div className="filter-width-container">
                        <FilterContainer label="По автору">
                            {getUsersFilter("Автор", createdBy, (id) =>
                                setCreatedBy(id)
                            )}
                        </FilterContainer>
                    </div>
                    <div className="filter-width-container">
                        {/*<FilterContainer label="По дате создания">*/}
                        {/*    <SimpleCalendar*/}
                        {/*        style={{margin: "5px 0px"}}*/}
                        {/*        date={createdAt}*/}
                        {/*        onChange={(date) => {*/}
                        {/*            setCreatedAt(date);*/}
                        {/*        }}*/}
                        {/*        showResetButton={createdAt !== undefined}*/}
                        {/*        onReset={() => setCreatedAt(undefined)}*/}
                        {/*        placeholder="Дата создания"*/}
                        {/*    />*/}
                        {/*</FilterContainer>*/}
                    </div>
                </div>
            </div>
        )
            ;
    };

    // Подсказки по выбранным фильтрам
    const hintsFilter = () => {
        return (
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {createdBy && filters?.users ? (
                    () => {
                        const user = filters.users.find(xx => xx.id === createdBy);
                        return (
                            <div className="search-global-filter-block-current-search">
                                <a>Автор:</a>
                                {user ? fullNameFormat({
                                    name: user.name ?? "",
                                    middlename: user.middlename ?? "",
                                    surname: user.surname ?? "",
                                }, "s n") : "Неизвестный пользователь"}
                                <img onClick={() => setCreatedBy(undefined)} src={Cross}/>
                            </div>
                        );
                    }
                )() : null}
                {createdAt ?
                    <div className="search-global-filter-block-current-search">
                        <a>Дата:</a>
                        <a>{dateToNiceString(createdAt, "dd.MM.yyyy")}</a>
                        <img onClick={() => setCreatedAt(undefined)} src={Cross}/>
                    </div>
                    : null}
                {taskType && filters?.users ? (
                    () => {
                        const type = filters.taskTypes.find(xx => xx.id === taskType);
                        const selectedTypeColor = JSON.parse(type?.color ?? "{}").color;
                        const selectedTypeIcon = JSON.parse(type?.color ?? "{}").icon;
                        return (
                            <div className="search-global-filter-block-current-search">
                                <a>Тип:</a>
                                {type ?
                                    <TaskType
                                        color={selectedTypeColor}
                                        name={type.name}
                                        icon={selectedTypeIcon}
                                    />
                                    : null}
                                <img onClick={() => setTaskType(undefined)} src={Cross}/>
                            </div>
                        );
                    }
                )() : null}
                {taskPerformer && filters?.users ? (
                    () => {
                        const user = filters.users.find(xx => xx.id === taskPerformer);
                        return (
                            <div className="search-global-filter-block-current-search">
                                <a>Исполнитель:</a>
                                {user ? fullNameFormat({
                                    name: user.name ?? "",
                                    middlename: user.middlename ?? "",
                                    surname: user.surname ?? "",
                                }, "s n") : "Неизвестный пользователь"}
                                <img onClick={() => setTaskPerformer(undefined)} src={Cross}/>
                            </div>
                        );
                    }
                )() : null}
                {projectTeam && filters?.users ? (
                    () => {
                        const user = filters.users.find(xx => xx.id === projectTeam);
                        return (
                            <div className="search-global-filter-block-current-search">
                                <a>Команда:</a>
                                {user ? fullNameFormat({
                                    name: user.name ?? "",
                                    middlename: user.middlename ?? "",
                                    surname: user.surname ?? "",
                                }, "s n") : "Неизвестный пользователь"}
                                <img onClick={() => setProjectTeam(undefined)} src={Cross}/>
                            </div>
                        );
                    }
                )() : null}
                {taskTagText ?
                    <div className="search-global-filter-block-current-search">
                        <a>Тег:</a>
                        <a>{taskTagText}</a>
                        <img onClick={() => setTaskTagText("")} src={Cross}/>
                    </div>
                    : null}
            </div>
        )
    }

    // Строка поиска
    const searchComponent = () => {
        return (
            <div>
                <div className="search-global-filter-block">
                    <Search
                        placeholder="Поиск..."
                        value={searchText}
                        changeSearchValue={setSearchText}
                        hideSubmitButton={true}
                        className={`${validSearch ? "" : "search-filters-main-search-error"} search-filters-main-search`}
                        onSubmitFunc={search}
                        showResetButton={searchText.length > 0}
                        onReset={() => setSearchText("")}
                        onClick={() => setShowFilters(true)}
                    />
                    <div style={{marginRight: "10px"}}>
                        <Button
                            style={{height: "36px", width: "36px"}}
                            classBlock={"search-global-filter-button"}
                            callBack={() => setShowFilters(prevState => !prevState)}
                            image={FilterIcon}
                        />
                    </div>
                    <div>
                        {getSearchButton(search)}
                    </div>
                </div>
                <div>
                    {!validSearch ?
                        <div className="search-global-filter-block-error">
                            <a>Поле поиска или фильтры не могут быть пустыми!</a>
                        </div>
                        : showFilters ?
                            null
                            : hintsFilter()
                    }
                </div>
            </div>
        )
    }

    return (
        <WidgetContainer
            className="search-filters-container"
        >
            <TabNavigator>
                <CurrentTab
                    key={"all"}
                    name="Все"
                    callBack={() => {
                        clearAllFilters();
                        // setShowFilters(false);
                        setCurrentTab(GlobalSearchNavigator.all);
                    }}
                >
                    {searchComponent()}
                    {showFilters ? getBaseFilters() : null}
                </CurrentTab>
                <CurrentTab
                    key={"project"}
                    name="Проект"
                    callBack={() => {
                        clearAllFilters();
                        // setShowFilters(false);
                        setCurrentTab(GlobalSearchNavigator.project);
                    }}
                >
                    {searchComponent()}
                    {showFilters ? getProjectFilters() : null}
                </CurrentTab>
                <CurrentTab
                    key={"board"}
                    name="Доска"
                    callBack={() => {
                        clearAllFilters();
                        // setShowFilters(false);
                        setCurrentTab(GlobalSearchNavigator.board);
                    }}
                >
                    {searchComponent()}
                    {showFilters ? getBaseFilters() : null}
                </CurrentTab>
                <CurrentTab
                    key={"task"}
                    name="Задача"
                    callBack={() => {
                        clearAllFilters();
                        // setShowFilters(false);
                        setCurrentTab(GlobalSearchNavigator.task);
                    }}
                >
                    {searchComponent()}
                    {showFilters ? getTaskFilters() : null}
                </CurrentTab>
            </TabNavigator>
        </WidgetContainer>
    );
};

export default SearchFilters;
