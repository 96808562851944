import { FileIcons } from "../component/Shared/InputChat/FileIcons";
import unknown_svg from "../assets/FileIcons/unknown.svg";

interface IFileMapper {
    data: {
        type: string;
        id: string;
        url: string
    }[]
}

interface CheckCodeResult {
    prevIndex: number;
    newMass: string[];
    checkCode: boolean;
}

interface CheckBlockquoteResult {
    prevIndex: number;
    newMass: string[];
    checkQuote: boolean;
}


export const generateFileId = (value: string, fileType: string) => {
    let id = value.split("=").pop();
    if (id && id.length < 8) {
        for (let i = 0; i < (8 - id.length); i++) {
            id = "0"+id;
        }
    }
    return `${fileType}${id}`;
};

function checkCodeInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkCode: boolean): CheckCodeResult {
    if (checkCode == false) {
        prevIndex = index + 51
        checkCode = true;
    } else {
        if (index == 0) {
            newMass.push()
            newMass.push("```\n"+changeValue.substring(prevIndex, index - 19).replace(/<br>/g, "\n"));
        } else {
            newMass.push()
            newMass.push("\n```\n"+changeValue.substring(prevIndex, index - 19).replace(/<br>/g, "\n")+"```");
        }

        prevIndex = index + 1;
        checkCode = false;
    }

    return { prevIndex, newMass, checkCode };
}

function checkBlockquoteInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkQuote: boolean): CheckBlockquoteResult {
    if (checkQuote == false) {
        newMass.push("\n> ")
        prevIndex = index + 13
        checkQuote = true;
    } else {
        newMass.push(changeValue.substring(prevIndex, index - 13));
        // newMass.push("\n")
        prevIndex = index + 1;
        checkQuote = false;
    }

    return { prevIndex, newMass, checkQuote };
}

function checkBoldInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkBold: boolean) {
    if (checkBold == false) {
        prevIndex = index + 8
        checkBold = true;
    } else {

        newMass.push("**" + changeValue.substring(prevIndex, index - 9) + "**");

        prevIndex = index + 1;
        checkBold = false;
    }

    return { prevIndex, newMass, checkBold };
}

function checkEmInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkEm: boolean) {
    if (checkEm == false) {
        // newMass.push()
        prevIndex = index + 4
        checkEm = true;
    } else {
        newMass.push("*" + changeValue.substring(prevIndex, index - 5) + "*");
        // newMass.push()
        // newMass.push("\n")
        prevIndex = index + 1;
        checkEm = false;
    }

    return { prevIndex, newMass, checkEm };
}

function checkULInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkUl: boolean) {
    if (checkUl == false) {
        // newMass.push()
        prevIndex = index + 5
        checkUl = true;
    } else {
        let newText =  changeValue.substring(prevIndex, index - 6).replace(/<li>/g, "*").replace(/<\/li>/g, "\n ");
        newMass.push(newText+"\n");
        // newMass.push()
        // newMass.push("\n")
        prevIndex = index + 1;
        checkUl = false;
    }

    return { prevIndex, newMass, checkUl };
}

function checkAudioInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkAudio: boolean) {
    if (checkAudio == false) {
        prevIndex = index
        checkAudio = true;
    } else {
        newMass.push(changeValue.slice(prevIndex, index));
        prevIndex = index + 1;
        checkAudio = false;
    }

    return { prevIndex, newMass, checkAudio };
}

function checkVideoInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkVideo: boolean) {
    if (checkVideo == false) {
        prevIndex = index
        checkVideo = true;
    } else {
        newMass.push(changeValue.slice(prevIndex, index));
        prevIndex = index + 1;
        checkVideo = false;
    }

    return { prevIndex, newMass, checkVideo };
}

function checkImgInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkImg: boolean) {
    if (checkImg == false) {
        prevIndex = index
        checkImg = true;
    } else {
        newMass.push(changeValue.slice(prevIndex, index));
        prevIndex = index + 1;
        checkImg = false;
    }

    return { prevIndex, newMass, checkImg };
}

function checkHrefInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkHref: boolean) {
    if (checkHref == false) {
        prevIndex = index
        checkHref = true;
    } else {
        let href = changeValue.slice(prevIndex+9, index-4).split('">')[1]

        newMass.push(href);
        prevIndex = index + 1;
        checkHref = false;
    }

    return { prevIndex, newMass, checkHref };
}

function checkMentionInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkMention: boolean, mentionPerson: {personId: number, name: string}[]) {
    if (checkMention == false) {
        prevIndex = index
        checkMention = true;
    } else {
        // debugger
        let splitSubText = changeValue.slice(prevIndex+43, index-7).split('">');
        let personId = splitSubText[0];
        let name = splitSubText[1];

        // let href = changeValue.slice(prevIndex+9, index-4).split('">')[1]
        //
        mentionPerson.push({personId: Number(personId), name: "\u200B"+name+"\u200B"});
        newMass.push("\u200B"+name+"\u200B");
        prevIndex = index + 1;
        checkMention = false;
    }

    return { prevIndex, newMass, checkMention, mentionPerson };
}

function checkHInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkH: boolean) {
    if (checkH == false) {
        // newMass.push()
        prevIndex = index
        checkH = true;
    } else {//<h1>
        let getNumH = Number(changeValue.slice(prevIndex + 2, prevIndex + 3));
        let newText = "\n";
        while (newText.length-1 < getNumH) {
            newText = newText + "#"
        }
        
        
        newMass.push(newText+ " " + changeValue.slice(prevIndex + 4, index-5));
        // newMass.push()
        // newMass.push("\n")
        prevIndex = index + 1;
        checkH = false;
    }

    return { prevIndex, newMass, checkH };
}

function checkFileInText(changeValue: string, newMass: string[], index: number, prevIndex: number, checkFile: boolean) {
    if (checkFile == false) {
        // newMass.push()
        prevIndex = index;
        checkFile = true;
    } else {
        newMass.push(changeValue.slice(prevIndex, index));
        // newMass.push()
        // newMass.push("\n")
        prevIndex = index + 1;
        checkFile = false;
    }

    return { prevIndex, newMass, checkFile };
}

function checkIndent(changeValue: string, newMass: string[], index: number, prevIndex: number ) {
    if (changeValue[index] === "\n" && newMass[newMass.length] === "\n") {
        newMass[newMass.length] = newMass[newMass.length - 1];
    } else if (changeValue[index] === "\n") {
        newMass.push(changeValue.substring(prevIndex, index + 1));
        prevIndex = index + 1;
    } else if (changeValue[index] === " ") {
        newMass.push(changeValue.substring(prevIndex, index));
        prevIndex = index + 1;
    } else if (index == changeValue.length) {
        newMass.push(changeValue.substring(prevIndex, index));
        prevIndex = index + 1;
    }

    return {newMass, prevIndex};
}

/**
 * Получение иконки файла.
 * @param {string | undefined} fileType Тип файла.
 * @returns {string} SVG для отображения конкретного файла.
 */
export function fileIcon(fileType: string | undefined) {
    let fileTypeClean = fileType?.replace(/\./g, "");
    let variableName = `${fileTypeClean}_svg`;
    let pictureSrc = (FileIcons as any)[variableName];
    if (!pictureSrc) {
        //Тут если не нашли картинку нужна женерик картинка файла
        return unknown_svg;
    } else {
        return pictureSrc;
    }
}

export function getFileName(stringBefore: string, nameLength: number) {
    // Замена для простого отслеживания мест с наименованиями файлов в дальнейшем
    const splitFileName: string[] = stringBefore.split(`.`);
    let lenghtFileExtension: number = 0;
    let fileExtension: string = "";
    // +1 т.к. нужно учитывать точку - начало расширения файла
    if (splitFileName.length !== 1) {
        lenghtFileExtension = (splitFileName[splitFileName.length - 1]).length + 1;
        fileExtension = splitFileName[splitFileName.length - 1];
    }
    const nameFileWithoutEx = stringBefore.length - lenghtFileExtension;
    if (splitFileName.length !== 1) {
        if (nameFileWithoutEx > nameLength) {
            stringBefore = stringBefore.slice(0, 4) + `...` + stringBefore.slice(nameFileWithoutEx - 4, nameFileWithoutEx) + `.` + fileExtension;
        }
    }
    else {
        if (nameFileWithoutEx > nameLength) {
            stringBefore = stringBefore.slice(0, 4) + `...` + stringBefore.slice(nameFileWithoutEx - 4, nameFileWithoutEx);
        }
    }

    return stringBefore;
}

//Обработка длины файлов
export function getHTMLFileName(fileName: string, fileType: string, url: string, imgGet: any) {
    return fileType === "G"
        ? `<img src="${url}" />`
        : fileType === "M"
            ? `<audio controls class="input--chat__block--messages__message--audio__chat--block" src="${url}">${fileName}</audio>`
            : fileType === "V"
                ? '<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="' +
                url +
                '"/></video>'
                : `<div class="input--chat__block--messages__message--file__chat--block"><div class="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${url}">${fileName}</a></div></div>`;
}

/**
 * Загрузка файла в чат.
 * @param {string} url Url файла для добавления в чат
 * @param {any} file Файл загруженный пользователем
 * @param {IFileMapper} fileMap Объект файлов для преноса в сообщение
 *
 * @returns {string}
 */

export function chatFileUpload(url: string, file: any, fileMap: IFileMapper, nameLength?: number) {
    let fileType = "F";
    let currentFileMap: IFileMapper = fileMap;

    if (file.type.includes("image")) {
        fileType = "G";
    } else if (file.type.includes("audio")) {
        fileType = "M";
    } else if (file.type.includes("video")) {
        fileType = "V";
    }

    let fileTypeGet = file.name.split(".");
    let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
    let fileName = file.name;
    // Сейчас эта строчка не нужна, так как в историю записывается лишь часть названия файла из-за метода getFileName, а нужно, разумеется, записывать полное наименование.
    //let fileName = getFileName(file.name, nameLength ?? 20);
    let dataSave = getHTMLFileName(fileName, fileType, url, imgGet);

    const fileId = generateFileId(url, fileType);

    const newFileMap: { type: string; id: string; url: string } = {
        type: fileType,
        id: "{" + fileId + "}",
        url: dataSave,
    };

    if (fileMap) {
        currentFileMap?.data.push(newFileMap)
    } else {
        currentFileMap = {data: [newFileMap]}
    }

    return { currentFileMap, fileId, dataSave }
}

// export function checkMentionInText(text: string, mentionPersons: {personId: number, name: string}[]): string {
    
// }

/**
 * Сборка сообщения из Markdown в HTML.
 * @param {string} text Исходный текст в маркдаун.
 * @param {IFileMapper} fileMap Объект файлов для преноса в сообщение
 *
 * @returns {string} disassembledText - разобранный текст
 * @returns {string} arrayChatFiles - массив файлов из текста}
 */
export function convertMessage(text: string, fileMap: IFileMapper, mentionPersons: {personId: number, name: string}[], cursorPos?: CodeMirror.EditorChange | undefined) {
    let changeValue = text;
    let newFileMap: { type: string; id: string; url: string }[] = fileMap?.data;
    let newMass: string[] = [];
    let prevIndex = 0;
    let checkCode = false;
    let checkQuote = false;
    let checkBold = false;
    let checkInner = false;
    let checkUl = false;
    let checkHr = false;
    let checkH = false;
    let checkTeg = false;
    let checkMention = false;
    let showWindow = false;
    let mentionIndex = 0;
    let mentionFilter = ""
    let chekPerson: {personId: number, name: string}[] = [];

    let arrayChatFiles: IFileMapper | undefined = undefined;
    let disassembledText: string = ""
    let splitText = changeValue.split("\n");
    let line = cursorPos?.from?.line!
    let pos = cursorPos?.from?.ch!
    let index = 0;
    let indexTo = 0;

    splitText.forEach((curLine, q) => {
        if (q < line) {
            index += curLine.length + 1;
        } else if (q == line) {
            index += pos + 1;
        }
    })


    for (let i = 0; i <= changeValue.length; i++) {
        // Скипаем нужное количество если prevIndex > i
        if (prevIndex > i) {
            break
        }


        // Проверяем код в тексте
        if (changeValue[i - 2] === "`" && changeValue[i - 1] === "`" && changeValue[i] === "`") {
            if (checkCode == false) {
                newMass.push('<div class="code__block--chat__message"><code><xmp>')
                prevIndex = i + 2
                checkCode = true;
            } else {
                if (changeValue.substring(prevIndex, i + 1).includes("```")) {
                    newMass.push(changeValue.substring(prevIndex, i - 2) + "");

                    newMass.push('</xmp></code></div>')
                    prevIndex = i + 1;
                    checkCode = false;
                } else {
                    checkCode = false;
                }
            }
        }

        // Проверяем на H
        if (changeValue[i] == "#" && i == 0 ||
            changeValue[i] == "#" && changeValue[i - 1] == "\n" ||
            changeValue[i] == "\n" && checkH == true ||
            changeValue[i] == "" && checkH == true ||
            i == changeValue.length && checkH == true
        ) {
            if (checkH == false) {
                prevIndex = i;
                checkH = true;
            } else {
                let numH = changeValue.substring(prevIndex, i - 1).split("#").length - 1;
                newMass.push(`<h${numH}>${changeValue.substring(prevIndex + 1 + Number(numH ? numH : 0), i)}</h${numH}>`)
                prevIndex = i + 1;
                checkH = false;
            }
        }

        // Проверяем на черту
        if (changeValue[i] == "-" && changeValue[i + 1] == "-" && changeValue[i + 2] == "-" && changeValue[i + 3] == "-" && changeValue[i + 4] == "-" ||
            changeValue[i] == "-" && changeValue[i - 1] == "-" && changeValue[i - 2] == "-" && changeValue[i - 3] == "-" && changeValue[i - 4] == "-"
        ) {
            if (checkHr == false) {
                prevIndex = i;
                checkHr = true;
            } else {
                newMass.push("<hr>")
                prevIndex = i + 1;
                checkHr = false;
            }
        }

        // Проверка на список с точками
        if (!checkBold && !checkInner && (
                changeValue[i - 1] != "*" && changeValue[i] === "*" && (changeValue[i + 1] === " " || changeValue[i + 1] === "\n") ||
                i == 0 && changeValue[i] === "*" && (changeValue[i + 1] === " " || changeValue[i + 1] === "\n") ||
                checkUl === true && changeValue[i] === "\n" && changeValue[i + 1] != "*") ||
            checkUl === true && i == changeValue.length
        ) {
            if (checkUl == false) {
                newMass.push('<ul>')

                prevIndex = i + 1;
                checkUl = true;
            } else {
                if (changeValue.substring(prevIndex, i + 1).includes("*")) {

                    newMass.push("<li>" + changeValue.substring(prevIndex, i) + "</li>");

                    prevIndex = i + 1;
                } else {
                    checkUl = false;
                    newMass.push("<li>" + changeValue.substring(prevIndex, i).replace("\n", "") + "</li>");
                    prevIndex = i + 1;
                    newMass.push('</ul>')
                }
            }
        }

        // Проверка на курсив
        if (changeValue[i - 1] != "*" && changeValue[i] === "*" && changeValue[i + 1] != "*" && changeValue[i + 1] != " " ||
            changeValue[i - 1] != "*" && changeValue[i] === "*" && changeValue[i + 1] != "*" && changeValue[i + 1] == " " && changeValue[i + 2] == " "
        ) {
            if (checkInner == false) {
                newMass.push('<em>')
                prevIndex = i + 1;
                checkInner = true;
            } else {
                if (changeValue.substring(prevIndex, i + 1).includes("*")) {
                    newMass.push(changeValue.substring(prevIndex, i));

                    newMass.push('</em>')
                    prevIndex = i + 1;
                    checkInner = false;
                } else {
                    checkInner = false;
                }
            }
        }

        // Проверка на болд
        if (changeValue[i - 1] === "*" && changeValue[i] === "*") {
            if (checkBold == false) {
                newMass.push('<strong>')
                prevIndex = i + 1;
                checkBold = true;
            } else {
                if (changeValue.substring(prevIndex, i + 1).includes("**")) {
                    newMass.push(changeValue.substring(prevIndex, i - 1));

                    newMass.push('</strong>')
                    prevIndex = i + 1;
                    checkBold = false;
                } else {
                    checkCode = false;
                }
            }
        }

        // \u200B
        if (!checkCode && !checkQuote && !checkBold && !checkInner && !checkUl && !checkHr && !checkH &&
            changeValue[i] == "\u200B" || changeValue[i] == " " && checkMention) {
            if (checkMention == false) {
                checkMention = true;
                prevIndex = i+1;
            } else {
                if (changeValue[i] == " ") {
                    // Значить пользователь стирает упоминание, удаляем его
                    newMass.push(changeValue.substring(prevIndex, i));
                    checkMention = false;
                    prevIndex = i;
                } else {
                    let splitName = changeValue.substring(prevIndex, i).replace("\u200B","").trim().toUpperCase();

                    let findPerson = mentionPersons?.find(xx =>
                        xx?.name.replace("\u200B","").trim().toUpperCase().includes(splitName))

                    if (findPerson && findPerson?.name.length-2 == splitName.length) {
                        chekPerson.push(findPerson)
                        let mention = `<span class="fio__block—chat__message" id="${findPerson?.personId}">${findPerson?.name?.replace("\u200B", "").trim().slice(0, findPerson.name.length-2).trim()}</span>`;
                        newMass.push(mention);
                    } else {
                        newMass.push(changeValue.substring(prevIndex, i));
                    }

                    checkMention = false;
                    prevIndex = i+1;
                }
            }
        }

        // 2+(n+1)+(n+1)
        // [" ", "\n", "i=0"]["@"]["n"][" "]["n"][" "]
        // 1. [" ", "\n", "i=0"]["@"]["n"][" "]
        // const codeMent = "&#xFEFF";// "\u200B";
        if (!checkCode && !checkQuote && !checkBold && !checkInner && !checkUl && !checkHr && !checkH &&
            (changeValue[i-1] == " " || i == 0 || changeValue[i-1] == "\n") &&
            changeValue[i] == "@" ||
            (changeValue[i] == " " || changeValue[i] == "\n" || i == changeValue.length) &&
            checkTeg == true
        ) {
            // Курсор в этом отрывке?
            if (prevIndex <= index && index-1 <= i) {
                showWindow = true;
                if (changeValue[i] == "@") {
                    mentionIndex = i;
                    mentionFilter = "@";
                } else {
                    mentionIndex = prevIndex;
                    mentionFilter = changeValue.substring(prevIndex, i);
                }
            } else {
                if (showWindow != true) {
                    showWindow = false;
                }
            }

            if (checkTeg == false) {
                checkTeg = true;
                prevIndex = i;
            } else {
                newMass.push(changeValue.substring(prevIndex, i));
                prevIndex = i;

                checkTeg = false;
            }
        }

        if (!checkCode && !checkQuote && !checkBold && !checkInner && !checkUl && !checkHr && !checkH && !checkTeg) {
            if (changeValue[i] === "\n" && newMass[newMass.length] === "\n") {
                newMass[newMass.length] = newMass[newMass.length - 1];
            } else if (changeValue[i] === "\n") {
                newMass.push(changeValue.substring(prevIndex, i + 1));
                prevIndex = i + 1;
            } else if (changeValue[i] === " ") {
                newMass.push(changeValue.substring(prevIndex, i));
                prevIndex = i + 1;
            } else if (i == changeValue.length) {
                newMass.push(changeValue.substring(prevIndex, i));
                prevIndex = i + 1;
            }
        }

        // Проверяем кавычки в тексте
        if (changeValue[i - 1] === "\n" &&
            changeValue[i] === ">" || i == 0 && changeValue[i] === ">" || checkQuote == true) {
            if (checkQuote == false) {
                prevIndex = i + 2
                checkQuote = true;
            } else {
                if (changeValue[i] === "\n" || i == changeValue.length) {
                    newMass.push('<blockquote>')
                    newMass.push(changeValue.substring(prevIndex, i + 1))
                    newMass.push('</blockquote>')
                    prevIndex = i + 2
                    checkQuote = false;
                }
            }
        }
    }

    newMass = newMass?.map((value) => {
        if (value.slice(0, 5).includes("https") || (value.slice(0, 5).includes("http") && !value.slice(0, 5).includes("<a"))) {
            let valueSave = value;
            let newValue = "";
            // if (value?.length > 80) {
            //     while (value?.length > 80) {
            //         newValue += value.slice(0, 80) + "&shy";
            //         value = value.substring(80, value?.length) + "&shy";
            //     }
            //     newValue += value;
            // }

            if (value.slice(value.length - 1, value.length).includes("\n")) {
                value = `<a href="${value}">${newValue?.length > 0 ? newValue : valueSave}</a>` + value.slice(value.length - 1, value.length);
            } else {
                value = `<a href="${value}">${
                    newValue?.length > 0 ? newValue : valueSave
                }</a>`;
            }
            return value;
        }
        return value;
    });

    changeValue = "";

    for (let i = 0; i < newMass.length; i++) {
        if (newMass[i]?.includes("<code>") || newMass[i - 1]?.includes("<code>") || newMass[i + 1]?.includes("</code>") ||
            newMass[i]?.includes("<strong>") || newMass[i - 1]?.includes("<strong>") || newMass[i + 1]?.includes("</strong>") ||
            newMass[i]?.includes("<em>") || newMass[i - 1]?.includes("<em>") || newMass[i + 1]?.includes("</em>")
        ) {
            if (newMass[i].length > 0) {
                changeValue += newMass[i];
            }
        } else {
            if (newMass[i].slice(newMass[i].length - 1, newMass[i].length) == "\n" &&
                newMass[i + 1] == "\n" && newMass[i + 2] == "\n"
            ) {
                changeValue += newMass[i] + "</br>";
            } else if (newMass[i].slice(newMass[i].length - 1, newMass[i].length) == "\n" &&
                newMass[i + 1] == "\n" && newMass[i + 2] != "\n"
            ) {
                changeValue += newMass[i] + "</br>";
            } else if (newMass[i - 1] != "</xmp></code></div>" &&
                newMass[i].slice(newMass[i].length - 1, newMass[i].length) == "\n"
            ) {
                changeValue += newMass[i] + "</br>";
            } else {
                changeValue += newMass[i] + " ";
            }
        }
    }

    fileMap.data.forEach((current) => {
        if (current.type != "H") {
            const startIndex = changeValue.indexOf(current.id);
            const endIndex = current.id.length + startIndex;
            if (startIndex !== -1) {
                changeValue =
                    changeValue.slice(0, startIndex) +
                    current.url +
                    changeValue.slice(endIndex, changeValue.length);
            }
        }
    });

    arrayChatFiles = {data: newFileMap};

    // setFileMap((prevFileMap) => ({
    //     data: newFileMap,
    // }));

    disassembledText = changeValue;

    return {disassembledText, arrayChatFiles, mentionIndex, mentionFilter, showWindow, chekPerson}
}


/**
 * Сборка сообщения из HTML в Markdown.
 * @param {string} text Исходный текст в маркдаун.
 * @param {IFileMapper} fileMap Объект файлов для преноса в сообщение
 *
 * @returns {string} disassembledText - разобранный текст
 * @returns {string} arrayChatFiles - массив файлов из текста}
 */
export function formatEditMessage(text: string, fileMap: IFileMapper) {
    let changeValue = text;
    let newFileMap: { type: string; id: string; url: string }[] = fileMap?.data;
    let newMass: string[] = [];
    let prevIndex = 0;
    let checkCode = false;
    let checkQuote = false;
    let checkBold = false;
    let checkEm = false;
    let checkUl = false;
    let chekAudio = false;
    let chekFile = false;
    let checkH = false;
    let checkVideo = false;
    let checkImg = false;
    let checkHref = false;
    let checkMention = false;
    let mentionPerson: {personId: number, name: string}[] = [];
    let arrayChatFiles: IFileMapper | undefined = undefined;
    let disassembledText: string = ""


    // Блок проверки div в тексте
    for (let i = 0; i <= changeValue.length; i++) {
        // Проверка на код в сообщении
        if (!checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 51) == "<div class=\"code__block--chat__message\"><code><xmp>" || changeValue.substring(i - 19, i) == "</xmp></code></div>")) {
            const resultCode = checkCodeInText(changeValue, newMass, i, prevIndex, checkCode);
            prevIndex = resultCode.prevIndex;
            newMass = resultCode.newMass;
            checkCode = resultCode.checkCode;
        }

        // Проверка на кавычки
        if (!checkCode && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 12) == '<blockquote>' || changeValue.substring(i - 13, i) == "</blockquote>")) {
            const resultBlockquote = checkBlockquoteInText(changeValue, newMass, i, prevIndex, checkQuote);
            prevIndex = resultBlockquote.prevIndex;
            newMass = resultBlockquote.newMass;
            checkQuote = resultBlockquote.checkQuote;
        }

        // Проверка на bold
        if (!checkCode && !checkQuote && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 8) == '<strong>' || changeValue.substring(i - 9, i) == "</strong>")) {

            const resultBold = checkBoldInText(changeValue, newMass, i, prevIndex, checkBold);
            prevIndex = resultBold.prevIndex;
            newMass = resultBold.newMass;
            checkBold = resultBold.checkBold;
        }

        // Проверка на em
        if (!checkCode && !checkQuote && !checkBold && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 4) == '<em>' || changeValue.substring(i - 5, i) == "</em>")) {
            const resultBlockquote = checkEmInText(changeValue, newMass, i, prevIndex, checkEm);
            prevIndex = resultBlockquote.prevIndex;
            newMass = resultBlockquote.newMass;
            checkEm = resultBlockquote.checkEm;
        }

        // Проверка на Ul
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 4) == '<ul>' || changeValue.substring(i - 5, i) == "</ul>")) {
            const resultBlockquote = checkULInText(changeValue, newMass, i, prevIndex, checkUl);
            prevIndex = resultBlockquote.prevIndex;
            newMass = resultBlockquote.newMass;
            checkUl = resultBlockquote.checkUl;
        }

        //Проверка на audio
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 6) == '<audio' || changeValue.substring(i - 8, i) == "</audio>")) {
            const resultAudio = checkAudioInText(changeValue, newMass, i, prevIndex, chekAudio);
            prevIndex = resultAudio.prevIndex;
            newMass = resultAudio.newMass;
            chekAudio = resultAudio.checkAudio;
        }

        //Проверка на video
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 6) == '<video' || changeValue.substring(i - 8, i) == "</video>")) {
            const resultVideo = checkVideoInText(changeValue, newMass, i, prevIndex, checkVideo);
            prevIndex = resultVideo.prevIndex;
            newMass = resultVideo.newMass;
            checkVideo = resultVideo.checkVideo;
        }

        // Проверка на файл в тексте
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 74) == '<div className="input--chat__block--messages__message--file__chat--block">' ||
            changeValue.substring(i, i + 70) == '<div class="input--chat__block--messages__message--file__chat--block">' ||
            changeValue.substring(i, i - 16) == '</a></div></div>')) {
            const resultFile = checkFileInText(changeValue, newMass, i, prevIndex, chekFile);
            prevIndex = resultFile.prevIndex;
            newMass = resultFile.newMass;
            chekFile = resultFile.checkFile;
        }

        //Проверка на img
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkHref && !checkH && !checkMention &&
            (changeValue.substring(i, i + 10) == '<img src="' || changeValue.substring(i - 2, i) == "/>" && checkImg == true)) {
            const resultImg = checkImgInText(changeValue, newMass, i, prevIndex, checkImg);
            prevIndex = resultImg.prevIndex;
            newMass = resultImg.newMass;
            checkImg = resultImg.checkImg;
        }

        //Проверка на ссылку
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkH && !checkMention &&
            (changeValue.substring(i, i + 9) == '<a href="' || changeValue.substring(i - 4, i) == "</a>")) {
            const resultHref = checkHrefInText(changeValue, newMass, i, prevIndex, checkHref);
            prevIndex = resultHref.prevIndex;
            newMass = resultHref.newMass;
            checkHref = resultHref.checkHref;
        }

        // Проверка на H
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkMention &&
            changeValue.substring(i, i + 4) == '<h1>' || changeValue.substring(i - 5, i) == "</h1>" ||
            changeValue.substring(i, i + 4) == '<h2>' || changeValue.substring(i - 5, i) == "</h2>" ||
            changeValue.substring(i, i + 4) == '<h3>' || changeValue.substring(i - 5, i) == "</h3>" ||
            changeValue.substring(i, i + 4) == '<h4>' || changeValue.substring(i - 5, i) == "</h4>" ||
            changeValue.substring(i, i + 4) == '<h5>' || changeValue.substring(i - 5, i) == "</h5>" ||
            changeValue.substring(i, i + 4) == '<h6>' || changeValue.substring(i - 5, i) == "</h6>"
        ) {
            const resultH = checkHInText(changeValue, newMass, i, prevIndex, checkH);
            prevIndex = resultH.prevIndex;
            newMass = resultH.newMass;
            checkH = resultH.checkH;
        }

        // Проверка на Упоминание пользователя
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref &&
            (changeValue.substring(i, i + 43) == '<span class="fio__block—chat__message" id="' || changeValue.substring(i - 7, i) == "</span>")
        ) {
            const resultCheckMention = checkMentionInText(changeValue, newMass, i, prevIndex, checkMention, mentionPerson);
            prevIndex = resultCheckMention.prevIndex;
            newMass = resultCheckMention.newMass;
            checkMention = resultCheckMention.checkMention;
            mentionPerson = resultCheckMention.mentionPerson;
        }

        // Проверка на перенос строки
        if (!checkCode && !checkQuote && !checkBold && !checkEm && !checkUl && !chekAudio && !chekFile && !checkVideo && !checkImg && !checkHref && !checkH && !checkMention) {
            const resultIndent = checkIndent(changeValue, newMass, i, prevIndex);
            newMass = resultIndent.newMass;
            prevIndex = resultIndent.prevIndex;
        }
    }


    // Фильтруем пустые значения
    newMass = newMass.filter((xx) => xx.length > 0);

    // for (let i = 0; i < newMass.length; i++) {
    //     if (newMass[i].includes("</a></div></div>")) {
    //         let j = i - 1;
    //         while (!newMass[i].includes("href=") && j >= 0) {
    //             newMass[i] = newMass[j] + " " + newMass[i];
    //             newMass[j] = "";
    //             j--;
    //         }
    //     }
    // }


    newMass = newMass.map((value) => {
        //Убираем артефакты разбиения
        if (
            value == "<a" ||
            value == "<a\n" ||
            value == "<img" ||
            value == "<img\n" ||
            value == "/>" ||
            value == "<div" ||
            value.includes('href="[object') ||
            value == "/>\n" ||
            value == "<div\n"
        ) {
            value = "";
            return value;
        }

        if (value.includes('<hr>')) {
            return "-----"
        }

        if (value.slice(0, 1).includes("*") &&
            value.slice(value.length - 1, value.length).includes("*")) {
            return value;
        }

        if (value.slice(0, 1).includes("*") &&
            !value.slice(value.length - 1, value.length).includes("*")) {
            return value.slice(0, value.length - 2);
        }

        if (value.slice(value.length - 8, value.length).includes("</video>") || value.slice(value.length - 9, value.length).includes("</video>\n")) {
            let oneSplit = value.split('src="');
            let urlVideo = oneSplit[1].slice(0, oneSplit[1].length-12)

            let fileType = "V";

            const fileId = generateFileId(urlVideo, fileType);

            const newFileMap: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url:
                    `<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="` +
                    urlVideo +
                    `"/></video>`,
            };

            if (arrayChatFiles?.data) {
                arrayChatFiles?.data.push(newFileMap)
            } else {
                arrayChatFiles = {data: [newFileMap]}
            }

            value = "{" + fileId + "}";

            return value;
        }

        if (value.includes("</audio>")) {
            let oneSplit = value.split('src="');
            let urlAudio = oneSplit[1].split('">')[0]
            let nameAudio = oneSplit[1].split('">')[1].slice(0, oneSplit[1].split("")[1].length - 9)

            let fileType = "M";

            const fileId = generateFileId(urlAudio, fileType);

            const newFileMap: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: `<audio controls class="input--chat__block--messages__message--audio__chat--block" src="${urlAudio}">${nameAudio}</audio>`,
            };

            if (arrayChatFiles?.data) {
                arrayChatFiles?.data.push(newFileMap)
            } else {
                arrayChatFiles = {data: [newFileMap]}
            }

            value = "{" + fileId + "}";

            return value;
        }

        // На что-то влияет?
        // if (value.slice(value.length - 6, value.length).includes('')) {
        //     value = "";
        //     return value;
        // }
        if (value.includes('<div className="input--chat__block--messages__message--file__chat--block">') ||
            value.includes('<div class="input--chat__block--messages__message--file__chat--block">')
        ) {
            let oneSplit = value.split('"/><a href="');
            let urlImgFile = oneSplit[0].split('<img src="')[1]
            let urlFile = oneSplit[1].split('">')[0]
            let nameFile = oneSplit[1].split('">')[1].slice(0, oneSplit[1].split('">')[1].length - 17)
            let fileType = "F";

            const fileId = generateFileId(urlFile, fileType);

            const newFileMap: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: `<div class="input--chat__block--messages__message--file__chat--block"><div class="input--chat__block--messages__message--file__chat"><img src="${urlImgFile}"/><a href="${urlFile}">${nameFile}</a></div></div>`,
            };


            if (arrayChatFiles?.data) {
                arrayChatFiles?.data.push(newFileMap)
            } else {
                arrayChatFiles = {data: [newFileMap]}
            }
            // arrayChatFiles = {data: [...fileMap.data, newFileMap]};

            value = "{" + fileId + "}";

            return value;
        }

        if (value.slice(0, 6).includes("href=")) {
            value = value.slice(6, value.length - 4);
            value = value.split('"')[0];
            return value;
        }

        if (value.slice(0,10).includes("<img src=")) {
            let oneSplit = value.split('src="');
            let urlImg = oneSplit[1].slice(0, oneSplit[1].length-4)

            let fileType = "G";

            const fileId = generateFileId(urlImg, fileType);

            const newFileMap: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: `<img src="${urlImg}" />`,
            };

            if (arrayChatFiles?.data) {
                arrayChatFiles?.data.push(newFileMap)
            } else {
                arrayChatFiles = {data: [newFileMap]}
            }
            value = "{" + fileId + "}";

            return value;
        }

        return value;
    });

    newMass = newMass.filter((xx) => xx != "").filter((x) => x != " ");
    changeValue = "";

    newMass= newMass.map(xx => {
        if (xx.includes("</br>")) {
            return xx.replace("</br>", "");
        }else {
            return xx;
        }
    })

    for (let i = 0; i < newMass.length; i++) {
        if (newMass[i].includes("</br>")) {
            let newMassSplit = newMass[i].split("</br>").filter((x) => x !== "");
            if (newMassSplit?.length > 0) {
                newMassSplit.forEach((yy) => {
                    if (yy != "") {
                        changeValue += yy;
                    }
                });

                changeValue += " ";
            }
        } else {
            if (newMass[i] == "```\n" || newMass[i+1] == "```" || i == newMass.length-1) {
                changeValue += newMass[i];
            } else if (newMass[i + 1] == "\n") {
                changeValue += `${newMass[i]}`;
            } else if (newMass[i] == "\n") {
                changeValue += `${newMass[i]}`;
            } else {
                changeValue += newMass[i] + " ";
            }
        }
    }

    disassembledText = changeValue;

    return {disassembledText, arrayChatFiles, mentionPerson}
}