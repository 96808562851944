export enum SortType {
    date = "date",
    text = "text",
    number = "number",
}

/**
 * Сортирует массив данных в зависимости от указанного типа сортировки.
 *
 * @param {SortType} type - Тип сортировки: 'date' (дата), 'text' (текст), 'number' (число).
 * @param {string[] | number[]} data - Массив данных для сортировки.  Должен содержать только строки (для 'date' и 'text') или только числа (для 'number').
 * @returns {string[] | number[] | undefined} - Отсортированный массив данных того же типа, что и входной массив. Возвращает `undefined`, если произошла ошибка (неверный тип данных или ошибка сортировки).
 *
 * Функция выполняет сортировку массива данных в зависимости от указанного типа сортировки.  Для дат используется сортировка по времени, для строк - лексикографическая сортировка, для чисел - числовая сортировка.
 * Функция проверяет тип данных и обрабатывает потенциальные ошибки. Если входные данные имеют неверный формат, функция возвращает `undefined` и выводит сообщение об ошибке в консоль.
 */
export const sortAny = <T>(
    type: SortType,
    data: T[],
    sortDirection: "asc" | "desc",
    sortField?: keyof T // Поле, по которому нужно сортировать
): T[] | undefined => {
    try {
        const multiplier = sortDirection === "asc" ? 1 : -1;

        if (type === SortType.date) {
            return data.sort((a, b) => {
                const dateA = new Date(a[sortField as keyof T] as string).getTime();
                const dateB = new Date(b[sortField as keyof T] as string).getTime();
                return multiplier * (dateA - dateB);
            });
        } else if (type === SortType.text) {
            return data.sort((a, b) => {
                const textA = a[sortField as keyof T] as string;
                const textB = b[sortField as keyof T] as string;
                return multiplier * textA.localeCompare(textB);
            });
        } else if (type === SortType.number) {
            return data.sort((a, b) => {
                const numA = a[sortField as keyof T] as number;
                const numB = b[sortField as keyof T] as number;
                return multiplier * (numA - numB);
            });
        }
    } catch (error) {
        console.error("Неверный формат данных", error);
        return undefined;
    }

    return undefined;
};