import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {ProgressBar} from 'primereact/progressbar';
import "../../styles/generalInformation.pc.css";
import {IProjectProgressInfo} from "../../../models/ProjectModels";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import OpenBlock from "../../../assets/openBlock.svg";
import GraphRenderer, {REPORT_LIST} from "../../GraphRenderer/GraphRenderer";
import {Context} from "../../../index";
import {TabPanel, TabView} from "primereact/tabview";
import {AppContext} from "../../../App";
import {Dropdown} from 'primereact/dropdown';
import StatisticsService from "../../../services/StatisticsService";
import "./GeneralInformation.css";
import {fullNameFormat} from "../../../helpers/Inicials";
import {ISideBarBoard, ISideBarProject, ISideBarSubproject} from "../../../models/LeftMenuModels";
import {IGetTaskData, IOverdueTask} from "../../GraphRenderer/GraphTypes";
import Button from "../../UiLib/Button/Button";
import {Table, TableBody, TableHead, TableTd, TableTh, TableTr} from "../../UiLib/Table/Table";

interface IProjectProgressProps {
    data: IProjectProgressInfo | undefined;
    projectId: number;
}

interface ISideBarBoardWithGraphParent extends ISideBarBoard {
    graph_parent?: number;
}

interface ISideBarProjectWithGraphParent extends ISideBarProject {
    graph_parent?: number;
}

interface ISideBarSubProjectWithGraphParent extends ISideBarSubproject {
    graph_parent?: number;
}

interface IOverdueTasksDisplay {
    id: number,
    name: string,
    datestart: string | undefined,
    dateend: string | undefined,
    remaininghours: number,
    completionpercent: number,
    responsible: string,
    rolename: string
}

const RenderBigFatProgressBar = (data: any, currentBoard: any | undefined, currentProject: ISideBarProjectWithGraphParent | ISideBarSubProjectWithGraphParent | undefined) => {
    let progressPercent = data?.progressPercent ?? 0
    let projectName = currentProject?.name || "Проект не был найден"
    return (
        <div className="big-fat-progress-bar">
            {progressPercent > 0 && (
                <div className='progress-bar-render'
                style={{
                    width: `${progressPercent}%`,
                    backgroundColor: 'var(--big-fat-progress-bar)'
                }}
            /> 
            )}
            
                <div className='new_bar' style={{width: "fit-content", overflow: "visible"}}>
                    <div className="h2_20-black">
                        <span style={{whiteSpace: 'nowrap'}}>
                            {currentBoard?.name || ""}
                        </span>
                    </div>
                    <div style={{whiteSpace: "nowrap"}}>{projectName}</div>
                    <div className="h1_60">{progressPercent}%</div>
                </div>
        </div>
    )
}

const GeneralInformation: React.FC<IProjectProgressProps> = ({data, projectId}) => {
    const {store} = useContext(Context);
    const {sideBarProjects} = useContext(AppContext);

    const [progressInfo, setProgressInfo] = useState<IProjectProgressInfo | undefined>(undefined)
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false)
    const [currentReport, setCurrentReport] = useState<REPORT_LIST>(REPORT_LIST.overdue_tasks)
    const [currentGraph, setCurrentGraph] = useState<any>(undefined)

    const [availableProjects, setAvailableProjects] =
        useState<(ISideBarProjectWithGraphParent | ISideBarSubProjectWithGraphParent)[]>([])
    const [currentProject, setCurrentProject] =
        useState<ISideBarProjectWithGraphParent | ISideBarSubProjectWithGraphParent | undefined>(undefined)
    const [availableBoards, setAvailableBoards] = useState<any>([])
    const [currentBoard, setCurrentBoard] = useState<any>(undefined)

    const [warningMsg, setWarningMsg] = useState<string | undefined>(undefined)
    const [invalidTasks, setInvalidTasks] = useState<any[]>([]);

    //Секретный тест дебуг
    const [showSecret, setShowSecret] = useState(false)
    const [testData, setTestData] = useState<IGetTaskData[]>([])

    useEffect(() => {
        parseProjectStructure()
    }, [openOnFullWindow, projectId]);

    useEffect(() => {
        if (openOnFullWindow) {
            LoadGraph(currentReport)
        }
    }, [openOnFullWindow, currentReport, currentProject, currentBoard]);

    /**@deprecated отладочная история по итогу умрёт смертью храбрых*/
    const getTestData = async () => {
        let data: IGetTaskData[] = (await StatisticsService.getTestData({
            projectId: currentProject!.id,
            boardId: currentBoard!.id
        })).data;
        console.log(data)
        setTestData(data)
    }

    function parseProjectStructure() {

        let allProjectsFlat: (ISideBarProjectWithGraphParent | ISideBarSubProjectWithGraphParent)[] = []
        let allBoardsFlat: ISideBarBoardWithGraphParent[] = []

        //плохая сортировка
        for (let project of sideBarProjects as ISideBarProject[]) {
            allProjectsFlat.push(Object.assign({}, project))
            for (let board of project.boards as ISideBarBoardWithGraphParent[] ?? []) {
                let board_item = Object.assign({}, board)
                board_item.graph_parent = project.id
                allBoardsFlat.push(board_item)
                for (let sub_board of board.boards as ISideBarBoardWithGraphParent[] ?? []) {
                    let sub_board_item = Object.assign({}, sub_board)
                    sub_board_item.graph_parent = project.id
                    allBoardsFlat.push(sub_board_item)
                }
            }

            for (let sub_project of project.subprojects as ISideBarSubProjectWithGraphParent[] ?? []) {
                let sub_project_item = Object.assign({}, sub_project)
                sub_project_item.graph_parent = project.id
                allProjectsFlat.push(sub_project_item)
                for (let board of sub_project.boards as ISideBarBoardWithGraphParent[] ?? []) {
                    let board_item = Object.assign({}, board)
                    board_item.graph_parent = sub_project.id
                    allBoardsFlat.push(board_item)
                    for (let sub_board of board.boards as ISideBarBoardWithGraphParent[] ?? []) {
                        let sub_board_item = Object.assign({}, sub_board)
                        sub_board_item.graph_parent = sub_project.id
                        allBoardsFlat.push(sub_board_item)
                    }
                }
            }
        }

        allProjectsFlat = allProjectsFlat.filter(xx => xx.id === projectId || xx.graph_parent === projectId)
        allBoardsFlat = allBoardsFlat.filter(xx => !xx.isProxyBoard)
        let thisProjectBoards = allBoardsFlat.filter(xx => xx.graph_parent === projectId)


        setAvailableProjects(allProjectsFlat)
        setAvailableBoards(allBoardsFlat)
        setCurrentProject(allProjectsFlat[0])
        setCurrentBoard(thisProjectBoards[0])
    }

    function graphWarning() {
        if (invalidTasks?.length) {
            return (<div className='graph-warning-block'>
                <a style={{fontWeight: "bold", marginBottom: "8px"}}>⚠️ {warningMsg}</a><br/>
                Заполните задачи: {invalidTasks?.map(task => {
                return (<a href={`/task/${task.id}`}>{task.name},</a>)
            })}
                {/*Заполните задачи: {(invalidTasks as any[])?.map(xx=> xx.name)?.join(', ')}*/}
            </div>)
        } else {
            return (<></>)
        }

    }

    function buildOverdueTasks(rawData: IOverdueTask[]) {
        let display_tasks: IOverdueTasksDisplay[] = []


        for (let task of rawData ?? []) {
            let responsible = task.responsible//Тут не совпадает


            display_tasks.push({
                id: task.id,
                name: task.name,
                datestart: convertDate(task.dateStart),
                dateend: convertDate(task.dateEnd),
                remaininghours: (task.remainingHours ?? 0) / 60,
                completionpercent: task.completionPercent ?? 0,
                responsible: responsible ? fullNameFormat({...responsible}, 's N M') : '-',
                rolename: task.roleName
            })
        }

        if (!display_tasks.length) {
            return (<></>)
        }

        let highlightRow = true;

        return (<table id='report-special-table'>
            <thead className="custom_table_thead">
            <tr>
                <th className='a_header_name_16'>ЗАДАЧА</th>
                <th className='a_header_name_16'>НАЧАЛО</th>
                <th className='a_header_name_16'>ОКОНЧАНИЕ</th>
                <th className='a_header_name_16' style={{width: '200px'}}>ОСТАВШИЕСЯ ТРУДОЗАТРАТЫ, Ч</th>
                <th className='a_header_name_16'>% ЗАВЕРШЕНИЯ</th>
                <th className='a_header_name_16'>ИСПОЛНИТЕЛЬ</th>
                <th className='a_header_name_16'>РОЛЬ</th>
            </tr>
            </thead>
            <tbody>
            {display_tasks.map(task => {
                highlightRow = !highlightRow;
                return (
                    <tr key={Math.random()} className={`${highlightRow ? 'table-row-striped-highlight' : ''}`}>
                        <td>
                            <div className='td-inner-limiter'><a href={`/task/${task.id}`}>{task.name}</a></div>
                        </td>
                        <td>{task.datestart ?? '-'}</td>
                        <td>{task.datestart ?? '-'}</td>
                        <td>{Number(task.remaininghours)?.toFixed(2)}</td>
                        <td>{task.completionpercent}</td>
                        <td>
                            <div className='td-inner-limiter'>{task.responsible}</div>
                        </td>
                        <td>
                            <div className='td-inner-limiter'>{task.rolename}</div>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>)
    }

    async function LoadGraph(currentGraph: REPORT_LIST) {
        if (!currentProject) {
            return
        }

        //Статистика по прогрессу, с "фильтрами"
        let data: any = undefined
        try {
            data = await StatisticsService.getStatisticsData({
                projectId: currentProject?.id,
                boardIds: currentBoard?.id ? [currentBoard?.id] : []
            })
        } catch (err: any) {
            // setWarningMsg(err?.response?.data?.message ?? undefined)
            // setInvalidTasks(err?.response?.data?.invalidTasks ?? [])
        }

        setProgressInfo(data?.data)


        switch (currentGraph) {
            case REPORT_LIST.burn_down: {
                let data: any = undefined
                try {
                    data = await StatisticsService.getBurndown({
                        projectId: currentProject?.id,
                        boardId: currentBoard?.id
                    })
                } catch (err: any) {
                    setWarningMsg(err?.response?.data?.message ?? undefined)
                    setInvalidTasks(err?.response?.data?.invalidTasks ?? [])
                }

                if (data) {
                    setInvalidTasks([])
                }

                setCurrentGraph(<GraphRenderer rawData={data?.data} reportType={currentReport} secretDebug={showSecret}
                                               board={currentBoard?.id} project={currentProject?.id}/>)
                break;
            }
            case REPORT_LIST.burn_up: {
                let data: any = undefined
                try {
                    data = await StatisticsService.getBurnup({
                        projectId: currentProject?.id,
                        boardId: currentBoard?.id
                    })
                } catch (err: any) {
                    setWarningMsg(err?.response?.data?.message ?? undefined)
                    setInvalidTasks(err?.response?.data?.invalidTasks ?? [])
                }

                if (data) {
                    setInvalidTasks([])
                }

                setCurrentGraph(
                    <GraphRenderer rawData={data?.data} reportType={currentReport} secretDebug={showSecret}
                                   board={currentBoard?.id} project={currentProject?.id}/>
                )
                break;
            }
            case REPORT_LIST.overdue_tasks: {
                let tasksStatus: any = undefined
                let data: any = undefined
                try {
                    tasksStatus = await StatisticsService.reportTasksStatus({
                        projectId: currentProject?.id,
                        boardId: currentBoard?.id
                    })
                } catch (err: any) {
                    setWarningMsg(err?.response?.data?.message ?? undefined)
                    setInvalidTasks(err?.response?.data?.invalidTasks ?? [])
                }
                try {
                    data = await StatisticsService.reportGetOverdueTasks({
                        projectId: currentProject?.id,
                        boardId: currentBoard?.id
                    })
                } catch (err) {

                }
                if (data) {
                    setInvalidTasks([])
                }

                setCurrentGraph(<>
                    <div className='special-pie-graph-container'>
                        {<GraphRenderer rawData={tasksStatus?.data} reportType={currentReport} secretDebug={showSecret}
                                        board={currentBoard?.id} project={currentProject?.id}/>
                        }
                    </div>
                    <div className='report-special-table-container'>
                        <div className='report-special-table-wrapper'>
                            {buildOverdueTasks(data?.data)}
                        </div>
                    </div>
                </>)
                break;
            }
            default:
                return (<></>)
        }
    }

    const handleProjectChange = (e: { value: any }) => {
        let foundProject = availableProjects.find((xx: any) => xx.id == e.value)
        setCurrentProject(foundProject);
        setCurrentBoard(availableBoards.filter((xx: any) => xx.graph_parent === foundProject?.id)[0])
    };

    const handleBoardChange = (e: { value: any }) => {
        let foundBoard = availableBoards.find((xx: any) => xx.id == e.value)
        setCurrentBoard(foundBoard);
    };

    const RenderFullMode = (data: any) => {
        return (<>
            <div style={{scale: "1"}} className="genInfo-bigmode-header-wrapper">
                <div className="big-fat-progress-bar-wrapper">
                    {RenderBigFatProgressBar(progressInfo, currentBoard, currentProject)}
                </div>
                <div className="filters-and-selectors-wrapper">
                    <Dropdown onChange={handleProjectChange} optionLabel="label"
                              style={{width: '100%'}}
                              value={currentProject?.id}
                              options={availableProjects?.map((item: any) => {
                                  return {
                                      label: item.name,
                                      value: item.id
                                  }
                              })} placeholder='Выбрать доску'/><br/><br/>

                    <Dropdown onChange={handleBoardChange} optionLabel="label"
                              style={{width: '100%'}}
                              value={currentBoard?.id}
                              options={[{
                                  label: "Нет доски",
                                  value: undefined
                              }, ...availableBoards?.filter((item: any) => item.graph_parent == currentProject?.id)
                                  .map((item: any) => {
                                      return {
                                          label: item.name,
                                          value: item.id
                                      }
                                  })]} placeholder='Выбрать доску'/><br/>
                    {showSecret ? <div style={{padding: '8px'}}>
                        Test JSON
                        <input style={{height: '30px', width: '30px'}} id='super-duper-graph-test-check'
                               type="checkbox"/>
                        <input id='super-duper-graph-test-input' type="text"
                               defaultValue={JSON.stringify(fakeData)}/>
                        <br/>
                    </div> : <></>}
                </div>
                <div style={{marginLeft: "auto"}}>
                    {RenderMiniMode(progressInfo)}
                </div>
            </div>
            <TabView activeIndex={currentReport}
                     onTabChange={(e) => {
                         setCurrentReport(e.index)
                     }}>
                <TabPanel header="Просроченные задачи"></TabPanel>
                <TabPanel header="Burn Down"></TabPanel>
                <TabPanel header="Burn Up️"></TabPanel>
            </TabView>

            <div className="genInfo-bigmode-body-wrapper">
                {graphWarning()}
                <div className='plot-block-wrapper'>
                    <div className="graph-wrapper">

                        {currentGraph}
                    </div>
                </div>
            </div>
            {showSecret ? <div>
                <Button callBack={getTestData} text={"Получение данных построения графика"}></Button>
                {testData?.length > 0 ?
                    <div><Table children={<Table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <TableHead>
                            <TableTr>
                                <TableTh>id</TableTh>
                                <TableTh>Номер тикета</TableTh>
                                <TableTh>Наименование</TableTh>
                                <TableTh>Дата создания</TableTh>
                                <TableTh>Дата начала</TableTh>
                                <TableTh>Дата окончания</TableTh>
                                <TableTh>Запланировано часов</TableTh>
                                <TableTh>Дата смена статуса</TableTh>
                                <TableTh>Старый статус</TableTh>
                                <TableTh>Новый статус</TableTh>
                                <TableTh>Актуальный статус</TableTh>
                            </TableTr>
                        </TableHead>
                        <TableBody>
                            {testData.map(xx => {
                                return (

                                    <TableTr>

                                        <TableTh>{xx.id}</TableTh>
                                        <TableTh>{xx.idForView}</TableTh>
                                        <TableTh>{xx.shortDescription}</TableTh>
                                        <TableTh>{xx.createdAt}</TableTh>
                                        <TableTh>{xx.startDate}</TableTh>
                                        <TableTh>{xx.endDate}</TableTh>
                                        <TableTh>{(xx.allottedTime / 60).toFixed(1)}</TableTh>
                                        <TableTh>{xx.dateOfChangeStatus}</TableTh>
                                        <TableTh>{xx.oldStatusId}</TableTh>
                                        <TableTh>{xx.newStatusId}</TableTh>
                                        <TableTh>{xx.actualStatusId}</TableTh>


                                    </TableTr>
                                )
                            })}
                        </TableBody>
                    </Table>}/></div> : null}
            </div> : null}
        </>)
    }

    const RenderMiniMode = (data: any, showSmallProgressBar?: boolean) => {
        return (
            <>
                <div className='genInfo_body'>
                    <div className='genInfo_body_item'>
                        <p className='genInfo_body_item_p11 p_gray'>НАЧАЛО/ОКОНЧАНИЕ {openOnFullWindow ? "ПЕРИОДА" : "ПРОЕКТА"}</p>
                        <div style={{width: "16px"}}></div>
                        <p className='genInfo_body_item_p11 p_blue'>{data?.startDate ? toDisplayDate(data?.startDate) : ""}/{`${data?.endDate ? toDisplayDate(data?.endDate) : ""}`}</p>
                    </div>
                    <div className='genInfo_body_item'>
                        <p className='genInfo_body_item_p11 p_gray'>ЗАДАЧ В РАБОТЕ</p>
                        <div style={{width: "16px"}}></div>
                        <p className='genInfo_body_item_p11 p_blue'>{data?.tasksInProgress}</p>
                    </div>
                    <div className='genInfo_body_item'>
                        <p className='genInfo_body_item_p11 p_gray'>ВЫПОЛНЕНО ЗАДАЧ</p>
                        <div style={{width: "16px"}}></div>
                        <p className='genInfo_body_item_p11 p_blue'>{data?.tasksCompleted}</p>
                    </div>
                    <div className='genInfo_body_item'>
                        <p className='genInfo_body_item_p11 p_gray'>ПРОСРОЧЕНО ЗАДАЧ</p>
                        <div style={{width: "16px"}}></div>
                        <p className='genInfo_body_item_p11 p_red'>{data?.tasksOverdue}</p>
                    </div>
                    <div className='genInfo_body_item'>
                        <p className='genInfo_body_item_p11 p_gray'>ПРОГРЕСС</p>
                        <div style={{width: "16px"}}></div>
                        <p className='genInfo_body_item_p11 p_blue'>{data?.progressPercent}%</p>
                    </div>
                    {showSmallProgressBar ? <ProgressBar value={data?.progressPercent ?? 0}></ProgressBar> : <></>}
                </div>
            </>
        )
    }

    return (
        <div id='project-progress-info-widget-component'
             className={`project-files-widget-component ${openOnFullWindow ? 'widget_wrapper--full__window' : 'widget_wrapper'}`}>
            <div
                className={`${openOnFullWindow ? 'full-screen-vertical-flex widget_wrapper-full__window--block fit_content_variant' : ''}`}>
                <div className={'widgets_header--container'}>
                    {!store.getBlockProjectReports() ?
                        openOnFullWindow ?
                            <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>
                                <img src={CloseBlockWindow}/>
                            </div>
                            :
                            <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>
                                <img src={OpenBlock}/>
                            </div>
                        : null}
                </div>
                <div className='widget_header'>
                    <div className="widjets_header_left">
                        <h2 className='widget-card-header-style' onClick={() => setShowSecret(!showSecret)}>Общие
                            сведения</h2>
                    </div>
                </div>
                {openOnFullWindow ? RenderFullMode(progressInfo) : RenderMiniMode(data, true)}
            </div>
        </div>
    )
}

export default observer(GeneralInformation);

function convertDate(dateStr: string) {
    if (!dateStr) {
        return
    }
    const [year, month, day] = dateStr.split('-');
    return `${day}.${month}.${year}`;
}

function toDisplayDate(dateStr: string): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) => value.toString().padStart(2, '0');
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1);
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    } catch (err: any) {
        return "-"
    }
}

let fakeData = {
    actualHours: [
        {
            date: '2025-01-01',
            hours: -20
        },
        {
            date: '2025-01-02',
            hours: -30
        },
        {
            date: '2025-01-03',
            hours: -35
        },
        {
            date: '2025-01-04',
            hours: 40
        },
        {
            date: '2025-01-05',
            hours: 50
        },
        {
            date: '2025-01-06',
            hours: 60
        },
        {
            date: '2025-01-07',
            hours: 80
        },
        {
            date: '2025-01-08',
            hours: 90
        },
        {
            date: '2025-01-09',
            hours: 130
        },
        // {
        //     date: '10.01.2025',
        //     hours: 140
        // },
        // {
        //     date: '11.01.2025',
        //     hours: 165
        // },
        // {
        //     date: '12.01.2025',
        //     hours: 170
        // },
        {
            date: '2025-01-10',
            hours: 190
        },
        {
            date: '2025-01-11',
            hours: 200
        },
        {
            date: '2025-01-12',
            hours: 220
        }
    ],
    idealHours: [
        {
            date: '2025-01-01',
            hours: 30
        },
        {
            date: '2025-01-12',
            hours: 150
        },
        {
            date: '2025-01-16',
            hours: -10
        }
    ]
}
// let fakeData2: IPieChartPart[] = [
//     {
//     label: 'Жабы',
//     value: 150
//     },{
//         label: 'Крысы',
//         value: 30
//     },{
//         label: 'Обезьяны',
//         value: 270
//     },{
//         label: 'Хомяки',
//         value: 65
//     },{
//         label: 'Жабы',
//         value: 150
//     },{
//         label: 'Крысы',
//         value: 30
//     },{
//         label: 'Обезьяны',
//         value: 270
//     },{
//         label: 'Хомяки',
//         value: 65
//     }
// ]