import React, {CSSProperties, FC} from 'react';
import "./TabNavigator.css"

import {useState, ReactNode, cloneElement, isValidElement
} from 'react';

interface ITabNavigatorProps {
    children: ReactNode;
    style?: CSSProperties;
    classBlock?: string | string[];
}


interface ICurrentTabProps {
    name: string;
    children: ReactNode;
    callBack?: () => void;
    style?: CSSProperties;
    className?: string;
    disabled?: boolean;

}

/**
 * Компонент вкладок для навигатора.
 *
 * @param {String} name - Имя вкладки.
 * @param {ReactNode} props.children - Дочерние элементы, которые представляют вкладки (`CurentTab`).
 * @param {() => void} callBack - Функция обратного вызова, которая будет выполнена при клике на вкладку.
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру.
 * @param {string} [props.className] - Класс контейнера.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент навигации с вкладками.
 */
const CurrentTab: FC<ICurrentTabProps> = ({name, children, callBack, style, disabled}) => {
    return (
        <div style={{...style}} onClick={callBack}>
            {children}
        </div>
    );
}

/**
 * Компонент для отображения навигации с помощью вкладок.
 *
 * @param {ITabNavigatorProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы, которые представляют вкладки (`CurentTab`).
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент навигации с вкладками.
 */
const TabNavigator: FC<ITabNavigatorProps> = ({children, style, classBlock}) => {
    const [currentTab, setCurrentTab] = useState(0);

    const tabs = React.Children.toArray(children).filter(child =>
        React.isValidElement(child) && child.type === CurrentTab
    ) as React.ReactElement<ICurrentTabProps>[];

    const handleTabClick = (index: number) => {
        if (tabs[index]?.props?.disabled) {

            return;
        }

        setCurrentTab(index);

        // Вызов callBack
        if (typeof tabs[index]?.props?.callBack === 'function' && tabs[index]?.props?.callBack !== undefined) {
            tabs[index].props.callBack!();
        }
    };

    return (
        <div style={{...style}} className={`${classBlock} ui__component--tab__navigator`}>
            <div className="ui__component--navigator">
                {tabs.map((tab, index) => (
                    <div key={index} className={`ui__component--navigator__tab ${index === currentTab ? "ui__component--navigator__tab--selected" : ""}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.props.name}
                    </div>
                ))}
            </div>
            <div className="tab-navigator__content">
                {tabs[currentTab] ? tabs[currentTab].props.children : null}
            </div>
        </div>
    );
};

export  { TabNavigator, CurrentTab };