import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import {Context} from "../../index";
import {options_linear, options_pie} from './GraphOptions'
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import {IPieChartPart} from "./GraphTypes";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

export enum REPORT_LIST {
    overdue_tasks = 0,
    burn_down = 1,
    burn_up = 2
}

enum PIE_NAME_MAP {
    finished = "Завершенные",
    inProgress = "В работе",
    new = "Новые",
    Overdue = "Опоздание"
}

interface IGraphRendererProps {
    rawData: any,
    reportType: REPORT_LIST,
    secretDebug: boolean,
    board: number | undefined,
    project: any
}

function graphPalletMain(){
    return [
        pullColorFromVariable('--graph-red-main'),
        pullColorFromVariable('--graph-blue-main'),
        pullColorFromVariable('--graph-purple-main'),
        pullColorFromVariable('--graph-pink-main'),
        pullColorFromVariable('--graph-orange-main'),
        pullColorFromVariable('--graph-yellow-main'),
        pullColorFromVariable('--graph-salat-main'),
        pullColorFromVariable('--graph-green-main')
    ]
}

function graphPalletFill(){
    return [
        pullColorFromVariable('--graph-red-fill'),
        pullColorFromVariable('--graph-blue-fill'),
        pullColorFromVariable('--graph-purple-fill'),
        pullColorFromVariable('--graph-pink-fill'),
        pullColorFromVariable('--graph-orange-fill'),
        pullColorFromVariable('--graph-yellow-fill'),
        pullColorFromVariable('--graph-salat-fill'),
        pullColorFromVariable('--graph-green-fill')
    ]
}

const GraphRenderer: React.FC<IGraphRendererProps> = ({rawData, reportType, secretDebug, board, project}) => {
    const {store} = useContext(Context);
    const [chartData, setChartData] = useState<any>(undefined)

    function buildBurnDown() {
        //Тут для теста фиговина
        let check = document.getElementById('super-duper-graph-test-check') as any
        let json = document.getElementById('super-duper-graph-test-input') as any

        let data = rawData
        if(check?.checked){
            data = JSON.parse(json?.value)
        }
        if(!data){
            setChartData(undefined)
            return
        }
        let labels: any[] = removeDuplicateDates(sortDates([
            ...data.actualHours?.map((item: { date: any; }) => item.date),
            ...data.idealHours?.map((item: { date: any; }) => item.date)
        ]))?.map(xx=> convertDate(xx))

        let graph_data = data.actualHours?.map((item: { hours: any; date: any; }) => {
            return {
                x: convertDate(item.date),
                y: item.hours / 60
            }
        })

        if(!data.actualHours?.length){
            setChartData(undefined);
            return;
        }

        let goodData = {
            labels: labels,
            datasets: [{
                label: 'Реальные Показатели',
                data: graph_data,
                borderColor: graphPalletMain()[1],
                backgroundColor: graphPalletFill()[1],
                spanGaps: true,
                parsing: {
                    xAxisKey: 'x',
                    yAxisKey: 'y'
                }
            },
            {
                label: 'Идеальные Показатели',
                data: data.idealHours?.map((item: { hours: any; date: any; }) => {
                    return {
                        x: convertDate(item.date),
                        y: item.hours / 60
                    }
                }),
                borderColor: graphPalletMain()[0],
                backgroundColor: graphPalletFill()[0],
                spanGaps: true,
                parsing: {
                    xAxisKey: 'x',
                    yAxisKey: 'y'
                }
            }],
        };

        setChartData(goodData);
    }
    function buildBurnUp() {
        //Это пока что идентично burn_down
        buildBurnDown();

    }
    function buildTaskStates(){
        let fillColors = graphPalletFill();
        let mainColors = graphPalletMain();

        function getPieSectorColor(sectorCode: string): {fill: string, main: string} | undefined {
            if(sectorCode === PIE_NAME_MAP.finished){return {fill: fillColors[7], main: mainColors[7]}}
            if(sectorCode === PIE_NAME_MAP.inProgress){return {fill: fillColors[1], main: mainColors[1]}}
            if(sectorCode === PIE_NAME_MAP.new){return {fill: fillColors[5], main: mainColors[5]}}
            if(sectorCode === PIE_NAME_MAP.Overdue){return {fill: fillColors[0], main: mainColors[0]}}

            return {fill: fillColors[0], main: mainColors[0]}
        }

        let labels = (rawData as IPieChartPart[])?.map(xx=> xx.label)
        if(!labels?.length){
            setChartData(undefined);
            return;
        }
        labels = labels?.map(xx=> (PIE_NAME_MAP as any)[xx])
        let mappedColors = labels?.map(xx => getPieSectorColor(xx))
 
        let data = (rawData as IPieChartPart[])?.map(xx=> xx.value)

        //Ну пусть пока так цвета в график выставляются
        let goodData = {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: [
                    ...mappedColors.map(xx=> xx?.fill),
                    ...graphPalletFill()
                ],
                borderColor: [
                    ...mappedColors.map(xx=> xx?.main),
                    ...graphPalletMain()
                ],
                borderWidth: 1
            }]
        };

        setChartData(goodData);
    }

    useEffect(() => {
        build()
    }, [reportType, board, project]);

    function build(){
        if(reportType == REPORT_LIST.burn_down){buildBurnDown();}
        if(reportType == REPORT_LIST.burn_up){buildBurnUp();}
        if(reportType == REPORT_LIST.overdue_tasks){buildTaskStates();}
    }

    function selectGraphType(){
        if(reportType == REPORT_LIST.burn_down){return (
            <>
                {chartData ? <Line options={options_linear as any} data={chartData}/> : <></>}
            </>
        )}
        if(reportType == REPORT_LIST.burn_up){return (
            <>
                {chartData ? <Line options={options_linear as any} data={chartData}/> : <></>}
            </>
        )}
        if(reportType == REPORT_LIST.overdue_tasks){return (
            <>
                {chartData ? <Pie options={options_pie as any} data={chartData}/> : <></>}
            </>
        )}
    }

    return (
        <>
            {selectGraphType()}
        </>
    )
}

export default observer(GraphRenderer);

function sortDates(dates: string[]): string[] {
    if (!Array.isArray(dates)) {
        throw new TypeError('Input must be an array');
    }

    return [...dates].sort((a, b) => {
        return a?.localeCompare(b);
    });
}

function convertDate(dateStr: string) {
    if (!dateStr){return}
    const [year, month, day] = dateStr.split('-');
    return `${day}.${month}`;
}

function removeDuplicateDates(dates: string[]): string[] {
    const uniqueDates = new Set<string>(dates);
    return Array.from(uniqueDates);
}

export function pullColorFromVariable(colorVariable: string): string {
    const graphColor = getComputedStyle(document.documentElement)
        ?.getPropertyValue(colorVariable)
        ?.trim();

    return graphColor
}