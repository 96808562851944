import React, {useContext, useEffect, useState} from "react";
import "./BoardMenu.css";
import { BoardContext } from "../../../pages/Board/Board";
import BoardMenuColumn from "../BoardMenuColumn/BoardMenuColumn";
import CreateColumn from "../CreateColumnForm/CreateColumnForm";
import ExitCross from "../../../assets/cancelGreey.svg"

import DeleteBoardBlue from "../../../assets/deleteElementGray.svg"
import DeleteBoardGray from "../../../assets/grayDelete.svg"

import EditBoardBlue from "../../../assets/edit.svg"
import EditBoardGray from "../../../assets/editButtonGray.svg"
import {AppContext} from "../../../App";
import {Context} from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";

export interface IBoardProps {
    userAccess: IHasPermission[]
}

const BoardMenu: React.FC<IBoardProps> = ({userAccess}) => {

    const {
        closeMenu,
        boardName,
        columns,
        handleMoveColumn,
        handleRenameBoard,
        handleDeleteBoard,
    } = useContext(BoardContext);

    const [previewColumnIndex, setPreviewColumnIndex] = useState<
        number | undefined
    >();
    const [dragEventTarget, setDragEventTarget] = useState<any>();
    const [editNameMode, setEditNameMode] = useState<boolean>(false);
    const [addColumnMode, setAddColumnMode] = useState<boolean>(false);
    const { showToast } = useContext(AppContext);
    const { store } = useContext(Context);

    // Ограничение прав
    const [editNameBoard, setEditNameBoard] = useState(false);
    const [delBoard, setDelBoard] = useState(false);
    const [addColumn, setAddColumn] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "BoardAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditNameBoard(true);
                    }
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelBoard(true);
                    }
                })
            }
            if (xx.functionCode == "ColumnAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddColumn(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setEditNameBoard(true);
        //     setDelBoard(true);
        //     setAddColumn(true);
        // }
    }, [userAccess]);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragEventTarget(e.target);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const droppedPosition = e.clientY;

        let insertIndex = columns.length;

        for (let column of columns) {
            const columnElement = document.getElementById(
                `column-${column.id}`
            );
            if (columnElement) {
                const rect = columnElement.getBoundingClientRect();
                const midY = rect.top + rect.height / 2;
                if (droppedPosition < midY) {
                    insertIndex = columns.indexOf(column);
                    break;
                }
            }
        }

        if (previewColumnIndex === insertIndex) return;

        removePreview();

        if (insertIndex < columns.length) {
            const columnElement = document.getElementById(
                `column-${columns[insertIndex].id}`
            );
            if (columnElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                columnElement.insertAdjacentElement("beforebegin", newElement);
                setPreviewColumnIndex(insertIndex);
            }
        } else {
            const columnElement = document.getElementById(
                `column-${columns[columns.length - 1].id}`
            );
            if (columnElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                columnElement.insertAdjacentElement("afterend", newElement);
                setPreviewColumnIndex(insertIndex);
            }
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragEventTarget !== e.target) {
            removePreview();
        }
    };

    const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        try {
            const { columnId } = JSON.parse(
                e.dataTransfer.getData("application/json")
            );

            const droppedPosition = e.clientY;

            const movedColumn = columns.find((c) => c.id === columnId);
            if (!movedColumn) return;

            let insertIndex = columns.length;

            for (let column of columns) {
                const columnElement = document.getElementById(
                    `column-${column.id}`
                );
                if (columnElement) {
                    const rect = columnElement.getBoundingClientRect();
                    const midY = rect.top + rect.height / 2;
                    if (droppedPosition < midY) {
                        insertIndex = columns.indexOf(column);
                        break;
                    }
                }
            }

            if (insertIndex !== columns.indexOf(movedColumn)) insertIndex--;
            if (insertIndex < columns.indexOf(movedColumn)) insertIndex++;

            if (insertIndex === columns.indexOf(movedColumn)) {
                return;
            }

            handleMoveColumn(columnId, insertIndex);
            removePreview();
        } catch (err) {
            removePreview();
        }
    };

    const handleDragEnd = () => {
        removePreview();
    };

    const removePreview = () => {
        const elements = document.querySelectorAll(".column-preview");
        elements.forEach((element) => element.remove());
        setPreviewColumnIndex(undefined);
    };

    const createPreviewElement = (content: string) => {
        let newElement = document.createElement("div");
        newElement.className = "column-preview";
        newElement.innerHTML = content;
        newElement.style.backgroundColor = "#149cce6b";
        newElement.style.padding = "25px";
        newElement.style.borderRadius = "6px";
        newElement.style.pointerEvents = "none";
        return newElement;
    };

    const handleNameChange = (e: any) => {
        if (e.key === "Enter") {
            handleRenameBoard(e.target.value);
            setEditNameMode(false);
        }
    };

    return (
        <div>
            <div className="board-menu-overlay">
                <div className="board-menu-container" onClick={(e) => e.stopPropagation()}>
                    <div className="board-name-row">
                        <div className="board-name-row__name">
                            {editNameMode ? (
                                <input
                                    defaultValue={boardName}
                                    onKeyDown={handleNameChange}
                                ></input>
                            ) : (
                                <h1 title={boardName}>{boardName}</h1>
                            )}
                            <button className="board-menu-overlay__button" onClick={() => (editNameBoard ? setEditNameMode(!editNameMode) : showToast("У вас нет прав для редактирования названия!"))}>
                                <img src={editNameBoard ? EditBoardBlue : EditBoardGray}/>
                            </button>
                            <button className="board-menu-overlay__button" onClick={() => (delBoard ? handleDeleteBoard() : showToast("У вас нет прав для удаления доски!"))}>
                                <img src={delBoard ? DeleteBoardBlue : DeleteBoardGray}/>
                            </button>
                        </div>
                        <button className="board-menu-overlay__button" onClick={closeMenu}>
                            <img src={ExitCross}/>
                        </button>
                    </div>
                    <div className="board-menu-overlay__button--crete">
                        <button style={addColumn ? {} : {background: "#75848A"}} onClick={() => (addColumn ? setAddColumnMode(!addColumnMode) : showToast("У вас нет прав для создания колонки!"))}>
                            {addColumnMode ? "Закрыть режим создания" : "Добавить колонку"}
                        </button>
                    </div>
                    {addColumnMode ? <CreateColumn userAccess={userAccess}/> : null}
                    <div>
                        <div className="board-menu-columns" onDragEnter={handleDragEnter} onDragOver={(e) => handleDragOver(e)}
                         onDrop={(e) => handleOnDrop(e)} onDragLeave={handleDragLeave}>
                            {columns.map((c, index) => (
                                <BoardMenuColumn
                                    key={c.id}
                                    column={c}
                                    onColumnDragEnd={handleDragEnd}
                                    draggable={index !== 0}
                                    deletable={index !== 0}
                                    userAccess={userAccess}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoardMenu;
