export const HistoryActions = new Map<string, string>([
  ["create", "Создание"],
  ["edit", "Редактирование"],
  ["move", "Перемещение"],
  ["delete", "Удаление"],
  ["changePassword", "Смена пароля"],
  ["invite", 'Приглашение'],
  ["archive", 'Архивация'],
  ["unarchive", 'Разархивация']
]);

//Для фильтра на странице проекта
export const ProjectHistoryActions = new Map<string, string>([
  ["create", "Создание"],
  ["edit", "Редактирование"],
  ["move", "Перемещение"],
  ["delete", "Удаление"],
  ["archive", 'Архивация'],
  ["unarchive", 'Разархивация']
]);