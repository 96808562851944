import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import "./MenuForm.css";
import "../../globalstyles/ControlElementsStyles.css";
import { Context } from "../../index";
import Logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import NavigationPanel from "./NavigationMenu/NavigationPanel";
import { IHasPermission } from "../../models/IChekRole";
import ArrowDown from "../../assets/arrowDown.svg";

interface MenuFormProps {
    userAccess: IHasPermission[];
}

const MenuForm = ({ userAccess }: MenuFormProps) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const [companyLogo, setCompanyLogo] = useState<string | undefined>("");
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(
        store.leftMenuCollapsed
    );

    function goToProject() {
        navigate("/projects");
    }

    useEffect(() => {
        (async () => {
            try {
                await store.getSystemSettings();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const picture = await store.getSystemLogo();
            setCompanyLogo(picture?.length > 0 ? picture : Logo);
        })();
    }, [store.systemSettings]);

    const toggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        store.setLeftMenuCollapsed(!menuCollapsed);
    };

    return (
        <div className={`menu-container ${menuCollapsed ? "collapsed" : ""}`}>
            <div className="menu-header">
                <img
                    className="logo"
                    alt="logo"
                    src={companyLogo}
                    onClick={goToProject}
                />
                <button className="collapse-button" onClick={toggleMenu}>
                    <img
                        src={ArrowDown}
                        alt="arrow"
                        style={{ rotate: menuCollapsed ? "270deg" : "90deg" }}
                    />
                </button>
            </div>

            <div className="menu-nav-panel">
                <NavigationPanel
                    userAccess={userAccess}
                    collapsed={menuCollapsed}
                />
            </div>
            {!menuCollapsed ? (
                <div className="menu-footer">
                    <a
                        className="wiki-link"
                        href={
                            "https://wiki.bpmlab.ru/doc/rukovodstvo-polzovatelya-dRyZh90XbW"
                        }
                        target="_blank"
                    >
                        Руководство пользователя
                    </a>
                </div>
            ) : null}
        </div>
    );
};

export default observer(MenuForm);
