import React, { useContext, useEffect, useState } from "react";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import Search from "../../UiLib/Search/Search";
import "./BoardToolbar.css";
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import BtnMove from "../../buttons/MoveButton/MoveButton";
import BtnSettings from "../../buttons/SettingsButton/SettingsButton";
import ActionModeButton from "../../buttons/BoardActionModeButton/ActionModeButton";
import BoardFastFilters from "../BoardFastFilters/BoardFastFilters";
import { BoardContext } from "../../../pages/Board/Board";
import { AppContext } from "../../../App";
import { Context } from "../../../index";
import { IHasPermission } from "../../../models/IChekRole";
import FilterContainer from "../../Shared/Filter/FilterContainer";

export interface IToolbarProps {
    onMassActionsFunc: () => void;
    selectedCount: number;
    userAccess: IHasPermission[];
}

const BoardToolbar: React.FC<IToolbarProps> = ({
    onMassActionsFunc,
    selectedCount,
    userAccess,
}) => {
    const [showMoveDropdown, setShowMoveDropdown] = useState<boolean>();
    const { showToast } = useContext(AppContext);

    // Ограничение прав
    const [editBoard, setEditBoard] = useState(false);
    const [delTask, setDelTask] = useState(false);
    const [editTask, setEditTask] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "BoardAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditBoard(true);
                    }
                });
            }
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelTask(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                });
            }
        });
    }, [userAccess]);

    const {
        taskNameSearch,
        setTaskNameSearch,
        actionMode,
        cancelActionMode,
        handleDeleteTasks,
        handleMoveTasks,
        handleMoveTasksOutsideBoard,
        selectedTasks,
        columns,
        openMenu,
        boardFilters,
        selectedBoardFilters,
        setSelectedBoardFilters,
        filterCounter,
        setFilterCounter,
        resetFilter,
        boardsForProxy,
        taskFilters,
        changeFastFilters,
        selectedFastFilters,
    } = useContext(BoardContext);

    const handleDeleteSubmit = () => {
        if (delTask) {
            handleDeleteTasks(selectedTasks);
        } else {
            showToast("У вас нет прав для удаления задач(и)!");
        }
    };

    const handleActionModeClick = () => {
        onMassActionsFunc();
    };

    const handleColumnClick = (e: any, columnId: number) => {
        handleMoveTasks(columnId);
        setShowMoveDropdown(false);
        e.stopPropagation();
    };

    const handleBoardClick = (e: any, boardId: number) => {
        handleMoveTasksOutsideBoard(boardId);
        setShowMoveDropdown(false);
        e.stopPropagation();
    };

    function onCancelClick() {
        resetFilter();
    }

    return (
        <div className="board-toolbar">
            <div className="toolbar-left">
                <BoardFastFilters
                    taskFilters={taskFilters}
                    changeFastFilters={changeFastFilters}
                    selectedFastFilters={selectedFastFilters}
                />
            </div>
            <div className="toolbar-right" style={{ alignSelf: "end" }}>
                {actionMode ? (
                    <CancelButton onClickFunc={cancelActionMode} />
                ) : null}
                {actionMode && selectedCount > 0 && (
                    <div className="mass-actions">
                        <BtnMove
                            onClickFunc={() =>
                                setShowMoveDropdown(!showMoveDropdown)
                            }
                            permission={editTask}
                        />
                        {showMoveDropdown ? (
                            <div className="move-task-popup-container">
                                <div
                                    className="move-task-popup-overlay"
                                    onClick={() => {
                                        setShowMoveDropdown(false);
                                    }}
                                ></div>
                                <div className="move-task-popup">
                                    <p className="move-task-popup-header">
                                        Переместить в колонку
                                    </p>
                                    <div className="move-task-popup-column-container">
                                        {columns.map((c) => (
                                            <p
                                                className="move-task-popup-column-text"
                                                onClick={(e) =>
                                                    handleColumnClick(e, c.id)
                                                }
                                            >
                                                {c.name}
                                            </p>
                                        ))}
                                    </div>
                                    <p className="move-task-popup-header">
                                        Переместить на доску
                                    </p>
                                    <div className="move-task-popup-column-container">
                                        {boardsForProxy.map((board) => (
                                            <p
                                                className="move-task-popup-column-text"
                                                onClick={(e) =>
                                                    handleBoardClick(
                                                        e,
                                                        board.id
                                                    )
                                                }
                                            >
                                                {board.name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <BtnDelete
                            onClickFunc={handleDeleteSubmit}
                            permission={delTask}
                        />
                    </div>
                )}
                {actionMode ? (
                    <span className="toolbar__point--task__counter">
                        {selectedCount}
                    </span>
                ) : (
                    <span className="toolbar__point--task__counter"></span>
                )}
                <ActionModeButton
                    onClickFunc={handleActionModeClick}
                    isActive={actionMode}
                    selectedCount={1}
                />
                <Search
                    value={taskNameSearch}
                    onSubmitFunc={() => {}}
                    changeSearchValue={setTaskNameSearch}
                />
                <FilterContainer 
                    filterCounter={filterCounter}
                    setFilterCounter={setFilterCounter}
                    selectedOptions={selectedBoardFilters}
                    setSelectedOptions={setSelectedBoardFilters}
                    filterOptions={boardFilters}
                    showResetButton={taskNameSearch.length > 0}
                    onReset={onCancelClick}
                />
                <BtnSettings onClickFunc={openMenu} permission={editBoard} />
            </div>
        </div>
    );
};

export default BoardToolbar;
