import { FC, } from "react";
import { observer } from "mobx-react-lite";
import filterIcon from "../../../assets/filter.svg";
import "../../buttons/btnFilter.component/BtnFilter.css";

interface IFilterProps {
    counter?: number;
    onClick?: () => void;
}

const BtnFilter: FC<IFilterProps> = ({ counter, onClick }) => {
    return (
        <div className="btnFilterWrapper">
            <div className="btnFilter" onClick={onClick}>
                <img src={filterIcon} />
            </div>
            {counter && counter !== 0 ? (
                <div className="label_counter">{counter}</div>
            ) : null}
        </div>
    );
};

export default observer(BtnFilter);
