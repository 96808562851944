import { FC } from "react";
import { Toast } from "../../../App";
import CancelIcon from "../../../assets/cancelGreey.svg";
import "./ToastNotification.css";

interface IToastNotificationProps {
    toasts: Toast[];
    removeToast: (toastId: string) => void;
}

const ToastNotification: FC<IToastNotificationProps> = ({
    toasts,
    removeToast,
}) => {
    return (
        <div className="toasts-container">
            {toasts.map((toast) => (
                <div key={toast.id} className="toast">
                    <div className="toast-body">
                        <p className="toast-text">{toast.text}</p>
                        {toast.content}
                    </div> 
                    <button
                        onClick={() => removeToast(toast.id)}
                        className="toast-cancel-button">
                        <img src={CancelIcon} />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ToastNotification;
