import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import Search from "../../UiLib/Search/Search";
import "../../../globalstyles/dataTableStyles.css";
import PersonLineDisplay from "../PersonLineDisplay";
import { Context } from "../../../index";
import { initials } from "../../../helpers/Inicials";

const PAGE_SIZE = 5;

export interface ITeamFilter {
    projectId: number;
    role: number[] | undefined;
    name: string | undefined;
}

interface IMember {
    id: number;
    name: string;
    surname: string;
    middleName: string | undefined;
    role: string;
    photoId: number | undefined;
}

interface IPersonSelectProps {
    members: IMember[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, filters: ITeamFilter) => void;
    updateFilters: (filters: ITeamFilter) => void;
    selectPerson: (personId: number) => void;
    projectId?: number;
}

const PersonSelector: React.FC<IPersonSelectProps> = ({
    members,
    scrollEnded,
    loadPage,
    selectPerson,
    projectId,
}) => {
    const { store } = useContext(Context);
    const page = useRef<number>(0);
    const filters = useRef<ITeamFilter>({
        projectId: projectId ?? store.currentProjectId,
        role: undefined,
        name: "",
    });
    const [searchSubstring, setSearchSubstring] = useState<string>("");
    const [membersForDisplay, setMembersForDisplay] = useState<IMember[]>([]);

    // const onScroll = (e: any) => {
    //     checkIfScrolledToBottom(e);
    // };


    // function checkIfScrolledToBottom(e: any) {
    //     const { scrollTop, offsetHeight, scrollHeight } = e.target;
    //     if (1 + scrollTop + offsetHeight >= scrollHeight && !scrollEnded) {
    //         page.current++;
    //         loadPage(page.current * PAGE_SIZE, PAGE_SIZE, filters.current);
    //     }
    // }

    useEffect(() => {
        const search = searchSubstring?.toLowerCase();
        const searchableFields: (keyof IMember)[] = [
            "name",
            "surname",
            "middleName",
            "role",
        ];

        let newMembers = members?.filter((member) => searchableFields.
            some((field) => typeof member[field] === "string" && String(member[field])?.toLowerCase().includes(search)))

        let filteredMembers: IMember[] = [];


        newMembers?.forEach((xx) => {
            if (filteredMembers.map(xx => xx.id).includes(xx.id)) {
                filteredMembers = filteredMembers.map(yy => {
                    if (yy.id == xx.id) {
                        yy.role = yy.role + ", " + xx.role

                        return yy
                    }
                    return yy
                })
            } else {
                filteredMembers.push(xx);
            }

        })
            // ?.filter((xx, index, self) => index === self.findIndex((t) => (t.id) === (xx.id)))

        setMembersForDisplay(filteredMembers);
    }, [members, searchSubstring]);

    function onPersonClick(key: number, e: any) {
        selectPerson(key);
        // getPersonMore(e);
    }

    // function getPersonMore(e: any) {
    //     if (members.length <= 5) {
    //         checkIfScrolledToBottom(e);
    //     }
    // }
    return (
        <div className="person-selector-container">
            <div className="widgets_header">
                <Search
                    onSubmitFunc={() => {}}
                    value={searchSubstring}
                    changeSearchValue={setSearchSubstring}
                />
            </div>
            <div className="person-selector-items_new">
                {membersForDisplay.map((member) => (
                    <div key={member.id} onClick={(e) => onPersonClick(member.id, e)} className="person-selection-item">
                        <div className="person-selector-items_new-name">
                            <PersonLineDisplay
                                name={initials(member.name)} // TODO нужно поменять на 3 переменных
                                photoId={member.photoId}
                            />
                        </div>
                        <div title={member.role} className="task-type-text-position ellipses">
                            <a>{member.role.length > 30 ? member.role.slice(0, 27) + "..." : member.role}</a>
                        </div>
                    </div>
                ))}
                {/*<table>*/}
                {/*    <tbody>*/}
                {/*        {membersForDisplay.map((member) => (*/}
                {/*            <tr title={member.role} key={member.id} onClick={(e) => onPersonClick(member.id, e)} className="person-selection-item">*/}
                {/*                <td>*/}
                {/*                    <PersonLineDisplay*/}
                {/*                        name={initials(member.name)} // TODO нужно поменять на 3 переменных*/}
                {/*                        photoId={member.photoId}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*                <td className="task-type-text-position ellipses">*/}
                {/*                    <a>{member.role}</a>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        ))}*/}
                {/*    </tbody>*/}
                {/*</table>*/}
            </div>
        </div>
    );
};

export default observer(PersonSelector);
