import { FC, ReactNode } from "react";
import "./ContainerWithOverlay.css";

interface IContainerWithOverlayProps {
    children: ReactNode;
    onOverlayClick: () => void;
    blackout?:boolean;
}


/**
 * Контейнер с оверлеем
 *
 * @param {ReactNode} children - Дочерние элементы.
 * @param{() => void} onOverlayClick - функция по нажатию на оверлей.
 * @returns {JSX.Element} JSX-элемент, представляющий компонент контенера с оверлеем.
 */
const ContainerWithOverlay: FC<IContainerWithOverlayProps> = ({
    children,
    onOverlayClick,
    blackout
}) => {
    const handleClick = () => {
        onOverlayClick();
    };

    return (
        <div className={blackout==false ?"overlay_container_without_background":"overlay_container"} onClick={handleClick}>
            <div
                className = "overlay_children"
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
};

export default ContainerWithOverlay;
