import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import "./InputChat.css";
import SimpleMDEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {Context} from "../../..";
import {useParams} from "react-router-dom";
import {format} from "date-fns";
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";
import unknown_svg from "../../../assets/FileIcons/unknown.svg";
import {IMessage, IMessageGroup} from "../../../models/IChatMessage";
import {equalDMY} from "../../../helpers/compareDates";
import {TaskContext} from "../../../pages/Task/Task";
import {FileIcons} from "./FileIcons";
import {convertMessage, formatEditMessage, generateFileId} from "../../../helpers/chatFucntion"
import {fullNameFormat} from "../../../helpers/Inicials";
import Avatar from "../Avatar/Avatar";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import UserPopupWrapper from "../UserPopup/UserPopupWrapper";
import Loader from "../Loader/Loader";
import HtmlPreview from "../../UiLib/HtmlPreview/HtmlPreview";
import {IFullUserInfo, IPerson} from "../../../models/IUser";
import UserMention from "../UserMention/UserMention";
import Button from "../../UiLib/Button/Button";
import Copy from "../../../assets/document-copy.svg"
import {dateToNiceString} from "../../../helpers/dateToNiceString";

type ToolbarButton =
    | "bold"
    | "italic"
    | "quote"
    | "unordered-list"
    | "ordered-list"
    | "link"
    | "image"
    | "strikethrough"
    | "code"
    | "table"
    | "redo"
    | "heading"
    | "undo"
    | "heading-bigger"
    | "heading-smaller"
    | "heading-1"
    | "heading-2"
    | "heading-3"
    | "clean-block"
    | "horizontal-rule"
    | "preview"
    | "side-by-side"
    | "fullscreen"
    | "guide";

interface Options {
    showIcons?: ReadonlyArray<ToolbarButton>;
}

interface IInputChat {
    userAccess: IHasPermission[];
    chatId: number;
    setMessagesCount? : React.Dispatch<React.SetStateAction<number>>
    projectId?: number;
}

const InputChat: FC<IInputChat> = ({userAccess, chatId, projectId, setMessagesCount}) => {
    const [isLoading, setIsLoading] = useState(true); //Требуется отслеживать загрузку данных, будем хранить состояние загрузки

    const {store} = useContext(Context);
    const [fileMap, setFileMap] = useState<{
        data: { type: string; id: string; url: string }[];
    }>({data: []});
    const [fileMapFront, setFileMapFront] = useState<{
        data: { type: string; id: string; url: string }[];
    }>({data: []});
    const [edit, setEdit] = useState(false);
    const [create, setCreate] = useState(false);
    const [idEditMessage, setIdEditMessage] = useState(-1);
    const [messageGroups, setMessageGroups] = useState<IMessageGroup[]>([]);

    const [editValueBackend, setEditValueBackend] = useState(""); 
    const [editValue, setEditValue] = useState("");
    const [value, setValue] = useState("");

    const {showToast} = useContext(AppContext);

    const {scrollComponentDiv, tab} = useContext(TaskContext);
    const [editedMessageDiv, setEditedMessageDiv] = useState<HTMLElement>();

    const [viewTaskChatMessage, setViewTaskChatMessage] = useState(false);
    const [createTaskChatMessage, setCreateTaskChatMessage] = useState(false);
    const [editTaskChatMessage, setEditTaskChatMessage] = useState(false);
    const [deleteTaskChatMessage, setDeleteTaskChatMessage] = useState(false);

    const [showPersons, setShowPersons] = useState(false);
    const [mentionPersons, setMentionPersons] = useState<{personId: number, name: string}[]>([]);
    const [mentionPersonsFilter, setMentionPersonsFilter] = useState<string>("");
    const [mentionPersonsPosition, setMentionPersonsPosition] = useState<number>(0);

    const [focusBlock, setFocusBlock] = useState<Element | null>()
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const SMALL_NAME: number = 20;

    type Permissions = {
        view: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
    };


    useEffect(() => {
        try {
            // Ищем доступ с функцией "TaskChatAction"
            const taskChatAccess = userAccess.find((access) => access.functionCode === "TaskChatAction");

            if (taskChatAccess) {
                // Создаем объект для хранения состояний
                const permissions: Permissions = {
                    view: false,
                    create: false,
                    edit: false,
                    delete: false,
                };

                // Проверяем разрешения
                taskChatAccess.permissions.forEach((permission) => {
                    if (permission.isGranted && permission.permissionCode in permissions) {
                        // Приводим permissionCode к ключу типа Permissions
                        permissions[permission.permissionCode as keyof Permissions] = true;
                    }
                });

                // Устанавливаем состояния одним вызовом
                setViewTaskChatMessage(permissions.view);
                setCreateTaskChatMessage(permissions.create);
                setEditTaskChatMessage(permissions.edit);
                setDeleteTaskChatMessage(permissions.delete);
            }
        } catch (err) {
            console.error("Error processing userAccess:", err);
        }
    }, [userAccess]);

    const loadChatMessages = async () => {
        let groups = await store.getChatMessages(chatId);
        if (groups) {
            groups.forEach(group => {
                group.messages = group.messages.sort((a, b) =>
                    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            })
            groups = groups.sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime()
            );
            if (setMessagesCount)
                setMessagesCount(groups.flatMap(g => g.messages).length)
            setMessageGroups(groups);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                await loadChatMessages();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [chatId]);

    function getCurrentDate(messageDateCur: string) {
        const messageDate = new Date(messageDateCur);
        const today = new Date();
        const yesterday = new Date(today);
        const dayBeforeYesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        if (equalDMY(messageDate, today)) {
            return "Сегодня";
        } else if (equalDMY(messageDate, yesterday)) {
            return "Вчера";
        } else if (equalDMY(messageDate, dayBeforeYesterday)) {
            return "Позавчера";
        } else {
            return `${messageDate.getDate().toString().padStart(2, "0")}.${(
                messageDate.getMonth() + 1
            )
                .toString()
                .padStart(2, "0")}.${messageDate.getFullYear()}`;
        }
    }

    function isToday(): boolean {
        if (!messageGroups.length)
            return false;
        const today = new Date();
        const lastGroup = messageGroups[messageGroups.length - 1];
        const lastDayInChat = lastGroup?.messages[lastGroup.messages?.length - 1];
        const lastDateInChat = new Date(lastDayInChat?.createdAt);

        return !equalDMY(lastDateInChat, today);
    }

    useEffect(() => {
        const boldElement: HTMLButtonElement | null =
            document.querySelector(".bold");
        if (boldElement) {
            boldElement.title = "Жирный (Ctrl+B)";
        }
        const italicElement: HTMLButtonElement | null =
            document.querySelector(".italic");
        if (italicElement) {
            italicElement.title = "Курсив (Ctrl+I)";
        }
        const headingElement: HTMLButtonElement | null =
            document.querySelector(".heading");
        if (headingElement) {
            headingElement.title = "Заголовок (Ctrl+H)";
        }
        const codeElement: HTMLButtonElement | null =
            document.querySelector(".code");
        if (codeElement) {
            codeElement.title = "Код (Ctrl+Alt+C)";
        }
        const quoteElement: HTMLButtonElement | null =
            document.querySelector(".quote");
        if (quoteElement) {
            quoteElement.title = "Кавычки (Ctrl+')";
        }
        const unorderedListElement: HTMLButtonElement | null =
            document.querySelector(".unordered-list");
        if (unorderedListElement) {
            unorderedListElement.title = "Неупорядоченный список (Ctrl+B)";
        }
        const orderedListElement: HTMLButtonElement | null =
            document.querySelector(".ordered-list");
        if (orderedListElement) {
            orderedListElement.title = "Упорядоченный список (Ctrl+Alt+L)";
        }
        const horizontalRuleElement: HTMLButtonElement | null =
            document.querySelector(".horizontal-rule");
        if (horizontalRuleElement) {
            horizontalRuleElement.title = "Горизонтальная линия";
        }
        const undoElement: HTMLButtonElement | null =
            document.querySelector(".undo");
        if (undoElement) {
            undoElement.title = "Назад (Ctrl+Z)";
        }
        const redoElement: HTMLButtonElement | null =
            document.querySelector(".redo");
        if (redoElement) {
            redoElement.title = "Вперед (Ctrl+Y)";
        }
    }, [userAccess]);

    const saveEditMessage = () => {
        (async () => {
            await store.saveEditMessage(editValueBackend, idEditMessage, mentionPersons.map(xx => xx.personId));
            setFileMap({data: []});
            setValue("");
            await loadChatMessages();
            setEdit(false);
            setEditValueBackend("");
            setEditValue("");
            setIdEditMessage(-1);
            scrollToMessage();
        })();
    };

    function deleteMessage(idMessage: number) {
        if (deleteTaskChatMessage) {
            (async () => {
                await store.deleteMessage(idMessage);
                await loadChatMessages();
            })();
        } else {
            showToast("У вас нет прав на удаление комментариев");
        }
    }

    // const generateFileId = (fileType: string) => {
    //     const id = Date.now().toString(); //.slice(0,5);
    //     return `${fileType}${id}`;
    // };

    //Все что выше можно не трогать

    // useEffect(() => {
    //     replaceHtml(value);
    // }, [fileMap, value, id]);

    //Обработка файлов
    function fileIcon(fileType: string | undefined) {
        let fileTypeClean = fileType?.replace(/\./g, "");
        let variableName = `${fileTypeClean}_svg`;
        let pictureSrc = (FileIcons as any)[variableName];
        if (!pictureSrc) {
            //Тут если не нашли картинку нужна женерик картинка файла
            return unknown_svg;
        } else {
            return pictureSrc;
        }
    }

    //Обработка длины файлов
    function getHTMLFileName(fileName: string, fileType: string, url: string, imgGet: any) {
            return fileType === "G"
                ? `<img src="${url}" />`
                        : fileType === "M"
                            ? `<audio controls class="input--chat__block--messages__message--audio__chat--block" src="${url}">${fileName}</audio>`
                            : fileType === "V"
                                ? '<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="' +
                                url +
                                '"/></video>'
                                : `<div class="input--chat__block--messages__message--file__chat--block"><div class="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${url}">${fileName}</a></div></div>`;   
    }

    function getFileName(stringBefore: string) {
        // Замена для простого отслеживания мест с наименованиями файлов в дальнейшем
        const splitFileName: string[] = stringBefore.split(`.`);
        let lenghtFileExtension: number = 0;
        let fileExtension: string = "";
        // +1 т.к. нужно учитывать точку - начало расширения файла
        if (splitFileName.length !== 1) {
            lenghtFileExtension = (splitFileName[splitFileName.length - 1]).length + 1;
            fileExtension = splitFileName[splitFileName.length - 1];
        }
        const nameFileWithoutEx = stringBefore.length - lenghtFileExtension;
        if (splitFileName.length !== 1) {
            if (nameFileWithoutEx > SMALL_NAME) {
                stringBefore = stringBefore.slice(0, 4) + `...` + stringBefore.slice(nameFileWithoutEx - 4, nameFileWithoutEx) + `.` + fileExtension;
            }
        }
        else {
            if (nameFileWithoutEx > SMALL_NAME) {
                stringBefore = stringBefore.slice(0, 4) + `...` + stringBefore.slice(nameFileWithoutEx - 4, nameFileWithoutEx);
            }
        }
        
        return stringBefore;
    }

    const imageUpload = async (file: any, onSuccess: any, onError: any) => {
        try {
            const res = await store.addFileChat(file, chatId); // Поменять на чат
            const url = getFileUrlFromId(res.id);

            store.setUpdateFile(true);
            let fileType = "F";

            if (file.type.includes("image")) {
                fileType = "G";
            } else if (file.type.includes("audio")) {
                fileType = "M";
            } else if (file.type.includes("video")) {
                fileType = "V";
            }

            let fileTypeGet = file.name.split(".");
            let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
            let fileName = getFileName(file.name);
            let dataSaveFront = getHTMLFileName(fileName, fileType, url, imgGet);
            let dataSaveBackend = getHTMLFileName(file.name, fileType, url, imgGet);

            const fileId = generateFileId(url, fileType);
            // + url.split("=").pop();// generateFileId(fileType);

            const newFileMapBackend: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: dataSaveBackend,
            };
            const newFileMapFront: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: dataSaveFront,
            };

            setFileMap((prevFileMap) => ({
                data: [...prevFileMap.data, newFileMapBackend],
            }));
            setFileMapFront((prevFileMap) => ({
                data: [...prevFileMap.data, newFileMapFront],
            }));
            setEditValue((prevEditValue) => prevEditValue + ` ${dataSaveFront}`);
            setEditValueBackend((prevEditValue) => prevEditValue + ` ${dataSaveBackend}`);

            setValue((oldValue) => oldValue + ` {${fileId}}`);
            showToast(`Файл ${"{" + fileId + "}"} успешно загружен!`);
        } catch (error) {
            onError("Ошибка загрузки изображения");
        }
    };

    const getUserAndShow = async () => {
        try {
            return true;
        } catch (err) {
            return false
        }

    }

    // Механизм упоминани пользователей
    const insertUser = (user: IFullUserInfo) => {
        const codeMent = "\u200B";
        const markPerson = `${codeMent}${user.surname.trim()}_${user.name.trim()}${codeMent}`;

        // Создаем HTML-блок для вставки
        const divPerson = ` <span class="fio__block—chat__message" id="${user.id}">${user.surname.trim()}_${user.name.trim()}</span> `;

        // Обновляем состояние value
        setValue((oldValue) => {
            const before = oldValue.slice(0, mentionPersonsPosition);
            const after = oldValue.slice(mentionPersonsPosition + mentionPersonsFilter.length, oldValue.length);
            const newValue = before + markPerson + " " + after;

            return newValue;
        });

        // Обновляем состояние editValue
        setEditValue((oldValue) => {
            let result;
            let flagBr = false;
            if (oldValue.includes(" @")) {
                result = oldValue.split(" @")[0]?.length;
            } else if (oldValue.includes("\n@")) {
                result = oldValue.split("\n@")[0]?.length;
            } else if (oldValue.includes("\n</br>@")) {
                flagBr = true;
                result = oldValue.split("\n</br>@")[0]?.length;
            } else {
                result = 0
            }
            const before = oldValue.slice(0, result);
            const after = oldValue.slice(result + mentionPersonsFilter.length + (result == 0 ? 0 : flagBr ? 6 : 2), oldValue.length);
            const newEditValue = before + divPerson + after;
            return newEditValue;
        });

        setEditValueBackend((oldValue) => {
            let result;
            let flagBr = false;
            if (oldValue.includes(" @")) {
                result = oldValue.split(" @")[0]?.length;
            } else if (oldValue.includes("\n@")) {
                result = oldValue.split("\n@")[0]?.length;
            } else if (oldValue.includes("\n</br>@")) {
                flagBr = true;
                result = oldValue.split("\n</br>@")[0]?.length;
            } else {
                result = 0
            }

            const before = oldValue.slice(0, result);
            const after = oldValue.slice(result + mentionPersonsFilter.length + (result == 0 ? 0 : flagBr ? 6 : 2), oldValue.length);
            const newEditValue = before + divPerson + after;
            return newEditValue;
        });

        setMentionPersons(prevValue => [...prevValue, { personId: user.id!, name: markPerson }]);
        setShowPersons(false);


    }

    // Событие изминения маркдауна пользователем
    const onChange = (newValue: string, cursorPos: CodeMirror.EditorChange | undefined) => {
        const data = convertMessage(newValue, fileMap, mentionPersons, cursorPos);
        const dataFront = convertMessage(newValue, fileMap, mentionPersons, cursorPos);

        setMentionPersons(data?.chekPerson);
        setMentionPersonsFilter(data.mentionFilter);
        setMentionPersonsPosition(data.mentionIndex)

        if (data.arrayChatFiles) {
            setFileMap(data.arrayChatFiles);
        }
        if (dataFront.arrayChatFiles) {
            setFileMapFront(dataFront.arrayChatFiles);
        }
        setEditValue(data.disassembledText);
        setEditValueBackend(data.disassembledText);

        setValue(oldValue => oldValue = newValue);

        if (data?.showWindow) {
            getUserAndShow().
            then((xx => {setShowPersons(xx)})).
            catch(xx => {console.error(`Произошла ошибка ${xx}`); setShowPersons(false);});
        } else {
            setShowPersons(false);
        }
        scrollToInput();
        setFocusBlock(document.activeElement);
    };

    // Закрыть редактируемое сообщение
    const closeEditMessage = () => {
        setValue("");
        setEditValue("");
        setEditValueBackend("");
        setEdit(false);
        setFileMap({data: []});
        setIdEditMessage(-1);
    };

    // Сохранить сообщение
    const saveMessage = () => {
        if (createTaskChatMessage) {
            if (editValue.length <= 0) {
                showToast("Невозможно отправить пустое сообщение!");
                return
            }

            setShowLoader(true);

            (async () => {
                try {
                    await store.sendMessage(editValueBackend, chatId, mentionPersons.map(xx => xx.personId)); //Отправлять id чата
                    setValue("");
                    setEditValueBackend("");
                    setEditValue("");
                    setEdit(false);
                    setFileMap({data: []});
                    setFileMapFront({data:[]});
                    await loadChatMessages();
                    setShowLoader(false);
                } catch (e) {
                    showToast(`Произошла ошибка! ${e}`)
                    setShowLoader(false);
                }
            })();
        } else {
            showToast("У вас нет прав для отправки сообщений");
        }
    };

    // Приведение даты к нужному формату
    function formatDate(message: string) {
        const date = new Date(message);
        return format(date, "MMM dd, yyyy");
    }

    // Редактировать сообщение
    const editMessage = (message: IMessage, htmlElement: HTMLElement) => {
        if (editTaskChatMessage) {
            setEdit(true);
            setIdEditMessage(message.id);
            setEditValue(message.text);
            setEditValueBackend(message.text);
            let data = formatEditMessage(message.text, fileMap);
            if (data.arrayChatFiles) {
                setFileMap(data.arrayChatFiles);
            }
            setMentionPersons(data?.mentionPerson)
            setValue(data.disassembledText);
            setEditedMessageDiv(htmlElement);
        } else {
            showToast("У вас нет прав на редактирование комментариев");
        }
    }

    // Прокручивание при сохранении сообщения
    useEffect(() => {
        if (edit) scrollToBottom();
        if (create && tab === 0) {
            setTimeout(() => {
                scrollToBottom(); //❗❗❗
            }, 100);

            setCreate(false);
        }
    }, [edit, idEditMessage, create]);

    // Прокручивание при якоре
    useEffect(() => {
        const hash = window.location.hash;

        if (hash && !isLoading) {
            const element = document.querySelector(hash);
            element?.scrollIntoView({
                block: "center",
                behavior: 'smooth'
            });
        }
    }, [isLoading])

    // Скролл до конца
    const scrollToBottom = () => {
        if (scrollComponentDiv) {
            scrollComponentDiv.scrollTo({
                top: scrollComponentDiv.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    // Скрол до сообщения
    const scrollToMessage = () => {
        if (editedMessageDiv) {
            editedMessageDiv.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    };

    // Автоматический скролл к окну ввода
    const scrollToInput = () => {
        if (document.activeElement) {
            document.activeElement.scrollIntoView({
                behavior: "smooth",
                block: "end"
            });
        }
    }

    // Опции маркдауна
    const newOptions = useMemo(() => {
        return {
            spellChecker: false,
            hideIcons: [
                "link",
                "image",
                "strikethrough",
                "table",
                "heading-bigger",
                "heading-smaller",
                "heading-1",
                "heading-2",
                "heading-3",
                "clean-block",
                "preview",
                "side-by-side",
                "fullscreen",
                "guide",

            ],
            showIcons: [
                "code",
                "bold",
                "italic",
                "quote",
                "unordered-list",
                "ordered-list",
                "code",
                "redo",
                "undo",
                "heading",
                "horizontal-rule",
            ],
            uploadImage: true,
            imageUploadFunction: imageUpload,
            placeholder: "Введите текст...",
        } as Options;
    }, []);

    // Бызовый адресс
    const getBaseUrl = () => {
        return window.location.href.split('#')[0];
    }

    // Копирование в буфер ссылки
    const handleCopySuccess = async (anchor: string) => {
        const url = getBaseUrl() + anchor;
        try {
            await navigator.clipboard.writeText(url);
            showToast("Ссылка на сообщение скопирована")
        } catch (err) {
            showToast("Не удалось скопировать ссылку!")
        }
    }

    // Фокус на блок после вставки юзера
    const goToBlockFocus = () => {
        if (focusBlock && document.body.contains(focusBlock)) {
            const element = focusBlock as HTMLInputElement | HTMLTextAreaElement;

            if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
                element.focus();
                const value = element.value;
                element.setSelectionRange(value.length, value.length);
            }
        }
    }

    return (
        <div className="input--chat__block">
            {showLoader && (
                <Loader/>
            )}
            <div className="input--chat__block--messages">
                {viewTaskChatMessage &&
                    messageGroups.map((messageGroup, index) => (
                        <div>
                            <div
                                key={index + "date"}
                                className="input--chat__block--messages__date"
                            >
                                <div className="input--chat__block--messages__date--line"></div>
                                <div className="input--chat__block--messages__date--num">
                                    {getCurrentDate(messageGroup?.date)}
                                </div>
                                <div className="input--chat__block--messages__date--line"></div>
                            </div>
                            {messageGroup?.messages?.map((message) => (
                                <div
                                    id={`message-${message.id}`}
                                    className={store.user.id === message.createdBy.id
                                            ? "input--chat__block--messages__message--block__right"
                                            : "input--chat__block--messages__message--block__left"
                                    }
                                >
                                    <div
                                        className={
                                            store.user.id ===
                                            message.createdBy.id
                                                ? "input--chat__block--messages__message--block__flex--right"
                                                : "input--chat__block--messages__message--block__flex--left"
                                        }
                                        style={
                                            edit && message.id == idEditMessage
                                                ? String(window.location.hash) == `#message-${message.id}` ? {backgroundColor: "rgba(188,217,239,0.57)"} : {backgroundColor: "lightblue",}
                                                : window.location.hash == `#message-${message.id}` ? {backgroundColor: "rgba(188,217,239,0.57)"} : {}
                                        }
                                    >
                                        <UserPopupWrapper
                                            userId={message.createdBy.id}
                                        >
                                            <div style={{cursor: "pointer"}}>
                                                <Avatar
                                                    url={getFileUrlFromId(
                                                        message.createdBy
                                                            .photoId
                                                    )}
                                                    size="lg"
                                                />
                                            </div>
                                        </UserPopupWrapper>
                                        <div key={message.id}
                                            className="input--chat__block--messages__message"
                                        >
                                            <div className="input--chat__block--messages__message--all">
                                                <UserPopupWrapper
                                                    userId={
                                                        message.createdBy.id
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div className="input--chat__block--messages__message--author">
                                                            {fullNameFormat(
                                                                message.createdBy,
                                                                "s n"
                                                            )}
                                                        </div>
                                                    </div>
                                                </UserPopupWrapper>
                                                <div className="input--chat__block--messages__message--role">
                                                    {message.role}
                                                </div>
                                                <div className="input--chat__block--messages__message--date">
                                                    <a href={`#message-${message.id}`}>{dateToNiceString(new Date(message.createdAt), "month dd, yyyy")}</a>
                                                </div>
                                                <div className="input--chat__block--messages__message--all__copy">
                                                    <Button title={"Скопировать ссылку на сообщение"}
                                                            titlePosition={"right"}
                                                            style={{marginRight: "10px"}}
                                                            callBack={() => handleCopySuccess(`#message-${message.id}`)}
                                                            image={Copy}/>
                                                </div>
                                                {message.updatedAt ==
                                                message.createdAt ? (
                                                    ""
                                                ) : (
                                                    <a className="input--chat__block--messages__message--update">
                                                        Изменено
                                                    </a>
                                                )}
                                            </div>
                                            <div className="input--chat__block--messages__message--text">
                                                <HtmlPreview content={message.text}/>
                                            </div>
                                            {store.user.id ===
                                            message.createdBy.id ? (
                                                <div className="input--chat__block--messages__message--buttons">
                                                    <button
                                                        onClick={(e) =>
                                                            editTaskChatMessage
                                                                ? editMessage(
                                                                    message,
                                                                    e.currentTarget
                                                                )
                                                                : showToast(
                                                                    "У вас нет прав на редактирование!"
                                                                )
                                                        }
                                                    >
                                                        Редактировать
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            deleteTaskChatMessage
                                                                ? deleteMessage(
                                                                    message.id
                                                                )
                                                                : showToast(
                                                                    "У вас нет прав на удаление!"
                                                                )
                                                        }
                                                    >
                                                        Удалить
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            {value ? (
                edit ? (
                    <div>
                        <div
                            className={
                                "input--chat__block--messages__message--block__right"
                            }
                        >
                            <div
                                className={
                                    "input--chat__block--messages__message--block__flex--right"
                                }
                                style={{backgroundColor: "lightblue"}}
                            >
                                <div className="input--chat__block--messages__message">
                                    <div className="input--chat__block--messages__message--text">
                                        <HtmlPreview content={editValue}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        {isToday() ? (
                            <div className="input--chat__block--messages__date">
                                <div className="input--chat__block--messages__date--line"></div>
                                <div className="input--chat__block--messages__date--num">
                                    Сегодня
                                </div>
                                <div className="input--chat__block--messages__date--line"></div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="input--chat__block--messages__message--block__right">
                            <div className="input--chat__block--messages__message--block__flex--right">
                                <Avatar
                                    url={getFileUrlFromId(store.user.photoId)}
                                    size="lg"
                                />
                                <div className="input--chat__block--messages__message">
                                    <div className="input--chat__block--messages__message--all">
                                        <div className="input--chat__block--messages__message--author">
                                            {store.user.surname +
                                                " " +
                                                store.user.name}
                                        </div>
                                        <div className="input--chat__block--messages__message--date">
                                            <a href={`#message-new`}>{dateToNiceString(new Date(), "month dd, yyyy")}</a>
                                        </div>
                                    </div>
                                    <div className="input--chat__block--messages__message--text">
                                        <HtmlPreview content={editValue}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : null}
            {showPersons && projectId ?
                <div className="input--input__block--show__persons">
                    <UserMention
                        projectId={projectId}
                        filter={mentionPersonsFilter}
                        mentionPersons={mentionPersons.map(xx => xx.personId)}
                        callBack={(user) => {insertUser(user); goToBlockFocus();}}
                    />
                </div>
                : null
            }
            <div className="input--input__block">
                <div className="input--input__block--buttons">
                    <div>

                    </div>
                    <SimpleMDEditor
                        id="editor"
                        value={value}
                        onChange={(newTextValue, cursorPos) => onChange(newTextValue, cursorPos)}
                        options={newOptions}
                        // getLineAndCursor={}
                    />
                    <div className="input--input__block--buttons__send">
                        <div className="input--input__block--buttons__send--div">
                            {edit ? (
                                <div>
                                    <button
                                        className="input--input__block--buttons__send--div__cansel"
                                        onClick={closeEditMessage}
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        className="input--input__block--buttons__send--div__approve"
                                        onClick={saveEditMessage}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className={createTaskChatMessage ? "input--input__block--buttons__send--div__approve"
                                        : "input--input__block--buttons__send--div__approve-block"}
                                    onClick={saveMessage}
                                >
                                    Отправить
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputChat;
