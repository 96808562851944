import { FC, useContext, useEffect, useState } from "react";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import HistoryTable from "../../Shared/HistoryTable/HistoryTable";
import { Context } from "../../..";
import { ITaskHistoryFilter } from "../../../models/TaskModels";
import { useParams } from "react-router-dom";
import "./TaskHistory.css";
import { FilterOptions, SelectedOptions } from "../../../models/IFilterModel";
import FilterContainer from "../../Shared/Filter/FilterContainer";

interface TaskHistoryProps {
    projectId: number;
    records: IProjectHistoryDisplay[];
    onChangeFilters: (filters: ITaskHistoryFilter) => void;
}

const TaskHistory: FC<TaskHistoryProps> = ({
    records,
    onChangeFilters,
    projectId,
}) => {
    const { id } = useParams();
    const { store } = useContext(Context);

    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [historyFilter, setHistoryFilter] = useState<FilterOptions>();
    const [selectedFilter, setSelectedFilter] = useState<SelectedOptions>({});

    useEffect(() => {
        (async () => {
            if (!store.currentProjectId) return;

            let filters = await store.getDataForFilter({
                projectId: projectId,
                responsible: true,
                status: false,
                typeTask: false,
                priority: false,
                role: false,
                fileType: false,
            });
            if (!filters) return;
            filters.date = true;
            setHistoryFilter(filters);
        })();
    }, [store.currentProjectId]);

    useEffect(() => {
        (async () => {
            if (Object.keys(selectedFilter).length === 0) parseFilters();
        })();
    }, [selectedFilter]);

    const onCancelClick = async () => {
        setSelectedFilter({});
    };

    const applyFilters = async () => {
        parseFilters();
    };

    const parseFilters = async () => {
        const filters: ITaskHistoryFilter = {
            taskId: Number(id),
            dateFrom: (
                selectedFilter.dates && selectedFilter.dates[0]
            )?.toISOString(),
            dateTo: (
                selectedFilter.dates && selectedFilter.dates[1]
            )?.toISOString(),
            userIds: selectedFilter.responsibleIds,
        };
        onChangeFilters(filters);
    };

    return (
        <div>
            <div className="task-history-filters">
                <FilterContainer
                    filterCounter={filterCounter}
                    setFilterCounter={setFilterCounter}
                    selectedOptions={selectedFilter}
                    setSelectedOptions={setSelectedFilter}
                    filterOptions={historyFilter}
                    onReset={onCancelClick}
                    showApplyButton
                    onApply={applyFilters}
                    labels={{responsibleLabel: "Пользователь"}}
                />
            </div>
            <HistoryTable
                records={records}
                onScrolledToBottom={() => {}}
                showHyperlinkColumn={false}
            />
        </div>
    );
};

export default TaskHistory;
