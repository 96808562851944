import React, { useContext, useEffect, useRef, useState } from "react";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import TaskStatus from "../../Shared/TaskStatus";
import TaskPriority from "../../Shared/TaskPriority";
import TaskType from "../../Shared/TaskType";
import AddButton from '../../buttons/btnAdd.component/BtnAdd'
import FilterButton from '../../buttons/btnFilter.component/BtnFilter'
import Search from "../../UiLib/Search/Search";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import SortDirectionArrow from "../../../assets/sortDirectionArrow.svg"
import './TasksWidget.css'
import '../../../globalstyles/dataTableStyles.css'
import project, { ProjectContext } from "../../../pages/Project/Project";
import { Link, useNavigate, useParams } from "react-router-dom";
import {fullNameFormat, initials} from "../../../helpers/Inicials";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import TaskAddEditForm from "../../Task/TaskAddEditForm/TaskAddEditForm";
import { ITaskDisplay } from "../../../models/TaskModels";
import {AppContext} from "../../../App";
import {Context} from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";
import UserPopupWrapper from "../../Shared/UserPopup/UserPopupWrapper";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";
import FilterContainer from "../../Shared/Filter/FilterContainer";

export const PAGE_SIZE = 5;
// Число отображаемых задач для полностью развёрнутого экрана с задачами на странице проекта
const FULL_SCREEN_PAGE_SIZE = 40;

export interface ITaskFilter {
    projectId: number;
    responsible: number[] | undefined;
    date: string[] | undefined;
    taskId: number | undefined;
    status: number[] | undefined;
    priority: number[] | undefined;
    typeTask: number[] | undefined;
    name: string | undefined;
}

interface ISort {
    date?: "asc" | "desc";
    status?: "asc" | "desc";
    responsible?: "asc" | "desc";
    type?: "asc" | "desc";
    priority?: "asc" | "desc";
    nameTask?: "asc" | "desc";
}

interface ITasksProps {
    tasks: ITaskDisplay[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, sort: ISort) => void;
    loadTasks: (skip: number, take: number, sort: ISort) => void;
    userAccess: IHasPermission[];
}

export function toDisplayDate(dateStr: any): string {

    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) => value.toString().padStart(2, '0');
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
        // return dateStr
    } catch (err: any) {
        return "-"
    }
}

export const personRowDisplay = (rowData: ITaskDisplay) => {
    return rowData?.responsibleName? (
        <UserPopupWrapper userId={rowData.responsibleId}>
            <PersonLineDisplay name={fullNameFormat(rowData?.responsibleName, 's N M')} photoId={rowData.responsiblePhotoId}/>
        </UserPopupWrapper>
    ) : '';
};
export const taskStatusDisplay = (rowData: any) => {
    const style = rowData?.status?.style && JSON.parse(rowData?.status?.style)
    return (style && rowData?.status?.name) ? <TaskStatus name={rowData?.status?.name} color={style.color} /> : '';
};
export const taskPriorityDisplay = (rowData: any) => {
    const style = rowData?.priority?.style && JSON.parse(rowData?.priority?.style)
    return (style && rowData?.priority?.name) ? <TaskPriority name={rowData?.priority?.name} color={style.color} icon={style.icon} /> : '';
};
export const taskTypeDisplay = (rowData: any) => {
    const style = rowData?.type?.style && JSON.parse(rowData?.type?.style)
    return (style && rowData?.type?.name) ? <TaskType name={rowData?.type?.name} color={style.color} icon={style.icon} /> : '';
};

const Tasks: React.FC<ITasksProps> = ({ tasks, scrollEnded, loadPage, loadTasks, userAccess}) => {
    const { id } = useParams();
    const {
        setCounterTasks,
        sortAsc, setSortAsc, filterOptionsTask,
        counterTasks, selectedOptionsTask, setSelectedOptionsTask, taskSearchText, setTaskSearchText,
        skipTasks, setSkipTasks, setScrollEndedTasks } = useContext(ProjectContext);
    const { showToast, archivedPage } = useContext(AppContext);

    const [hidden, setHidden] = useState(false);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);

    const [dataSort, setDataSort] = useState([{ lable: "НАЗВАНИЕ ЗАДАЧИ", sort: false, code: 'nameTask' }, { lable: "СТАТУС", sort: false, code: 'status' }, { lable: "ПРИОРИТЕТ", sort: false, code: 'priority' }, { lable: "ТИП ЗАДАЧИ", sort: false, code: 'type' }, { lable: "ИСПОЛНИТЕЛЬ", sort: false, code: 'responsible' }, { lable: "ДАТА", sort: true, code: 'date' }]);
    const [currentSort, setCurrentSort] = useState('date');

    const curRef = useRef(null);

    // Ограничение прав
    const [viewTasks, setViewTasks] = useState(false);
    const [addTask, setAddTask] = useState(false);
    const navigate = useNavigate();

    const onScroll = async (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if ((1 + scrollTop + offsetHeight >= scrollHeight) && (!scrollEnded)) {
            const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
            setSkipTasks((prev: number) => prev + pageSize);
            await loadPage(skipTasks, pageSize, { [currentSort]: sortAsc ? "asc" : "desc" });
        }
    };

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewTasks(true);
                    }
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddTask(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setViewTasks(true);
        //     setAddTask(true);
        // }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            // Если перешли в полноэкранный режим, догружаем задачи до полной страницы
            if (openOnFullWindow && tasks.length < FULL_SCREEN_PAGE_SIZE) {
                await loadTasks(0, FULL_SCREEN_PAGE_SIZE, { [currentSort]: sortAsc ? "asc" : "desc" });
                setSkipTasks(FULL_SCREEN_PAGE_SIZE);
            }
        })()
    }, [openOnFullWindow])

    useEffect(() => {
        setOpenOnFullWindow(false);
    }, [id]);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            let searchFilter = document.querySelector('.p-listbox-filter') as HTMLInputElement;
            
            if (event.key === 'Escape') {
                
                event.preventDefault();
                setTaskSearchText('');
                onCancelClick();

                //Решение для очищение поля поиска пользователей в фильтре по esc
                // if(searchFilter && event.srcElement === searchFilter){
                //     filterDataTask && setFilterDataTask(filterDataTask)
                //     searchFilter.value = '';
                //     searchFilter.value = '';
                // }
                
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    async function onCancelClick() {
        setTaskSearchText("");
        setSelectedOptionsTask({});
    }

    useEffect(() => {
        if (taskSearchText.length === 0 && Object.keys(selectedOptionsTask).length === 0) {
            (async() => {setScrollEndedTasks(false);
                const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
                setSkipTasks(pageSize);
                await loadTasks(0, pageSize, { [currentSort]: !sortAsc ? "asc" : "desc" });})()
        }
    }, [taskSearchText, selectedOptionsTask])

    function onAddClick() {
        if (addTask) {
            setHidden(true);
        } else {
            showToast("У вас нет прав для создания задачи!")
        }
    }

    async function onSortChange(code: string, asc: boolean) {
        (curRef.current as any).scrollTo({
            top: 0,
            left: 0,   
        });
        setCurrentSort(code);
        const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
        setSkipTasks(pageSize);
        await loadTasks(0, pageSize, { [code]: asc ? "asc" : "desc" });
        setSortAsc(asc);
    }

    async function onSearchSubmit(value: string) {
        setScrollEndedTasks(false);
        const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
        setSkipTasks(pageSize);
        await loadTasks(0, pageSize, { [currentSort]: !sortAsc ? "asc" : "desc" });
    }
    function closeFunc() {
        setHidden(false);
    }

    function onTaskAction() {
        setHidden(false);
        (async () => {
            const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
            await loadTasks(0, pageSize, { [currentSort]: sortAsc ? "asc" : "desc" });
        })();
    }

    async function addSort(label: string, code: string) {
        let currentSort = dataSort.find(i => i.lable === label);
        let prevtSort = dataSort.find(i => i.sort === true);

        if (currentSort && prevtSort) {
            let indexCurrentSort = dataSort.indexOf(currentSort);
            let indexPrevtSort = dataSort.indexOf(prevtSort);

            currentSort.sort = true;
            prevtSort.sort = false;

            let left = dataSort.slice(0, indexCurrentSort);
            let rigth = dataSort.slice(indexCurrentSort + 1);
            let newDataSort = [...left, currentSort, ...rigth];
            let left2 = newDataSort.slice(0, indexPrevtSort);
            let rigth2 = newDataSort.slice(indexPrevtSort + 1);

            newDataSort = [...left2, prevtSort, ...rigth2];
            setDataSort(newDataSort);
            setSortAsc(true);
            await onSortChange(code, true);
        }
    }

    const applyFilters = async () => {
        setScrollEndedTasks(false);
        const pageSize = openOnFullWindow ? FULL_SCREEN_PAGE_SIZE : PAGE_SIZE;
        setSkipTasks(pageSize);
        await loadTasks(0, pageSize, {});
    }

    const goToTask = (taskId: number) => {
        navigate(`${archivedPage ? "/archive" : ""}/task/${taskId}`);
    }

    return (
        <div className={openOnFullWindow ? 'widget_wrapper--full__window' : 'wigget-tasks-test main_page_widget widget_wrapper'}>
            <div className={openOnFullWindow ? 'widget_wrapper-full__window--block' : ''}>
                {hidden &&
                    <div className="full-screen-overlay">
                        <div className="popup-form-background" onClick={(e) => e.stopPropagation()}>
                            <TaskAddEditForm mode={"create"} taskId={undefined} closeFunc={closeFunc}
                                             projectId={Number(id)} onTaskAction={onTaskAction}/>
                        </div>
                    </div>
                }
                <div className={'widgets_header--container'}>
                    {openOnFullWindow ?
                        <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>
                            <img src={CloseBlockWindow}/>
                        </div>
                        :
                        <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>
                            <img src={OpenBlock}/>
                        </div>
                    }
                </div>
                <div className='widgets_header'>

                    <div className='widgets_header--header__block'>
                        <h2 className='widget-card-header-style'>
                            Задачи
                        </h2>
                    </div>
                    <div className='widjets_header_rigth'>
                        <Search value={taskSearchText} onSubmitFunc={onSearchSubmit} changeSearchValue={setTaskSearchText}/>
                        <FilterContainer
                            filterCounter={counterTasks}
                            setFilterCounter={setCounterTasks}
                            selectedOptions={selectedOptionsTask}
                            setSelectedOptions={setSelectedOptionsTask}
                            filterOptions={filterOptionsTask}
                            showResetButton={taskSearchText.length > 0}
                            onReset={onCancelClick}
                            showApplyButton={true}
                            onApply={() => applyFilters()}
                        />
                        <AddButton onClickFunc={onAddClick} type={'task'} permission={addTask}/>
                    </div>
                </div>
                <div
                    ref={curRef}
                    onScroll={onScroll}
                    className={openOnFullWindow ? "custom_table--full__window" : "custom_table"}
                >
                    <table>
                        <thead className="custom_table_thead">
                            <tr>
                                {dataSort && dataSort.map(i => (
                                    i.sort ?
                                        <th key={i.code} onClick={() => onSortChange(i.code, !sortAsc)}>
                                            <div
                                                className={`no-highlight sort-direction-changing-header ${sortAsc ? "" : "desc"} sort-direction-changing-header-flex`}>
                                                {i.lable}
                                                <img src={SortDirectionArrow}/>
                                            </div>
                                        </th>
                                        :
                                        <th key={i.code} onClick={() => addSort(i.lable, i.code)}>
                                            <div
                                                className='sort-direction-changing-header sort-direction-changing-header-flex'>
                                                {i.lable}
                                            </div>
                                        </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {viewTasks && tasks.map(task => {
                            return (
                                // Если вместо Math.random() поставить task.id,
                                // то некоторые задачи в списке будут отображаться даже отсутствуя в массиве данных при обновлении
                                // Связано с механизмом обработки key в React :/
                                <tr key={Math.random()}>
                                    <td className={openOnFullWindow ? "name_colum--full__window" : "name_colum"}
                                    >
                                        <Tooltip text={task.name?.length > 27 ? task.name : ""} placement="bottom" offset={5}>
                                            <a onClick={() => goToTask(task.id)} className="task-name-new">{task.name}</a>
                                        </Tooltip>
                                    </td>
                                    <td>{taskStatusDisplay(task)}</td>
                                    <td>{taskPriorityDisplay(task)}</td>
                                    <td>{taskTypeDisplay(task)}</td>
                                    <td>{personRowDisplay(task)}</td>
                                    <td>{task.startDate ? toDisplayDate(task.startDate) : ""}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Tasks;


