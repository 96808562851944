import $api from "..";
import {AxiosResponse} from "axios";
import {IFilter} from "../models/response/IFilterResponse";
import {FilterOptions, IIParametrsFilter} from "../models/IFilterModel";


export default class FilterService {

    static async getFilters(body: IIParametrsFilter): Promise<AxiosResponse<FilterOptions>> {
        return $api.post<FilterOptions>(`/getFilters`, body); 
    }

}