import React, { useState, useEffect, useRef, ReactNode, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import "./CustomTooltip.css"

interface TooltipProps {
    children: ReactNode;
    text: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    offset?: number;
    style?: CSSProperties;
    className?: string;
    delay?: number;
}

const Tooltip: React.FC<TooltipProps> = ({ children, text, placement = 'top', offset = 10, style, className,delay=500 }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const tooltipContainer = useRef<HTMLDivElement | null>(null);
    const timerId = useRef<NodeJS.Timeout | null>(null); 

    useEffect(() => {
        const container = document.createElement('div');
        container.setAttribute('id', 'tooltip-container');
        document.body.appendChild(container);
        tooltipContainer.current = container;

        return () => {
            if (tooltipContainer.current) {
                document.body.removeChild(tooltipContainer.current);
            }
        };
    }, []);

    const calculatePosition = () => {
        if (!triggerRef.current || !tooltipRef.current) {
            return;
        }

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        let top = 0;
        let left = 0;

        switch (placement) {
            case 'top':
                top = triggerRect.top - tooltipRect.height - offset - 8;
                left = triggerRect.left + (triggerRect.width - tooltipRect.width) + tooltipRect.width - triggerRect.width - 8;
                break;
            case 'bottom':
                top = triggerRect.bottom + offset - 8;
                left = triggerRect.left + (triggerRect.width - tooltipRect.width) + tooltipRect.width - triggerRect.width - 8;
                break;
            case 'left':
                top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2 - 8;
                left = triggerRect.left - tooltipRect.width - offset - 8;
                break;
            case 'right':
                top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2 - 8;
                left = triggerRect.right + offset - 8;
                break;
        }

        setPosition({
            top: Math.round(top),
            left: Math.round(left),
        });
    };

    const handleMouseEnter = () => {
        calculatePosition();
        if(delay !=0){
        timerId.current = setTimeout(() => {
            setIsVisible(true);
        }, delay);
    }
        else{
            setIsVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (timerId.current !== null) {
            clearTimeout(timerId.current); 
            timerId.current = null;
        }
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible) {
            calculatePosition();
            window.addEventListener('resize', calculatePosition);
        } else {
            window.removeEventListener('resize', calculatePosition);
        }

        return () => {
            window.removeEventListener('resize', calculatePosition);
        };
    }, [isVisible, placement, offset]);

    const tooltipStyle: CSSProperties = {
        position: 'fixed',
        top: position.top,
        left: position.left,
        backgroundColor: 'white',
        color: 'black',
        border: "1px solid gray",
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: "12px",
        zIndex: 10000,
        maxWidth: "600px",
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        pointerEvents: 'none',
        margin: `8px`,
        ...style,
    };

    return (
        <React.Fragment>
            <div
                ref={triggerRef}
                className="ui__component--tooltip__show"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
            {tooltipContainer.current && text?.length > 0 && createPortal(
                <div
                    ref={tooltipRef}
                    style={tooltipStyle}
                    className={`ui__component--tooltip__block ${className}`}
                >
                    {text}
                </div>,
                tooltipContainer.current
            )}
        </React.Fragment>
    );
};

export default Tooltip;
