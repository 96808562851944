import React, { FC, useContext, useEffect } from "react";
// import ForgotPasswordForm from "../../component/Authorization/ForgotPassword/ForgotPasswordForm";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Confirmation from "../../component/Authorization/Confirmation/Confirmation";
import ForgotPasswordForm from "../../component/Authorization/ForgotPassword/ForgotPasswordForm";
import Logo from "../../assets/mainLogo.svg";
import Background from "../../assets/backgroundLogin.jpg";

//to commit
const NoAccessPages: FC = (index) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            try {
                if (location.pathname.includes("/noAccessPages/")) {
                    const matchHref = location.pathname.split("/")[2];


                    if (matchHref) {
                        store.setLinkNewPerson(matchHref);
                        const activatePerson = await store.activatePerson(matchHref);
                        const newPerson = await store.getDataLinkPerson(store.linkNewPerson);


                        if (!newPerson) {

                            return;
                        }

                        store.setUserWithStatus(newPerson);
                        const success: boolean | undefined = await store.logout();

                        if (!success) {
                            console.error("Не удалось выйти из системы");
                        }



                        if (newPerson.status == "Приглашен") {
                            navigate("/register");
                        } else if (newPerson.status == "Ждет активацию" || newPerson.status == "Активен") {
                            navigate("/login");
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        })();
    }, []);

    const handleLogout = () => {
        store.logout().then(() => navigate("/login"))
    }

        // navigate("/projects");
        return(
            <div className="form_main_center">
                <div className="form_container form_container_fullscreen form_container_center">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <h2>У вас нет прав на использование системы</h2>
                    <div className="form_description">
                        <p>
                            Для начала работы обратитесь к администратору
                        </p>
                    </div>
                    <div className="form_flex_row">
                        <p className="p_blue p_14 pointer_custom" onClick={handleLogout}>
                            <span>Выход</span>
                        </p>
                    </div>
                </div>
                <img
                    src={Background}
                    alt="Фон компании"
                    className="background_image_fullscreen"
                />
            </div>
        );
};

export default observer(NoAccessPages);
