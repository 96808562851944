export enum NotificationType {
    user = 'user',
    role = 'role',
    project = 'project',
    securitySettings = 'securitySettings',
    generalInformation = 'generalInformation',
    subProject = 'subProject',
    board = 'board',
    proxyBoard = 'proxyBoard',
    column = 'column',
    projectFile = 'projectFile',
    projectTeam  = 'projectTeam', 
    task = 'task',
    taskFile = 'taskFile',
    taskComment = 'taskComment',
    checkListComment = 'checkListComment'
}