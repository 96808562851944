import {
    createContext,
    Dispatch,
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import "../../pages/Main/MainPage.css";
import "../../pages/UserMonitor/UserMonitor.css";
import "../../component/Project/Tasks/TasksWidget.css";

import { AppContext } from "../../App";
import UserMonitorToolbar from "../../component/UserMonitor/ToolBar/UserMonitorToolbar";
import { ITaskMonitorFilters } from "../../models/TaskModels";
import {
    IGetTaskSort,
    IMonitorTaskDisplay,
    IMonitorTasksFilter,
} from "../../models/IUserMonitorTaskFullInfo";
import { ISelectedFastFilters } from "../Board/Board";
import { IFilter, IFilterItem } from "../../models/response/IFilterResponse";
import { FilterOptions, IIParametrsFilter, SelectedOptions } from "../../models/IFilterModel";
import TasksTable from "../../component/UserMonitor/TasksTable/TasksTable";
import TaskDescription from "../../component/UserMonitor/TaskDescription/TaskDescription";
import TasksInfo from "../../component/UserMonitor/TasksInfo/TasksInfo";
import {ICheckRole, IHasPermission} from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import {scrollYCheck} from "../../helpers/domUtils";
import axios from "axios";

export interface ISortOption {
    lable: string;
    sort: "asc" | "desc" | undefined;
    code: string;
}

interface IUserMonitorContext {
    fastFilters: ITaskMonitorFilters;
    selectedFastFilters: ISelectedFastFilters;
    changeFastFilters: (filters: ISelectedFastFilters) => void;
    filters: FilterOptions | undefined;
    selectedFilters: SelectedOptions,
    setSelectedFilters: Dispatch<SetStateAction<SelectedOptions>>;
    resetFilter: () => void;
}

export const UserMonitorContext = createContext<IUserMonitorContext>({fastFilters: { priority: [], type: [] },
    selectedFastFilters: {priority: undefined, type: undefined, tag: undefined,},
    changeFastFilters: () => {}, filters: undefined,
    selectedFilters: {},
    setSelectedFilters: () => {},
    resetFilter: () => {},
});

const UserMonitor: FC = () => {
    const { store } = useContext(Context);
    const { setBreadCrumb, setNavPanelHighlight, showToast } = useContext(AppContext);
    //Задачи
    const [tasks, setTasks] = useState<IMonitorTaskDisplay[]>([]);
    const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(undefined);
    const [favouriteTaskIds, setFavouriteTaskIds] = useState<number[]>([]);
    const TABLE_PAGE_SIZE = 15; // Инкремент задач при пагинации в режиме таблицы
    const [skip, setSkip] = useState(0);
    const [scrollEndedTasks, setScrollEndedTasks] = useState<boolean>(false);
    //Фильтры
    const [taskNameSearch, setTaskNameSearch] = useState<string>("");
    const [fastFilters, setFastFilters] = useState<ITaskMonitorFilters>({priority: [], type: [],});
    const [selectedFastFilters, setSelectedFastFilters] =
        useState<ISelectedFastFilters>({priority: undefined, type: undefined, tag: undefined,});
    const [filters, setFilters] = useState<FilterOptions>();
    const [selectedFilters, setSelectedFilters] = useState<SelectedOptions>({});
    const [forceFilterChange, setForceFilterChange] = useState<Boolean>(false);

    //Сортировки
    const [dataSort, setDataSort] = useState<ISortOption[]>([
        { lable: "НАЗВАНИЕ ЗАДАЧИ", sort: undefined, code: "nameTask" },
        { lable: "ПРОЕКТ", sort: undefined, code: "projectTask" },
        { lable: "КОЛОНКА", sort: undefined, code: "columnTask" },
        { lable: "СТАТУС", sort: undefined, code: "status" },
        { lable: "ПРИОРИТЕТ", sort: undefined, code: "priority" },
        { lable: "ТИП ЗАДАЧИ", sort: undefined, code: "type" },
        { lable: "ДАТА", sort: undefined, code: "date" },
    ]);
    //Тогглы
    const [showOnlyFavourite, setShowOnlyFavourite] = useState<boolean>(store.userMonitorShowFavourite);

    //Эти кастомные сеты нужны чтобы не было бага при включении звездочки.
    function setShowOnlyFavouriteFunc(value: boolean) {
        setSelectedTaskId(undefined);
        setShowOnlyFavourite(value);
        store.setUserMonitorShowFavourite(value);
    }
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    function setShowCompletedFunc(value: boolean) {
        setSelectedTaskId(undefined);
        setShowCompleted(value);
    }

    //Избранное
    //Правый бар
    const [totalStats, setTotalStats] =
        useState<{total: number; ongoing: number; completed: number; overdue: number; } | undefined>(undefined);


    useEffect(() => {
        (async () => {
            setBreadCrumb([{ label: "Монитор пользователя", url: "monitor" }]);
            setNavPanelHighlight("monitor");
            const fastFilters = await store.getMonitorFilters();
            if (fastFilters) {
                setFastFilters(fastFilters);
            }

            const filterParams: IIParametrsFilter = {
                projectId: undefined,
                responsible: false,
                priority: true,
                typeTask: true,
                status: false,
                role: false,
                fileType: false,
                project: true
            };
            let data = await store.getDataForFilter(filterParams);
            if (data) {
                data.date = true;
                setFilters(data);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setSelectedTaskId(undefined);
            loadTasks();
        })();
    }, [
        showOnlyFavourite,
        showCompleted,
        selectedFastFilters,
        selectedFilters,
        dataSort,
    ]);

    const loadTasks = async () => {
        // debugger
        store.getFavouriteTasks().then((res) => {
            if (res?.favourited) {
                setFavouriteTaskIds(res.favourited);
            }
        });
        setScrollEndedTasks(false);

        store.userMonitorService.getTasks({
                skip: 0,
                take: TABLE_PAGE_SIZE,
                filters: getCurrentFilters(),
                sort: getCurrentSort(),
            })
            .then((res) => {
                setTasks(res.data.tasks);
                setTotalStats(res.data.rightBarStats);
                if (res.data.count === 0)
                    setScrollEndedTasks(true);
                setSkip(TABLE_PAGE_SIZE);
            });
    };

    const loadPage = async () => {
        try {
            const tasksRes = await store.userMonitorService.getTasks({
                    skip: skip,
                    take: TABLE_PAGE_SIZE,
                    filters: getCurrentFilters(),
                    sort: getCurrentSort(),
                })
            setTasks((prev) => [...prev, ...tasksRes.data.tasks]);

            if (tasksRes.data.count === 0)
                setScrollEndedTasks(true);

            setSkip(skip + TABLE_PAGE_SIZE);

        } catch (err) {
            if (axios.isAxiosError(err)) {
                showToast(err.response?.data.message);
                setScrollEndedTasks(true);
            }
        }
    };

    const getCurrentFilters = (): IMonitorTasksFilter => {
        const filter: IMonitorTasksFilter = {
            responsible: undefined,
            date: selectedFilters.dates?.map(date => date.toISOString()),
            status: undefined,
            priority: selectedFilters.priorityIds?.length ? selectedFilters.priorityIds : undefined,
            typeTask: selectedFilters.typeTaskIds?.length ? selectedFilters.typeTaskIds : undefined,
            projectIds: selectedFilters.projectIds?.length ? selectedFilters.projectIds : undefined,
            name: taskNameSearch,
            favourite: showOnlyFavourite,
            onlyOngoing: !showCompleted,
        };

        return filter;
    };

    const getCurrentSort = (): IGetTaskSort => {
        const sortOption = dataSort.find((s) => s.sort);

        const sort: IGetTaskSort = {
            date: sortOption?.code === "date" ? sortOption.sort : undefined,
            status: sortOption?.code === "status" ? sortOption.sort : undefined,
            type: sortOption?.code === "type" ? sortOption.sort : undefined,
            priority:
                sortOption?.code === "priority" ? sortOption.sort : undefined,
            nameTask:
                sortOption?.code === "nameTask" ? sortOption.sort : undefined,
            project:
                sortOption?.code === "projectTask"
                    ? sortOption.sort
                    : undefined,
        };

        return sort;
    };

    useEffect(() => {
        if (!forceFilterChange) {
            if (
                selectedFilters.priorityIds &&
                selectedFilters.priorityIds.length > 0 &&
                selectedFilters.typeTaskIds &&
                selectedFilters.typeTaskIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: undefined,
                    tag: undefined,
                });
            } else if (
                selectedFilters.priorityIds &&
                selectedFilters.priorityIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: selectedFastFilters.type,
                    tag: undefined,
                });
            } else if (
                selectedFilters.typeTaskIds &&
                selectedFilters.typeTaskIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: selectedFastFilters.priority,
                    type: undefined,
                    tag: undefined,
                });
            }
        }
        setForceFilterChange(false);
    }, [selectedFilters]);

    const changeFastFilters = (fastFilters: ISelectedFastFilters) => {
        setSelectedFastFilters(fastFilters);


        const filtersCopy: SelectedOptions = structuredClone(selectedFilters);
        if (fastFilters.priority)
            filtersCopy.priorityIds = [fastFilters.priority.id]

        setSelectedFilters(filtersCopy);
        setForceFilterChange(true);
    };

    const resetFilter = () => {
        setTaskNameSearch("");
        setSelectedFilters({});
        setSelectedFastFilters({
            priority: undefined,
            type: undefined,
            tag: undefined,
        });
    };

    async function updateFavourite(newFavourite: { favourited: number[] }) {
        let tasksUpdated = await store.setFavouriteTasks(newFavourite);

        if (tasksUpdated?.favourited) {
            setFavouriteTaskIds(tasksUpdated.favourited);
        }
    }

    function onSortChange() {
        const dataSortCopy = structuredClone(dataSort);
        dataSortCopy.forEach((s: ISortOption) => {
            if (s.sort)
                s.sort = !s.sort
                    ? "asc"
                    : s.sort === "asc"
                    ? "desc"
                    : undefined;
        });
        setDataSort(dataSortCopy);
        setSelectedTaskId(undefined);
    }

    function addSort(code: string) {
        const dataSortCopy = structuredClone(dataSort);
        dataSortCopy.forEach((s: ISortOption) => {
            s.sort = s.code === code ? "asc" : undefined;
        });
        setDataSort(dataSortCopy);
        setSelectedTaskId(undefined);
    }

    const onScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        if (scrollYCheck(event) && !scrollEndedTasks) {
            loadPage();
        }
    };

    return (
        <UserMonitorContext.Provider
            value={{
                selectedFastFilters, changeFastFilters,
                fastFilters, filters,
                selectedFilters, setSelectedFilters,
                resetFilter,
            }}
        >
            <div className="user-monitor-page-main-container">
                <div className="user-monitor-main-widget-area">
                    <div className="user-monitor-top-toolbar card-shadow">
                        <UserMonitorToolbar
                            setShowCompleted={setShowCompletedFunc}
                            setShowOnlyFavourite={setShowOnlyFavouriteFunc}
                            showCompleted={showCompleted}
                            showOnlyFavourite={showOnlyFavourite}
                            taskNameSearch={taskNameSearch}
                            setTaskNameSearch={setTaskNameSearch}
                            onSearchSubmit={() => loadTasks()}
                        />
                    </div>
                    <TasksTable
                        dataSort={dataSort}
                        onSortChange={onSortChange}
                        addSort={addSort}
                        selectedTaskId={selectedTaskId}
                        setSelectedTaskId={setSelectedTaskId}
                        tasks={tasks}
                        favouriteTaskIds={favouriteTaskIds}
                        updateFavourite={updateFavourite}
                        onScroll={onScroll}
                    />
                </div>
                <div className="user-monitor-right-bar-container card-shadow">
                    {selectedTaskId ? (
                        <TaskDescription
                            task={tasks.find((t) => t.id === selectedTaskId)!}
                            setSelectedTaskId={setSelectedTaskId}
                        />
                    ) : (
                        <TasksInfo totalStats={totalStats} />
                    )}
                </div>
            </div>
        </UserMonitorContext.Provider>
    );
};

export default UserMonitor;
