import {FC, ReactNode, useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import BtnAdd from "../buttons/btnAdd.component/BtnAdd";
import AddEditProject from "../Shared/AddEditProject/AddEditProject";
import {AppContext} from "../../App";


interface ProjectsToolbarProps {
    loadData: () => Promise<void>,
    addProject?: boolean,
}

const ProjectsMain: FC<ProjectsToolbarProps> = ({loadData, addProject}: ProjectsToolbarProps) => {
    const {showToast, archivedPage} = useContext(AppContext);


    const [openEdit, setOpenEdit] = useState<boolean>(false);
    // const [addProject, setAddProject] = useState(false);

    return (
        <div>
            <div>
                {archivedPage ? null :
                <BtnAdd
                    onClickFunc={() => (addProject ? setOpenEdit(true) : showToast("У вас нет прав на добавление проекта!"))}
                    type="" permission={addProject}/>
                }
            </div>
            {openEdit && <AddEditProject setOpen={setOpenEdit} onAction={loadData}/>}
        </div>
    )
}

export default observer(ProjectsMain);