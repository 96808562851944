import $api from "..";
import {AxiosResponse} from "axios";
import {IGetNotifications} from "../models/NotificationModels/IGetNotifications";
import {IRespNotification, IRespNotificationData} from "../models/NotificationModels/IRespNotification";

export default class NotificationService {
    async getNotifications(body: IGetNotifications): Promise<AxiosResponse<IRespNotification>> {
        return $api.post<IRespNotification>('/getNotifications', body);
    }

    async checkNotificationViewed(body: number[]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/checkNotificationViewed', body);
    }
}