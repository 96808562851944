import React, {FC, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import "./SystemSettings.css"
import BasicTab from "../../component/SystemSettings/BasicTab/BasicTab";
import RolesSystem from "../../component/SystemSettings/RolesSystem/RolesSystem";
import SystemUsers from "../../component/SystemSettings/SystemUsers/SystemUsers";
import { AppContext } from '../../App';
import SafetySettings from "../../component/SystemSettings/SafetySettings/SafetySettings";
import AdministrationStorage from "../../component/SystemSettings/AdministrationStorage/AdministrationStorage";

const SystemSettings: FC = (index) => {
    const [tab, setTab] = React.useState<number>(0);
    const tabs: string[] = ["Основные", "Пользователи", "Список ролей", "Безопасность"];
    const { setBreadCrumb, setNavPanelHighlight } = useContext(AppContext);

    useEffect(() => {
        setBreadCrumb([])
        setNavPanelHighlight(undefined);
    }, [])

    function changePage(id: number) {
        setTab(id);
    };

    return (
        <div className="system__settings">
            <div className="system__settings--header">
                <div className="system__settings--header__tabs">
                    {tabs.map((tabName, i) => (
                        <button className={`system__settings--header__button ${tab === i ? "system__settings--header__button__selected" : ""}`} onClick={() => (changePage(i))}>
                            {tabName}
                        </button>
                    ))}
                </div>
            </div>
            <div className="system__settings--body">
                {tab === 0 ?
                    <div>
                        <BasicTab/>
                    </div>
                    : tab === 1 ?
                        <div>
                            <SystemUsers/>
                        </div>
                        : tab === 2 ?
                            <div>
                                <RolesSystem/>
                            </div>
                            : tab === 3 ?
                                <div>
                                    <SafetySettings/>
                                </div>
                                :
                                <div>
                                    {/*<AdministrationStorage/>*/}
                                </div>
                }
            </div>
        </div>
    );
};

export default observer(SystemSettings);