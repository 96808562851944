import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import SearchFilters from "../../component/GlobalSearch/SearchFilters/SearchFilters";
import SearchResults from "../../component/GlobalSearch/SearchResults/SearchResults";
import { ISearchRequest, ISearchResult } from "../../models/ISearch";
import { Context } from "../..";
import { isAxiosError } from "axios";
import { scrollYCheck } from "../../helpers/domUtils";
import { useLocation, useNavigate } from "react-router-dom";
import "./GlobalSearch.css"

export const SEARCH_TAKE = 20;

export enum GlobalSearchNavigator {
    all = "all",
    project = "project",
    board = "board",
    task = "task"
}

const GlobalSearch: FC = () => {
    const { setBreadCrumb, setNavPanelHighlight } = useContext(AppContext);
    const { showToast } = useContext(AppContext);
    const { store } = useContext(Context);
    const [searchResult, setSearchResult] = useState<ISearchResult>();
    const [searchRequest, setSearchRequest] = useState<ISearchRequest>();
    const [scrollEnded, setScrollEnded] = useState<boolean>(false);
    const [heightComponent ,setHeightComponent] = useState<number>(0)
    const [currentTab, setCurrentTab] = useState<GlobalSearchNavigator>(GlobalSearchNavigator.all);

    const location = useLocation();
    const navigate = useNavigate();
    const searchText = location.state?.searchText;

    //TODO доверстать результаты поиска и кнопку поиска

    useEffect(() => {
        setBreadCrumb([{ label: "Поиск", url: "/globalsearch" }]);
        setNavPanelHighlight(undefined);
        // Нужно чтобы сбраcывался location.state
        navigate(location.pathname, { replace: true });
        (async () => {
            if (searchText) {
                const searchReq: ISearchRequest = {
                    search: String(searchText),
                    filters: null,
                    sort: {createdAt: "asc", createdBy: null, name: null},
                    skip: 0,
                    take: 20
                };
                await search(searchReq);
            }
        })();
    }, []);

    const search = async (req: ISearchRequest | undefined) => {
        try {
            if (!req) {
                setSearchRequest(undefined);
                setSearchResult(undefined);
                return;
            }
            const res = await store.searchService.search(req);
            setSearchResult(res.data);
            setSearchRequest(req);
            if (res.data.total === 0) setScrollEnded(true);
        } catch (err) {
            if (isAxiosError(err)) showToast(err.response?.data.message);
        }
    };

    const loadPage = async () => {
        try {
            if (!searchRequest || !searchResult) return;
            const reqCopy = structuredClone(searchRequest);
            reqCopy.skip += SEARCH_TAKE;
            const res = await store.searchService.search(reqCopy);
            if (res.data.total === 0) setScrollEnded(true);
            setSearchResult({
                total: searchResult.total + res.data.total,
                projects: [...searchResult.projects, ...res.data.projects],
                boards: [...searchResult.boards, ...res.data.boards],
                tasks: [...searchResult.tasks, ...res.data.tasks],
            });
            setSearchRequest(reqCopy);
        } catch (err) {
            if (isAxiosError(err)) {
                showToast(err.response?.data.message);
                setScrollEnded(true);
            }
        }
    };

    const onScroll = (event: any) => {
        if (scrollYCheck(event) && !scrollEnded) {
            loadPage();
        }
    };

    return (
        <div className="search-global-page" style={{display: "flex", flexDirection: "column"}}>
            <SearchFilters
                onSearch={search}
                defaultSearchText={searchText}
                currentTab={currentTab}
                setCurrentTab={(tab) => setCurrentTab(tab)}
            />
            <SearchResults
                result={searchResult}
                onScroll={onScroll}
                currentTab={currentTab}
                searchText={searchRequest?.search}
            />
        </div>
    );
};

export default GlobalSearch;
