import React, {
    FC,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { ISideBarProject } from "../../../models/LeftMenuModels";
import { AppContext } from "../../../App";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";
import { Link } from "react-router-dom";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { Context } from "../../..";
import { PersonalSettingsOptions } from "../../../models/PersonalSettings";
import BoardComponent from "./BoardComponent";
import SubprojectComponent from "./SubprojectComponent";

interface IProjectComponent {
    project: ISideBarProject;
}

const ProjectComponent: FC<IProjectComponent> = ({ project }) => {
    const { navBarInfo, toggleProject } = useContext(AppContext);
    const { store } = useContext(Context);
    const projectOpen = navBarInfo?.openProjectIds?.includes(project.id);
    const projectHighlighted =
        navBarInfo?.projectId === project.id &&
        navBarInfo?.boardId === undefined &&
        navBarInfo?.subprojectId === undefined;
    const projectMarked =
        !projectHighlighted && navBarInfo?.projectId === project.id;

    const [showProxyBoards, setShowProxyBoards] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        const showProxy = store.personalSettings.find(
            (s) =>
                s.paramName === PersonalSettingsOptions.showProxyBoardsLeftMenu
        );
        if (showProxy) setShowProxyBoards(showProxy.value as boolean);
        else setShowProxyBoards(true);
    }, [store.personalSettings]);

    useLayoutEffect(() => {
        const container = document.getElementById(
            `nav-panel-project-${project.id}`
        );
        const name = document.getElementById(
            `nav-panel-project-name-${project.id}`
        );

        if (!container || !name) return;

        if (name.offsetWidth > container.clientWidth) setShowTooltip(true);
    }, []);

    return (
        <div
            key={project.id}
            className={`nav-panel_project-container ${
                projectOpen ? "open" : ""
            }`}
        >
            <Tooltip
                text={project.name ?? ""}
                offset={5}
                placement={"right"}
                style={{
                    width: "fit-content",
                    maxWidth: "250px",
                    wordBreak: "break-all",
                    display: showTooltip ? "block" : "none",
                }}
            >
                <Link
                    to={"/project/" + project.id}
                    className={`nav-panel_project ${
                        projectOpen ? "open" : ""
                    } ${projectHighlighted ? "highlighted" : ""} ${
                        projectMarked ? "with-mark" : ""
                    }`}
                    onClick={() => {
                        if (projectHighlighted) toggleProject(project.id);
                    }}
                >
                    <div
                        id={`nav-panel-project-${project.id}`}
                        className="nav-panel_label"
                    >
                        <span id={`nav-panel-project-name-${project.id}`}>
                            {project.name}
                        </span>
                    </div>
                    <ArrowButton
                        onClick={(e) => {
                            e.preventDefault();
                            toggleProject(project.id);
                        }}
                        direction={projectOpen ? "bottom" : "top"}
                        whiteArrow={projectHighlighted}
                    />
                </Link>
            </Tooltip>
            {projectOpen ? (
                <div className="nav-panel_project-children">
                    <div className="nav-panel_project-boards">
                        {project.boards.length ? (
                            <div className="nav-panel_divider">ДОСКИ</div>
                        ) : null}
                        {project.boards
                            .filter((b) => showProxyBoards || !b.isProxyBoard)
                            .map((board) => (
                                <BoardComponent
                                    key={board.id}
                                    board={board}
                                    showProxyBoards={showProxyBoards}
                                />
                            ))}
                    </div>
                    {project.subprojects?.length ? (
                        <div className="nav-panel_divider">ПОДПРОЕКТЫ</div>
                    ) : null}
                    {project.subprojects?.map((subproject) => (
                        <SubprojectComponent
                            key={subproject.id}
                            subproject={subproject}
                            showProxyBoards={showProxyBoards}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default ProjectComponent;
