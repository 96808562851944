// @ts-ignore
import React, {CSSProperties, FC, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import "./Notifications.css"
import CheckboxIos from "../../UiLib/CheckboxIos/CheckboxIos";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import DropDownList from "../../UiLib/DropDownList/DropDownList";
import {Context} from "../../../index";
import {IGetNotifications} from "../../../models/NotificationModels/IGetNotifications";
import io from "socket.io-client";
import {INotification, IRespNotification} from '../../../models/NotificationModels/IRespNotification';
import { AppContext } from '../../../App';
import Person from '../../../assets/avatar.svg'
import { dateToNiceString } from '../../../helpers/dateToNiceString';
import MarkdownPreview from '../MarkdownPreview/MarkdownPreview';
import { NotificationType } from '../../../models/NotificationModels/EEntityTypeNotification';
import { SortType, sortAny } from '../../../helpers/sortUtils';
import { scrollYCheck } from '../../../helpers/domUtils';
import { TypeOfChange } from '../../../models/NotificationModels/ETypeOfChange';
import {formatEditMessage} from "../../../helpers/chatFucntion";


interface INotifications {
    onClose: () => void;
}

const Notifications: FC<INotifications> = ({onClose}) => {
    const outsideAlerterRef: any = useOutsideAlerter(onClose);
    const {notification, changeNotification, showToast} = useContext(AppContext);
    const [currentNotification, setCurrentNotification] = useState<INotification[]>(notification?.data?.notifications);
    const [countNot, setCountNot] = useState<number>(notification?.data?.allCount)
    const [countNotViewed, setCountNotViewed] = useState<number>(notification?.data?.countNotViewedData)
    const [blockFetch, setBlockFetch] = useState<boolean>(false)
    const [onlyNotViewed, setOnlyNotViewed] = useState<boolean>(false);
    const {store} = useContext(Context);
    const navigate = useNavigate();

    const checkNotificationViewed = async (chekIds: number[]) => {
        try {
            await store.notificationService.checkNotificationViewed(chekIds);
        } catch (err) {
            return err;
        }
    }

    const fetchCurentData = async (skip: number, viewed: boolean) => {
        let reqBody: IGetNotifications = {
            skip: skip,
            take: 10,
            filters: {
                onlyNotViewed: viewed,
                mention: true
            },
            sort: {
                date: "desc"
            }
        }; 

        try {
            let newNotification = await store.notificationService.getNotifications(reqBody)
            return newNotification;
        } catch (err) {
            console.error(err);
            return undefined;
        }
    }

    const fetchData = async () => {
        setOnlyNotViewed(false);
        let reqBody: IGetNotifications = {
            skip: 0,
            take: 10,
            filters: {
                onlyNotViewed: false,
                mention: true
            },
            sort: {
                date: "desc"
            }
        }; 

        try {
            let newNotification = await store.notificationService.getNotifications(reqBody)
            return newNotification;
        } catch (err) {
            console.error(err);
            return undefined;
        }
    }


    useEffect(() => {

        if (!notification?.data) {
            return
        }

        let dateNotifications: INotification[] = notification.data.notifications as INotification[];
        let notificationCount: number = notification.data.allCount as number;

        let data = dateNotifications?.filter(xx => !currentNotification.map(yy => yy.id).includes(xx.id));
        let mass = [ ...data, ...currentNotification];
        let sortedMassDesc = sortAny(SortType.date, mass, "desc", "createdAt");

        setCurrentNotification(sortedMassDesc || mass);

        if (notificationCount <= 0) {
            setBlockFetch(true)
        } else {
            setCountNot(notificationCount);
            setBlockFetch(false)
        }
    }, [notification]);



    const filterUnreadMessages = () => {
        setOnlyNotViewed(prevValue => !prevValue);
        fetchCurentData(0, onlyNotViewed ? false : true)
            .then(newGetData => {
                if (!newGetData?.data?.data) {
                    setCurrentNotification([]);
                    setBlockFetch(false);
                    setCountNot(0);
                    return;
                }

                let currentGetData = newGetData.data.data;

                let dateNotifications: INotification[] = currentGetData?.notifications as INotification[];

                let notificationCount: number = currentGetData?.allCount as number;
                setCurrentNotification(prevValue => {
                    let mass = [ ...dateNotifications];
                    let sortedMassDesc = sortAny(SortType.date, mass, "desc", "createdAt");
        
                    return sortedMassDesc || mass;
                });
                if (notificationCount <= 0) {
                    setBlockFetch(true)
                } else {
                    setCountNot(notificationCount);
                    setBlockFetch(false)
                }
            })
            .catch(yy => {console.error(yy)})
    }

    const getData = (event: React.UIEvent<HTMLDivElement>) => {
        if (scrollYCheck(event, 1) && !blockFetch) {
            fetchCurentData(currentNotification.length, onlyNotViewed)
                .then(newGetData => {
                    if (!newGetData?.data && !newGetData?.data?.data) {
                        return;
                    }

                    let currentGetData = newGetData.data.data;
                    let dateNotifications: INotification[] = currentGetData?.notifications as INotification[];
                    let notificationCount: number = currentGetData?.allCount as number;

                    let data = currentGetData!.notifications.filter(xx => dateNotifications?.map(yy => yy.id).includes(xx.id));

                    setCurrentNotification(prevValue => {
                        let mass = [ ...data, ...prevValue];
                        let sortedMassDesc = sortAny(SortType.date, mass, "desc", "createdAt");

                        return sortedMassDesc || mass;
                    });

                    if (notificationCount <= 0) {
                        setBlockFetch(true)
                    } else {
                        setCountNot(notificationCount);
                        setBlockFetch(false)
                    }
                })
                .catch(yy => {console.error(yy)})
        }
    }

    const clickMentions = (notificationId: number, entityId: number, entityType: NotificationType) => {
        if (entityType == NotificationType.taskComment || entityType == NotificationType.checkListComment) {
            checkNotificationViewed([notificationId])
                .then(xx => {
                    fetchData()
                        .then(newGetData => {
                            if (!newGetData?.data) {
                                return;
                            }

                            let curentData: IRespNotification = newGetData.data as IRespNotification;

                            let dateNotifications: INotification[] = curentData.data?.notifications as INotification[];
                            let notificationCount: number = curentData.data?.allCount as number;
                            let countNotViewedNot: number = curentData.data?.countNotViewedData as number;

                            changeNotification({data: {notifications: dateNotifications, allCount: notificationCount, countNotViewedData: countNotViewedNot}});

                            setCurrentNotification(prevValue => {
                                let mass = [ ...dateNotifications];
                                let sortedMassDesc = sortAny(SortType.date, mass, "desc", "createdAt");

                                return sortedMassDesc || mass;
                            });

                            setCountNotViewed(countNotViewedNot);

                            if (notificationCount <= 0) {
                                setBlockFetch(true)
                            } else {
                                setCountNot(notificationCount);
                                setBlockFetch(false)
                            }
                        });
                })
                .catch(yy => {console.error(yy)});
        }
    }

    const goToEntity = (notificationId: number, entityId: number, entityType: NotificationType) => {
        if (entityType == NotificationType.taskComment || entityType == NotificationType.checkListComment) {
            checkNotificationViewed([notificationId])
                .then(xx => {
                    onClose();
                    let currentNot = currentNotification.find(xx => xx.id == notificationId);
                    const checklistParam = entityType == NotificationType.taskComment ? `?checkList=${0}` : `?checkList=${1}`;
                    if (currentNot && currentNot.messageId) {
                        navigate(`/task/${entityId}${checklistParam}#message-${currentNot.messageId}`);
                    } else {
                        navigate(`/task/${entityId}${checklistParam}`);
                    }
                    fetchData()
                        .then(newGetData => {
                            if (!newGetData?.data) {
                                return;
                            }

                            let curentData: IRespNotification = newGetData.data as IRespNotification;
                            


                            let dateNotifications: INotification[] = curentData.data?.notifications as INotification[];
                            let notificationCount: number = curentData.data?.allCount as number;
                            let countNotViewedNot: number = curentData.data?.countNotViewedData as number;

                            changeNotification({data: {notifications: dateNotifications, allCount: notificationCount, countNotViewedData: countNotViewedNot}});

                            setCurrentNotification(prevValue => {
                                let mass = [ ...dateNotifications];
                                let sortedMassDesc = sortAny(SortType.date, mass, "desc", "createdAt");

                                return sortedMassDesc || mass;
                            });

                            setCountNotViewed(countNotViewedNot);

                            if (notificationCount <= 0) {
                                setBlockFetch(true)
                            } else {
                                setCountNot(notificationCount);
                                setBlockFetch(false)
                            }
                        });
                    })
                .catch(yy => {console.error(yy)});
        }
    }

    const getNotificationBlock = (id: number, notification: INotification) => {
        // debugger
        let contentToDesc: string = notification && notification.changeData && notification.changeData.newData && notification.changeData.newData[0]
            ? notification.changeData.newData[0].value : "";
        return (
            <div id={String(id)} className={`${notification.viewed ? "" : "mention__block--current__not--viewed"} mention__block--current`} onClick={() => clickMentions(notification.id ,notification?.entityId, notification?.entityType)}>
                <div className='mention__block--current__img'>
                    <img src={Person} />
                </div>
                <div className='mention__block--current__body'>
                    <div className='mention__block--current__name'>
                        <a>{`${notification?.createdBy?.name} ${notification?.createdBy?.surname}`}</a>
                        <a className='mention__block--current__name--gray'>{dateToNiceString(new Date(notification?.createdAt), "month dd, yyyy hh:mm")}</a>
                    </div>
                    <div className='mention__block--current__markdown'>
                        <div className='mention__block--current__markdown--block'>
                            {contentToDesc.length > 40 ?
                                (<p title={formatEditMessage(contentToDesc, {data: []}).disassembledText} className="mention__block--current__markdown--block__text">
                                    <div dangerouslySetInnerHTML={{__html: formatEditMessage(contentToDesc, {data: []}).disassembledText?.length > 40 ?
                                            formatEditMessage(contentToDesc, {data: []}).disassembledText?.slice(0, 40) + "..."  :
                                            formatEditMessage(contentToDesc, {data: []}).disassembledText?.slice(0, 40)
                                    }}/>
                                </p>)
                                :
                                (<p className="task--description__board">
                                    {contentToDesc}
                                </p>)
                            }
                            {/*<MarkdownPreview content={}/>*/}
                        </div>
                        <div className='mention__block--current__markdown--text'>
                            <a className='mention__block--current__markdown--text__go--to' onClick={() => goToEntity(notification.id ,notification?.entityId, notification?.entityType)}>Перейти</a>
                        </div>
                    </div>
                </div>
                <div style={notification.viewed ? {display: "none"} : {display: "flex"}} className='mention__block--current__read'>
                    <div className='mention__block--current__read--point'></div>
                </div>
            </div>
        )
    }
    
    return (
        <div ref={outsideAlerterRef}>
            <WidgetContainer style={{width: "480px",height:'550px', display: 'flex', flexDirection: 'column'}} >
                <div className="notification--block__header">
                    <h3>Уведомления</h3>
                    <p>Только непрочитанные</p>
                    <CheckboxIos id={"checkbox-notifications"} checked={onlyNotViewed} callBack={() => filterUnreadMessages()}/>
                </div>
                <div className="notification--block__body" onScroll={(event) => getData(event)}>
                    <DropDownList numChild={countNotViewed} initialStateOpen={countNotViewed <= 0 ? false : true} componentMod="dropDownList" listName={"Упоминания"}>
                        <div>
                            {currentNotification?.map((notif, index) => (
                                getNotificationBlock(index, notif)
                            ))}
                        </div>
                    </DropDownList>
                </div>
            </WidgetContainer>
        </div>
    );
};

export default Notifications;