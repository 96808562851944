import React, {CSSProperties, FC, useState} from 'react';
import "./Button.css"
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tooltip from "../CustomTooltip/CustomTooltip";

interface IButtonProps {
    text?: string | undefined;
    image?: any;
    id?: string;
    callBack: () => void;
    style?: CSSProperties;
    textColor?: string;
    classBlock?: string | string[];
    title?: string;
    titlePosition?: "top" | "left" | "right" | "bottom";
}

/**
 * Компонент для отображения интерактивной кнопки.
 *
 * @param {IButtonProps} props - Свойства компонента.
 * @param {string | undefined} props.text - Текст, отображаемый на кнопке. Может быть undefined, если на кнопке только изображение.
 * @param {any} props.image - Изображение, отображаемое на кнопке.  Может быть как URL картинки, так и JSX элемент.
 * @param {string} id - id кнопки
 * @param {() => void} props.callBack - Функция обратного вызова, вызываемая при клике на кнопку.
 * @param {CSSProperties} [props.style] - Дополнительные стили, которые применяются к контейнеру.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий компонент кнопки.
 */
const Button: FC<IButtonProps> = ({text, image,id, callBack, style, textColor, classBlock
                                      , title, titlePosition}) => {
    return (
        <button
            key={id}
            style={{...style}}
            className={`${classBlock} ui__component--button ${text ? "ui__component--button__text" : "ui__component--button__none--text"}`}
            onClick={callBack}
        >
            <Tooltip offset={5} text={title ? title : ""} placement={titlePosition}>
                {image ?
                    <img src={image}/>
                : null}
                {text ?
                    <a style={{color: `var(${textColor})`}}>{text}</a>
                : null}
            </Tooltip>
        </button>
    );
};

export default Button;