import { Link } from "react-router-dom";
import "./NavigationPanel.css";
import { ISideBarProject } from "../../../models/LeftMenuModels";
import {
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { AppContext } from "../../../App";
import InfoPanel from "../InfoPanel/InfoPanel";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { Context } from "../../..";
import BoardComponent from "./BoardComponent";
import SubprojectComponent from "./SubprojectComponent";
import { IHasPermission } from "../../../models/IChekRole";
import { PersonalSettingsOptions } from "../../../models/PersonalSettings";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";
import ProjectComponent from "./ProjectComponent";

export type HighlightOptions = "projects" | "monitor" | "archive" | "basket";

export interface INavBarInfo {
    projectId?: number;
    subprojectId?: number;
    boardId?: number;
    openProjectIds?: number[];
    openBoardIds?: number[];
}

interface NavigationPanelProps {
    userAccess: IHasPermission[];
    collapsed: boolean;
}

const NavigationPanel: FC<NavigationPanelProps> = ({
    userAccess,
    collapsed,
}) => {
    const { sideBarProjects, navPanelHighlight, navBarInfo } =
        useContext(AppContext);
    const [projectListOpen, setProjectListOpen] = useState<boolean>(false);
    const { store } = useContext(Context);

    //ролевая модель
    const [viewMonitor, setViewMonitor] = useState(false);
    const [viewBasket, setViewBasket] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [viewProjects, setViewProjects] = useState(false);
    const [viewArchive, setViewArchive] = useState(false);

    useEffect(() => {
        if (navPanelHighlight === "projects") setProjectListOpen(true);
        if (
            (navBarInfo?.projectId ||
                navBarInfo?.subprojectId ||
                navBarInfo?.boardId) &&
            navPanelHighlight !== "archive"
        )
            setProjectListOpen(true);
    }, [navPanelHighlight, navBarInfo]);

    useEffect(() => {
        const checkPermission = (
            functionCode: string,
            permissionCode: string,
            setter: {
                (value: SetStateAction<boolean>): void;
                (value: SetStateAction<boolean>): void;
                (value: SetStateAction<boolean>): void;
                (value: SetStateAction<boolean>): void;
                (arg0: boolean): void;
            }
        ) => {
            const functionItem = userAccess.find(
                (xx) => xx.functionCode === functionCode
            );
            if (functionItem) {
                const permission = functionItem.permissions.find(
                    (yy) => yy.permissionCode === permissionCode && yy.isGranted
                );
                if (permission) {
                    setter(true);
                }
            }
        };

        checkPermission("UserMonitorAction", "view", setViewMonitor);
        checkPermission("SystemProjectAction", "view", setViewProjects);
        checkPermission("ArchiveAction", "view", setViewArchive);
        checkPermission("BasketAction", "view", setViewBasket);
    }, [userAccess]);

    return (
        <div className="nav-panel">
            {viewProjects ? (
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "projects" ? "highlighted" : ""
                    }`}
                    to={"/projects"}
                >
                    <div className="nav-panel_projects-container">
                        <div className="nav-panel_label-container">
                            <div className="image-placeholder project" />
                            {!collapsed ? (
                                <div className={`nav-panel_label`}>Проекты</div>
                            ) : null}
                        </div>
                        {!collapsed ? (
                            <div className="nav-panel_project-icons">
                                <InfoPanel
                                    count={sideBarProjects?.length}
                                    idKey="projectCount"
                                />
                                <ArrowButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setProjectListOpen(!projectListOpen);
                                    }}
                                    direction={
                                        projectListOpen ? "bottom" : "top"
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                </Link>
            ) : null}
            {viewProjects || viewAll ? (
                <div className="nav-panel_projects-list">
                    {!collapsed && projectListOpen
                        ? (sideBarProjects as ISideBarProject[]).map(
                              (project) => (
                                  <ProjectComponent project={project} />
                              )
                          )
                        : null}
                </div>
            ) : null}
            {viewMonitor ? (
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "monitor" ? "highlighted" : ""
                    }`}
                    to={"/monitor"}
                >
                    <div className="nav-panel_label-container">
                        <div className="image-placeholder monitor" />
                        {!collapsed ? (
                            <div className="nav-panel_label">
                                Монитор пользователя
                            </div>
                        ) : null}
                    </div>
                </Link>
            ) : (
                <div></div>
            )}
            {!store.getBlockArchive() && viewArchive ? (
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "archive" ? "highlighted" : ""
                    }`}
                    to={"/archive"}
                >
                    <div className="nav-panel_label-container">
                        <div className="image-placeholder archive" />
                        {!collapsed ? (
                            <div className="nav-panel_label">Архив</div>
                        ) : null}
                    </div>
                </Link>
            ) : (
                <div></div>
            )}
            {viewBasket ? (
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "basket" ? "highlighted" : ""
                    }`}
                    to={"/basket"}
                >
                    <div className="nav-panel_label-container">
                        <div
                            className={`image-placeholder ${
                                collapsed ? "collapsed" : ""
                            } trash`}
                        />
                        {!collapsed ? (
                            <div className="nav-panel_label">Корзина</div>
                        ) : null}
                    </div>
                </Link>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default NavigationPanel;
