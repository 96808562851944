import React, {FC, useContext, useState} from 'react';
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import {
    IExcludePermissionFunction,
    IExcludePermissionFunctionExcludedPermissions
} from "../../../models/IExcludePermissionFunction";
import {ItemPersonRole} from "../../../models/PersonModels/ItemPersonRole";

import {AppContext} from "../../../App";
import {IRestrictAccess} from "../../../models/IRestrictAccess";
import {EPermissionCode} from "../../../models/PermissionModels/EPermissionCode";
import {EEditPermissionCode} from "../../../models/PermissionModels/EEditPermissionCode";

interface IParticipantRights {
    userRole: IExcludePermissionFunction | undefined;
    boardsData: IRestrictAccess | undefined;
    idRoleToEdit: number;
    personId: ItemPersonRole | null | undefined;
    setUserRoleCallback: (userRole: IExcludePermissionFunction) => void;
    setBoardsDataCallback: (boardsData: IRestrictAccess) => void;
}

const ParticipantRights: FC<IParticipantRights> = ({userRole, idRoleToEdit, personId, setUserRoleCallback, boardsData, setBoardsDataCallback}) => {
    const {showToast} = useContext(AppContext);

    // Открываем, скрываем блок
    const handleViewBlocks = (arrowOne: string, arrowTwo: string, block: string) => {
        const arrowOneFind = document.getElementById(arrowOne);
        const arrowTwoFind = document.getElementById(arrowTwo);
        const blockFind = document.getElementById(block);

        if (arrowOneFind && arrowTwoFind && blockFind) {
            if (arrowOneFind.style.display === 'none') {
                arrowOneFind.style.display = 'block';
            } else {
                arrowOneFind.style.display = 'none';
            }
            if (arrowTwoFind.style.display === 'none') {
                arrowTwoFind.style.display = 'block';
            } else {
                arrowTwoFind.style.display = 'none';
            }
            if (blockFind.style.display === 'none') {
                blockFind.style.display = 'block';
            } else {
                blockFind.style.display = 'none';
            }
        }
    }

    const checkAccessToBoard = (getData: IExcludePermissionFunction | undefined, oldExclude: IExcludePermissionFunctionExcludedPermissions[] | undefined) => {
        let allSystemFunction = getData?.roles[idRoleToEdit].functionGroups.flatMap(xx =>
            xx.systemFunctions);
        let findBoardAccess = allSystemFunction?.find(xx =>
            xx.functionCode == "BoardAction")
        let findViewBoard = findBoardAccess?.accessPermissions.find(yy =>
            yy.code == "view");
        let allExcludePerm = getData?.roles[idRoleToEdit].excludedPermissions.map(xx =>
            xx.accessPermission);

        let newBoardData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));

        if (findViewBoard != undefined && allExcludePerm?.includes(findViewBoard.id)) {
            // нужно записать доски
            newBoardData.boards = newBoardData?.boards.map(xx => {
                if (xx.personRoleIds?.includes(getData!.roles[idRoleToEdit].personRoleId)) {
                    return xx
                } else {
                    if (xx.personRoleIds == undefined) {
                        xx.personRoleIds = []
                    }
                    xx.personRoleIds.push(getData!.roles[idRoleToEdit].personRoleId);
                    xx.columns = xx.columns.map(yy => {
                        if (yy.personRoleIds?.includes(getData!.roles[idRoleToEdit].personRoleId)) {
                            return yy;
                        } else {
                            if (yy.personRoleIds == undefined) {
                                yy.personRoleIds = []
                            }
                            yy.personRoleIds.push(getData!.roles[idRoleToEdit].personRoleId);
                        }
                        return yy;
                    });
                }
                return xx
            })
        } else if (findViewBoard != undefined && oldExclude?.map(xx => xx.accessPermission).includes(findViewBoard.id) && !allExcludePerm?.includes(findViewBoard.id)) {
            newBoardData.boards = newBoardData?.boards.map(xx => {
                xx.personRoleIds = xx.personRoleIds?.filter(zz => zz != getData!.roles[idRoleToEdit].personRoleId)
                xx.columns = xx.columns.map(zy => {
                    zy.personRoleIds = zy.personRoleIds?.filter(zz => zz != getData!.roles[idRoleToEdit].personRoleId)
                    return zy;
                })
                return xx;
            })
        }

        setBoardsDataCallback(newBoardData);
    }

    const getDataRole = () => {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(JSON.stringify(userRole));
        if (!getData) {
            showToast('Не удалось получить данные!')
            return undefined;
        }

        let shortAppeal = getData.roles[idRoleToEdit];
        if (!shortAppeal) {
            showToast('Не удалось получить данные текущей роли!')
            return undefined;
        }

        return {getData, shortAppeal};
    }

    // Добавления пользователя в группу объектных доступов
    const changeGroup = (groupId: number, checked: boolean) => {
        let data = getDataRole()
        let oldExclude = data?.shortAppeal.excludedPermissions;
        if (!data) {
            showToast('Не удалось получить данные!')
            return;
        }

        let currentGroup = data.shortAppeal.functionGroups.find((xx) => xx.id == groupId);
        let allSystemFunction = currentGroup!.systemFunctions.flatMap(xx => xx.accessPermissions);
        let allExclude = data.shortAppeal.excludedPermissions.map(xx => xx.accessPermission);

        if (checked == false) {
            allSystemFunction.forEach((xx) => {
                if (!allExclude.includes(xx!.id)) {
                    data!.shortAppeal.excludedPermissions.push({
                        id: 0,
                        accessPermission: xx!.id,
                        personRoleId: Number(personId!.code!)
                    });
                }
            })
        } else {
            let newAccess = allSystemFunction.map(xx => xx!.id);
            data.shortAppeal.excludedPermissions =
                data.shortAppeal.excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
        }

        setUserRoleCallback(data.getData);
        checkAccessToBoard(data.getData, oldExclude);
    }

    // Добавления пользователя в группу функциональных доступов
    const changeSystemFunctions = (systemFunction: number, checked: boolean) => {
        let data = getDataRole()
        let oldExclude = data?.shortAppeal.excludedPermissions;
        if (!data) {
            showToast('Не удалось получить данные!')
            return;
        }

        let group = data.shortAppeal.functionGroups.map((xx) => xx);
        let system = group.flatMap(xx => {
            const foundSystemFunction = xx.systemFunctions.find((xx) => xx.id === systemFunction);
            return foundSystemFunction ? foundSystemFunction : undefined;
        });
        let allAccessItem = system
            .flatMap(xx => xx?.accessPermissions)
            .filter(accessPermission => accessPermission !== undefined);
        let allExclude = data.shortAppeal.excludedPermissions.map(xx => xx.accessPermission);
        if (checked == false) {
            allAccessItem.forEach((xx) => {
                if (!allExclude.includes(xx!.id)) {
                    data!.shortAppeal.excludedPermissions.push({
                        id: 0,
                        accessPermission: xx!.id,
                        personRoleId: Number(personId!.code!)
                    });
                }
            })
        } else {
            let newAccess = allAccessItem.map(xx => xx!.id);
            data.shortAppeal.excludedPermissions =
                data.shortAppeal.excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
        }

        setUserRoleCallback(data.getData);
        checkAccessToBoard(data.getData, oldExclude);
    }

    // Добавления пользователю доступа к определенному действию
    function chengeAccessPermissions(accessPermission: number, accessPermissionCode: string) {
        let data = getDataRole()
        let oldExclude = data?.shortAppeal.excludedPermissions;
        if (!data) {
            showToast('Не удалось получить данные!')
            return;
        }

        let allAccessItem = data.shortAppeal.excludedPermissions.map((xx) => xx.accessPermission);
        let findItem = allAccessItem.find((xx) => xx === accessPermission);

        // Добавляем доступ
        if (findItem) {
            data.shortAppeal.excludedPermissions =
                data.shortAppeal.excludedPermissions.filter(
                    (xx) => xx.accessPermission !== accessPermission
                );
            // Забираем доступ
        } else {
            data.shortAppeal.excludedPermissions.push({
                id: 0,
                accessPermission: accessPermission,
                personRoleId: Number(personId!.code!)
            });
        }

        setUserRoleCallback(data.getData);
        checkAccessToBoard(data.getData, oldExclude);
    };

    return (
        <div>
            <div>
                <div className="project__edit--role__board--component__edit">
                    <div>
                        <div className="project__edit--role__board--component__role">

                        </div>
                        <div className="project__edit--role__board--component__block">
                            {userRole?.roles[idRoleToEdit].functionGroups.map((group, index) => (
                                <div>
                                    <div className="project__edit--role__board--access__permissions">
                                        <div className="project__edit--role__board--arrow"
                                             onClick={() => (handleViewBlocks("ShowGroupSub" + String(group.id),
                                                 "ShowGroupRight" + String(group.id), "ShowGroupBlock" + String(group.id)))}>
                                            <img src={ArrowSub} id={"ShowGroupSub" + String(group.id)}
                                                 style={{display: 'none'}}/>
                                            <img src={ArrowRight} id={"ShowGroupRight" + String(group.id)}/>
                                        </div>
                                        <div>
                                            {group.name}
                                        </div>
                                        <div
                                            className="project__edit--role__board--access__permissions--input__margin">
                                            <div
                                                className={`project__edit--role__board--access__permissions--input ${group.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                <input type="checkbox"
                                                       checked={group.systemFunctions.some(systemFunction =>
                                                           systemFunction.accessPermissions.some(accessPermission =>
                                                               !userRole?.roles[idRoleToEdit].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                           ))}
                                                       onChange={() => (group.systemRole != true ? changeGroup(group.id, group.systemFunctions.some(systemFunction =>
                                                           systemFunction.accessPermissions.some(accessPermission =>
                                                               userRole?.roles[idRoleToEdit].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                           ))) : showToast("Эта функция выдана в рамках системной роли!"))
                                                       }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"ShowGroupBlock" + String(group.id)}
                                         className="project__edit--role__board--access__permissions--block"
                                         style={{display: 'none'}}>
                                        {group.systemFunctions.map((systemFunction) => (
                                            <div>
                                                <div
                                                    className="project__edit--role__board--access__permissions">
                                                    <div className="project__edit--role__board--arrow"
                                                         onClick={() => (handleViewBlocks("ShowAccessPermissionsSub" + String(systemFunction.id),
                                                             "ShowAccessPermissionsRight" + String(systemFunction.id), "ShowAccessPermissionsBlock" + String(systemFunction.id)))}>
                                                        <img src={ArrowSub}
                                                             id={"ShowAccessPermissionsSub" + String(systemFunction.id)}
                                                             style={{display: 'none'}}/>
                                                        <img src={ArrowRight}
                                                             id={"ShowAccessPermissionsRight" + String(systemFunction.id)}/>
                                                    </div>
                                                    <div
                                                        className="project__edit--role__board--access__permissions--name__function">
                                                        {systemFunction.nameFunction}
                                                    </div>
                                                    <div
                                                        className="project__edit--role__board--access__permissions--input__margin">
                                                        <div
                                                            className={`project__edit--role__board--access__permissions--input ${systemFunction.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                            <input type="checkbox"
                                                                   checked={systemFunction.accessPermissions.some(accessPermission =>
                                                                       !userRole?.roles[idRoleToEdit].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   )}
                                                                   onChange={() => (systemFunction.systemRole != true ? changeSystemFunctions(systemFunction.id, systemFunction.accessPermissions.some(accessPermission =>
                                                                       userRole?.roles[idRoleToEdit].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   )) : showToast("Эта функция выдана в рамках системной роли!"))}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id={"ShowAccessPermissionsBlock" + String(systemFunction.id)}
                                                    className="project__edit--role__board--access__permissions--block__item"
                                                    style={{display: 'none'}}>
                                                    {systemFunction.accessPermissions.map((accessPermission) => (
                                                        EPermissionCode[accessPermission.code as EEditPermissionCode] &&
                                                        EEditPermissionCode[accessPermission.code as EEditPermissionCode] !==
                                                        EEditPermissionCode.full ?
                                                            <div
                                                                className="project__edit--role__board--access__permissions--flex">
                                                                <div
                                                                    className="project__edit--role__board--access__permissions--item">
                                                                    {EPermissionCode[accessPermission.code as EEditPermissionCode]}
                                                                </div>
                                                                <div
                                                                    className="project__edit--role__board--access__permissions--input__margin">
                                                                    <div
                                                                        className={`project__edit--role__board--access__permissions--input ${accessPermission.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                                        <input type="checkbox"
                                                                               checked={!userRole?.roles[idRoleToEdit].excludedPermissions.map(xx =>
                                                                                   xx.accessPermission).includes(accessPermission.id)}
                                                                               onChange={() => (accessPermission.systemRole != true ? chengeAccessPermissions(accessPermission.id, accessPermission.code) : showToast("Эта функция выдана в рамках системной роли!"))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipantRights;