import ProjectsMain from "../../component/ProjectsMain/ProjectsMain";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import {Context} from "../../index";
import io from 'socket.io-client';
import button from "../../component/UiLib/Button/Button";

const Projects: FC = () => {
       const {store} = useContext(Context);

       return (
           <>
               <ProjectsMain></ProjectsMain>
           </>
       )
}

export default observer(Projects);
