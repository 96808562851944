import { FC, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { observer } from "mobx-react-lite";
import "./Filter.css";
import { ListBox } from "primereact/listbox";
import { Calendar } from "primereact/calendar";
import Arrow from "../../../assets/arrowFilter.svg";
import Checkbox from "../Checkbox/Checkbox";
import { FilterOptions, SelectedOptions } from "../../../models/IFilterModel";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import { fullNameFormat } from "../../../helpers/Inicials";

//TODO раскидать интерфейсы

interface FilterOpenableRowProps {
    label: string;
    selected: boolean;
    onClick: () => void;
}

const FilterOpenableRow: FC<FilterOpenableRowProps> = ({
    label,
    selected,
    onClick,
}) => {
    return (
        <li className="filter_item " onClick={onClick}>
            <div className="filter_item_left">
                <img src={Arrow} />
                <div className="filter_label">{label}</div>
            </div>
            <div className="filter_check p_gray_light ">
                {selected ? "Выбрано" : "Не выбрано"}
            </div>
        </li>
    );
};

interface LabelIdFilterProps {
    label: string;
    open: boolean;
    options: { id: number | string; name: string }[];
    onOptionSelect: (id: number | string) => void;
    selectedOptions: (number | string)[] | undefined;
    setOpenFilter: (filter: string | undefined) => void;
}

const LabelIdFilter: FC<LabelIdFilterProps> = ({
    label,
    open,
    options,
    onOptionSelect,
    selectedOptions,
    setOpenFilter,
}) => {
    return (
        <div>
            <ul className="p_black p_12" style={{ position: "relative" }}>
                <FilterOpenableRow
                    label={label}
                    selected={
                        selectedOptions !== undefined &&
                        selectedOptions.length > 0
                    }
                    onClick={() => setOpenFilter(open ? undefined : label)}
                />
                {open ? (
                    <ul className="filter_items">
                        {options.map((option, index) => (
                            <li className="filter_item_inner">
                                <Checkbox
                                    id={index}
                                    handleSelection={() =>
                                        onOptionSelect(option.id)
                                    }
                                    checked={selectedOptions?.some(
                                        (oId) => oId === option.id
                                    )}
                                />
                                <div className="filter_label">
                                    {option.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : null}
            </ul>
        </div>
    );
};

export interface IFilterLabels {
    datesLabel?: string;
    responsibleLabel?: string;
    statusLabel?: string;
    taskTypeLabel?: string;
    priorityLabel?: string;
    roleLabel?: string;
    fileTypeLabel?: string;
    entityTypeLabel?: string;
    actionTypeLabel?: string;
    projectLabel?: string;
}

interface IFilterProps {
    options: FilterOptions | undefined;
    selectedOptions: SelectedOptions;
    setSelectedOptions: (options: SelectedOptions) => void;
    onApply?: () => Promise<void>;
    showApplyButton?: boolean;
    labels?: IFilterLabels;
}

const Filter: FC<IFilterProps> = ({
    options,
    onApply,
    selectedOptions,
    setSelectedOptions,
    showApplyButton,
    labels,
}) => {
    const [openFilter, setOpenFilter] = useState<string | undefined>();

    const toggleFilter = (key: string, id: number | string) => {
        const copy = JSON.parse(JSON.stringify(selectedOptions));

        if (!copy[key]) copy[key] = [];

        const exists = copy[key].includes(id);
        copy[key] = exists
            ? copy[key].filter((item: typeof id) => item !== id)
            : [...copy[key], id];

        setSelectedOptions(copy);
    };

    const selectDate = (dates: (Date | null)[]) => {
        const copy = JSON.parse(JSON.stringify(selectedOptions));
        if (dates[0] && dates[1]) {
            copy.dates = [dates[0], dates[1]];
        } else if (dates[0]) {
            copy.dates = [dates[0]];
        } else {
            copy.dates = undefined;
        }
        setSelectedOptions(copy);
    };

    const selectUsers = (userIds: number[] | undefined) => {
        const copy = JSON.parse(JSON.stringify(selectedOptions));
        copy.responsibleIds = userIds;
        setSelectedOptions(copy);
    };

    const getUserFilter = (
        label: string,
        open: boolean,
        options: { id: number; name: string }[],
        onOptionSelect: (ids: number[] | undefined) => void,
        selectedOptions: number[]
    ) => {
        return (
            <div>
                <ul className="p_black p_12" style={{ position: "relative" }}>
                    <FilterOpenableRow
                        label={label}
                        selected={selectedOptions.length > 0}
                        onClick={() => setOpenFilter(open ? undefined : label)}
                    />
                    {open ? (
                        <div className="filter_items filter_users">
                            <ListBox
                                filterPlaceholder="Поиск..."
                                listStyle={{ maxHeight: "100px" }}
                                multiple
                                filter
                                value={options.filter((o) =>
                                    selectedOptions.some((so) => so === o.id)
                                )}
                                onChange={(e) =>
                                    onOptionSelect(
                                        e.value.map((v: any) => v.id)
                                    )
                                }
                                options={options}
                                optionLabel="name"
                                className="w-full md:w-14rem"
                            />
                        </div>
                    ) : null}
                </ul>
            </div>
        );
    };

    const getDateFilter = (
        label: string,
        open: boolean,
        onOptionSelect: (dates: (Date | null)[]) => void,
        selectedDates: Date[]
    ) => {
        return (
            <div>
                <ul className="p_black p_12" style={{ position: "relative" }}>
                    <FilterOpenableRow
                        label={label}
                        selected={selectedDates.length > 0}
                        onClick={() => setOpenFilter(open ? undefined : label)}
                    />
                    {open ? (
                        <div className="filter_items">
                            <Calendar
                                value={selectedDates}
                                onChange={(e) =>
                                    onOptionSelect(e.value ? e.value : [])
                                }
                                selectionMode="range"
                                readOnlyInput
                                hideOnRangeSelection
                                dateFormat="dd.mm.yy"
                            />
                        </div>
                    ) : null}
                </ul>
            </div>
        );
    };

    return (
        <div className="filter_body">
            {options ? (
                <>
                    {options.date
                        ? getDateFilter(
                              labels?.datesLabel ?? "Дата",
                              openFilter === (labels?.datesLabel ?? "Дата"),
                              selectDate,
                              selectedOptions.dates ?? []
                          )
                        : null}
                    {options.responsible
                        ? getUserFilter(
                              labels?.responsibleLabel ?? "Исполнитель",
                              openFilter ===
                                  (labels?.responsibleLabel ?? "Исполнитель"),
                              options.responsible.map((r) => {
                                  return {
                                      id: r.id,
                                      name: fullNameFormat(r, "s N M"),
                                  };
                              }),
                              (ids: number[] | undefined) => {
                                  selectUsers(ids);
                              },
                              selectedOptions.responsibleIds ?? []
                          )
                        : null}
                    {options.priority ? (
                        <LabelIdFilter
                            label={labels?.priorityLabel ?? "Приоритет"}
                            open={
                                openFilter ===
                                (labels?.priorityLabel ?? "Приоритет")
                            }
                            options={options.priority}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("priorityIds", id);
                            }}
                            selectedOptions={selectedOptions.priorityIds ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.typeTask ? (
                        <LabelIdFilter
                            label={labels?.taskTypeLabel ?? "Тип задачи"}
                            open={
                                openFilter === (labels?.taskTypeLabel ?? "Тип задачи")
                            }
                            options={options.typeTask}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("typeTaskIds", id);
                            }}
                            selectedOptions={selectedOptions.typeTaskIds}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.status ? (
                        <LabelIdFilter
                            label={labels?.statusLabel ?? "Статус"}
                            open={
                                openFilter === (labels?.statusLabel ?? "Статус")
                            }
                            options={options.status}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("statusIds", id);
                            }}
                            selectedOptions={selectedOptions.statusIds ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.role ? (
                        <LabelIdFilter
                            label={labels?.roleLabel ?? "Роль"}
                            open={openFilter === (labels?.roleLabel ?? "Роль")}
                            options={options.role}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("roleIds", id);
                            }}
                            selectedOptions={selectedOptions.roleIds ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.entityType ? (
                        <LabelIdFilter
                            label={labels?.entityTypeLabel ?? "Тип сущности"}
                            open={
                                openFilter ===
                                (labels?.entityTypeLabel ?? "Тип сущности")
                            }
                            options={options.entityType.map((type) => {
                                return { name: type.name, id: type.code };
                            })}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("entityTypes", id);
                            }}
                            selectedOptions={selectedOptions.entityTypes ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.actionType ? (
                        <LabelIdFilter
                            label={labels?.actionTypeLabel ?? "Тип действия"}
                            open={
                                openFilter ===
                                (labels?.actionTypeLabel ?? "Тип действия")
                            }
                            options={options.actionType?.map((action) => {
                                return { id: action.code, name: action.name };
                            })}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("actionTypes", id);
                            }}
                            selectedOptions={selectedOptions.actionTypes ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                    {options.project ? (
                        <LabelIdFilter
                            label={labels?.projectLabel ?? "Проект"}
                            open={
                                openFilter ===
                                (labels?.projectLabel ?? "Проект")
                            }
                            options={options.project}
                            onOptionSelect={(id: number | string) => {
                                toggleFilter("projectIds", id);
                            }}
                            selectedOptions={selectedOptions.projectIds ?? []}
                            setOpenFilter={setOpenFilter}
                        />
                    ) : null}
                </>
            ) : null}
            {showApplyButton ? (
                <div className="filterBtnApply">
                    <Button label="Применить" onClick={onApply} />
                </div>
            ) : null}
        </div>
    );
};

export default observer(Filter);
