import React, {FC, ReactNode, useEffect, useState} from 'react';
import "./AnimatedSearch.css"
import {CSSTransition} from "react-transition-group";
import Search from "../../../assets/search_back.svg"
import Button from "../Button/Button";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";

export interface IAnimatedSerach {
    widthInput: number;
    value: string;
    placeholder?: string;
    children?: ReactNode;
    onSubmitFunc?: (value: string) => void;
    changeSearchValue?: (value: string) => void;
    hideSubmitButton?: boolean;
    showPreviewList?: boolean;
    swapFuncButton?: boolean;
    stashDate?: boolean;
    callBackStash?: (stash: boolean) => void;
}


const AnimatedSearch: FC<IAnimatedSerach> = ({
                                                 value,
                                                 placeholder,
                                                 children,
                                                 onSubmitFunc,
                                                 changeSearchValue,
                                                 hideSubmitButton,
                                                 showPreviewList,
                                                 widthInput,
                                                 swapFuncButton,
                                                 stashDate,
                                                 callBackStash
                                             }) => {
    const [isMoved, setIsMoved] = useState(false);
    const [buttonSwap, setButtonSwap] = useState(false);
    const outsideAlerterRef: any = useOutsideAlerter(() => {
        setIsMoved(false);
        changeSearchValue && changeSearchValue("");
        setButtonSwap(false);
        callBackStash && callBackStash(true);
    });

    useEffect(()=> {
        if (stashDate) {
            setIsMoved(false);
            changeSearchValue && changeSearchValue("");
            setButtonSwap(false);
            callBackStash && callBackStash(true);
        }
    },[stashDate]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            funcButtonSwap();
        }
    }

    const startAnimation = () => {
        if (swapFuncButton) {
            setButtonSwap(true);
        }
        setIsMoved(prevState => !prevState);
    };

    const funcButtonSwap = () => {
        setIsMoved(false);
        setButtonSwap(false);
        onSubmitFunc && onSubmitFunc(value);
        changeSearchValue && changeSearchValue("");
    }

    // Класс анимации
    const posClassName = `ui__component--animated__search--body ${isMoved ? 'moved' : ''}`;
    const childPosClassName = `ui__component--animated__search--pos__child`;
    const backgroundClassName = `ui__component--animated__search--pos__input`

    return (
        <div ref={outsideAlerterRef} className="ui__component--animated__search">
            <div className="ui__component--animated__search--abs__pos">
                <div className="ui__component--animated__search--pos">
                    <div
                        className={isMoved ? backgroundClassName : "ui__component--animated__search--pos__input--non__color"}
                        style={showPreviewList ? {borderRadius: "6px 6px 0px 0px"} : {}}
                    >
                        <input placeholder={placeholder}
                               style={isMoved ? {width: `${widthInput}px`} : {width: "0px", padding: "0px"}}
                               className={posClassName}
                               value={value}
                               onChange={
                                   changeSearchValue && ((e: any) => changeSearchValue(e.target.value))
                               }
                               onKeyDown={handleKeyDown}
                        >
                        </input>
                        <Button callBack={() => buttonSwap ? funcButtonSwap() : startAnimation()} image={Search} classBlock="ui__component--animated__search--button"/>
                    </div>
                    {showPreviewList && isMoved ? (
                        <div className="ui__component--animated__search--child">
                            <div
                                className={childPosClassName}
                                style={isMoved ? {width: `${widthInput+36}px`, borderRadius: "0px 0px 6px 6px"} : {width: "0px"}}
                            >
                                {children}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default AnimatedSearch;