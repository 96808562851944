import {
    createContext,
    Dispatch,
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import "../../component/styles/dashboard.pc.css";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import BoardColumns from "../../component/Board/BoardColumns/BoardColumns";
import { IColumnDisplay } from "../../models/IColumnDisplay";
import BoardToolbar from "../../component/Board/BoardToolbar/BoardToolbar";
import ConfirmationWindow from "../../component/Shared/ConfirmationWindow/ConfirmationWindow";
import BoardMenu from "../../component/Board/BoardMenu/BoardMenu";
import { IFilterData, ITasksDisplayFilters } from "../../models/TaskModels";
import { IColumnEdit } from "../../models/IColumnEdit";
import { IMakeColumnProxy } from "../../models/IMakeColumnProxy";
import { IMakeColumnCommon } from "../../models/IMakeColumnCommon";
import { AppContext } from "../../App";
import { IMoveColumn } from "../../models/IMoveColumn";
import {
    FilterOptions,
    IIParametrsFilter,
    SelectedOptions,
} from "../../models/IFilterModel";
import { IAddColumn } from "../../models/IAddColumn";
import { IBoardTaskDisplay } from "../../models/IBoardTaskDisplay";
import { initials } from "../../helpers/Inicials";
import TaskAddEditForm from "../../component/Task/TaskAddEditForm/TaskAddEditForm";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import { ICheckRole, IHasPermission } from "../../models/IChekRole";
import { ITeamMemberDisplay } from "../../models/TeamModels";
import {
    IMoveTasksData,
    IMoveTasksOutsideData,
} from "../../models/IMoveTaskData";
import axios, { AxiosError } from "axios";
import { SEO } from "../../component/Helmet/HelmetSEO";

export interface ISelectedFastFilters {
    priority: IFilterData | undefined;
    type: IFilterData | undefined;
    tag: IFilterData | undefined;
}

interface ISelectedBoardFilters {
    priority: IFilterData[];
    type: IFilterData[];
}

export interface IBoardForProxy {
    id: number;
    name: string;
}

interface BoardContextType {
    boardIdNumber: number;
    handleDeleteTask: (taskId: number) => void;
    handleDeleteTasks: (taskIds: number[]) => void;
    handleSelectTask: (taskId: number) => void;
    handleSelectTaskToColumn: (taskToColumnId: number) => void;
    handleMoveTask: (
        taskId: number,
        newColumnId: number,
        position: number
    ) => void;
    handleMoveTaskBoard: (taskId: number, boardId: number) => void;
    handleMoveTasks: (columnId: number) => void;
    handleMoveColumn: (columnId: number, position: number) => void;
    handleMoveTasksOutsideBoard: (columnId: number) => void;
    columns: IColumnDisplay[];
    filteredColumns: IColumnDisplay[];
    taskNameSearch: string;
    setTaskNameSearch: Dispatch<SetStateAction<string>>;
    taskFilters: ITasksDisplayFilters;
    selectedFastFilters: ISelectedFastFilters;
    changeFastFilters: (filters: ISelectedFastFilters) => void;
    actionMode: boolean;
    cancelActionMode: () => void;
    selectedTasks: number[];
    selectedTasksToColumn: number[];
    relatedUsers: ITeamMemberDisplay[];
    openMenu: () => void;
    closeMenu: () => void;
    boardName: string;
    handleDeleteColumn: (columnId: number) => void;
    handleEditColumn: (
        columndId: number,
        newName?: string,
        newColor?: string,
        newWeight?: number
    ) => void;
    openAddTaskForm: (columnId: number) => void;
    handleRenameBoard: (newName: string) => void;
    handleDeleteBoard: () => void;
    handleAddColumn: (
        name: string,
        color: string,
        weight: number,
        columnStatus: number | undefined,
        columnPriority: number | undefined,
        proxyBoardName?: string,
        proxiBoardId?: number
    ) => void;
    // Доски, отличающиеся от текущей
    boardsForProxy: IBoardForProxy[];
    handleMakeColumnCommon: (columnId: number) => void;
    handleMakeColumnProxy: (
        columnId: number,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => void;
    colorOptions: string[];
    boardFilters: FilterOptions | undefined;
    setBoardFilters: Dispatch<SetStateAction<FilterOptions | undefined>>;
    selectedBoardFilters: SelectedOptions;
    setSelectedBoardFilters: Dispatch<SetStateAction<SelectedOptions>>;
    filterCounter: number;
    setFilterCounter: Dispatch<SetStateAction<number>>;
    handleTaskAction: () => void;
    resetFilter: () => void;
    projectId: number | undefined;
    isProxyBoard: boolean;
    abbreviationProject: string | undefined;
}

export const BoardContext = createContext<BoardContextType>({
    boardIdNumber: -1,
    handleDeleteTask: () => {},
    handleDeleteTasks: () => {},
    handleSelectTask: () => {},
    handleSelectTaskToColumn: () => {},
    handleMoveTask: () => {},
    handleMoveTaskBoard() {},
    handleMoveTasks: () => {},
    handleMoveColumn: () => {},
    handleMoveTasksOutsideBoard: () => {},
    columns: [],
    filteredColumns: [],
    taskNameSearch: "",
    setTaskNameSearch: () => {},
    taskFilters: { priority: [], type: [], status: [], tag: [] },
    selectedFastFilters: {
        priority: undefined,
        type: undefined,
        tag: undefined,
    },
    changeFastFilters: () => {},
    actionMode: false,
    cancelActionMode: () => {},
    selectedTasks: [],
    selectedTasksToColumn: [],
    relatedUsers: [],
    openMenu: () => {},
    closeMenu: () => {},
    boardName: "",
    handleDeleteColumn: () => {},
    handleEditColumn: () => {},
    openAddTaskForm: () => {},
    handleRenameBoard: () => {},
    handleDeleteBoard: () => {},
    handleAddColumn: () => {},
    boardsForProxy: [],
    handleMakeColumnCommon: () => {},
    handleMakeColumnProxy: () => {},
    colorOptions: [],
    boardFilters: {},
    setBoardFilters: () => {},
    selectedBoardFilters: {},
    setSelectedBoardFilters: () => {},
    filterCounter: 0,
    setFilterCounter: () => {},
    handleTaskAction: () => {},
    resetFilter: () => {},
    projectId: -1,
    isProxyBoard: false,
    abbreviationProject: "-",
});

const Board: FC = () => {
    const { store } = useContext(Context);
    const { showToast, pageTitle, chekRole } = useContext(AppContext);
    const { boardId } = useParams();
    const [columns, setColumns] = useState<IColumnDisplay[]>([]);
    const [filteredColumns, setFilteredColumns] = useState<IColumnDisplay[]>(
        []
    );
    const boardIdNumber = Number(boardId);
    const [actionMode, setActionMode] = useState<boolean>(false);
    const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
    const [selectedTasksToColumn, setSelectedTasksToColumn] = useState<
        number[]
    >([]);
    const [taskNameSearch, setTaskNameSearch] = useState<string>("");
    const [taskFilters, setTaskFilters] = useState<ITasksDisplayFilters>({
        priority: [],
        type: [],
        status: [],
        tag: [],
    });
    const [selectedFastFilters, setSelectedFastFilters] =
        useState<ISelectedFastFilters>({
            priority: undefined,
            type: undefined,
            tag: undefined,
        });
    //ФИЛЬТРЫ
    const [boardFilters, setBoardFilters] = useState<FilterOptions>();
    const [selectedBoardFilters, setSelectedBoardFilters] =
        useState<SelectedOptions>(store.getBoardSavedFilters(Number(boardId)));
    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [forceFilterChange, setForceFilterChange] = useState<boolean>(false);
    //END ФИЛЬТРЫ
    const [tasksToDelete, setTasksToDelete] = useState<number[]>([]);
    const [columnToDelete, setColumnToDelete] = useState<IColumnDisplay>();
    const [boardsForProxy, setBoardsForProxy] = useState<IBoardForProxy[]>([]);
    const [showConfirmDeleteTasks, setShowConfirmDeleteTasks] =
        useState<boolean>(false);
    const [showConfirmDeleteBoard, setShowConfirmDeleteBoard] =
        useState<boolean>(false);
    const [showConfirmDeleteColumn, setShowConfirmDeleteColumn] =
        useState<boolean>(false);
    const [relatedUsers, setRelatedUsers] = useState<ITeamMemberDisplay[]>([]);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [boardName, setBoardName] = useState<string>("Название доски");
    const [showTaskPopup, setShowTaskPopup] = useState(false);
    const [addTaskColumnId, setAddTaskColumnId] = useState<
        number | undefined
    >();
    const [rerenderBoard, setRerenderBoard] = useState<boolean>(false);
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const [projectId, setProjectId] = useState<number | undefined>(
        store.currentProjectId
    );
    const [isProxyBoard, setIsProxyBoard] = useState<boolean>(false);
    const [abbreviationProject, setAbbreviationProject] = useState<string>();

    const colorOptions: string[] = [
        "rgba(20,156,206,0.2)",
        "#C7DAF0",
        "#C2CBF8",
        "#E4D4F8",
        "#FACEF3",
        "#FADBE1",
        "#FCF8D6",
        "#D7F6D4",
        "#EAEAEA",
        "#AFAFAF",
    ];

    const navigate = useNavigate();

    const { getData } = useContext(AppContext);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                let projectId = await store.getProjectIdDashBoards(
                    Number(boardId)
                );

                if (projectId != 0 && projectId != undefined) {
                    setProjectId(projectId);

                    // Нужно отказаться от этого парамаетра в сторе!
                    store.setCurrentProjectId(projectId);

                    const functionClass = new FunctionSystem();
                    const newCheckRole: ICheckRole = {
                        projectId: projectId,
                        boardId: Number(boardId),
                        functionCodes: functionClass.getProjectBoardFunction,
                    };

                    const checkThisRole = await chekRole(
                        projectId,
                        Number(boardId),
                        functionClass.getProjectBoardFunction
                    );
                    setUserAccess(checkThisRole);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [store.currentProjectId, boardId, rerenderBoard]);

    useEffect(() => {
        (async () => {
            try {
                setShowMenu(false);

                if (isNaN(store.currentProjectId) && !projectId) {

                    return;
                }

                let data = await store.getAllColumnsBoard(Number(boardId));

                if (!data) {
                    navigate(`/projects`);
                }
                
                await getFilterData();

                setColumns(store.columns);

                setBoardName(store.currentBoardData.label);
                setProjectId(store.currentProjectId);
                // setIsProxyBoard(store.dashBoards.find((b) => b.id === Number(boardId))!.isProxyBoard)
                let res = await store.getProjectGeneralInfo(
                    store.currentProjectId
                );
                if (res && typeof res !== "number") {
                    setAbbreviationProject(res.abbreviation);
                }

                const boardData = await store.restrictAccess(
                    store.currentProjectId
                );

                if (boardData == undefined) {

                    return;
                }

                const personsRolesIds: number[] | undefined = boardData?.person
                    .filter((xx) => xx.email == store.user.email)
                    ?.map((yy) => yy.personRoleId);

                if (personsRolesIds == undefined) {

                    return;
                }

                const checkBoard = boardData.boards.filter((xx) => {
                    return xx.personRoleIds?.some((personRoleId) =>
                        personsRolesIds.includes(personRoleId)
                    );
                });

                if (!checkBoard.map((xx) => xx.id).includes(Number(boardId))) {
                    await store.getAllColumnsBoard(Number(boardId));
                    setColumns(store.columns);
                    setBoardName(store.currentBoardData.label);
                    setProjectId(store.currentProjectId);

                    await store
                        .getProjectTeamDisplayData({
                            filters: {
                                projectId: 1,
                                name: undefined,
                                role: undefined,
                            },
                        })
                        .then((res) => {
                            if (!res?.team || !(res?.team?.length > 0)) {
                                return;
                            }
                            setRelatedUsers(res.team);
                        });

                        try {
                            const response = await store.taskService.getProjectTasksFilters(store.currentProjectId);
                            const taskFilters = response.data;
                            if (
                                    taskFilters &&
                                    taskFilters.type &&
                                    taskFilters.priority &&
                                    taskFilters.status &&
                                    taskFilters.tag
                                ) {
                                    setTaskFilters(taskFilters);
                                }
                        } catch (error) {
                            if (axios.isAxiosError(error)) {
                                showToast(error.response?.data?.message);
                            } 
                        }
                        

                    let data = await store.getProjectDashBoards(
                        store.currentProjectId
                    );

                    setBoardsForProxy(
                        store.dashBoards.filter((b) => b.id !== Number(boardId))
                    );
                } else {
                    showToast("У вас нет доступа к этой доске!");
                    navigate(`/project/${store.currentProjectId}`);
                }
            } catch (error) {

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProjectId, boardId, rerenderBoard]);

    useEffect(() => {
        const curentDashBoard = store.dashBoards.find(
            (b) => b.id === Number(boardId)
        );
        if (curentDashBoard) setIsProxyBoard(curentDashBoard.isProxyBoard);
    }, [boardId, store.dashBoards]);

    useEffect(() => {
        setBoardName(store.currentBoardData.label);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentBoardData]);

    const getFilterData = async () => {
        const filterParams: IIParametrsFilter = {
            projectId: store.currentProjectId,
            responsible: true,
            priority: true,
            typeTask: true,
            status: false,
            role: false,
            fileType: false,
        };
        let data = await store.getDataForFilter(filterParams);
        if (data) {
            data.date = true;
            setBoardFilters(data);
        }
    };

    const resetFilter = () => {
        setTaskNameSearch("");
        setSelectedBoardFilters({});
        setSelectedFastFilters({
            priority: undefined,
            type: undefined,
            tag: undefined,
        });
    };

    const filterColumns = () => {
        const filterTasks = (
            tasks: IBoardTaskDisplay[]
        ): IBoardTaskDisplay[] => {
            let filteredTasks = tasks;
            if (taskNameSearch !== "" && taskNameSearch !== undefined) {
                const lowerCaseSearchText = taskNameSearch.toLowerCase();
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        t.name.toLowerCase().includes(lowerCaseSearchText) ||
                        t.code.toLowerCase().includes(lowerCaseSearchText)
                );
            }
            if (selectedFastFilters.priority !== undefined) {
                filteredTasks = filteredTasks.filter(
                    (t) => t.priority.id === selectedFastFilters.priority!.id
                );
            }
            if (selectedFastFilters.type !== undefined) {
                filteredTasks = filteredTasks.filter(
                    (t) => t.type.id === selectedFastFilters.type!.id
                );
            }
            if (selectedFastFilters.tag !== undefined) {
                filteredTasks = filteredTasks.filter((t) =>
                    t.tags.some((tag) => tag.id === selectedFastFilters.tag!.id)
                );
            }
            if (
                selectedBoardFilters.priorityIds !== undefined &&
                selectedBoardFilters.priorityIds.length > 0
            ) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (selectedBoardFilters.priorityIds as number[]).find(
                            (priorityId) => priorityId == t.priority.id
                        ) !== undefined
                );
            }
            if (
                selectedBoardFilters.typeTaskIds &&
                selectedBoardFilters.typeTaskIds.length > 0
            ) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (selectedBoardFilters.typeTaskIds as number[]).find(
                            (typeId) => typeId == t.type.id
                        ) !== undefined
                );
            }
            if (
                selectedBoardFilters.responsibleIds &&
                selectedBoardFilters.responsibleIds.length > 0
            ) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (selectedBoardFilters.responsibleIds as number[]).find(
                            (responsibleId) => responsibleId === t.responsibleId
                        ) !== undefined
                );
            }
            if (selectedBoardFilters.dates && selectedBoardFilters.dates[0]) {
                filteredTasks = filteredTasks.filter((t) => {
                    const startDate = new Date(t.startDate);
                    return (
                        startDate.getTime() >=
                        selectedBoardFilters.dates![0].getTime()
                    );
                });
            }
            if (selectedBoardFilters.dates && selectedBoardFilters.dates[1]) {
                filteredTasks = filteredTasks.filter((t) => {
                    const startDate = new Date(t.startDate);
                    return (
                        startDate.getTime() <=
                        selectedBoardFilters.dates![1]!.getTime()
                    );
                });
            }
            return filteredTasks;
        };

        // Нет возможности использовать structuredClone
        const columnsCopy: IColumnDisplay[] = JSON.parse(
            JSON.stringify(columns)
        );

        setFilteredColumns(
            columnsCopy.map((c) => {
                c.tasks = filterTasks(c.tasks);
                return c;
            })
        );
    };

    useEffect(() => {
        if (!forceFilterChange) {
            if (
                selectedBoardFilters.priorityIds &&
                selectedBoardFilters.priorityIds.length > 0 &&
                selectedBoardFilters.typeTaskIds &&
                selectedBoardFilters.typeTaskIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: undefined,
                    tag: undefined,
                });
            } else if (
                selectedBoardFilters.priorityIds &&
                selectedBoardFilters.priorityIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: selectedFastFilters.type,
                    tag: undefined,
                });
            } else if (
                selectedBoardFilters.typeTaskIds &&
                selectedBoardFilters.typeTaskIds.length > 0
            ) {
                setSelectedFastFilters({
                    priority: selectedFastFilters.priority,
                    type: undefined,
                    tag: undefined,
                });
            }
        }
        // Сохранение фильтров в localStorage
        store.setBoardsSavedFilters(Number(boardId), selectedBoardFilters);
        setForceFilterChange(false);
        filterColumns();
    }, [selectedFastFilters, selectedBoardFilters, taskNameSearch, columns]);

    const changeFastFilters = (fastFilters: ISelectedFastFilters) => {
        setSelectedFastFilters(fastFilters);

        let checkedFiltersCopy = structuredClone(selectedBoardFilters);
        checkedFiltersCopy.priorityIds = fastFilters.priority
            ? [fastFilters.priority.id]
            : undefined;
        checkedFiltersCopy.typeTaskIds = fastFilters.type
            ? [fastFilters.type.id]
            : undefined;

        setSelectedBoardFilters(checkedFiltersCopy);
        setForceFilterChange(true);
    };

    const handleMoveTask = async (
        taskId: number,
        newColumnId: number,
        position: number
    ) => {
        const oldColumn = columns.find(
            (c) => c.tasks.find((t) => t.id === taskId) !== undefined
        );
        const newColumn = columns.find((c) => c.id === newColumnId);
    
        if (!oldColumn || !newColumn || position < 0) return;
    
        const task = oldColumn.tasks.find((t) => t.id === taskId);
    
        if (!task) return;
        try {
            await store.taskService.moveTask({
                taskId: task.id,
                taskToColumnId: task.taskToColumnId,
                columnId: oldColumn === newColumn ? oldColumn.id : newColumn.id,
                boardId: Number(boardId),
                position: position,
            });
        
            const updatedColumns = columns.map((column) => {
                if (column.id === oldColumn.id && column.id === newColumn.id) {
                    // Перемещение внутри одной колонки
                    const tasks = [...column.tasks];
                    const taskIndex = tasks.findIndex((t) => t.id === taskId);
                    tasks.splice(taskIndex, 1);
                    tasks.splice(position, 0, task);
                    return { ...column, tasks };
                } else if (column.id === oldColumn.id) {
                    // Удаляем задачу из старой колонки
                    return { ...column, tasks: column.tasks.filter((t) => t.id !== taskId) };
                } else if (column.id === newColumn.id) {
                    // Добавляем задачу в новую колонку
                    const tasks = [...column.tasks];
                    tasks.splice(position, 0, task);
                    return { ...column, tasks };
                }
                return column;
            });
            setColumns(updatedColumns);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } else {
                showToast("Произошла ошибка при перемещении задачи");
            }
        }
        
    };
    
    
    

    const handleMoveTaskBoard = async (taskId: number, toBoardId: number) => {
        try {
            const oldColumn = columns.find(
                (c) => c.tasks.find((task) => task.id === taskId) !== undefined
            );
            if (!oldColumn) return;
    
            const task = oldColumn.tasks.find((t) => t.id === taskId);
            if (!task) return;
            await store.taskService.moveTaskBoard({
                taskId: taskId,
                taskToColumnId: task.taskToColumnId,
                boardId: Number(toBoardId),
            });
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } 
        }
    };
    

    const handleMoveTasks = async (columnId: number) => {
        try {
            const moveTasksData: IMoveTasksData = {
                columnId: columnId,
                tasksToColumnId: selectedTasksToColumn,
                boardId: Number(boardId),
                tasksId: selectedTasks,
            };
            await store.taskService.moveTasks(moveTasksData);
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
            setSelectedTasks([]);
            setSelectedTasksToColumn([]);
            setActionMode(false);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } 
        }
    };
    

    const handleMoveTasksOutsideBoard = async (boardOutsideId: number) => {
        try {
            const moveTasksOutsideData: IMoveTasksOutsideData = {
                tasksId: selectedTasks,
                tasksToColumnId: selectedTasksToColumn,
                boardInsideId: Number(boardId),
                boardOutsideId: boardOutsideId,
            };
            await store.taskService.moveTasksOutsideBoard(moveTasksOutsideData);
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
            setSelectedTasks([]);
            setSelectedTasksToColumn([]);
            setActionMode(false);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } 
        }
    };
    

    const cancelActionMode = () => {
        setActionMode(!actionMode);
        setSelectedTasks([]);
    };

    const handleSelectTask = (taskId: number) => {
        if (selectedTasks.some((xx) => xx === taskId)) {
            setSelectedTasks(selectedTasks.filter((xx) => xx !== taskId));
        } else {
            let tasks = [...selectedTasks];
            tasks.push(taskId);
            setSelectedTasks(tasks);
        }
    };
    const handleSelectTaskToColumn = (taskToColumnId: number) => {
        if (selectedTasksToColumn.some((xx) => xx === taskToColumnId)) {
            setSelectedTasksToColumn(
                selectedTasksToColumn.filter((xx) => xx !== taskToColumnId)
            );
        } else {
            let tasksToColumn = [...selectedTasksToColumn];
            tasksToColumn.push(taskToColumnId);
            setSelectedTasksToColumn(tasksToColumn);
        }
    };

    const handleDeleteTask = async (taskId: number) => {
        try {
            await store.taskService.deleteTasks({ tasks: [taskId] });
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
            showToast("Задача удалена");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } 
        }
    };
    

    const handleDeleteTasks = async (taskIds: number[]) => {
        setTasksToDelete(taskIds);
        setShowConfirmDeleteTasks(true);
    };

    const deleteTasks = async () => {
        try {
            await store.taskService.deleteTasks({ tasks: tasksToDelete });
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
            setShowConfirmDeleteTasks(false);
            setSelectedTasks([]);
            showToast("Задачи удалены");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message);
            } 
        }
    };
    

    const openMenu = () => {
        setShowMenu(true);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const handleMoveColumn = async (columnId: number, position: number) => {
        const moveColumnData: IMoveColumn = {
            boardId: Number(boardId),
            columnId: columnId,
            position: position,
        };
        await store.moveColumn(moveColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleDeleteColumn = async (columnId: number) => {
        const column = columns.find((c) => c.id === columnId);
        if (!column) return;
        setShowMenu(false);
        setColumnToDelete(column);
        setShowConfirmDeleteColumn(true);
    };

    const deleteColumn = async () => {
        if (!columnToDelete) return;
        await store.deleteColumn({
            columnId: columnToDelete.id,
            boardId: Number(boardId),
        });
        await store.getAllColumnsBoard(Number(boardId));
        setShowConfirmDeleteColumn(false);
        setColumns(store.columns);
        showToast("Колонка удалена");
    };

    const handleEditColumn = async (
        columnId: number,
        newName?: string,
        newColor?: string,
        newWeight?: number
    ) => {
        const editColumnData: IColumnEdit = {
            columnId: columnId,
            boardId: Number(boardId),
            name: newName,
            color: newColor,
            weight: newWeight,
        };

        await store.editColumn(editColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        showToast("Изменения сохранены");
    };

    const openAddTaskForm = (columnId: number) => {
        setAddTaskColumnId(columnId);
        setShowTaskPopup(true);
    };

    const handleRenameBoard = async (newName: string) => {
        await store.editBoard({ id: Number(boardId), name: newName });
        showToast("Изменения сохранены");
        getData();
    };

    const handleDeleteBoard = () => {
        setShowMenu(false);
        setShowConfirmDeleteBoard(true);
    };

    const deleteBoard = async () => {
        await store.deleteBoard({ boardId: Number(boardId) });
        setShowConfirmDeleteBoard(false);
        await getData();
        navigate(`/project/${store.currentProjectId}`);
        showToast("Канбан доска удалена");
    };

    const handleAddColumn = async (
        name: string,
        color: string,
        weight: number,
        columnStatus: number | undefined,
        columnPriority: number | undefined,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => {
        const addColumnData: IAddColumn = {
            boardId: Number(boardId),
            name: name,
            color: color,
            weight: weight,
            statusId: columnStatus,
            priorityId: columnPriority,
            proxyBoardName: proxyBoardName,
            proxyBoardId: proxyBoardId,
        };
        await store.addColumn(addColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        setRerenderBoard(rerenderBoard ? false : true);
        setShowMenu(false);
        showToast("Колонка создана");
    };

    const handleMakeColumnCommon = async (columnId: number) => {
        const makeColumnCommonData: IMakeColumnCommon = {
            columnId: columnId,
            boardId: Number(boardId),
        };
        await store.makeColumnCommon(makeColumnCommonData);
        await getData();
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleMakeColumnProxy = async (
        columnId: number,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => {
        const makeColumnProxyData: IMakeColumnProxy = {
            columnId: columnId,
            boardId: Number(boardId),
            proxyBoardId: proxyBoardId,
            proxyBoardName: proxyBoardName,
        };
        await store.makeColumnProxy(makeColumnProxyData);
        await getData();
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleTaskAction = async () => {
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        setShowTaskPopup(false);
    };
    return (
        <BoardContext.Provider
            value={{
                boardIdNumber,
                handleDeleteTask,
                handleDeleteTasks,
                handleSelectTask,
                handleSelectTaskToColumn,
                handleMoveTask,
                handleMoveTaskBoard,
                handleMoveTasks,
                handleMoveColumn,
                handleMoveTasksOutsideBoard,
                columns,
                filteredColumns,
                taskNameSearch,
                setTaskNameSearch,
                taskFilters,
                selectedFastFilters,
                changeFastFilters,
                actionMode,
                cancelActionMode,
                selectedTasks,
                selectedTasksToColumn,
                relatedUsers,
                openMenu,
                closeMenu,
                boardName,
                handleDeleteColumn,
                handleEditColumn,
                openAddTaskForm,
                handleRenameBoard,
                handleDeleteBoard,
                handleAddColumn,
                boardsForProxy,
                handleMakeColumnCommon,
                handleMakeColumnProxy,
                colorOptions,
                boardFilters,
                setBoardFilters,
                selectedBoardFilters,
                setSelectedBoardFilters,
                filterCounter,
                setFilterCounter,
                handleTaskAction,
                resetFilter,
                projectId,
                isProxyBoard,
                abbreviationProject,
            }}
        >
            <SEO
                title={
                    boardName
                        ? boardName + " | Доска · " + pageTitle
                        : pageTitle
                }
            />
            {showTaskPopup ? (
                <div className="full-screen-overlay">
                    <div
                        className="popup-form-background"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <TaskAddEditForm
                            mode={"create"}
                            taskId={undefined}
                            closeFunc={() => setShowTaskPopup(false)}
                            columnId={addTaskColumnId!}
                            onTaskAction={handleTaskAction}
                            projectId={projectId}
                        />
                    </div>
                </div>
            ) : null}
            {showConfirmDeleteTasks ? (
                <ConfirmationWindow
                    headerText="Удаление задачи"
                    bodyText={`Вы уверены, что хотите удалить задачи?`}
                    confirmText="Да"
                    cancelText="Нет"
                    onConfirm={deleteTasks}
                    onCancel={() => setShowConfirmDeleteTasks(false)}
                />
            ) : null}
            {showConfirmDeleteBoard ? (
                <ConfirmationWindow
                    headerText="Удаление доски"
                    bodyText={`Вы уверены, что хотите удалить доску?`}
                    confirmText="Да"
                    cancelText="Нет"
                    onConfirm={deleteBoard}
                    onCancel={() => setShowConfirmDeleteBoard(false)}
                />
            ) : null}
            {showConfirmDeleteColumn ? (
                <ConfirmationWindow
                    headerText="Удаление колонки"
                    bodyText={`Вы уверены, что хотите удалить колонку? Все задачи в колонке будут удалены`}
                    confirmText="Да"
                    cancelText="Нет"
                    onConfirm={deleteColumn}
                    onCancel={() => setShowConfirmDeleteColumn(false)}
                />
            ) : null}
            <div className="board__container">
                <div className="toolbar-container">
                    <BoardToolbar
                        onMassActionsFunc={cancelActionMode}
                        selectedCount={selectedTasks.length}
                        userAccess={userAccess}
                    />
                </div>
                <div className="board__container--columns">
                    <BoardColumns userAccess={userAccess} />
                </div>
            </div>
            <div>{showMenu ? <BoardMenu userAccess={userAccess} /> : null}</div>
        </BoardContext.Provider>
    );
};
export default observer(Board);
