import React, {FC, useContext, useEffect, useState} from "react";
import {IBoardTaskDisplay} from "../../../models/IBoardTaskDisplay";
import TaskType from "../../Shared/TaskType";
import TaskPriority from "../../Shared/TaskPriority";
import "./BoardTask.css";
import {ProgressBar} from "primereact/progressbar";
import {BoardContext} from "../../../pages/Board/Board";
import TaskTeg from '../../Shared/TaskTeg';

import BtnMore from "../../buttons/btnMore.component/BtnMore";

import {Context} from "../../..";
import ConfirmationWindow from "../../Shared/ConfirmationWindow/ConfirmationWindow";
import TaskAddEditForm from "../../Task/TaskAddEditForm/TaskAddEditForm";
import {Link, useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import {IHasPermission} from "../../../models/IChekRole";
import Avatar from "../../Shared/Avatar/Avatar";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import {IRestrictAccess} from "../../../models/IRestrictAccess";
import {formatEditMessage} from "../../../helpers/chatFucntion";
import { IColumnDisplay } from "../../../models/IColumnDisplay";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";

import ArrowIcon from "../../../assets/arrowDown.svg"

interface IBoardTaskProps {
    task: IBoardTaskDisplay,
    onTaskDragEnd: () => void,
    isSelectable: boolean,
    isSelected: boolean,
    userAccess: IHasPermission[],
    abbreviationProject: string | undefined,
    columnAccess: boolean,
    boardsData?: IRestrictAccess | undefined,
    currentZoom: any
}

const tagGap: number = 6;

const BoardTask: FC<IBoardTaskProps> = ({
                                            task,
                                            onTaskDragEnd,
                                            isSelectable,
                                            isSelected,
                                            userAccess,
                                            abbreviationProject,
                                            columnAccess,
                                            boardsData,
                                            currentZoom
                                        }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const {handleSelectTask, handleSelectTaskToColumn, boardIdNumber, handleTaskAction, boardsForProxy} = useContext(BoardContext);
    const {store} = useContext(Context);
    const [dataForEdit, setDataForEdit] = useState(['Открыть в новой вкладке', 'Переместить', 'Редактировать', 'Удалить'])
    const [open, setOpen] = useState(false);

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [hideSelf, setHideSelf] = useState<boolean>(false);
    const {handleDeleteTask, handleMoveTask, columns} = useContext(BoardContext);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [columnChoices, setColumnChoices] = useState<IColumnDisplay[]>([]);
    const {archivedPage} = useContext(AppContext);

    const [showAllTags, setShowAllTags] = useState<boolean>(false);

    // Ограничение прав
    const [createTask, setCreateTask] = useState(false);
    const [editTask, setEditTask] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") { //Канбан
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setCreateTask(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setEditTask(true);
        //     setCreateTask(true);
        // }

    }, [userAccess]);

    useEffect(() => {
        const filteredColumns = columns.filter(
            (c) => !c.tasks.some((t) => t.id === task.id)
        );
        setColumnChoices(filteredColumns);
    }, [columns, task.id])



    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        // Без setTimeout не работает, т.к. задача исчезает раньше
        // чем начинается drag и он сразу заканчивается
        setTimeout(() => {
            (e.target as any).classList.add('drag-hidden');
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                taskId: task.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            (e.target as any).classList.remove('drag-hidden');
        }, 100);
        onTaskDragEnd();
    };

    function gerPersonShortName(): string {
        const name = task.responsibleName.split(" ")[0] + " " +
            task.responsibleName.split(" ")[1]?.slice(0, 1) + "." +
            task.responsibleName.split(" ")[2]?.slice(0, 1) + ".";
        const MAX_SIZE = 10;

        const newName = name.length > MAX_SIZE
            ?
            name.slice(0, MAX_SIZE).slice(MAX_SIZE - 1, MAX_SIZE) == " "
                ?
                name.slice(0, MAX_SIZE - 1) + "..."
                :
                name.slice(0, MAX_SIZE) + "..." : name;

        return newName;
    }

    const taskPriorityDisplay = () => {
        const styleItem = JSON.parse(task.priority.color);
        return (
            <TaskPriority name={task.priority.name} color={styleItem.color} icon={styleItem.icon}/>
        );
    };

    const taskTypeDisplay = () => {
        const styleItem = JSON.parse(task.type.color);
        return (
            <TaskType name={task.type.name} color={styleItem.color} icon={styleItem.icon}/>
        );
    };

    const taskTagsDisplay = (rowData: any) => {
        return <TaskTeg name={rowData.name} color={rowData?.color ? rowData.color : ""}/>;
    };

    function onEditClick(e: any) {

        setOpen(false);
        if (e.target.innerText === 'Редактировать') {
            store.setDrag(false);
            setHideSelf(true);
            setShowEditForm(!showEditForm);
        } else if (e.target.innerText === 'Переместить') {
        } else if (e.target.innerText === 'Удалить') {
            setOpenConfirmWindow(true);
        }
    }

    function onFormClick(event: any) {
        event.stopPropagation();
    }

    function onEditFormClose() {
        store.setDrag(true);
        setShowEditForm(false);
        setHideSelf(false);
    }

    function onOverlayClick() {
        // onEditFormClose();
    }

    const onConfirm = () => {
        handleDeleteTask(task.id);
        setOpenConfirmWindow(false);
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    const goToTask = (taskId: number) => {
        navigate(`${archivedPage ? "/archive" : ""}/board/${boardIdNumber}/task/${task.id}`);
    }

    const tagWrapperOverflowState = (containerId: string): {isOverflown: boolean, firstRowCount: number} => {
        let res = { isOverflown: false, firstRowCount: 0 }
        const container = document.getElementById(containerId);
        if(!container) return res;

        const childCount = container?.children?.length ?? 0;
        let childWidthSum = 0;

        for (let i = 0; i < childCount; i++) {
            const child = container.children[i]
            childWidthSum += child.clientWidth + tagGap
        }

        if(childWidthSum > container.clientWidth) {
            res.isOverflown = true
        }

        let firstRowCount = 0;
        let firstRowChildrenWidth = 0;
        for (let i = 0; i < childCount; i++) {
            const child = container.children[i]
            firstRowChildrenWidth += child.clientWidth + tagGap;
            if(firstRowChildrenWidth < container.clientWidth) {
                firstRowCount++;
            } else break;
        }

        res.firstRowCount = firstRowCount;
        return res
    }

    const tagOverflowButton = (tagWrapperId: string) => {
        let wrapperState = tagWrapperOverflowState(tagWrapperId)
        if(wrapperState.isOverflown){
            return (
                <div className={`show-all-tags-button`} onClick={() => {
                    setShowAllTags(!showAllTags)
                }}
                     style={{transform: `${showAllTags ? 'rotate(180deg)' : 'rotate(0deg)'}` ,
                         marginLeft: `${tagGap}px`, padding: `${showAllTags ? '1px 5px 5px 5px' : '5px'}`,
                         height: '30px', cursor: 'pointer'}}>
                    {showAllTags ? <img style={{}}
                                        src={ArrowIcon}/> : `+${task.tags.length - wrapperState.firstRowCount}`}
                </div>
            )
        } else return <></>
    }

    return (
        <div id={`task-${task.id}`} className="board-task-card" draggable={store.isDrag && !columnAccess ? true : false}
             onDragStart={store.isDrag && !columnAccess ? onDragStart : undefined}
             onDragEnd={store.isDrag && !columnAccess ? onDragEnd : undefined}
        >
            {openConfirmWindow && <ConfirmationWindow headerText='Удаление задачи'
                                                      bodyText='Вы уверены, что хотите удалить задачу?'
                                                      confirmText='Да' cancelText='Нет' onConfirm={onConfirm}
                                                      onCancel={onCancel}/>}
            {showEditForm ? (
                <div className="full-screen-overlay" onClick={onOverlayClick}>
                    <div className="popup-form-background" style={{zoom: `${1 / Number(currentZoom)}`}} onClick={onFormClick}>
                        <TaskAddEditForm mode={"edit"} taskId={task.id} closeFunc={onEditFormClose}
                                         columnId={task.columnPosition} onTaskAction={handleTaskAction}
                                         permission={editTask}/>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="dash__board--form-column__tasks--elem__header">
                <div className="dash__board--form-column__tasks--elem__header__shortDescription">
                    <div className="task-abbr-container">
                        <div
                            className="task__abbr__board">{task.idForView}</div>
                        <div className="task__short--description__button">
                            <BtnMore onClickFunc={onEditClick} data={dataForEdit} typeForKey='nameProj' open={open}
                                     setOpen={setOpen} columnsChoices={columnChoices} boardChoices={boardsForProxy}
                                     taskId={task.id} userAccess={userAccess} columnAccess={columnAccess}
                                     boardsData={boardsData}/>
                        </div>
                    </div>
                    <div className="task__name__board">
                        {isSelectable ? (
                            isSelected ? (
                                <input
                                    className="files-widget-checkbox"
                                    onChange={() => {
                                        handleSelectTask(task.id)
                                        handleSelectTaskToColumn(task.taskToColumnId)
                                    }}
                                    value={task.id}
                                    type="checkbox"
                                    checked
                                />
                            ) : (
                                <input
                                    className="files-widget-checkbox"
                                    onChange={() => {
                                        handleSelectTask(task.id)
                                        handleSelectTaskToColumn(task.taskToColumnId)
                                    }}
                                    value={task.id}
                                    type="checkbox"
                                />
                            )
                        ) : (
                            <></>
                        )}
                        <Tooltip text={task?.name.length > 36 ? task?.name : ""} offset={5}>
                            <a className="task__name__board--new" onClick={() => goToTask(task.id)}>{task?.name}</a>
                        </Tooltip>
                    </div>
                    {task?.description?.length > 20 ?
                        (<p title={formatEditMessage(task?.description, {data: []}).disassembledText} className="task--description__board">
                            <div dangerouslySetInnerHTML={{__html: formatEditMessage(task?.description, {data: []}).disassembledText?.length > 20 ?
                                formatEditMessage(task?.description, {data: []}).disassembledText?.slice(0, 20) + "..."  :
                                formatEditMessage(task?.description, {data: []}).disassembledText?.slice(0, 20)
                            }}/>
                        </p>)
                    :
                        (<p className="task--description__board">
                            {task?.description}
                        </p>)
                    }
                </div>
            </div>
            <div className="dash__board--form-column__tasks__menu">
                <div className="dash__board--form-column__tasks__priority--list">
                    <div className="dash__board--form-column__tasks__priority--list__item">
                        {taskTypeDisplay()}
                    </div>
                    <div>
                        {taskPriorityDisplay()}
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px'}}>
                    <div id={`tag-container-for-task-${task.id}`}
                         className={`dash__board--form-column__tasks--elem__teg--list 
                         ${ showAllTags && tagWrapperOverflowState(`tag-container-for-task-${task.id}`).isOverflown
                             ? 'tags-container-unwrapped' 
                             : 'tags-container-wrapped'}`}
                        // onMouseOver={()=> {setShowAllTags(true)}}
                        // onMouseOut={()=> {setShowAllTags(false)}}
                         style={{overflow: 'hidden', display: 'flex', flexDirection: 'row', gap: `${tagGap}px`}}>
                        {
                            task?.tags.map((tag, index) => (
                                <div key={index} id={`tag-container-for-board-card-${tag.id}`}>
                                    {taskTagsDisplay(tag)}
                                </div>
                            ))
                        }
                    </div>
                    {tagOverflowButton(`tag-container-for-task-${task.id}`)}
                </div>
            </div>
            {/* <div>
            <p>
                Дата начала: {task.startDate}
            </p>
            <p>
                Дата конца: {task.endDate}
            </p>
            </div> */}
            <div className="dash__board--form-column__tasks--elem__bottom">
                <div className="dash__board--form-column__tasks--elem__bottom--flex">
                    <div className="dash__board--form-column__tasks--elem__bottom--name">
                        <Avatar size="s" url={getFileUrlFromId(task.responsiblePhotoId)}/>
                        <p title={task?.responsibleName}
                           className="dash__board--form-column__tasks--elem__bottom--flex__name">
                            {gerPersonShortName()}
                        </p>
                    </div>
                    <div className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar">
                        <div className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block">
                            <div
                                className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block__header">
                                {task.percentageCompletion}%
                            </div>
                            <div style={{width: "100%"}}>
                                <ProgressBar
                                    value={task.percentageCompletion}
                                    style={{height: '8px', fontSize: '0px'}}
                                ></ProgressBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoardTask;
