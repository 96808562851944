import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import "../styles/header.pc.css";
import Settings from "../../assets/settings.svg";
import Notification from "../../assets/notification.svg";
import Avatar from "../Shared/Avatar/Avatar";
import "../Header/Header.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {BreadCrumb} from "primereact/breadcrumb";
import {AppContext} from "../../App";
import {IBreadCrumb} from "../../models/IBreadCrumb";
import {MenuItem} from "primereact/menuitem";
import SettingsPopup from "../PersonalSettings/SettingsPopup/SettingsPopup";
import PersonalSettings from "../PersonalSettings/PersonalSettings/PersonalSettings";
import PasswordChange from "../PersonalSettings/PasswordChangePopup/PasswordChange";
import {getFileUrlFromId} from "../../helpers/getFileUrlFromId";
import {IHasPermission} from "../../models/IChekRole";
import Button from "../UiLib/Button/Button";
import Notifications from "../Shared/Notifications/Notifications";
import {ISearchResult} from "../../models/ISearch";
import {isAxiosError} from "axios";
import AnimatedSearch from "../UiLib/AnimatedSearch/AnimatedSearch";
import LightSearchPreview from "../Shared/LightSearchPreview/LightSearchPreview";


interface HeaderProps {
    userAccess: IHasPermission[]
}

const Header = ({userAccess}: HeaderProps) => {
    const {store} = useContext(Context);
    const {breadCrumb, notification} = useContext(AppContext);
    const navigate = useNavigate();
    const [showSettingsPopup, setShowSettingsPopup] = useState<boolean>(false);
    const [showSettingsPopupLock, setShowSettingsPopupLock] =
        useState<boolean>(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState<boolean>(false);
    const [showPasswordChangeMenu, setPasswordChangeMenu] = useState<boolean>(false);

    const [breadCrumbMenuItem, setBreadCrumbMenuItem] = useState<MenuItem[]>([]);

    const [viewSystenSettings, setViewSystenSettings] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const [searchText, setSearchText] = useState<string>("");
    const [dataForView, setDataForView] = useState<ISearchResult>();
    const [countNotViewed, setCountNotViewed] = useState<number>(notification?.data?.countNotViewedData ?? 0)
    const [showAllSearch, setShowAllSearch] = useState<boolean>(false);

    useEffect(() => {
       
        const newBreadCrumbMenuItem: MenuItem[]=breadCrumb.map((b) =>{
            const label = b.label ?? ' ';
            const bread: MenuItem = {
                label: label,
                url: b.url,
                template: <Link to={b.url}>{label}</Link>,
            };
            return bread
        })
       
        setBreadCrumbMenuItem(newBreadCrumbMenuItem);
    }, [breadCrumb]);


    useEffect(() => {

        userAccess.forEach((xx) => {

            if (xx.functionCode == "SystemAction") { //Информация по задаче
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewSystenSettings(true);
                    }
                })
            }
            // if (store.user.email == "admin@admin.adm") {
            //     setViewSystenSettings(true);
            // }
        })

    }, [userAccess]);


    useEffect(() => {
        const searchTextLowercase = searchText.toLowerCase();
        (async () => {
                try {
                    if (searchTextLowercase?.length > 0) {
                        const res = await store.searchService.lightSearch(searchTextLowercase);
                        // const res = await store.searchService.search({
                        //     search: searchTextLowercase,
                        //     sort: {
                        //         createdAt: "asc",
                        //         name: null,
                        //         createdBy: null,
                        //     },
                        //     filters: null,
                        //     take: 10,
                        //     skip: 0
                        // });

                        if (res.data && res.data.boards.length > 0 || res.data.tasks.length > 0 || res.data.projects.length > 0) {
                            setDataForView(res.data)
                        }else{
                            setDataForView(undefined)
                        }
                    } else {
                        setDataForView(undefined)
                    }
                } catch (err) {
                    if (isAxiosError(err)) {
                        console.error(err.response?.data.message)
                    }
                }

            }
        )
        ()
    }, [searchText]);

    useEffect(() => {
        if(notification?.data)
        setCountNotViewed(notification?.data?.countNotViewedData)
    }, [notification])

    function goToSettings() {
        navigate("/system/settings");
    }


    const closeSettingsPopup = () => {
        setShowSettingsPopupLock(true);
        // Не мы такие, жизнь такая...
        window.setTimeout(() => {
            setShowSettingsPopupLock(false);
        }, 300);
        setShowSettingsPopup(false);
    };

    const closeNotification = () => {
        setShowNotifications(false)
    }

    const onSubmitSearchFunc = () => {
        navigate("/globalsearch", {state: {searchText: searchText}});
    }

    const closeSearch = () => {
        setDataForView(undefined)
    }


    return (
        <div className="header-component">
            <div className="headName">
                <BreadCrumb model={breadCrumbMenuItem}/>
            </div>
            <div id="right-control-panel">
                <AnimatedSearch
                    onSubmitFunc={onSubmitSearchFunc}
                    changeSearchValue={(text) => {
                        setSearchText(text)
                    }}
                    swapFuncButton={true}
                    showPreviewList={dataForView ? true : false}
                    placeholder={"Поиск..."}
                    value={searchText}
                    widthInput={455}
                    stashDate={showAllSearch}
                    callBackStash={(stash) => setShowAllSearch(!stash)}
                >
                    <LightSearchPreview callBack={(show) => setShowAllSearch(!show)} dataForView={dataForView}/>
                </AnimatedSearch>
                {viewSystenSettings ?
                    <Button callBack={() => goToSettings()} style={{margin: "2px"}} image={Settings}
                            classBlock={"right-control-panel-settings"}/>
                    :
                    null
                }
                <Button callBack={() => setShowNotifications(prevValue => !prevValue)} style={{margin: "2px"}}
                        image={Notification} classBlock={"right-control-panel-notification"}/>
                {countNotViewed > 0 ? (<span className="right-control-panel-notification-num">{countNotViewed}</span>) : null}
                <div
                    style={{cursor: "pointer", display: "flex", alignItems: "center"}}
                    onClick={() =>
                        showSettingsPopupLock
                            ? null
                            : setShowSettingsPopup(!showSettingsPopup)
                    }
                >
                    <div className="header__component-text">
                        <a className="a_header_name_16">
                            {store.user.name} {store.user.surname}
                        </a>
                    </div>
                    <Avatar url={getFileUrlFromId(store.user.photoId)} size="lg"/>
                </div>
                {showNotifications ? (
                    <div className="header-notification-popup-container">
                        <Notifications onClose={closeNotification}/>
                    </div>
                ) : null}
                {showSettingsPopup ? (
                    <div className="header-settings-popup-container">
                        <SettingsPopup
                            onClose={closeSettingsPopup}
                            onSettingsClick={() => {
                                setShowSettingsMenu(true);
                                setShowSettingsPopup(false);
                            }}
                            onPasswordChangeClick={() => { //Добавил такой-же попап для смены пароля
                                setPasswordChangeMenu(true);
                                setShowSettingsPopup(false);
                            }}
                        />
                    </div>
                ) : null}
                {showSettingsMenu ? (
                    <div className="header-settings-popup-container">
                        <PersonalSettings
                            onClose={() => setShowSettingsMenu(false)}
                        />
                    </div>
                ) : null}
                {showPasswordChangeMenu ? (
                    <div className="header-settings-popup-container">
                        <PasswordChange
                            onClose={() => setPasswordChangeMenu(false)}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default observer(Header);
