import {CSSProperties, FC} from "react";
import { getFileUrlFromId } from "../../helpers/getFileUrlFromId";
import Avatar from "./Avatar/Avatar";
import "./MisceleniousStyles.css";

interface IPersonLineDisplay {
    name: string;
    photoId?: number;
    style?: CSSProperties;
}

const PersonLineDisplay: FC<IPersonLineDisplay> = ({ name, photoId, style }) => {
    return (
        <div style={{...style}} className="line-person-display-widget nonselectable">
            <div className="line-profile-image-container">
                <Avatar size="s" url={getFileUrlFromId(photoId)} />
            </div>
            <div style={{paddingLeft: "10px"}} title={name} className="task-type-text">
                {name}
            </div>
        </div>
    );
};
export default PersonLineDisplay;
