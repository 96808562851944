
export const options_linear = {
    interaction: {
        axis: 'xy' as any,
        mode: 'nearest' as any,
        intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        datalabels: {
            enabled: false,
            color: 'rgb(0,0,0,0)'
        },
        legend: {
            position: 'right' as const,
        }
    },
    scales: {
        x: {
            border: {
                dash: [4, 4],
                // color: pullColorFromVariable('--graph-grid-color'),
            },
            grid: {
                display: true,
                // color: pullColorFromVariable('--graph-grid-color'),
                tickColor: 'transparent'
            },
            ticks: {
                align: 'start' as any
            }
        },
        y: {
            border: {
                dash: [4, 4],
                // color: pullColorFromVariable('--graph-grid-color'),
            },
            grid: {
                borderDash: [8, 4],
                display: true,
                // color: pullColorFromVariable('--graph-grid-color'),
                tickColor: 'transparent'
            },
        }
    },
};

export const options_pie = {
    cutout: '40%',
    // interaction: {
    //     axis: 'xy' as any,
    //     mode: 'index' as any,
    //     intersect: false,
    // },
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
        tooltip: {
            enabled: false,
            events: [] // Disable tooltip-specific interactions
        },
        datalabels: {
            formatter: (value: any, context: any) => {
                let percentage = (value / context.chart._metasets
                    [context.datasetIndex].total * 100)
                    .toFixed(2) + '%';
                return `${value} - ${percentage}`;
            },
            color: 'var(--graph-pie-data-label-color)',
            font: {
                size: 14,
                weight: 'bold',
                // family: 'Comic Sans',
            }
        }
    }
};