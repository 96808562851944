import { FC, useContext} from 'react';
import { observer } from "mobx-react-lite";
import DocumentCopy from '../../../assets/document-copy.svg';
import { AppContext } from '../../../App';
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";

export interface ICopyButtonProps {
    textDivId: string;
    title?: string;
    titlePosition?: "top" | "left" | "right" | "bottom";
}

const BtnCopyText: FC<ICopyButtonProps> = ({ textDivId, title, titlePosition }) => {
    const {showToast} = useContext(AppContext);

    function onClick() {
        const text = document.getElementById(textDivId)?.innerText;
        if (!text) { return }
        navigator.clipboard.writeText(text);
        showToast("Скопировано в буфер обмена");
    }

    return (
        <Tooltip text={title ?? ""} placement={titlePosition} offset={3}>
            <div className='button_copy'>
                <div className="button-base" onClick={onClick}>

                        <img src={DocumentCopy} />

                </div>
            </div>
        </Tooltip>
    )
}

export default observer(BtnCopyText);