import React, {CSSProperties, FC, ReactNode, useEffect} from 'react';
import ArrowSub from "../../../assets/arrowDown.svg";
import Button from "../Button/Button";
import "./DropDownList.css"
import CheckboxIos from "../CheckboxIos/CheckboxIos";
import checkboxIos from "../CheckboxIos/CheckboxIos";
import ChekboxDefault from "../ChekboxDefault/ChekboxDefault";

interface IDropDownList {
    id?: number;
    listName: string;
    children?: ReactNode;
    style?: CSSProperties;
    classBlock?: string | string[];
    componentMod: "dropDownList" | "dropDownListDefaultCheckBox" | "dropDownListIosCheckBox";
    checked?: boolean;
    initialStateOpen?: boolean;
    numChild?: number;
    callBackCheckBox?: () => void;
    disabled?: boolean;
}

/**
 * Компонент для отображения выпадающего списка с возможностью добавления различных модификаций.
 *
 * @param {IDropDownList} props - Свойства компонента.
 * @param {number | undefined} props.id - Уникальный идентификатор выпадающего списка. Необязательный параметр.
 * @param {string} props.listName - Название списка, отображаемое в заголовке.
 * @param {ReactNode} [props.children] - Дочерние элементы, отображаемые внутри выпадающего списка.
 * @param {CSSProperties} [props.style] - Дополнительные стили, применяемые к контейнеру списка.
 * @param {string | string[]} [props.classBlock] - Дополнительные CSS-классы, применяемые к контейнеру списка.
 * @param {"dropDownList" | "dropDownListDefaultCheckBox" | "dropDownListIosCheckBox"} props.componentMod - Модификация компонента:
 *   - "dropDownList": Базовая версия выпадающего списка.
 *   - "dropDownListDefaultCheckBox": Версия с чекбоксом по умолчанию.
 *   - "dropDownListIosCheckBox": Версия с iOS-стилизованным чекбоксом.
 * @param {boolean} [props.checked] - Состояние чекбокса (активен/неактивен). Необязательный параметр.
 * @param {() => void} [props.callBackCheckBox] - Функция обратного вызова, вызываемая при изменении состояния чекбокса. Необязательный параметр.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли чекбокс. Если true, чекбокс недоступен для взаимодействия и отображается в заблокированном состоянии. Необязательный параметр.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий выпадающий список.
 */
const DropDownList: FC<IDropDownList> = ({
                                             id,
                                             children,
                                             style,
                                             listName,
                                             classBlock,
                                             componentMod,
                                             checked,
                                             initialStateOpen,
                                             numChild,
                                             callBackCheckBox,
                                             disabled
                                         }) => {
    const [open, setOpen] = React.useState<boolean>(initialStateOpen ?? false);

    return (
        <div style={style} className={classBlock + ` ui__component--drop__down--list`}>
            {componentMod == "dropDownList" ? (
                <div className="ui__component--drop__down--list__name">
                    <a style={{padding: "5px", width: "100%"}}>{listName}</a>
                    {numChild ? (<span className="ui__component--drop__down--list__child--num">{numChild}</span>) : null}
                    <Button id={"drop-down-listButton-" + String(id)} style={open ? {rotate: "180deg"} : {}}
                            image={ArrowSub} callBack={() => setOpen((prevOpen) => !prevOpen)}/>
                </div>
            ) : componentMod == "dropDownListIosCheckBox" ? (
                <div className="ui__component--drop__down--list__name">
                    <Button id={"drop-down-listButton-" + String(id)} style={open ? {rotate: "180deg"} : {}}
                            image={ArrowSub} callBack={() => setOpen((prevOpen) => !prevOpen)}/>
                    <a style={{padding: "5px", width: "100%"}}>{listName}</a>
                    {numChild ? (<span className="ui__component--drop__down--list__child--num">{numChild}</span>) : null}
                    <CheckboxIos disabled={disabled} checked={checked} callBack={() => {callBackCheckBox && callBackCheckBox()}}/>
                </div>
            ) : (
                <div className="ui__component--drop__down--list__name">
                    <Button id={"drop-down-listButton-" + String(id)} style={open ? {rotate: "180deg"} : {}}
                            image={ArrowSub} callBack={() => setOpen((prevOpen) => !prevOpen)}/>
                    <a style={{padding: "5px", width: "100%"}}>{listName}</a>
                    {numChild ? (<span className="ui__component--drop__down--list__child--num">{numChild}</span>) : null}
                    <ChekboxDefault disabled={disabled} checked={checked} callBack={() => {callBackCheckBox && callBackCheckBox()}}/>
                </div>
            )}
            <div style= {open ? {display: "block"} : {display: "none"}}
                 className="ui__component--drop__down--list__block">
                {children}
            </div>
        </div>
    );
};

export default DropDownList;