import { CSSProperties, FC } from "react";
import { getFileUrlFromId } from "../../../helpers/getFileUrlFromId";
import Avatar from "../Avatar/Avatar";
import "./PersonWithSubtext.css";

interface IPersonWithSubtextProps {
    name: string;
    subtext: string;
    photoId?: number;
    style?: CSSProperties;
    className?: string;
}

const PersonWithSubtext: FC<IPersonWithSubtextProps> = ({
    name,
    photoId,
    style,
    subtext,
    className,
}) => {
    return (
        <div
            style={{ ...style }}
            className={`person-with-subtext-container nonselectable ${className}`}
        >
            <div className="person-with-subtext-img">
                <Avatar size="m" url={getFileUrlFromId(photoId)} />
            </div>
            <div>
                <div
                    title={name}
                    className="task-type-text person-with-subtext-name"
                >
                    {name}
                </div>
                <div
                    title={subtext}
                    className="task-type-text person-with-subtext-subtext"
                >
                    {subtext}
                </div>
            </div>
        </div>
    );
};
export default PersonWithSubtext;
