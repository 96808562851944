import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {IFullUserInfo, IUser} from "../../../models/IUser";
import {AppContext} from "../../../App";
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import {fileURLToPath} from "node:url";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import Avatar from "../../../assets/avatar.svg"
import "./UserMention.css"
import {fullNameFormat} from "../../../helpers/Inicials";
import PersonLineDisplay from "../PersonLineDisplay";
import {IHasPermission} from "../../../models/IChekRole";

interface IUserMention {
    filter?: string;
    mentionPersons: number[];
    callBack: (user: IFullUserInfo) => void;
    projectId: number;
}

const UserMention: FC<IUserMention> = ({filter, callBack, mentionPersons, projectId}) => {
    const {store} = useContext(Context);
    const {showToast} = useContext(AppContext);
    const [saveAllUsers, setSaveAllUsers] = useState<IFullUserInfo[]>([])
    const [allUsers, setAllUsers] = useState<IFullUserInfo[]>([])

    const fetchData = async () => {
        let data = await store.fetchAllUsers(projectId);
        if (!data) {
            showToast("Произошла ошибка")
        }

        data = data!.filter(user => {
            if (!mentionPersons.includes(user.id!)) {
                return user
            }
        })

        setAllUsers(data!)
        setSaveAllUsers(data!)
    }

    useEffect(() => {
        (async () => {
            await fetchData()
        })();
    }, []);

    useEffect(() => {
        if (filter && filter.length > 0 && filter != "@") {
            let splitData = filter.split(" ");

            let curSurname = splitData[0]?.slice(1)?.toUpperCase() ?? "";
            let curName = splitData[1]?.toUpperCase() ?? "";

            setAllUsers(
                saveAllUsers
                .filter(user => {
                    const userName = user.name.toUpperCase();
                    const userSurname = user.surname.toUpperCase();

                    const nameMatch = curName ? userName.includes(curName) : true;
                    const surnameMatch = curSurname ? userSurname.includes(curSurname) : true;

                    return nameMatch && surnameMatch;
                })
            );
        } else {
            (async () => {
                await fetchData()
            })();
        }
    }, [filter]);

    return (
        <div className="user__mention--widget">
            <WidgetContainer style={{maxHeight: "200px"}}>
                <div style={{overflowY: "scroll", maxHeight: "175px"}}>
                    {allUsers?.map(user => (
                        <div id={`${user.id}-user`} className="user__mention--widget__block" onClick={() => callBack(user)}>
                            <PersonLineDisplay
                                style={{width: "200px"}}
                                name={fullNameFormat(
                                    {
                                        surname: user.surname ?? "",
                                        name: user.name ?? "",
                                        middlename: user.middlename ?? "",
                                    },
                                    "s N M"
                                )}
                                photoId={user.photoId}
                            />
                            <div>

                            </div>
                            {user?.role?.name}
                        </div>
                    ))}
                </div>
            </WidgetContainer>
        </div>
    );
};

export default UserMention;