import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

const MarkdownPreview: FC<{ content: string }> = ({ content }) => {
    return <ReactMarkdown 
        rehypePlugins={[rehypeRaw]}>
        {content}
    </ReactMarkdown>;
};

export default MarkdownPreview;