import { ProgressBar } from "primereact/progressbar";
import "./SmallProgressBar.css";
import { CSSProperties, FC } from "react";

interface ISmallProgressBarProps {
    progress: number;
    style?: CSSProperties;
    className?: string;
}

const SmallProgressBar: FC<ISmallProgressBarProps> = ({
    progress,
    style,
    className,
}) => {
    return (
        <div className={`small-progress-bar ${className}`} style={style}>
            <ProgressBar value={progress}/>
        </div>
    );
};

export default SmallProgressBar;
