import React, {FC} from 'react';
import "./LightSearchPreview.css"
import {formatEditMessage} from "../../../helpers/chatFucntion";
import MarkdownPreview from "../MarkdownPreview/MarkdownPreview";
import BoardIcon from "../../../assets/board_frame.svg";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import WidgetContainer from "../../UiLib/WidgetContainer/WidgetContainer";
import {ISearchBoardResult, ISearchProjectResult, ISearchResult, ISearchTaskResult} from "../../../models/ISearch";
import {useNavigate} from "react-router-dom";

export interface ILightSearchPreview {
    callBack?: (show: boolean) => void;
    dataForView?: ISearchResult;
}

const LightSearchPreview: FC<ILightSearchPreview> = ({dataForView, callBack}) => {
    const navigate = useNavigate();

    const taskConstructor = (taskData: ISearchTaskResult) => {
        const descriptionText = formatEditMessage(taskData?.whatFound.str, {data: []}).disassembledText
        
        return (
            <div
                onClick={() => {callBack && callBack(false); navigate(`/task/${taskData.id}`)}}
                id={'search_result_component-task-' + taskData.id}
                className='search_result_component-task'
            >
                <div className='search_result_component-number'>
                    {taskData.whatFound.strEnum == 2 ?
                        <MarkdownPreview content={taskData.whatFound.str}/>
                    :
                        <span>
                            {taskData.taskNumber}
                        </span>
                    }
                </div>
                <div className='search_result_component-task-name'>
                    {taskData.whatFound.strEnum == 0 ?
                        <MarkdownPreview content={taskData.whatFound.str}/>
                    :
                        <span>
                            {taskData.name}
                        </span>
                    }
                </div>
                <div className='search_result_component-task-description'>
                    { taskData.whatFound.strEnum == 1 && descriptionText.length > 0 ?
                        <MarkdownPreview content={descriptionText?.length > 40 ?
                            descriptionText?.slice(0, 40) + "..."
                        :
                            descriptionText?.slice(0, 40)}
                        />
                    :
                        <span>
                            {taskData.description.length > 40 ?
                                (<p title={formatEditMessage(taskData.description, {data: []}).disassembledText}>
                                    <div dangerouslySetInnerHTML={{__html: formatEditMessage(taskData.description, {data: []}).disassembledText?.length > 40 ?
                                            formatEditMessage(taskData.description, {data: []}).disassembledText?.slice(0, 40) + "..."  :
                                            formatEditMessage(taskData.description, {data: []}).disassembledText?.slice(0, 40)
                                    }}/>
                                </p>)
                                :
                                (<p className="task--description__board">
                                    {taskData.description}
                                </p>)
                            }
                        </span>
                    }
                </div>
            </div>
        )
    }

    const boardConstructor = (boardData: ISearchBoardResult) => {
        return (
            <div
                onClick={() => {callBack && callBack(false); navigate(`/board/${boardData.id}`);}}
                id={'search_result_component-board-' + boardData.id}
                className='search_result_component-board'
            >
                <img
                    src={BoardIcon}
                    className="board-icon"
                    alt="board-icon"
                />
                <MarkdownPreview content={boardData.whatFound.str}></MarkdownPreview>
            </div>
        )
    }

    const projectConstructor = (projectData: ISearchProjectResult) => {
        return (
            <div
                onClick={() => {callBack && callBack(false); navigate(`/project/${projectData.id}`);}}
                id={'search_result_component-project-' + projectData.id}
            >
                <div className="search_result_component-project">
                    <div>
                        <img src={getFileUrlFromId(projectData!.photoId!)}
                        />
                    </div>
                    <div>
                        <div className='search_result_component-number'>
                            {projectData.whatFound.strEnum == 2 ?
                                <MarkdownPreview content={projectData.whatFound.str}/>
                            :
                                <span>
                                    {projectData.projectNumber}
                                </span>
                            }
                        </div>
                        <div className='search_result_component-task-name'>
                            {projectData.whatFound.strEnum == 0 ?
                                <MarkdownPreview content={projectData.whatFound.str}/>
                            :
                                <span>
                                    {projectData.name}
                                </span>
                            }
                        </div>
                        <div className='search_result_component-description'>
                            {projectData.whatFound.strEnum == 1 ?
                                <MarkdownPreview content={projectData.whatFound.str}/>
                            :
                                <span>
                                    {projectData.description}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <WidgetContainer style={{
            marginTop: '0px', padding: "0px", borderRadius: "0px", boxSizing: 'unset',
            maxHeight: "480px", overflowY: "auto", paddingRight: "2px"
        }}>
            <div className="search_result_component">
                <div className="search_result_component_head">
                    <span>Задачи - {dataForView?.tasks?.length ?? 0}</span>
                </div>
                <div className="search_result_component_body">
                    {dataForView?.tasks?.map(taskData => {
                        return taskConstructor(taskData)
                    })}
                </div>
            </div>
            <div className="search_result_component">
                <div className="search_result_component_head">
                    <span>Доски - {dataForView?.boards?.length ?? 0}</span>
                </div>
                <div className="search_result_component_body">
                    {dataForView?.boards?.map(boardData => {
                        return boardConstructor(boardData)
                    })}
                </div>
            </div>
            <div className={"search_result_component"}>
                <div className="search_result_component_head">
                    Проекты - {dataForView?.projects?.length ?? 0}
                </div>
                <div className="search_result_component_body">
                    {dataForView?.projects?.map(projectData => {
                        return projectConstructor(projectData)
                    })}
                </div>
            </div>
        </WidgetContainer>
    );
};

export default LightSearchPreview;