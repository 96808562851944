import $api from "..";
import {AxiosResponse} from "axios";
import {
    IGetTaskHistoryDisplay,
    IGetTasksDisplayData,
    ITasksDisplay,
    ITasksDisplayFilters,
} from "../models/TaskModels";
import {IMoveTaskBoardData, IMoveTaskData, IMoveTasksData, IMoveTasksOutsideData} from "../models/IMoveTaskData";
import {IDeleteTasks} from "../models/IDeleteTasks";
import {ITaskGeneralInfo} from "../models/ITask";
import {ICreateTegTask} from "../models/ICreateTegTask";
import {ITag, ITagFromDisplay, ITaskFormDisplay} from "../models/ITaskFormDisplay";
import {ITaskCreate} from "../models/ITaskCreate";
import {ITaskEdit} from "../models/ITaskEdit";
import {IUpdateTegTask} from "../models/IUpdateTegTask";
import {IUpdateTypeTask} from "../models/IUpdateTypeTask";
import {IGetAllTaskPerson} from "../models/IAllTaskPerson";
import {IGetTaskRequest} from "../models/IGetTaskRequest";
import {ITypeAndPriority} from "../models/ITypeAndPriority";
import {ISubTask, ISubTaskView} from "../models/ISubTask";
import {ISetTaskParent} from "../models/ISetTaskParent";
import { IProjectHistoryDisplay } from "../models/ProjectModels";
import {ITaskChecklistGroup} from "../models/ITaskChecklistGroup";
import {ITaskChecklistGroupEdit} from "../models/ITaskChecklistGroupEdit";
import {ITaskChecklist} from "../models/ITaskChecklist";
import { ICreateTeg } from "../models/ICreateTeg";

export default class TaskService {


    async getProjectTasksDisplayData(body: IGetTasksDisplayData): Promise<AxiosResponse<ITasksDisplay>> {
        return $api.post<ITasksDisplay>(`Project/getTasks`, body);
    }

    async getAllTaskPerson(taskId: number): Promise<AxiosResponse<IGetAllTaskPerson>> {
        return $api.get<IGetAllTaskPerson>(`Task/getAllTaskPerson?taskId=${taskId}`);
    }

    async getTypeAndPriority(): Promise<AxiosResponse<ITypeAndPriority>> {
        return $api.get<ITypeAndPriority>(`Project/getTypeAndPriority`);
    }

    async updateTaskType(body: IUpdateTypeTask): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskType`, body);
    }

    async updateTaskPriority(body: IUpdateTegTask): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskPriority`, body);
    }

    async getTask(reqBody: IGetTaskRequest): Promise<AxiosResponse<ITaskGeneralInfo>> {
        return $api.post<ITaskGeneralInfo>(`Project/getTaskInfo`, reqBody);
    }

    async getTaskHistory(reqBody: IGetTaskHistoryDisplay): Promise<AxiosResponse<IProjectHistoryDisplay[]>> {
        return $api.post<IProjectHistoryDisplay[]>(`Project/getTaskLogs`, reqBody);
    }

    async getTaskForm(taskId: number): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.get<ITaskFormDisplay>(`Project/getTask?taskId=${taskId}`);
    }
    async addTeg(body: ICreateTeg): Promise<AxiosResponse<number>> {
        return $api.post<number>(`teg/addTeg`, body);
    }
    async addTegTask(body: ICreateTegTask): Promise<AxiosResponse<number>> {
        return $api.post<number>(`teg/addTegTaskInfo`, body);
    }

     async getTegs(body: { taskId: number | undefined; projectId: number | undefined; userId: number; }): Promise<AxiosResponse<ITag[]>> {
        return $api.post<ITag[]>(`teg/getTegs`, body);
    }

    async getProjectTasksFilters(projectId: number): Promise<AxiosResponse<ITasksDisplayFilters>> {
        return $api.get<ITasksDisplayFilters>(`Project/taskDisplayFilters?projectId=${projectId}`);
    }

    async moveTask(body: IMoveTaskData): Promise<void> {
        return $api.post("Project/dashBoard/moveTask", body);
    }

    async moveTaskBoard(body: IMoveTaskBoardData): Promise<void> {
        return $api.post("/Task/moveTaskOutsideBoard", body);
    }

    async moveTasks(body: IMoveTasksData): Promise<void> {
        return $api.post("/Project/dashBoard/bulkMoveTasks", body);
    }

    async moveTasksOutsideBoard(body: IMoveTasksOutsideData) : Promise<void> {
        return $api.post("/Project/dashBoard/bulkMoveTasksBoard", body);
    }

    async deleteTasks(body: IDeleteTasks): Promise<void> {
        return $api.post("Project/deleteTasks", body);
    }

    async createTask(body: ITaskCreate): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.post("Project/addTask", body);
    }

    async editTask(body: ITaskEdit): Promise<void> {
        return $api.post("Project/editTask", body);
    }

    async addTagsInTasks(body: ITagFromDisplay): Promise<void> {
        return $api.post("Task/addTagsInTasks", body);
    }
    async removeTagsInTasks(body: ITagFromDisplay): Promise<void> {
        return $api.post("Task/removeTagsInTasks", body);
    }

    async getTaskChecklist(taskId: number): Promise<AxiosResponse<ITaskChecklistGroup[] | []>> {
        return $api.get(`Task/getTaskChecklist?taskId=${taskId}`);
    }

    async createChecklistGroup(body: ITaskChecklistGroup) {
        return $api.post("Task/addChecklistGroup", body);
    }

    async editChecklistGroup(body: ITaskChecklistGroupEdit) {
        return $api.post("Task/editChecklistGroup", body);
    }

    async deleteTaskChecklistGroup(groupId: number) {
        return $api.get(`Task/deleteTaskChecklistGroup?groupId=${groupId}`);
    }

    async createChecklistElement(body: ITaskChecklist) {
        return $api.post("Task/addChecklistElement", body);
    }
    async deleteTaskChecklistElement(elementId: number) {
        return $api.get(`Task/deleteElementTaskChecklist?elementId=${elementId}`);
    }

    async editTaskChecklistElement(body: ITaskChecklistGroupEdit) {
        return $api.post("Task/editChecklistElement", body);
    }

    async getSubTasksTree(taskId: number): Promise<AxiosResponse<ISubTaskView>> {
        return $api.get<ISubTaskView>(`Task/getSubTasksTree?taskId=${taskId}`,);
    }

    async getSubTasks(taskId: number): Promise<AxiosResponse<ISubTask[]>> {
        return $api.get(`Task/getSubTasks?taskId=${taskId}`);
    }
    async getAvailableSubTasks(taskId: number): Promise<AxiosResponse<ISubTask[]>> {
        return $api.get(`Task/getAvailableSubTasks?taskId=${taskId}`);
    }

    setTaskParent(body: ISetTaskParent): Promise<AxiosResponse<void>> {
        return $api.post("Task/setParent", body);
    }
}
