import { CSSProperties, FC } from "react";
import { observer } from "mobx-react-lite";
import "./Search.css";
import SearchElem from "../../../assets/search.svg";
import Cross from "../../../assets/cancelGreey.svg";
import "../../buttons/DefaultButtons.css";

export interface ISearchComponentProps {
    value: string;
    placeholder?: string;
    onSubmitFunc?: (value: string) => void;
    changeSearchValue?: (value: string) => void;
    onClick?: () => void;
    hideSubmitButton?: boolean;
    showPreviewList?: boolean;
    className?: string;
    style?: CSSProperties;
    showResetButton?: boolean;
    onReset?: () => void;
}

const Search: FC<ISearchComponentProps> = ({
    value,
    placeholder,
    onSubmitFunc,
    changeSearchValue,
    hideSubmitButton,
    showPreviewList,
    showResetButton,
    onReset,
    className,
    style,
    onClick
}) => {
    function handleSubmit(event: any) {
        event.preventDefault();
        if (onSubmitFunc) onSubmitFunc(event.nativeEvent.srcElement[0].value);
    }

    return (
        <div style={style} className={`custom_search ${className}`}>
            <div style={{ width: "100%" }}>
                <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex" }}
                    className={"custom_search-form"}
                >
                    <input
                        onClick={onClick}
                        placeholder={placeholder}
                        onChange={
                            changeSearchValue
                                ? (e: any) => changeSearchValue(e.target.value)
                                : () => {}
                        }
                        type="search"
                        value={value}
                    />
                    {showResetButton ? (
                        <img src={Cross} alt="cross" onClick={onReset}/>
                    ) : null}
                    {hideSubmitButton ? null : (
                        <button type="submit" className="button-base">
                            <img src={SearchElem} alt="search" />
                        </button>
                    )}
                </form>
            </div>
            {showPreviewList ? <div></div> : null}
        </div>
    );
};

export default observer(Search);
