import React, {createContext, FC, useContext, useEffect, useRef, useState} from "react";
import TaskInformationForm from "../../component/Task/TaskForm/TaskInformationForm";
import "./Task.css";
import TaskRightInfo from "../../component/Task/TaskRightInfo/TaskRightInfo";
import TaskChat from "../../component/Task/TaskChat/TaskChat";
import { AppContext } from "../../App";
import { Context } from "../..";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import {useNavigate, useParams} from "react-router-dom";
import { ITaskGeneralInfo } from "../../models/ITask";
import { IGetAllTaskPerson } from "../../models/IAllTaskPerson";

import { ICheckRole, IHasPermission } from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import {
    IGetTaskHistoryDisplay,
    ITaskHistoryFilter,
} from "../../models/TaskModels";
import { IProjectHistoryDisplay } from "../../models/ProjectModels";
import TaskChecklist from "../../component/Task/TaskChecklist/TaskChecklist";
import TaskHistory from "../../component/Task/TaskHistory/TaskHistory";
import tasks from "../../component/Project/Tasks/Tasks";
import { SEO } from "../../component/Helmet/HelmetSEO";
import Favicon from "../../assets/favicon-128x128.png";
import {ISubTask, ISubTaskView} from "../../models/ISubTask";
import {IProjectMinimal} from "../../models/IProjectMinimal";
import SubTasksGraph from "../../component/Task/TaskRightInfo/SubTasksGraph/SubTasksGraph";
import { ITaskChecklistGroup } from "../../models/ITaskChecklistGroup";
import axios, { AxiosError } from "axios";
import { IGetTaskRequest } from "../../models/IGetTaskRequest";


const HISTORY_PAGE_SIZE = 20;

interface TaskContextType {
    taskInformation: ITaskGeneralInfo | undefined;
    loadTaskInfo: () => void;
    getAllTaskPerson: () => void;
    allPerson: IGetAllTaskPerson | undefined;
    scrollComponentDiv: HTMLDivElement | undefined;
    subTasks: ISubTask[];
    loadSubTasks:  () => void;
    allowedProjects?: IProjectMinimal[];
    onSubTaskSelectControlClick?: (task: ISubTask) => void;
    tab?: number;
}

export const TaskContext = createContext<TaskContextType>({
    taskInformation: undefined,
    loadTaskInfo: () => {},
    getAllTaskPerson: () => {},
    allPerson: undefined, 
    scrollComponentDiv: undefined,
    subTasks: [],
    loadSubTasks: () => {},
    allowedProjects: [],
    tab: 0
});

const tabs: string[] = [
    "Комментарии",
    "Чек-лист",
    "Связанные задачи",
    "История по задаче",
];


function getParameterByName(url: Location, parameterName: string): number | null {
    const urlObject = new URL(url.href);
    const paramValue = urlObject.searchParams.get(parameterName);

    if (paramValue === null) {
        return null;
    }

    if (paramValue === "0") {
        return 0;
    } else if (paramValue === "1") {
        return 1;
    } else {
        console.warn(`Некорректное значение параметра ${parameterName}: ${paramValue}`);
        return null;
    }
}


const Task: FC = () => {
    const { setBreadCrumb, findBoard, sideBarProjects, setNavPanelHighlight, archivedPage, pageTitle, archivedProjects, showToast } = useContext(AppContext);
    const { store } = useContext(Context);
    const [defaultBoardId, setDefaultBoardId] = useState<number>();
    const { boardId, id } = useParams();
    const [allPerson, setAllPerson] = useState<IGetAllTaskPerson | undefined>(
        undefined
    );
    const [taskInformation, setTaskInformation] = useState<ITaskGeneralInfo>();
    const [historySkip, setHistorySkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [taskHistory, setTaskHistory] = useState<IProjectHistoryDisplay[]>(
        []
    );
    const [subtasksHead, setSubtasksHead] = useState<ISubTaskView>();
    const [checklistGroups, setCheklistGroups] = useState<ITaskChecklistGroup[]>([]);
    const [tab, setTab] = React.useState<number>(0);

    const [taskHistoryFilters, setTaskHistoryFilters] =
        useState<ITaskHistoryFilter>({
            taskId: Number(id),
        });

    //Подзадачи
    const [allowedProjects, setAllowedProjects] = useState<IProjectMinimal[]>([]);
    //Тут лежит все дерево задач доступных и нет для связи как подзадача.
    const [subTasks, setSubTasks] = useState<ISubTask[]>([]);
    let checkList = getParameterByName(window.location, "checkList");




    useEffect(() => {
            if (checklistGroups.some(group => group.checklist!.length > 0))
                    setTab(1);
    }, [checklistGroups]);


    
    const loadSubTasksTree = async () => {
        if (store.getBlockSubtasks())
            return;
        try {
            const subTasksTreeResp = await store.taskService.getSubTasksTree(Number(id));
            setSubtasksHead(subTasksTreeResp.data);
            const availableSubTasks = await  store.taskService.getAvailableSubTasks(Number(id))
            setSubTasks(availableSubTasks.data)
        } catch (err) {
            if (axios.isAxiosError(err))
                showToast(err.response?.data.message);
        }
    }
    
    const loadSubTasks = async () => {
        if (store.getBlockSubtasks())
            return;
        try {
            let subtasksResp = await store.taskService.getSubTasks(Number(id))
            let projectsResp = await store.projectService.getAllowedProjects(Number(id))
            setSubTasks(subtasksResp.data);
            setAllowedProjects(projectsResp.data);
        } catch (err) {
            if (axios.isAxiosError(err))
                showToast(err.response?.data.message);
        }
    }


    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const scrollComponentDiv = useRef(null);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);
    const [editTask, setEditTask] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                });
            }
        });

        // if (store.user.email == "admin@admin.adm") {
        //     setViewHistory(true);
        //     setEditTask(true);
        // }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();

            //чек-листы
            await loadChecklist();

            //Подзадачи
            await loadSubTasksTree();
        })();
    }, [id, window.location]);

    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: taskInformation?.projectId,
                    boardId: taskInformation?.board.id,
                    functionCodes: functionClass.getTaskFunction
                };

                const checkThisRole = await store.hasPermission(newCheckRole);
                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error)
            }
        })();
    }, [taskInformation]);

    useEffect(() => {
        (async () => {
            if (viewHistory) {
                await loadTaskHistory(0, HISTORY_PAGE_SIZE, undefined, false);
                setHistorySkip(HISTORY_PAGE_SIZE);
            }
        })();
    }, [viewHistory, id]);

    useEffect(() => {
        if (archivedPage) {
            if (taskInformation && defaultBoardId) {
                let bread: IBreadCrumb[] = [
                    {
                        label: "Архив",
                        url: "/archive",
                    },
                ];
    
                bread = [...bread, ...findBoard(archivedProjects, defaultBoardId, true)];
                bread.push({
                    label: taskInformation.shortDescription,
                    url: "/task/" + taskInformation.id,
                });
    
                setBreadCrumb(bread);
            }
        } else {
            if (taskInformation && defaultBoardId) {
                let bread: IBreadCrumb[] = [
                    {
                        label: "Проекты",
                        url: "/projects",
                    },
                ];
    
                bread = [...bread, ...findBoard(sideBarProjects, defaultBoardId, false)];
                bread.push({
                    label: taskInformation.shortDescription,
                    url: "/task/" + taskInformation.id,
                });
    
                setBreadCrumb(bread);
                setNavPanelHighlight(undefined);
            }
        }
    }, [setBreadCrumb, taskInformation, defaultBoardId, sideBarProjects, archivedPage]);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem();
                const newCheckRole: ICheckRole = {
                    projectId: Number(id),
                    boardId: undefined,
                    functionCodes: functionClass.getTaskFunction,
                };

                const checkThisRole = await store.hasPermission(newCheckRole);

                setUserAccess(checkThisRole);
                await loadChecklist();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                await loadTaskHistory(
                    0,
                    HISTORY_PAGE_SIZE,
                    taskHistoryFilters,
                    false
                );
                setHistorySkip(HISTORY_PAGE_SIZE);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [taskHistoryFilters]);

    const loadTaskHistory = async (
        skip: number,
        take: number,
        filters: ITaskHistoryFilter | undefined,
        append: boolean
    ): Promise<boolean> => {
        let req: IGetTaskHistoryDisplay = {
            skip: skip,
            take: take,
            filters: filters ?? { taskId: Number(id) },
            sort: {
                date: "desc",
            },
        };

        try {
            const response = await store.taskService.getTaskHistory(req);
                const res = response.data;
                if (!res) return false;
                if (Array.isArray(res)) {
                    if (append) {
                        setTaskHistory([...taskHistory, ...res]);
                    } else {
                        setTaskHistory(res);
                    }
                }
                return res.length > 0;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data?.message || "Ошибка загрузки истории");
            }
            return false;
        }
        
    };

    const loadTaskInfo = async () => {
        try {
            const reqBody: IGetTaskRequest = {
                taskId: Number(id),
                boardId: boardId ? Number(boardId) : undefined,
            };
            const response = await store.taskService.getTask(reqBody);
            if (response.status === 200) {
                setTaskInformation(response.data);
                setDefaultBoardId(response.data?.board.id);
            } else {
                navigate(`/projects`);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                showToast(error.response?.data.message);
            } 
            navigate(`/projects`);
        }
    };
    

    const loadChecklist = async () => {
        try {
            let res = await store.taskService.getTaskChecklist(Number(id))
            setCheklistGroups(res.data)
            const groups = res.data;
            if ((groups.length > 1 || (groups.length && groups[0].checklist?.length)) && checkList as number == 1) {
                setTab(1)
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                showToast(err.response?.data.message);
            }
        }
    }

    const getAllTaskPerson = async () => {
        try {
            const response = await store.taskService.getAllTaskPerson(Number(id));
                setAllPerson(response.data);
         
        } catch (err: any) {
            if (err instanceof AxiosError) {
                showToast(err.response!.data.message);
            } 
        }
    };
    

    const onScroll = async (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight) {
            if (tab === 3 && haveMoreRecords) {
                setHistorySkip((prev: number) => prev + HISTORY_PAGE_SIZE);

                setHaveMoreRecords(
                    await loadTaskHistory(
                        historySkip,
                        HISTORY_PAGE_SIZE,
                        taskHistoryFilters,
                        true
                    )
                );
            }
        }
    };

    return (
        <TaskContext.Provider
            value={{
                taskInformation,
                loadTaskInfo,
                getAllTaskPerson,
                allPerson,
                scrollComponentDiv: scrollComponentDiv.current ?? undefined,
                subTasks,
                loadSubTasks,
                allowedProjects,
                tab
            }}
        >
            <SEO title={taskInformation ? taskInformation.projectAbrevation + "-" +
                (taskInformation.id < 10 ? '00' + taskInformation.id : (taskInformation.id < 100 ? '0' + taskInformation.id : taskInformation.id)) + " · " +
                (taskInformation.shortDescription.length > 70 ? taskInformation.shortDescription.slice(0, 67) + "..." : taskInformation.shortDescription ) + " | Задача · " + pageTitle : pageTitle} />
            <div className="widgets_task_container">
                <div id="task-chat-wrapper-scrollable-part" className="widgets_task_container_1" onScroll={onScroll} ref={scrollComponentDiv}>
                    <div className="widgets_task_container_sub">
                        <TaskInformationForm
                            taskNumber={taskInformation ? taskInformation.idForView:''}
                            projectId={taskInformation?.projectId}
                            userAccess={userAccess}
                        />
                    </div>
                    <div className="task_tabs--header">
                        <div className="task__tabs">
                            {tabs.map((tabName, i) => 
                                <>
                                    {
                                        store.getBlockSubtasks() && tabName === tabs[2] ? null : (
                                            <button
                                                className={`system__settings--header__button ${
                                                    tab === i
                                                        ? "system__settings--header__button__selected"
                                                        : ""
                                                }`}
                                                onClick={() => setTab(i)}
                                            >
                                                {tabName}
                                            </button>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        {tab === 0 ? (
                            <div>
                                {taskInformation ? (
                                    <TaskChat userAccess={userAccess} chatId={taskInformation.chatId} projectId={taskInformation.projectId}/>
                                ) : null}
                            </div>
                        ) : tab === 1 ? (
                            <div>
                                <TaskChecklist openAllDropdown={checkList == 1 ? true : false} userAccess={userAccess} checklistGroups={checklistGroups} loadChecklist={loadChecklist}/>
                            </div>
                        ) : tab === 2 ? (
                            <div
                                className="widget_wrapper"
                            >
                                {id ? (<SubTasksGraph taskId={Number(id)} treeHead={subtasksHead}/>) : null}
                            </div>
                        ) : (
                            <div>
                                <div
                                    id="main-history-widget"
                                    className="widget_wrapper"
                                >
                                    <div className="task-history-table-container">
                                        {viewHistory && taskInformation ? (
                                            <TaskHistory
                                                records={taskHistory}
                                                onChangeFilters={
                                                    setTaskHistoryFilters
                                                }
                                                projectId={taskInformation.projectId}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="widgets_task_container_2">
                    <TaskRightInfo setSubTasks={setSubTasks} userAccess={userAccess}/>
                </div>
            </div>
        </TaskContext.Provider>
    );
};

export default Task;
