import React, { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import TaskType from "../TaskType";
import TaskPriority from "../TaskPriority";
import TaskStatus from "../TaskStatus";
import { dateToNiceString } from "../../../helpers/dateToNiceString";
import { fullNameFormat } from "../../../helpers/Inicials";
import PersonLineDisplay from "../PersonLineDisplay";
import MarkdownPreview from "../MarkdownPreview/MarkdownPreview";
import {
    HistoryItemType,
    HistoryItemValueType,
    IAccess,
    IChecklistGroup,
    INameColor,
    IPerson,
    IPriority,
    IStatus,
    ITaskType,
} from "../../../models/History/Log";
import "./HistoryTable.css";
import UserPopupWrapper from "../UserPopup/UserPopupWrapper";
import { group } from "node:console";
import Checkbox from "../Checkbox/Checkbox";
import HtmlPreview from "../../UiLib/HtmlPreview/HtmlPreview";

interface RenderValueProps {
    type: HistoryItemType;
    value: HistoryItemValueType | null;
    showFullChanges: boolean;
    minimized?: boolean;
    onMinimizeButtonClick?: () => void;
}

export const RenderValue: FC<RenderValueProps> = ({
    type,
    value,
    showFullChanges,
    minimized,
    onMinimizeButtonClick,
}) => {
    const refDiv = useRef<HTMLInputElement>(null);
    const [ showButton, setShowButton ] = useState(false);

    useLayoutEffect(() => {
        if (refDiv.current && ((refDiv.current as any).clientHeight < (refDiv.current as any).scrollHeight))
            setShowButton(true);
        });

    switch (type) {
        case HistoryItemType.string:
            const message = value as string | undefined;
            return <div className="history-table-markdown-img">
                        <div ref={refDiv}
                            className={`${minimized ? "history-table-markdown-container" : ""}`}>
                                <HtmlPreview content={message ?? ""}/>
                        </div>
                        {showButton ? (message && minimized !== undefined ? (
                            <p
                                className="history-table-hide-button"
                                onClick={onMinimizeButtonClick}
                            >
                                {minimized ? "Развернуть" : "Свернуть"}
                            </p>
                        ) : null) : null}
                    </div>;
        case HistoryItemType.stringArray:
            return (
                <div>
                    {(value as string[] | null)?.map((vStr) => (
                        <div>{vStr}</div>
                    ))}
                </div>
            );
        case HistoryItemType.date:
            const date = value as string | null;
            return date ? (
                <div>
                    {value
                        ? dateToNiceString(new Date(date), "dd.MM.yyyy")
                        : ""}
                </div>
            ) : (
                <></>
            );
        case HistoryItemType.ITaskType:
            const type = value as ITaskType | null;
            const typeStyle = type?.style && JSON.parse(type.style);
            return type ? (
                <div id="taskType" style={{margin: "0px 10px"}}>
                    <TaskType
                        name={type?.name}
                        color={typeStyle.color}
                        icon={typeStyle.icon}
                    />
                </div>
            ) : (
                <></>
            );
        case HistoryItemType.IStatus:
            const status = value as IStatus | null;
            const statusStyle = status?.style && JSON.parse(status.style);
            return status ? (
                <div id="status" style={{margin: "0px 10px"}}>
                    <TaskStatus
                        name={status.name}
                        color={statusStyle.color}
                        icon={statusStyle.icon}
                    />
                </div>
            ) : (
                <></>
            );
        case HistoryItemType.IPriority:
            const priority = value as IPriority | null;
            const priorityStyle = priority?.style && JSON.parse(priority.style);
            return priority ? (
                <div id="priority" style={{margin: "0px 10px"}}>
                    <TaskPriority
                        name={priority.name}
                        color={priorityStyle.color}
                        icon={priorityStyle.icon}
                    />
                </div>
            ) : (
                <></>
            );
        case HistoryItemType.INameColorArray:
            return (
                <div>
                    {(value as INameColor[] | null)?.map((elem: any) => (
                        <div
                            className="tag-display-field tag-display-field__size"
                            style={{
                                backgroundColor: `${elem.color}`,
                                width: "fit-content",
                                lineHeight: "20px",
                            }}
                        >
                            <div style={{color: "rgba(0, 0, 0, 0.7)"}}>
                                {elem.name}
                            </div>
                        </div>
                    ))}
                </div>
            );
        case HistoryItemType.IPersonArray:
            return (
                <div style={{display: "flex"}}>
                    {(value as IPerson[] | null)?.map((elem) => (
                        <UserPopupWrapper userId={elem.id}>
                            <PersonLineDisplay
                                photoId={elem.photoId}
                                name={fullNameFormat(
                                    {
                                        name: elem.name,
                                        middlename: elem.middlename,
                                        surname: elem.surname,
                                    },
                                    "s M N"
                                )}

                            />
                        </UserPopupWrapper>
                    ))}
                </div>
            );
        case HistoryItemType.IPerson:
            const person = value as IPerson | null;
            return person ? (
                <div>
                    <UserPopupWrapper userId={person.id}>
                        <PersonLineDisplay
                            photoId={person.photoId}
                            name={fullNameFormat(
                                {
                                    name: person.name,
                                    middlename: person.middlename,
                                    surname: person.surname,
                                },
                                "s M N"
                            )}
                        />
                    </UserPopupWrapper>
                </div>
            ) : (
                <></>
            );
        case HistoryItemType.color:
            return (
                <div
                    className="color-picker-color-variant"
                    style={{
                        backgroundColor: (value as string | null) ?? "",
                        margin: "0px 10px",
                    }}
                ></div>
            );
        case HistoryItemType.markdown:
            const messageText = value as string | undefined;
            return showFullChanges ? (
                <div>
                    <div ref={refDiv}
                         className={`${
                            minimized ? "history-table-markdown-container" : ""
                        }`}
                    >
                        <div className="history-table-markdown-img">
                            <HtmlPreview content={messageText ?? ""}/>
                        </div>
                    </div>
                    {showButton ? (messageText && minimized !== undefined ? (
                        <p
                            className="history-table-hide-button"
                            onClick={onMinimizeButtonClick}
                        >
                            {minimized ? "Развернуть" : "Свернуть"}
                        </p>
                    ) : null) : null}
                </div>
            ) : (
                <div>...</div>
            );
        case HistoryItemType.IAccessArray:
            const accesses = value as IAccess[] | null;
            return showFullChanges ? (
                <div>
                    <div
                        className={`${
                            minimized ? "history-table-markdown-container" : ""
                        }`}
                    >
                        <div>
                            {accesses?.map((a) => (
                                <div
                                    style={
                                        !a?.granted
                                            ? {textDecoration: "line-through"}
                                            : {}
                                    }
                                >
                                    {a?.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    {accesses && minimized !== undefined ? (
                        <p
                            className="history-table-hide-button"
                            onClick={onMinimizeButtonClick}
                        >
                            {minimized ? "Развернуть" : "Свернуть"}
                        </p>
                    ) : null}
                </div>
            ) : (
                <div>...</div>
            );
        case HistoryItemType.IChecklistGroups: 
            const groups = value as IChecklistGroup[];
            return (
                <div>
                    {groups.map((g, indexGroup) => (
                        <div key={indexGroup}>
                        {g.name ? (<div>{g.name}:</div>) : null}
                        {g.checklists.map((c, index) => (
                            <div>
                                <span style={{pointerEvents: "none"}}>
                                    <Checkbox id={index}
                                        handleSelection={() => {}}
                                        checked={c.checked}
                                        color={'gray'}/>
                                </span>
                                <span style={{verticalAlign: "text-bottom"}}>
                                    {c.text}
                                </span>
                            </div>
                        ))}
                    </div>
                    ))}
                </div>
            )
        default:
            return <></>;
    }
};
