import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "./HistoryProject.css";
import "../../../globalstyles/dataTableStyles.css";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import { Context } from "../../../index";
import { IHasPermission } from "../../../models/IChekRole";
import {
    IGetProjectHistoryFilters,
    IProjectHistoryDisplay,
} from "../../../models/ProjectModels";
import { useParams } from "react-router-dom";
import HistoryTable from "../../Shared/HistoryTable/HistoryTable";
import Search from "../../UiLib/Search/Search";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import { IFilter, IFilterItem } from "../../../models/response/IFilterResponse";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import { initials } from "../../../helpers/Inicials";
import {
    HistoryEntities,
    ProjectHistoryEntities,
} from "../../../models/History/HistoryEntities";
import { ProjectHistoryActions } from "../../../models/History/HistoryActions";
import FilterContainer from "../../Shared/Filter/FilterContainer";
import {
    FilterOptions,
    INameCode,
    SelectedOptions,
} from "../../../models/IFilterModel";

export const HISTORY_PAGE_SIZE = 5;
const FULL_SCREEN_PAGE_SIZE = 20;

interface IHistoryProjectProps {
    records: IProjectHistoryDisplay[];
    loadPage: (
        skip: number,
        take: number,
        filters: IGetProjectHistoryFilters,
        append: boolean
    ) => Promise<boolean>;
    userAccess: IHasPermission[];
}

const History: React.FC<IHistoryProjectProps> = ({
    records,
    loadPage,
    userAccess,
}) => {
    const { id } = useParams();
    const [skip, setSkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    const { store } = useContext(Context);

    //Фильтры
    const [searchText, setSearchText] = useState<string>("");
    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [historyFilters, setHistoryFilters] = useState<FilterOptions>();
    const [selectedFilters, setSelectedFilters] = useState<SelectedOptions>({});

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);

    useEffect(() => {
        (async () => {
            let filters = await store.getDataForFilter({
                projectId: Number(id),
                responsible: true,
                status: false,
                typeTask: false,
                priority: false,
                role: false,
                fileType: false,
            });
            if (!filters) return;
            filters.entityType = Array.from(ProjectHistoryEntities).map(
                (entity) => {
                    return { name: entity[1], code: entity[0] };
                }
            );
            filters.actionType = Array.from(ProjectHistoryActions).map(
                (action) => {
                    return { name: action[1], code: action[0] };
                }
            );
            setHistoryFilters(filters);
        })();
    }, []);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectHistory") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                });
            }
        });
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            if (openOnFullWindow && records.length < FULL_SCREEN_PAGE_SIZE) {
                await loadRecords(0, false);
            }
        })();
    }, [openOnFullWindow]);

    useEffect(() => {
        setOpenOnFullWindow(false);
    }, [id]);

    useEffect(() => {
        (async () => {
            if (
                searchText.length === 0 &&
                Object.keys(selectedFilters).length === 0
            )
                await loadRecords(0, false);
        })();
    }, [searchText, selectedFilters]);

    const handleScrolledToBottom = async () => {
        if (haveMoreRecords) {
            await loadRecords(skip, true);
        }
    };

    const onCancelClick = async () => {
        setSelectedFilters({});
        setSearchText("");
    };

    async function onSearchSubmit() {
        await loadRecords(0, false);
    }

    const applyFilters = async () => {
        await loadRecords(0, false);
    };

    const loadRecords = async (from: number, append: boolean) => {
        const pageSize = openOnFullWindow
            ? FULL_SCREEN_PAGE_SIZE
            : HISTORY_PAGE_SIZE;
        const filters: IGetProjectHistoryFilters = {
            projectId: Number(id),
            dateFrom: selectedFilters.dates?.[0].toISOString(),
            dateTo: selectedFilters.dates?.[1]?.toISOString(),
            userIds: selectedFilters.responsibleIds,
            entityTypes: selectedFilters.entityTypes,
            actionTypes: selectedFilters.actionTypes,
            entityName: searchText,
        };
        setHaveMoreRecords(await loadPage(from, pageSize, filters, append));
        setSkip(from + pageSize);
    };

    return (
        <div
            id="main-history-widget"
            className={`project-history-widget ${
                openOnFullWindow
                    ? "widget_wrapper--full__window"
                    : "widget_wrapper"
            }`}
        >
            <div
                className={
                    openOnFullWindow ? "widget_wrapper-full__window--block" : ""
                }
            >
                <div className={"widgets_header--container"}>
                    {openOnFullWindow ? (
                        <div
                            onClick={() => setOpenOnFullWindow(false)}
                            className="widgets_header--open__block"
                        >
                            <img src={CloseBlockWindow} />
                        </div>
                    ) : (
                        <div
                            onClick={() => setOpenOnFullWindow(true)}
                            className="widgets_header--open__block"
                        >
                            <img src={OpenBlock} />
                        </div>
                    )}
                </div>
                <div className="widgets_header">
                    <div className="widjets_header_left">
                        <h2 className="widget-card-header-style">
                            История проекта
                        </h2>
                    </div>
                    <div className="widjets_header_rigth">
                        <Search
                            value={searchText}
                            onSubmitFunc={onSearchSubmit}
                            changeSearchValue={setSearchText}
                        />
                        <FilterContainer
                            filterCounter={filterCounter}
                            setFilterCounter={setFilterCounter}
                            selectedOptions={selectedFilters}
                            setSelectedOptions={setSelectedFilters}
                            filterOptions={historyFilters}
                            showResetButton={searchText.length > 0}
                            onReset={onCancelClick}
                            showApplyButton
                            onApply={applyFilters}
                            labels={{responsibleLabel: "Пользователь"}}
                        />
                    </div>
                </div>
                {viewHistory ? (
                    <HistoryTable
                        showFullChanges={openOnFullWindow}
                        records={records}
                        onScrolledToBottom={handleScrolledToBottom}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default observer(History);
