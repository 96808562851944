import React, {CSSProperties, FC} from 'react';
import "./ChekboxDefault.css"

interface IChekboxDefault {
    id?: string;
    callBack: () => void;
    style?: CSSProperties;
    classBlock?: string | string[];
    checked?: boolean;
    disabled?: boolean;
}

/**
 * Компонент для отображения стандартного чекбокса.
 *
 * @param {IChekboxDefault} props - Свойства компонента.
 * @param {string | undefined} props.id - Уникальный идентификатор чекбокса. Необязательный параметр.
 * @param {() => void} props.callBack - Функция обратного вызова, вызываемая при изменении состояния чекбокса.
 * @param {CSSProperties} [props.style] - Дополнительные стили, применяемые к контейнеру чекбокса.
 * @param {string | string[]} [props.classBlock] - Дополнительные CSS-классы, применяемые к контейнеру чекбокса.
 * @param {boolean} [props.checked] - Состояние чекбокса (активен/неактивен). Необязательный параметр.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли чекбокс. Если true, чекбокс недоступен для взаимодействия и отображается в заблокированном состоянии. Необязательный параметр.
 *
 * @returns {JSX.Element} - JSX-элемент, представляющий стандартный чекбокс.
 */
const ChekboxDefault: FC<IChekboxDefault> = ({id, callBack, style, classBlock, checked, disabled}) => {
    return (
        <label key={id} style={{...style}} className={`${classBlock} ui__component--checkbox-default`}>
            <input disabled={disabled} type="checkbox" onChange={callBack} checked={checked}/>
            <span></span>
        </label>
    );
};

export default ChekboxDefault;