import React, {FC, useContext, useEffect, useState} from 'react';
import "./SystemUsers.css"
import "../RolesSystem/RolesSystem.css"
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import EditIcon from "../../../assets/edit.svg";
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import {Context} from "../../../index";
import {
    IGetAllUsersWithSystemRolesChecked
} from "../../../models/IGetAllUsersWithSystemRoles";
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import ArrowUp from "../../../assets/arrow_up.svg";
import ArrowDown from "../../../assets/arrow_down.svg";
import PersonIcon from "../../../assets/avatar.svg"
import DeletedStatus from "../../../assets/addTeg.svg"
import {IGetAllRoles} from "../../../models/IGetAllRoles";
import Cross from "../../../assets/cancelGreey.svg";
import {IEditUser} from "../../../models/IEditUser";
import {ISystemDeletePerson} from "../../../models/ISystemDeletePerson";
import {ISystemInvitePerson} from "../../../models/ISystemInvitePerson";
import {InputText} from "primereact/inputtext";
import {IGetRole} from "../../../models/IGetRole";
import {fullNameFormat} from '../../../helpers/Inicials';
import {IUserStatus} from "../../../models/response/IUserStatusResponse";
import {AppContext} from "../../../App";
import Avatar from '../../Shared/Avatar/Avatar';
import { getFileUrlFromId } from '../../../helpers/getFileUrlFromId';
import { dateToNiceString } from '../../../helpers/dateToNiceString';
import Loader from "../../Shared/Loader/Loader";

interface ISystemUsersSort {
    type?: SortType
    asc: boolean
    status?: IUserStatus
}

enum SortType  {
    name,
    role,
    dateEntry,
    dateUpdate,
    status
}

const SystemUsers: FC = (index) => {
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);
    const [allUsers, setAllUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const [usersForView, setUsersForView] = useState<IGetAllUsersWithSystemRolesChecked[]>([]);
    const [allSaveUsers, setAllSaveUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const [allRoles, setAllRoles] = useState<IGetRole[] | undefined>([]);
    const [cheksTrue, setCheksTrue] = useState<boolean>(false);
    const [editShow, setEditShow] = useState<boolean>(false);
    const [editWidgetShow, setEditWidgetShow] = useState<boolean>(false);
    const [currentEditUser, setCurrentEditUser] = useState<{ id: number,
        systemRole: {
            id: number,
            name: string},
        status: IUserStatus,
        personRolesId: number } | undefined>();
    const [currentSort, setCurrentSort] = useState<ISystemUsersSort>({type: undefined, asc: true});
    const [showDeleteMessage, setShowDeleteMessage] = useState<boolean>(false);
    const [deletePersonCount, setDeletePersonCount] = useState<number>(0);
    const [showAddWidget, setShowAddWidget] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [surname, setSurname] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();
    const [roleNewUser, setRoleNewUser] = useState<number>(0);
    const [roleNewUserError, setRoleNewUserError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [surnameError, setSurnameError] = useState<string>();
    const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
    const [userStatuses, setUserStatuses] = useState<IUserStatus[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    // const statusAll = ["Активен","Ждет активацию","Приглашен","Заблокирован"];

    useEffect(() => {
        getUsers();
        getUserStatuses()
    }, []);

    useEffect(() => {
        if (!allUsers)
            return;
        let data: IGetAllUsersWithSystemRolesChecked[] = structuredClone(allUsers);
        if (currentSort.type === SortType.dateUpdate) {
            setUsersForView(data.sort((a, b) => {
                const dateA = new Date(a.user.dateUpdate);
                const dateB = new Date(b.user.dateUpdate);
                if (dateA < dateB)
                    return currentSort.asc ? -1 : 1;
                else if (dateA > dateB)
                    return currentSort.asc ? 1 : -1;
                else
                    return 0;
            }));
        } else if (currentSort.type === SortType.dateEntry) {
            setUsersForView(data.sort((a, b) => {
                const dateA = a.user.dateLastIn !== "" ? new Date(a.user.dateLastIn) : null;
                const dateB = b.user.dateLastIn !== "" ? new Date(b.user.dateLastIn) : null;

                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return 1;
                if (dateB === null) return -1;

                if (dateA < dateB)
                    return currentSort.asc ? -1 : 1;
                else if (dateA > dateB)
                    return currentSort.asc ? 1 : -1;
                else
                    return 0;
            }));
        } else if (currentSort.type === SortType.status) {
            const withStatus = data.filter(user => user.user.status.code === currentSort.status?.code); //Фильтр по коду будет
            const withoutStatus = data.filter(user => user.user.status.code !== currentSort.status?.code); //Фильтр по коду будет
            const sortedUsers = [...withStatus, ...withoutStatus];
            setUsersForView(sortedUsers);
        } else if (currentSort.type === SortType.role) {
            setUsersForView(data.sort((a, b) => {
                if (a.user.systemRole.name < b.user.systemRole.name)
                    return currentSort.asc ? -1 : 1;
                else if (a.user.systemRole.name > b.user.systemRole.name)
                    return currentSort.asc ? 1 : -1;
                else
                    return 0;
            }));
        } else if (currentSort.type === SortType.name) {
            setUsersForView(data.sort((a, b) => {
                if (a.user.user.name < b.user.user.name)
                    return currentSort.asc ? -1 : 1;
                else if (a.user.user.name > b.user.user.name)
                    return currentSort.asc ? 1 : -1;
                else
                    return 0;
            }));
        } else if (currentSort.type === undefined) {
            setUsersForView(allUsers);
        }
            
    }, [currentSort, allUsers])

    const setSort = (newSort: SortType) => {
        if (currentSort.type === newSort && !currentSort.asc)
            setCurrentSort({type: undefined, asc: true});
        else
            setCurrentSort({type: newSort, asc: newSort === currentSort.type ? !currentSort.asc : true});
    }

    const setStatusSort = (statusCode: string | undefined) => {
        setCurrentSort({type: SortType.status, status: userStatuses.find(xx=> xx.code == statusCode), asc: true});
    }

    function getUserStatuses(){
        (async () => {
            try {
                //Ну если статусы не подгрузятся будет гг
                const statuses = await store.getUserAllStatuses()

                if(statuses?.length){
                    setUserStatuses(statuses);
                }
            } catch (err) {
                console.log(err)
            }
        })();
    }

    function getUsers() {
        (async () => {
            try {
                const users = await store.getAllUsersWithSystemRoles(undefined);
                if (!users?.length) {
                    return showToast("У вас нет доступа к списку пользователей!");
                }
                if (users) {
                    let data: IGetAllUsersWithSystemRolesChecked[] = [];
                    users.map((xx) => {
                        data.push({
                            chek: false,
                            user: xx
                        })
                    });
                    setAllUsers(data);
                }
                const roles = await store.getNameAllRoles();
                setAllRoles(roles);
            } catch(err) {
                console.log(err)
            }
        })();
    }

    function viewBlock(idView: string, imageOne: string, imageTwo: string) {
        const element = document.getElementById(idView);
        const elementOne = document.getElementById(imageOne);
        const elementTwo = document.getElementById(imageTwo);

        if (element) {
            if (element.style.display === 'none') {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${idView} не найден.`);
        }

        if (elementOne) {
            if (elementOne.style.display === 'none') {
                elementOne.style.display = 'block';
            } else {
                elementOne.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementOne} не найден.`);
        }

        if (elementTwo) {
            if (elementTwo.style.display === 'none') {
                elementTwo.style.display = 'block';
            } else {
                elementTwo.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementTwo} не найден.`);
        }
    };

    function showDeleteFunc() {
        let deletePerson = allUsers?.filter(xx => xx.chek == true).length;
        setCheksTrue(false);
        setEditShow(false);
        setDeletePersonCount(deletePerson ? deletePerson : 0);
        setShowDeleteMessage(true);
    };

    function deletePersonFunc() {
        let getPersonToBlock = allUsers?.filter(xx => xx.chek == true);
        if (getPersonToBlock) {
            (async () => {
                try {
                    const data: ISystemDeletePerson[] = getPersonToBlock.map(xx => {
                        return {
                            userId: xx.user.id
                        }
                    });
                    const delPers = await store.deleteSystemPerson(data);
                    getUsers();
                } catch(err) {
                    console.log(err)
                }
            })();
        }
        setShowDeleteMessage(false);
    };    

    function closeAdd() {
        setFirstName("");
        setSurname("");
        setEmail("");
        setRoleNewUser(0);
        setNameError("");
        setSurnameError("");
        setMiddleName("");
        setEmailError("");
        setRoleNewUserError("");
        setShowAddWidget(false);
    };

    function addPersonFunc() {
        setShowLoader(true);

        if (firstName?.length != 0 && surname?.length != 0 && email?.length != 0 && roleNewUser != 0  &&
            (emailError?.length == 0 || emailError == undefined) && (surnameError?.length == 0  || surnameError == undefined ) &&
            (nameError?.length == 0  || nameError == undefined )) {
            setNameError("");
            setSurnameError("");
            setEmailError("");
            setRoleNewUserError("");

            let newUser: ISystemInvitePerson = {
                name: firstName!,
                surname: surname!,
                middleName: middleName!,
                email: email!,
                roleId: roleNewUser!
            };

            // pmosolov@bpmlab.ru
            (async () => {
                try {
                    let addNewUser = await store.systemInviteUser(newUser);
                    getUsers();
                    setShowLoader(false);
                } catch(err) {
                    console.log(err)
                }
            })();

            setFirstName("");
            setSurname("");
            setMiddleName("");
            setEmail("");
            setRoleNewUser(0);
            setShowAddWidget(false);
        } else {
            nameError?.length == 0 ? setNameError("Заполните имя") : setNameError("");
            surnameError?.length == 0 ? setSurnameError("Заполните фамилию") : setSurnameError("");
            emailError?.length == 0 ? setEmailError("Введите email") : setEmailError("");
            roleNewUser == 0 ? setRoleNewUserError("Выберите роль") : setRoleNewUserError("");
        }
    }

    function saveUser() {
  
        if (currentEditUser) {
            const editUser: IEditUser = {
                id: currentEditUser.id,
                statusCode: currentEditUser.status.code, //Тут стал код
                roleId: currentEditUser.systemRole.id,
                personRolesId: currentEditUser.personRolesId
            };

            (async () => {
                try {
                    const data = await store.editUser(editUser);
                    getUsers();
                } catch(err) {
                    console.log(err)
                }
            })();
        }

        setCheksTrue(false);
        setEditShow(false);
        setCurrentEditUser(undefined);
        setAllSaveUsers(undefined);
        setEditWidgetShow(false);
    }

    function editPerson() {
        let currentUser = allUsers?.find(xx => xx.chek == true);
        if (currentUser) {
            const allSaveUsersCopy = JSON.parse(JSON.stringify(allUsers));
            setAllSaveUsers(allSaveUsersCopy);
            setCurrentEditUser({
                id: currentUser!.user.id,
                systemRole: {
                    id: currentUser!.user.systemRole.id,
                    name: currentUser!.user.systemRole.name
                },
                status: currentUser!.user.status,
                personRolesId: currentUser!.user.personRolesId
            })
            setEditWidgetShow(true);
        }
    };

    const validateName = (): boolean => {
        let nameErr = undefined;

        if (!firstName || firstName.length === 0) {
            nameErr = "Заполните имя";
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    };

    const validateSurname = (): boolean => {
        let surnameErr = undefined;

        if (!surname || surname.length === 0) {
            surnameErr = "Заполните фамилию";
        }

        setSurnameError(surnameErr);
        return surnameErr !== undefined;
    };

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }

        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    function addPerson() {

    };

    function closeFunc() {
        // setCheksTrue(false);
        // setEditShow(false);
        setCurrentEditUser(undefined);
        setAllUsers(allSaveUsers);
        setAllSaveUsers(undefined);
        setEditWidgetShow(false);
    }

    function changeRole(idRole: number, idPerson: number) {
        if (allUsers && allRoles) {
            const userToUpdate = allUsers.find(user => user.user.id === idPerson);
            if (userToUpdate) {
                const newRole = allRoles.find(role => role.id === idRole);

                if (newRole) {
                    userToUpdate.user.systemRole = { id: idRole, name: newRole.roleName };
                    // setAllUsers([...allUsers]);

                    if (currentEditUser) {
                        let data = currentEditUser;
                        data.systemRole.name = newRole.roleName;
                        data.systemRole.id = newRole.id;

                        setCurrentEditUser(data);
                    }

                    const editUser: IEditUser = {
                        id: userToUpdate.user.id,
                        statusCode: userToUpdate.user.status.code, //Тут код в запросе
                        roleId: userToUpdate.user.systemRole.id,
                        personRolesId: userToUpdate.user.personRolesId
                    };

                    (async () => {
                        try {
                            const data = await store.editUser(editUser);
                            getUsers();
                        } catch(err) {
                            console.log(err)
                        }
                    })();

                } else {
                    console.error(`Role with ID ${idRole} not found.`);
                }
            } else {
                console.error(`User with ID ${idPerson} not found.`);
            }
        } else {
            console.error("Either allUsers or allRoles is undefined.");
        }
    }

    function dateToString(dateISO: string) {
        return dateISO ? dateToNiceString(new Date(dateISO), "dd.MM.yyyy в hh:mm") : "";
    }

    function changeStatus(chek: boolean) {
        let findUser = allUsers!.find(xx => xx.user.id == currentEditUser?.id)
        let blocked = userStatuses.find(xx => xx.code == "Blocked");
        let active = userStatuses.find(xx => xx.code == "Active");
        let pending = userStatuses.find(xx => xx.code == "PendingActivation");
        let invited = userStatuses.find(xx => xx.code == "Invited");

        if(!blocked || !active || !pending || !invited){
            return
        }
        let newStatus: IUserStatus | undefined = undefined
        if(currentEditUser?.status.code == "Blocked"){
            newStatus = active
        }
        if(currentEditUser?.status.code == "Active"){
            newStatus = blocked
        }
        if(currentEditUser?.status.code == "Invited"){
            newStatus = blocked
        }
        if(currentEditUser?.status.code == "PendingActivation"){
            newStatus = active
        }

        let userNewState = JSON.parse(JSON.stringify(currentEditUser))
        userNewState.status = newStatus
        // const updatedUser: { id: number, systemRole: { id: number, name: string }, status: { code: string, name: string, color: string}, personRolesId: number } = {
        //     id: currentEditUser!.id,
        //     systemRole: {
        //         id: currentEditUser!.systemRole.id,
        //         name: currentEditUser!.systemRole.name
        //     },
        //     personRolesId: findUser!.user.personRolesId
        // };


        setCurrentEditUser(userNewState);
    }

    function changeChek(idUser: number, chek: boolean) {
        const newData = allUsers?.map((xx) => {
            if (xx.user.id === idUser) {
                return {
                    ...xx,
                    chek: chek,
                };
            }
            return xx;
        });

        let getCountChek = newData!.filter(xx => xx.chek == true).length;
        if (getCountChek == 0) {
            setCheksTrue(false)
            setEditShow(false);
        } else if (getCountChek  == 1) {
            setCheksTrue(true);
            setEditShow(true);
        } else {
            setEditShow(false);
            setCheksTrue(true)
        }
        setAllUsers(newData);
    }

    function resetChecks() {
        const newData = allUsers?.map((user) => {
            user.chek = false;
            return user;
        });
        setCheksTrue(false)
        setEditShow(false);
        setAllUsers(newData);
    }

    const handleRoleChange = (block: string, idRole: number, idUser: number) => {
        changeRole(idRole, idUser);
        viewBlock(block, "SystemGroupArrowSub" + String(idUser), "SystemGroupArrowRight" + String(idUser));
    };

    const handleRoleChangeWidget = (block: string, arrowSub: string, arrowRight: string, idRole: number, idUser: number) => {
        changeRole(idRole, idUser);
        viewBlock(block, arrowSub, arrowRight);
    };

    const handleRoleChangeRoleNewPerson = (block: string, arrowSub: string, arrowRight: string, idRole: number) => {
        let findRole = allRoles?.find(xx => xx.id == idRole);
        setRoleNewUser( findRole ? findRole.id : 0)
        viewBlock(block, arrowSub, arrowRight);
    };

    const trashCanIcon = () => {
        if(allUsers?.some(user =>
            user.chek &&
            (user.user.status.code == "Active" ||
            user.user.status.code == "Blocked"))){
            return(<></>)
        } else {
            return(
                <BtnDelete onClickFunc={showDeleteFunc} permission={true}/>
            )
        }
    }

    return (
        <div className="system--users__component">
            {showLoader ?
                <Loader/>
            :
                null
            }
            {showAddWidget ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__add">
                        <div className="system--users__component--widget__add--header">
                            <div>
                                Новый пользователь
                            </div>
                            <div className='add__edit--role__component--header__img'>
                                <img src={Cross} onClick={()=>(closeAdd())}/>
                            </div>
                        </div>
                        <div className='system--users__component--widget__add--header__block'>
                            <div className='system--users__component--widget__add--header__text'>
                                Имя
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_first_name"
                                    value={firstName}
                                    placeholder="Имя"
                                    onBlur={validateName}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setFirstName(e.target.value)}
                                />
                                {nameError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">{nameError}</p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Фамилия
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_surname"
                                    value={surname}
                                    placeholder="Фамилия"
                                    onBlur={validateSurname}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setSurname(e.target.value)}
                                />
                                {surnameError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">
                                        {surnameError}
                                    </p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Отчество
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="middle_name"
                                    value={middleName}
                                    placeholder="Отчество"
                                    autoComplete="off"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setMiddleName(e.target.value)}
                                />
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Почта
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_email"
                                    value={email}
                                    placeholder="email"
                                    onBlur={validateEmail}
                                    autoComplete="email"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setEmail(e.target.value)}
                                />
                                {emailError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">{emailError}</p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Роль
                            </div>
                            <div className="system--users__component--widget__add--header__role">
                                <div className='system--users__component--widget__Edit--block__role'>
                                    <div className='system--users__component--widget__Edit--block__role--text'>
                                        {roleNewUser ? allRoles!.find(xx => xx.id == roleNewUser)!.roleName : "Выберите роль"}
                                    </div>
                                    <button
                                        onClick={() => (viewBlock("WidgetRoleAdd", "WidgetRoleArrowSubAdd", "WidgetRoleArrowRightAdd"))}
                                        className='add__edit--role__component__widget--button__show--more'>
                                        <img src={ArrowRight} id={"WidgetRoleArrowSubAdd"}
                                             style={{display: 'none'}}/>
                                        <img src={ArrowSub} id={"WidgetRoleArrowRightAdd"}/>
                                    </button>
                                </div>
                                <div className='system--users__component--table__content--td__role--choise'
                                     id={"WidgetRoleAdd"} style={{display: "none"}}>
                                    <div className='system--users__component--widget__Edit--block__role--absolut'
                                         style={{background: "white"}}>
                                        {allRoles && allRoles.map((role) => (
                                            <div
                                                className='system--users__component--table__content--td__role--choise__one'
                                                onClick={() => (handleRoleChangeRoleNewPerson("WidgetRoleAdd", "WidgetRoleArrowSubAdd", "WidgetRoleArrowRightAdd", role.id))}>
                                                {role.roleName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {roleNewUserError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">
                                        {roleNewUserError}
                                    </p>
                                ) : null}
                            </div>
                            <div>
                                <div className="system--users__component--widget__button">
                                    <button onClick={() => (closeAdd())}
                                            className='add__edit--role__component--buttons__cansel'>
                                        Отмена
                                    </button>
                                    <button onClick={addPersonFunc} className='add__edit--role__component--buttons__create'>
                                        Подтвердить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            {showDeleteMessage ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__delete">
                        <div>
                            Вы уверены, что хотите
                            заблокировать {deletePersonCount == 1 ? "выбранного пользователя" : "выбранных пользователей"}?
                        </div>
                        <div>
                            <div className="system--users__component--widget__button">
                                <button onClick={() => {
                                    setShowDeleteMessage(false);
                                    resetChecks();
                                }}
                                        className='add__edit--role__component--buttons__cansel'>
                                    Отмена
                                </button>
                                <button onClick={deletePersonFunc}
                                        className='add__edit--role__component--buttons__create'>
                                    Подтвердить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            {editWidgetShow ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__Edit--block">
                        <div className="system--users__component--widget__Edit--block__header">
                            <div className="system--users__component--widget__Edit--block__header--name">
                                Редактирование пользователя
                            </div>
                            <div className='add__edit--role__component--header__img'>
                                <img src={Cross} onClick={closeFunc}/>
                            </div>
                        </div>
                        <div className='system--users__component--widget__Edit--block__role--new'>
                            <div className='system--users__component--widget__Edit--block__role--name__h'>
                                Системная роль
                            </div>
                            <div className="system--users__component--widget__Edit--block__right">
                                <div className='system--users__component--widget__Edit--block__role--new'>
                                    <div>
                                        {currentEditUser?.systemRole.name}
                                    </div>
                                    <button
                                        onClick={() => (viewBlock("WidgetRole", "WidgetRoleArrowSub", "WidgetRoleArrowRight"))}
                                        className='add__edit--role__component__widget--button__show--more'>
                                        <img src={ArrowRight} id={"WidgetRoleArrowSub"}
                                             style={{display: 'none'}}/>
                                        <img src={ArrowSub} id={"WidgetRoleArrowRight"}/>
                                    </button>
                                </div>
                                <div className='system--users__component--table__content--td__role--choise' id={"WidgetRole"} style={{display: "none"}}>
                                    <div className='system--users__component--widget__Edit--block__role--absolut' style={{background: "white"}}>
                                        {allRoles && allRoles.map((role) => (
                                            <div className='system--users__component--table__content--td__role--choise__one'
                                                 onClick={() => (handleRoleChangeWidget("WidgetRole", "WidgetRoleArrowSub", "WidgetRoleArrowRight", role.id, currentEditUser!.id))}>
                                                {role.roleName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='system--users__component--widget__Edit--block__role--new'>
                            <div className='system--users__component--widget__Edit--block__role--name__h'>
                                Статус
                            </div>
                            <div className="system--users__component--widget__Edit--block__right">
                                <div className="system--users__component--widget__Edit--block__status">
                                    <div className="system--users__component--widget__Edit--block__status--width">
                                        <div
                                            className='system--users__component--table__content--td_status system--users__component--widget__Edit--block__status--shoise'
                                            style={{
                                                border: "1px solid " + currentEditUser!.status.color,
                                                color: currentEditUser!.status.color,
                                            }}>
                                            {currentEditUser?.status?.name}
                                        </div>
                                    </div>
                                </div>
                                {currentEditUser!.status.code != "Blocked" && currentEditUser!.status.code != "PendingActivation" ?
                                    <div onClick={() => (changeStatus(true))} className="system--users__component--widget__Edit--block__style--action">
                                        <img src={Cross}/>
                                    </div>
                                :
                                    <div onClick={() => (changeStatus(false))} className="system--users__component--widget__Edit--block__style--action__aprove">
                                        <img src={DeletedStatus}/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="system--users__component--widget__button">
                            <button onClick={closeFunc} className='add__edit--role__component--buttons__cansel'>
                                Отмена
                            </button>
                            <button onClick={saveUser} className='add__edit--role__component--buttons__create'>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <div className='roles--system__component__buttons--block'>
                <div className='roles--system__component__buttons--block__size'>
                    {cheksTrue ?
                        <div className='roles--system__component__buttons--block__size--hiden'>
                            {trashCanIcon()}
                            {editShow ?
                                <button className='roles--system__component__buttons--block__edit' onClick={editPerson}>
                                    <img src={EditIcon}/>
                                </button>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    <BtnAdd onClickFunc={()=>(setShowAddWidget(true))} type='add' permission={true}/>
                </div>
            </div>
            <div>
                <table className="system--users__component--table">
                    <tr className="system--users__component--table__tr">
                        <th className="system--users__component--table__content--td__chekbox"></th>
                        <th style={currentSort.type === SortType.name ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div className='system--users__component--table__content--th__datentry'
                                onClick={() => setSort(SortType.name)}>
                                Пользователь
                                {currentSort.type === SortType.name && currentSort.asc ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowUp}/>
                                    </div>
                                : currentSort.type === SortType.name && !currentSort.asc ?
                                <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowDown}/>
                                    </div>
                                : <></>
                                }
                            </div>
                        </th>
                        <th style={currentSort.type === SortType.role ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div className='system--users__component--table__content--th__datentry'
                                onClick={() => setSort(SortType.role)}>
                                Системная роль
                                {currentSort.type === SortType.role && currentSort.asc ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowUp}/>
                                    </div>
                                : currentSort.type === SortType.role && !currentSort.asc ?
                                <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowDown}/>
                                    </div>
                                : <></>
                                }
                            </div>
                        </th>
                        <th style={currentSort.type === SortType.dateEntry ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div onClick={() => setSort(SortType.dateEntry)} className='system--users__component--table__content--th__datentry'>
                                <div>
                                    Дата последнего входа
                                </div>
                                {currentSort.type === SortType.dateEntry && currentSort.asc ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowUp}/>
                                    </div>
                                : currentSort.type === SortType.dateEntry && !currentSort.asc ?
                                <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowDown}/>
                                    </div>
                                : <></>
                                }
                            </div>
                        </th>
                        <th style={currentSort.type === SortType.dateUpdate ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div onClick={() => setSort(SortType.dateUpdate)}  className='system--users__component--table__content--th__datentry'>
                                <div>
                                    Дата обновления
                                </div>
                                {currentSort.type === SortType.dateUpdate && currentSort.asc ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowUp}/>
                                    </div>
                                    : currentSort.type === SortType.dateUpdate && !currentSort.asc ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                            <img src={ArrowDown}/>
                                        </div>
                                    : <></>
                                }
                            </div>
                        </th>
                        <th>
                            <div onClick={() => (setShowStatusDropdown(!showStatusDropdown))} className='system--users__component--table__content--th__status'>
                                <div className='system--users__component--table__content--th__status--header'>
                                    {currentSort.type === SortType.status && currentSort.status !== undefined && currentSort.status ? currentSort.status.name : "Статус"}
                                </div>
                                {showStatusDropdown ?
                                    <div className='system--users__component--table__content--td__status--choise'>
                                        <div className='system--users__component--widget__Edit--block__status--absolut'>
                                            {userStatuses.map(xx => (
                                                <div onClick={() => (setStatusSort(xx.code), setShowStatusDropdown(!showStatusDropdown))}
                                                     className='system--users__component--table__content--td__role--choise__one'>
                                                    {xx.name}
                                                </div>
                                            ))}
                                            <div onClick={() => (setStatusSort(undefined), setShowStatusDropdown(!showStatusDropdown))}
                                                 className='system--users__component--table__content--td__role--choise__one--clean'>
                                                Сбросить
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </th>
                    </tr>
                    {usersForView && usersForView?.map((record) => (
                        <tr className="system--users__component--table__content--tr">
                            <td className="system--users__component--table__content--td__chekbox">
                                <input type='checkbox' checked={record.chek}
                                       onClick={() => (changeChek(record.user.id, !record.chek))}/>
                            </td>
                            <td style={currentSort.type === SortType.name ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div className="system--users__component--table__content--td__block--img">
                                    <div className="system--users__component--table__content--td__img">
                                        <Avatar size="s" url={getFileUrlFromId(record.user.user.photoId)}/>
                                        {/* <img src={record.user.user.photo ? record.user.user.photo : PersonIcon}/> */}
                                    </div>
                                    <div className='system--users__component--table__content--td__block--name__email'>
                                        <div className='system--users__component--table__content--td__block--name'>
                                            {fullNameFormat(record.user.user, 's N M')}
                                        </div>
                                        <div className='system--users__component--table__content--td__block--email'>
                                            {record.user.user.email}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td  className='system--users__component--table__content--td__block--role'
                                style={currentSort.type === SortType.role ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div className='system--users__component--table__content--td__role--block'>
                                    <div className='system--users__component--table__content--td__role'>
                                        <div className='system--users__component--table__content--td__role--name'>
                                            {record.user.systemRole.name}
                                        </div>
                                        <button
                                            onClick={() => (viewBlock("SystemRole-" + String(record?.user.id), "SystemGroupArrowSub" + String(record?.user.id), "SystemGroupArrowRight" + String(record?.user.id)))}
                                            className='add__edit--role__component--button__show--more'>
                                            <img src={ArrowRight} id={"SystemGroupArrowSub" + String(record?.user.id)}
                                                style={{display: 'none'}}/>
                                            <img src={ArrowSub} id={"SystemGroupArrowRight" + String(record?.user.id)}/>
                                        </button>
                                    </div>
                                    <div className='system--users__component--table__content--td__role--choise' style={{display: "none"}} id={"SystemRole-" + String(record?.user.id)}>
                                        <div className='system--users__component--widget__Edit--block__role--absolut' style={{background: "white"}}>
                                            {allRoles && allRoles.map((role) => (
                                                <div className='system--users__component--table__content--td__role--choise__one'
                                                     onClick={() => (handleRoleChange("SystemRole-" + String(record?.user.id), role.id, record?.user.id))}>
                                                    {role.roleName}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style={currentSort.type === SortType.dateEntry ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div className='system--users__component--table__content--td__date'>
                                    {dateToString(record.user.dateLastIn)}
                                </div>
                            </td>
                            <td style={currentSort.type === SortType.dateUpdate ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div  className='system--users__component--table__content--td__date'>
                                    {dateToString(record.user.dateUpdate)}
                                </div>
                            </td>
                            <td>
                                <div className='system--users__component--table__content--td_status' style={{border: "1px solid " + record.user.status.color, color: record.user.status.color}}>
                                    {record.user.status.name}
                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default SystemUsers;