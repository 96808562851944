import $api from "..";
import {AxiosResponse} from "axios";
import {
    IBurnDownInfo,
    IBurnDownRequest,
    IGetStatisticsData,
    IOverdueTask,
    IPieChartPart,
    IGetTaskData
} from "../component/GraphRenderer/GraphTypes";
import {IProjectProgressInfo} from "../models/ProjectModels";

export default class StatisticsService {
    static async getBurndown(body: IBurnDownRequest): Promise<AxiosResponse<IBurnDownInfo[]>> {
        return $api.post<IBurnDownInfo[]>('/statistics/getBurnDown', body);
    }
    static async getBurnup(body: IBurnDownRequest): Promise<AxiosResponse<IBurnDownInfo[]>> {
        return $api.post<IBurnDownInfo[]>('/statistics/getBurnUp', body);
    }
    static async reportGetOverdueTasks(body: IBurnDownRequest): Promise<AxiosResponse<IOverdueTask[]>> {
        return $api.post<IOverdueTask[]>('/statistics/getOverdueTasks', body);
    }
    static async reportTasksStatus(body: IBurnDownRequest): Promise<AxiosResponse<IPieChartPart[]>> {
        return $api.post<IPieChartPart[]>('/statistics/getTasksStatus', body);
    }
    static async getStatisticsData(body: IGetStatisticsData): Promise<AxiosResponse<IProjectProgressInfo>> {
        return $api.post<IProjectProgressInfo>('/Project/getStatisticData', body);
    }

    static async getTestData(body: IBurnDownRequest): Promise<AxiosResponse<IGetTaskData[]>> {
        return $api.post<IGetTaskData[]>('/statistics/getTestData', body);
    }
}