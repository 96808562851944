import React, { FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {IGetRole, IGetRoleCheked} from '../../../models/IGetRole';
import "./RolesSystem.css"
import BtnDelete from '../../buttons/btnDelete.component/BtnDelete';
import BtnAdd from '../../buttons/btnAdd.component/BtnAdd';
import EditIcon from '../../../assets/edit.svg'
import ArrowDown from '../../../assets/arrowDown.svg'
import {IGetOneRole} from '../../../models/IGetOneRole';
import AddEditRole from '../AddEditRole/AddEditRole';
import {AppContext} from "../../../App";

const RolesSystem: FC = () => {
    const { store } = useContext(Context);
    const [allSystemRoles, setAllSystemRoles] = useState<IGetRoleCheked[] | undefined>([]);
    const [allProjectRoles, setAllProjectRoles] = useState<IGetRoleCheked[] | undefined>([]);
    const [curentRole, setCurentRole] = useState<IGetOneRole | undefined>();
    const [showAllSystemRoles, setShowAllSystemRoles] = useState<boolean>(true);
    const [showAllProjectRoles, setShowAllProjectRoles] = useState<boolean>(true);
    const [cheksTrue, setCheksTrue] = useState<boolean>(false);
    const [editShow, setEditShow] = useState<boolean>(false);
    const [addEditWidgetShow, setAddEditWidgetShow] = useState<boolean>(false);
    const [addWidgetShow, setAddWidgetShow] = useState<boolean>(false);
    const [chooseSystemRole, setChooseSystemRole] = useState<IGetRoleCheked>();
    const [chooseProjectRole, setChooseProjectRole] = useState<IGetRoleCheked>();
    const { showToast } = useContext(AppContext);

    function chekRole(currentRole: IGetRoleCheked) {
        const newSystemRoles = allSystemRoles?.map((xx) => {
            if (xx.role.id === currentRole.role.id) {
              return {...xx, chek: !xx.chek};
            } else {
              return xx;
            }
        });
        setAllSystemRoles(newSystemRoles)
        
        const newProjectRoles = allProjectRoles?.map((xx) => {
            if (xx.role.id === currentRole.role.id) {
                return {...xx, chek: !xx.chek};
            } else {
                return xx;
            }
        });
        setAllProjectRoles(newProjectRoles)

        newSystemRoles?.find(xx => xx.chek == true) || 
        newProjectRoles?.find(xx => xx.chek == true) ?
            setCheksTrue(true) : setCheksTrue(false);
        
        newSystemRoles?.filter(xx => xx.chek == true)?.length! > 1 ||
        newProjectRoles?.filter(xx => xx.chek == true)?.length! > 1 || 
        newProjectRoles?.filter(xx => xx.chek == true)?.length! + newSystemRoles?.filter(xx => xx.chek == true)?.length! > 1 ?
        setEditShow(false) : setEditShow(true);
    }

    function addRole() {
        (async () => {
            try {
                const allFunc = await store.getAllAvailablePermissions();
                if (allFunc) {
                    let newRole: IGetOneRole = {
                        id: 0,
                        roleName: "",
                        requiredProject: 0,
                        systemFunctions: [],
                        functionGroups: allFunc.map((group) => {
                            return {
                                id: group.id,
                                nameGroup: group.nameGroup,
                                availableFunctions: group.availableFunctions.map((xx) => {
                                    return {
                                        id: xx.id,
                                        nameFunction: xx.nameFunction,
                                        functionCode: xx.functionCode,
                                        availablePermissions: xx.availablePermissions
                                    };
                                })
                            }
                        })
                    }

                    setCurentRole(newRole);
                    setAddWidgetShow(true)
                    getData();
                }
            } catch(err) {
                console.log(err)
            }
        })();
    };

    function deleteRole() {
        let roleSystem = allSystemRoles?.filter(xx => xx.chek == true)
        let roleProject = allProjectRoles?.filter(xx => xx.chek == true)

        if (roleSystem || roleProject) {
            if (roleSystem?.find(xx => xx.role.id == chooseSystemRole?.role.id)) {
                showToast('Вы не можете удалить системную роль пока она привязана на вкладке "Безопасность"!')
                return;
            }
            if (roleProject?.find(xx => xx.role.id == chooseProjectRole?.role.id)) {
                showToast('Вы не можете удалить проектную роль пока она привязана на вкладке "Безопасность"!')
                return;
            }
            if (roleSystem!.length + roleProject!.length > 1) {
                let allDeleted: { id: number[] } = {id: []};

                roleSystem?.length ? allDeleted.id.push(...roleSystem.map(xx => xx.role.id)) : allDeleted.id.push();
                roleProject?.length ? allDeleted.id.push(...roleProject.map(xx => xx.role.id)) : allDeleted.id.push();

                (async () => {
                    try {
                        await store.deleteRoles(allDeleted);
                        getData();
                    } catch (err) {
                        console.log(err)
                    }
                })();
            } else {
                let idDeletedRole: number = 0;
                if (roleSystem?.length) {
                    idDeletedRole  = roleSystem![0].role.id;
                } else {
                    idDeletedRole = roleProject![0].role.id;
                }

                (async () => {
                    try {
                        if (idDeletedRole != 0) {
                            await store.deleteRole(idDeletedRole);
                            getData();
                        }
                    } catch (err) {
                        console.log(err)
                    }
                })();
            }
        }

        setCheksTrue(false);
        setEditShow(false);
    };

    function editRole() {
        (async () => {
            try {
                let roleSystem = allSystemRoles?.find(xx => xx.chek == true)
                let roleProject = allProjectRoles?.find(xx => xx.chek == true)
                if (roleSystem) {
                    const roleData = await store.getRole(roleSystem.role.id);
                    setCurentRole(roleData);
                    setAddEditWidgetShow(true);
                }
                if (roleProject) {
                    const roleData = await store.getRole(roleProject.role.id);
                    setCurentRole(roleData);
                    setAddEditWidgetShow(true);
                }
            } catch(err) {
                console.log(err)
            }    
        })();
    }

    function getData() {
        (async () => {
            const dataRoles = await store.getAllRoles();
            const systemRoles: IGetRoleCheked[] = [];
            const projectRoles: IGetRoleCheked[] = [];



            dataRoles?.filter(xx => xx.requiredProject == 0).forEach(((yy) => {
                systemRoles.push({role: yy, chek: false})
            }))

            dataRoles?.filter(xx => xx.requiredProject != 0).forEach(((yy) => {
                projectRoles.push({role: yy, chek: false})
            }))

            const chooseRoles = await store.getSystemSettings();
            
            if (!chooseRoles?.length) {
                return showToast("У вас нет доступа к списку ролей!");
            }

            let chooseSystemRole = chooseRoles.find(xx => xx.paramName == "systemRole");
            let findSystemRole = systemRoles?.find(xx => xx.role.id == Number(chooseSystemRole?.value));
            setChooseSystemRole(findSystemRole);

            let chooseProjectRole = chooseRoles.find(xx => xx.paramName == "projectRole");
            let findProjectRole = projectRoles?.find(xx => xx.role.id == Number(chooseProjectRole?.value));
            setChooseProjectRole(findProjectRole);



            setAllProjectRoles(projectRoles)
            setAllSystemRoles(systemRoles);
        })();
    }

    useEffect(() => {
        getData();
    }, []);

    function closeAddWidget() {
        setAddWidgetShow(!addWidgetShow);
    }

    function closeWidget() {
        setAddEditWidgetShow(!addEditWidgetShow);
        getData();
    }

    return (
        <div className='roles--system__component'>
            {addEditWidgetShow ?
                <div className='roles--system__component--widget__add--edit'>
                    <AddEditRole role={curentRole} mode={"edit"} closeFunc={closeWidget} getData={getData}/>
                </div>
            : addWidgetShow ?
                <div className='roles--system__component--widget__add--edit'>
                    <AddEditRole role={curentRole} mode={"create"} closeFunc={closeAddWidget} getData={getData}/>
                </div>
            :
                <></>
            }
            <div className='roles--system__component__buttons--block'>
                <div className='roles--system__component__buttons--block__size'>
                {cheksTrue ?
                        <div className='roles--system__component__buttons--block__size--hiden'>
                            <BtnDelete onClickFunc={deleteRole} permission={true}/>
                            {editShow ?
                                <button className='roles--system__component__buttons--block__edit' onClick={editRole}>
                                    <img src={EditIcon}/>
                                </button>
                            :
                                <></>
                            }
                        </div>
                    : 
                        <></>
                    }
                    <BtnAdd onClickFunc={addRole} type='add' permission={true}/>
                </div>
            </div>
            <div className='roles--system__component--header' onClick={() => (setShowAllSystemRoles(!showAllSystemRoles))}>
                <div>
                    Системная роль
                </div>
                <button onClick={() => (setShowAllSystemRoles(!showAllSystemRoles))}>
                    <img className={`${showAllSystemRoles ? "" : "right-arrow"}`} src={ArrowDown}/>
                </button>
            </div>
            {showAllSystemRoles && allSystemRoles ? 
                <div className='roles--system__component--roles'>
                    {allSystemRoles.map((xx) => (
                        <div className={`roles--system__component--roles__curent--role ${xx.chek ? 'roles--system__component--roles__curent--role__cheked' : ""}`} onClick={() => (chekRole(xx))}>
                            <div className='roles--system__component--roles__curent--role__input'>
                                <input id={String(xx.role.id)+"-role"} type='checkbox' checked={xx.chek} onClick={() => (chekRole(xx))}/>
                            </div>
                            <div>
                                {xx!.role!.roleName}
                            </div>
                        </div>
                    ))}
                </div>
                : 
                <></>
            }
            <div className='roles--system__component--header' onClick={() => (setShowAllProjectRoles(!showAllProjectRoles))}>
                <div>
                    Проектная роль
                </div>
                <button onClick={() => (setShowAllProjectRoles(!showAllProjectRoles))}>
                    <img className={`${showAllProjectRoles ? "" : "right-arrow"}`} src={ArrowDown}/>
                </button>
            </div>
            {showAllProjectRoles && allProjectRoles ? 
                <div className='roles--system__component--roles'>
                    {allProjectRoles.map((xx) => (
                        <div key={"ProjectRoleAll" + xx.role} className={`roles--system__component--roles__curent--role ${xx.chek ? 'roles--system__component--roles__curent--role__cheked' : ""}`} onClick={() => (chekRole(xx))}>
                            <div className='roles--system__component--roles__curent--role__input'>
                                <input id={String(xx.role.id)+"-role"} type='checkbox' checked={xx.chek} onClick={() => (chekRole(xx))}/>
                            </div>
                            <div>
                                {xx!.role!.roleName}
                            </div>
                        </div>
                    ))}
                </div>
                : 
                <></>
            }
        </div>
    );
};

export default RolesSystem;