import $api from "../index";
import { ISearchFilters, ISearchRequest, ISearchResult } from "../models/ISearch";

export class SearchService {
    async search(body: ISearchRequest) {
        return await $api.post<ISearchResult>(`/search`, body);
    }

    async lightSearch(searchStr: string) {
        return await $api.get<ISearchResult>(`/search?qt=${searchStr}`);
    }

    async getFilters() {
        return await $api.get<ISearchFilters>("/search/filters")
    }
}
