import { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import { ISideBarSubproject } from "../../../models/LeftMenuModels";
import { Link } from "react-router-dom";
import SubprojectIcon from "../../../assets/subproject.svg";
import SubprojectIconBlue from "../../../assets/subproject_blue.svg";
import { Context } from "../../..";
import BoardComponent from "./BoardComponent";
import "./NavigationPanel.css";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { AppContext } from "../../../App";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";

interface ISubprojectComponentProps {
    subproject: ISideBarSubproject;
    showProxyBoards: boolean;
}

const SubprojectComponent: FC<ISubprojectComponentProps> = ({
    subproject,
    showProxyBoards,
}) => {
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const { toggleProject, navBarInfo } = useContext(AppContext);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        setHighlighted(navBarInfo.subprojectId === subproject.id);
    }, [navBarInfo, subproject]);

    const handleClick = () => {
        if (highlighted) toggleProject(subproject.id);
    };

    useLayoutEffect(() => {
        const container = document.getElementById(
            `nav-panel-project-${subproject.id}`
        );
        const name = document.getElementById(
            `nav-panel-project-name-${subproject.id}`
        );

        if (!container || !name) return;

        if (name.offsetWidth > container.clientWidth) setShowTooltip(true);
    }, []);

    return (
        <div className="nav-panel_subproject-container">
            <Tooltip
                text={subproject.name ?? ""}
                offset={5}
                placement={"right"}
                style={{
                    width: "fit-content",
                    maxWidth: "250px",
                    wordBreak: "break-all",
                    display: showTooltip ? "block" : "none",
                }}
            >
                <Link
                    className={`nav-panel_subproject ${
                        highlighted ? "highlighted" : ""
                    } ${
                        navBarInfo.openProjectIds !== undefined &&
                        navBarInfo.openProjectIds?.includes(subproject.id)
                            ? "open"
                            : ""
                    }`}
                    to={"/project/" + subproject.id}
                    onClick={handleClick}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            className="nav-panel_suproject-image"
                            src={
                                highlighted
                                    ? SubprojectIconBlue
                                    : SubprojectIcon
                            }
                            alt="subproject-icon"
                        />
                        <div
                            id={`nav-panel-project-${subproject.id}`}
                            className="nav-panel_label"
                        >
                            <span
                                id={`nav-panel-project-name-${subproject.id}`}
                            >
                                {subproject.name}
                            </span>
                        </div>
                    </div>
                    {subproject.boards ? (
                        <ArrowButton
                            onClick={(e) => {
                                e.preventDefault();
                                toggleProject(subproject.id);
                            }}
                            direction={
                                navBarInfo.openProjectIds !== undefined &&
                                navBarInfo.openProjectIds?.includes(
                                    subproject.id
                                )
                                    ? "bottom"
                                    : "top"
                            }
                        />
                    ) : null}
                </Link>
            </Tooltip>
            {subproject.boards &&
            navBarInfo.openProjectIds !== undefined &&
            navBarInfo.openProjectIds?.includes(subproject.id) ? (
                <div className="nav-panel_subproject_children">
                    {subproject.boards
                        .filter((b) => showProxyBoards || !b.isProxyBoard)
                        .map((board) => (
                            <div
                                className="nav-panel_subproject-board"
                                key={board.id}
                            >
                                <BoardComponent
                                    board={board}
                                    showProxyBoards={showProxyBoards}
                                />
                            </div>
                        ))}
                </div>
            ) : null}
        </div>
    );
};

export default SubprojectComponent;
