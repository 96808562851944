import {createContext, FC, useEffect, useState} from 'react';
import InputChat from "../../Shared/InputChat/InputChat";
import "./TaskChat.css"
import {IHasPermission} from "../../../models/IChekRole";

export const ChatContext = createContext<any>(null);


interface ITaskChat {
    userAccess: IHasPermission[];
    chatId: number;
    projectId: number;
}

const TaskChat: FC<ITaskChat> = ({userAccess, chatId, projectId}) => {

    return (
        <div>
            <InputChat userAccess={userAccess} chatId={chatId} projectId={projectId}/>
        </div>
    );
};

export default TaskChat;