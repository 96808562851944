import { FC, useEffect, useState } from "react";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import Filter, { IFilterLabels } from "./Filter";
import { FilterOptions, SelectedOptions } from "../../../models/IFilterModel";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import "./Filter.css"

interface FilterContainerProps {
    filterCounter: number;
    setFilterCounter: (counter: number) => void;
    selectedOptions: SelectedOptions;
    setSelectedOptions: (options: SelectedOptions) => void;
    filterOptions: FilterOptions | undefined;
    showResetButton?: boolean;
    onReset?: () => void;
    showApplyButton?: boolean;
    onApply?: () => void;
    labels?: IFilterLabels;
}

const FilterContainer: FC<FilterContainerProps> = ({
    filterCounter,
    setFilterCounter,
    selectedOptions,
    setSelectedOptions,
    filterOptions,
    showResetButton,
    onReset,
    showApplyButton,
    onApply,
    labels
}) => {
    useEffect(() => {
        let counter = 0;
        counter += selectedOptions.dates ? 1 : 0;
        counter += selectedOptions.responsibleIds?.length ?? 0;
        counter += selectedOptions.statusIds?.length ?? 0;
        counter += selectedOptions.typeTaskIds?.length ?? 0;
        counter += selectedOptions.priorityIds?.length ?? 0;
        counter += selectedOptions.roleIds?.length ?? 0;
        counter += selectedOptions.fileTypes?.length ?? 0;
        counter += selectedOptions.entityTypes?.length ?? 0;
        counter += selectedOptions.actionTypes?.length ?? 0;
        counter += selectedOptions.projectIds?.length ?? 0;
        setFilterCounter(counter);
    }, [selectedOptions, setFilterCounter]);

    const [openFilter, setOpenFilter] = useState<boolean>(false);

    return (
        <div style={{ display: "flex", position: "relative" }}>
            <BtnFilter
                counter={filterCounter}
                onClick={() => setOpenFilter(!openFilter)}
            />
            {openFilter ? (
                <div>
                    <div
                        className="filter-container"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Filter 
                            options={filterOptions}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            showApplyButton={showApplyButton}
                            onApply={async () => {
                                setOpenFilter(false);
                                if (onApply) await onApply();
                            }}
                            labels={labels}
                        />
                    </div>
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            top: "0",
                            left: "0",
                        }}
                        onClick={() => setOpenFilter(false)}
                    ></div>
                </div>
            ) : null}
            {(filterCounter !== 0 || showResetButton) && (
                <CancelButton
                    onClickFunc={() => {
                        if (onReset) onReset();
                    }}
                />
            )}
        </div>
    );
};

export default FilterContainer;
