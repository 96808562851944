import { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ProxyBoardIcon from "../../../assets/proxy_board_frame.svg";
import ProxyBoardIconWhite from "../../../assets/proxy_board_frame_white.svg";
import BoardIcon from "../../../assets/board_frame.svg";
import BoardIconWhite from "../../../assets/board_frame_white.svg";
import { ISideBarBoard } from "../../../models/LeftMenuModels";
import "./NavigationPanel.css";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { AppContext } from "../../../App";
import Tooltip from "../../UiLib/CustomTooltip/CustomTooltip";

interface IBoardComponentProps {
    board: ISideBarBoard;
    showProxyBoards: boolean;
}

const BoardComponent: FC<IBoardComponentProps> = ({
    board,
    showProxyBoards,
}) => {
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const { toggleBoard, navBarInfo } = useContext(AppContext);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const container = document.getElementById(`nav-panel-board-${board.id}`);  
    const name = document.getElementById(`nav-panel-board-name-${board.id}`);

    useEffect(() => {
        setHighlighted(navBarInfo.boardId === board.id);
    }, [navBarInfo, board]);
    useLayoutEffect(() => {
        if(!container || !name) return 
        if (name.offsetWidth > container.clientWidth){
            setShowTooltip(true)
        }
    })
    

    const handleClick = () => {
        if (highlighted) toggleBoard(board.id);
    };

    if (!showProxyBoards && board.isProxyBoard) return <></>;

    return (
        <div>
            <Tooltip
                text={board.name ?? ""}
                offset={5}
                style={{
                    width: "fit-content",
                    maxWidth: "250px",
                    wordBreak: "break-all",
                    visibility: showTooltip ? "visible" : "hidden",
                }}
                placement={"right"}
            >
                <Link
                    className={`nav-panel_board ${
                        highlighted ? "highlighted" : ""
                    } ${board.isProxyBoard ? "nav-panel_proxy-board" : ""}`}
                    to={"/board/" + board.id}
                    onClick={handleClick}
                
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={
                                highlighted
                                    ? board.isProxyBoard
                                        ? ProxyBoardIconWhite
                                        : BoardIconWhite
                                    : board.isProxyBoard
                                    ? ProxyBoardIcon
                                    : BoardIcon
                            }
                            className="board-icon"
                            alt="board-icon"
                        />
                        <div
                            id={`nav-panel-board-${board.id}`}
                            className="nav-panel_label"
                        >
                            <span id={`nav-panel-board-name-${board.id}`}>
                                {board.name}
                            </span>
                        </div>
                    </div>
                    {board.boards && board.boards.length && showProxyBoards ? (
                        <ArrowButton
                            onClick={(e) => {
                                e.preventDefault();
                                toggleBoard(board.id);
                            }}
                            direction={
                                navBarInfo.openBoardIds !== undefined &&
                                navBarInfo.openBoardIds?.includes(board.id)
                                    ? "bottom"
                                    : "top"
                            }
                        />
                    ) : null}
                </Link>
            </Tooltip>
            {navBarInfo.openBoardIds?.includes(board.id) ? (
                <div>
                    {board.boards?.length ? (
                        <div className="nav-panel_divider">ПРОКСИ-ДОСКИ</div>
                    ) : null}
                    {board.boards?.map((proxyBoard) => (
                        <BoardComponent
                            board={proxyBoard}
                            showProxyBoards={showProxyBoards}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default BoardComponent;
