import { CSSProperties, FC } from "react";
import "./MisceleniousStyles.css";

interface ITaskTagProps {
    color: string;
    name: string;
    style?: CSSProperties;
    className?: string;
}

const TaskTeg: FC<ITaskTagProps> = ({ color, name, style, className }) => {
    return (
        <div
            className={`task-teg-widget nonselectable ${className}`}
            style={{
                backgroundColor: color,
                ...style,
            }}
        >
            <div
                className="task-type-text"
                style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
                {name}
            </div>
        </div>
    );
};
export default TaskTeg;
