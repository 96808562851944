import React, {FC, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import More from "../../../assets/moreGray.svg";
import MoreGray from "../../../assets/moreGrayYes.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import ArrowDown from "../../../assets/arrowDown.svg";
import "../../styles/dashboard.pc.css";
import "../../styles/popUp.pc.css";
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import {BoardContext, IBoardForProxy} from '../../../pages/Board/Board';
import {IColumnDisplay} from '../../../models/IColumnDisplay';
import {IHasPermission} from "../../../models/IChekRole";
import {Context} from "../../../index";
import {AppContext} from "../../../App";
import {IRestrictAccess, IRestrictAccessBoardsColumn} from "../../../models/IRestrictAccess";

export interface IMoreButtonProps {
    onClickFunc: (e: any) => void,
    data: string[],
    typeForKey: string,
    open: boolean,
    setOpen: (open: boolean) => void,
    columnsChoices?: IColumnDisplay[],
    boardChoices?: IBoardForProxy[],
    taskId?: number,
    userAccess: IHasPermission[],
    columnAccess: boolean,
    boardsData?: IRestrictAccess | undefined
}


const BtnMore: FC<IMoreButtonProps> = ({
                                           onClickFunc,
                                           data,
                                           typeForKey,
                                           open,
                                           setOpen,
                                           columnsChoices,
                                           boardChoices,
                                           taskId,
                                           userAccess,
                                           columnAccess,
                                           boardsData
                                       }) => {
    const [showColumns, setShowColumns] = useState<boolean>(false);
    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));
    const {handleMoveTask, handleMoveTaskBoard} =
        useContext(BoardContext);

    const {boardIdNumber} = useContext(BoardContext)
    const [editTask, setEditTask] = useState(false);
    const [columns, setColums] = useState<IRestrictAccessBoardsColumn[]>([]);
    // const [moveTask, setMoveTask] = useState(false);
    const [delTask, setDelTask] = useState(false);
    const {store} = useContext(Context);
    const {showToast} = useContext(AppContext);
    const [clickMoveTask, setClickMoveTask] = useState(true);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelTask(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                })
            }
        })

    }, [userAccess]);

    useEffect(() => {
        let columnsRaw =boardsData?.boards.find(xx => xx.id === boardIdNumber)?.columns
        setColums(columnsRaw ?? [])
    },[showColumns, boardsData])
    return (
        <div key={typeForKey} className="button-base" ref={outsideAlerterRef}
             onMouseLeave={() => setShowColumns(false)}>
            <div onClick={() => (!columnAccess ? setOpen(true) : null)}>
                {columnAccess ?
                    <img src={MoreGray} draggable="false"/>
                    :
                    <img src={More} draggable="false"/>
                }
            </div>
            {open &&
                <div>
                    <div className='popUpWrapper' 
                                    onMouseLeave={() => {
                                        setShowColumns(false);
                                        setClickMoveTask(true);
                                        }}>
                        <div className='popUpContainer'>
                            {data.map(i => (
                                    (i === "Открыть в новой вкладке") ?
                                        <a key={i} className='popUpItem' href={`/board/${boardIdNumber}/task/${taskId}`} target="_blank" style={{textDecoration: "none"}}>
                                            <div className='p_12_popUP'
                                             key={i} >
                                                {i}
                                            </div>
                                        </a>
                                        : (i === "Редактировать") ?
                                            <div key={i} className='popUpItem'
                                                 onClick={(e: any) => (editTask ? onClickFunc(e) : showToast("У вас нет прав для редактирования задачи!"))}>
                                                <div className='p_12_popUP' key={i}>
                                                    {i}
                                                </div>
                                            </div>
                                            : (i === "Удалить") ?
                                                <div key={i} className='popUpItem'
                                                     onClick={(e: any) => (delTask ? onClickFunc(e) : showToast("У вас нет прав для удаления задачи!"))}>
                                                    <div className='p_12_popUP' key={i}>
                                                        {i}
                                                    </div>
                                                </div>
                                                : (i === "Переместить") ?
                                                    (<div key={i} className='popUpItem'>
                                                        <div className='p_12_popUP' key={i}
                                                            onClick={(e: any) => {
                                                                setShowColumns(clickMoveTask);
                                                                setClickMoveTask(!clickMoveTask);
                                                            }}>{i}
                                                            {clickMoveTask ? <img src={ArrowRight} height={"16px"} style={{float: "right"}}/> : <img src={ArrowDown} height={"16px"} style={{float: "right"}}/>}
                                                        </div>
                                                    </div>) : 
                                                    (<div key={i} className='popUpItem'>
                                                        <div className='p_12_popUP' onMouseLeave={() => setShowColumns(false)}
                                                             key={i} onClick={(e: any) => onClickFunc(e)}>{i}</div>
                                                    </div>)
                                )
                            )}
                        </div>
                        {showColumns && taskId ? (
                            <div className="popUpWrapper_inner ">
                                <div className="popUpContainer">
                                    <label className='popUpLabel'>
                                        Переместить в колонку
                                    </label>
                                    {columnsChoices?.map((c) => (
                                        !columns || columns.find(yy => yy.id === c.id)?.columnAcess === false
                                            ?
                                            <div className="popUpItem_inner p_12_popUP"
                                                 onClick={() => handleMoveTask(taskId, c.id, 0)}>
                                                {c.name.length > 20 ? c.name?.slice(0, 20) + "..." : c.name}
                                            </div> : <div></div>
                                    ))}
                                    <label className='popUpLabel'>
                                        На доску
                                    </label>
                                    {boardChoices?.map((b) => (
                                        <div className="popUpItem_inner p_12_popUP"
                                             onClick={() => handleMoveTaskBoard(taskId, b.id)}>
                                            {b.name.length > 20 ? b.name?.slice(0, 20) + "..." : b.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(BtnMore);